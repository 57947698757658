<template>
  <my-case-header title="Case Shipped">
    <p class="small-text mb-0">
      Your case is on the way!<br> <slot/>
    </p>
    <track-shipment-button
      :shipped-package="shippedPackages[0]"
      variant="primary"
      class="mt-3 mb-1 w-50"
      small
      :block="false"/>
  </my-case-header>
</template>

<script>
import MyCaseMixin from '../MyCaseMixin'
import MyCaseHeader from './MyCaseHeader'
import TrackShipmentButton from '../../../global/shipments/TrackShipmentButton'

export default {
  components: {
    MyCaseHeader,
    TrackShipmentButton
  },
  mixins: [MyCaseMixin]
}
</script>
