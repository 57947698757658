<template>
  <div>
    <div v-if="extraStylesItems.length && !pickupAddress && canEdit">
      <app-message
        variant="danger"
        class="small-text mt-2 mb-4 text-center">
        Your case includes extra styles bonus items! Pickup at Armoire Go is required. Please select pickup at Armoire Go.
      </app-message>
    </div>
    <div
      v-if="employeePickup"
      class="small-text mt-2 mb-4 text-center">
      You'll receive an email when your items are ready
      to pick up.
    </div>
    <div v-else-if="pickupAddress">
      <div class="small-text semi-bold">
        Pickup at:
      </div>
      <div class="d-flex justify-content-between">
        <pickup-address
          class="small-text mb-3"
          :pickup-address="pickupAddress"/>
        <text-link
          v-if="canEdit && canChangeShipping"
          @click="setMyCaseFlyoutName('address-selection')">
          Change
        </text-link>
      </div>
    </div>
    <div v-else-if="shippingAddress">
      <div class="small-text mb-1 mt-2 semi-bold">
        {{ shipping }}
      </div>
      <div class="d-flex justify-content-between">
        <my-case-shipping-address
          class="mb-3"
          :shipping-address="shippingAddress"/>
        <text-link
          v-if="canEdit && canChangeShipping"
          @click="setMyCaseFlyoutName('address-selection')">
          Change
        </text-link>
      </div>
    </div>
    <app-message
      v-else
      variant="danger"
      class="mx-n3 mt-n3 mb-3 p-4">
      You must <text-link
        variant="danger"
        size="small-text"
        @click="setMyCaseFlyoutName('address-selection')">
        add or select a shipping address
      </text-link> to confirm your case
    </app-message>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import MyCaseShippingAddress from './MyCaseShippingAddress'
import AppMessage from '../../global/sequin/AppMessage'
import PickupAddress from '../../global/address/PickupAddress'
import TextLink from '../../global/sequin/TextLink'
import MyCaseMixin from './MyCaseMixin'

export default {
  components: {
    MyCaseShippingAddress,
    AppMessage,
    PickupAddress,
    TextLink
  },
  mixins: [MyCaseMixin],
  props: {
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('client', [
      'firstName',
      'lastName',
      'shippingAddress',
      'shippingEligibility',
      'pickupAddress',
      'canChangeShipping'
    ]),
    ...mapGetters('client', [
      'employee',
      'extraStylesItems'
    ]),
    ...mapGetters('case', [
      'selectedShipper'
    ]),
    employeePickup () {
      return this.employee && this.shippingEligibility?.employee.employeeMustPickup
    },
    shipping () {
      if (this.selectedShipper?.type === 'expedited') {
        return `Shipping with ${this.selectedShipper.name} to:`
      }
      return 'Shipping to:'
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ])
  }
}
</script>
