<template>
  <div>
    <app-message
      v-if="priceData && priceData.unallowedPromo"
      variant="info"
      class="p-4 no-padding">
      The value of this promo code exceeds the item price.  If you
      confirm this purchase, the remaining balance
      <b>({{ formatPrice(priceData.unallowedPromo) }})</b>
      will be carried over to a new promo code.
    </app-message>
    <app-message
      v-if="formErrors.length > 0"
      variant="danger"
      class="p-4 no-padding">
      <div
        v-for="(error, index) in formErrors"
        :key="index">
        <span v-html="error"/>
        <br>
      </div>
    </app-message>
    <div class="mt-2 d-flex">
      <img
        class="item-image"
        :class="size"
        :src="styleColor.images.front.url">
      <div class="ms-3 w-100 small-text">
        <div class="line-item">
          <div>
            <span v-if="inlineSizeAndName">{{ shortName }}</span>
            <span v-else>Retail Value</span>
          </div>
          <del>${{ Math.round(styleColor.style.retailPrice) }}</del>
        </div>
        <div class="line-item">
          <div>
            <span v-if="inlineSizeAndName">Size {{ itemToPurchase.trueSize }}</span>
            <span v-else>Price</span>
          </div>
          <div>{{ formatPrice(price) }}</div>
        </div>
        <div
          v-if="appliedPromoCode"
          class="line-item promo mt-2">
          <div>Promo code {{ appliedPromoCode.toUpperCase() }}</div>
          <div>-{{ formatPrice(promoSavings) }}</div>
        </div>
        <div
          v-if="credit"
          class="line-item promo mt-2">
          <div>Account credit</div>
          <div>-{{ formatPrice(credit) }}</div>
        </div>
        <div
          v-if="giftCardRedeem"
          class="line-item promo mt-2">
          <div>Gift card amount</div>
          <div>-{{ formatPrice(giftCardRedeem) }}</div>
        </div>
        <div class="line-item mt-2">
          <div>Tax</div>
          <div>{{ taxDisplay }}</div>
        </div>
        <div class="line-item mt-2">
          <div
            v-if="purchased">
            Paid with {{ defaultPayment.brand }} ending in  {{ defaultPayment.last4 }}
          </div>
          <div
            v-else
            class="total">
            Total
          </div>
          <div
            class="total">
            {{ formatPrice(total) }}
          </div>
        </div>
        <hr
          v-if="remainingCredit !== 0"
          class="w-100">
        <div
          v-if="remainingCredit !== 0"
          class="line-item mt-4 semi-bold text-plum">
          <div>Remaining account credit</div>
          <div>{{ formatPrice(remainingCredit) }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="!purchased"
      class="mt-2 mb-4">
      <div
        v-if="!itemToPurchase.itemPromoEligible"
        class="small-text mt-4 mb-5">
        This item is not eligible for purchase with promo codes
      </div>
      <div v-else>
        <div class="mt-lg d-flex align-items-center justify-content-end">
          <base-input
            v-model.trim="promoCode"
            :disabled="!itemToPurchase.itemPromoEligible"
            class="promo-entry"
            input-id="promoCode"
            label="Gift or promo code"/>
          <sequin-button
            :disabled="disabled || applyPromoDisabled || !itemToPurchase.itemPromoEligible"
            variant="secondary"
            class="ms-3"
            small
            @click="applyPromo">
            Apply
          </sequin-button>
        </div>
        <base-form-errors
          class="small-text m-0 px-1"
          :errors="promoErrors"/>
      </div>
    </div>
    <div
      v-if="!purchased && total > 0">
      <my-case-default-payment-updater/>
    </div>
  </div>
</template>

<script>
import SequinButton from '../../global/sequin/SequinButton'
import { formatPrice } from '@/utils/stringParsing.js'
import MyCaseDefaultPaymentUpdater from '../common/MyCaseDefaultPaymentUpdater'
import AppMessage from '../../global/sequin/AppMessage'
import BaseInput from '@/components/global/BaseInput.vue'
import BaseFormErrors from '@/components/global/BaseFormErrors.vue'

import { mapState, mapActions } from 'vuex'

export default {
  components: {
    MyCaseDefaultPaymentUpdater,
    AppMessage,
    SequinButton,
    BaseInput,
    BaseFormErrors
  },
  props: {
    itemToPurchase: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default',
      validator: value => {
        return value.match(/(large|default)/)
      }
    },
    inlineSizeAndName: {
      type: Boolean,
      default: true
    },
    purchased: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      applyPromoDisabled: false,
      formDisabled: false,
      promoErrors: [],
      formErrors: [],
      promoCode: '',
      appliedPromoCode: null,
      priceData: null
    }
  },
  computed: {
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    ...mapState('client', [
      'defaultPayment'
    ]),
    hasPaymentMethod () {
      return Boolean(this.defaultPayment)
    },
    styleColor () {
      return this.styleColorsMap[this.itemToPurchase.styleColor]
    },
    shortName () {
      return `${this.styleColor.style.brand} ${this.styleColor.style.subcategory}`
    },
    taxDisplay () {
      if (this.priceData === null) { return '---' }
      return '$' + (this.priceData.tax / 100).toFixed(2)
    },
    remainingCredit () {
      if (this.priceData === null) { return 0 }
      return this.priceData.remainingCredit
    },
    credit () {
      if (this.priceData === null) { return 0 }
      return this.priceData.credit
    },
    giftCardRedeem () {
      if (this.priceData === null) { return 0 }
      return this.priceData.gcRedeem
    },
    price () {
      if (this.priceData === null) { return this.itemToPurchase.priceClient * 100 }
      return this.priceData.client
    },
    total () {
      // this.itemToPurchase.priceClient needs a multiplier to make it equivalent to this.priceData.chargeAmount...
      if (this.priceData === null) { return this.itemToPurchase.priceClient * 100 }
      return this.priceData.chargeAmount
    },
    promoSavings () {
      if (this.priceData === null) { return 0 }
      return this.priceData.client - this.priceData.promo
    }
  },
  mounted () {
    this.$emit('total-updated', this.total, this.remainingCredit)
    this.getItemPrice({ itemPk: this.itemToPurchase.id }).then((result) => {
      this.priceData = result
      this.$emit('total-updated', this.total, this.remainingCredit)
    })
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    ...mapActions('client', [
      'buyPackageItem',
      'getItemPrice'
    ]),
    formatPrice,
    async applyPromo () {
      this.applyPromoDisabled = true
      this.formErrors = []
      this.priceData = null
      try {
        this.priceData = await this.getItemPrice({
          itemPk: this.itemToPurchase.id,
          promoCode: this.promoCode
        })
        this.appliedPromoCode = this.promoCode
        this.promoErrors = []
        this.$emit('applied-promo', this.appliedPromoCode)
        this.$emit('total-updated', this.total)
      } catch (errors) {
        this.promoErrors = errors
        this.appliedPromoCode = ''
      }
      this.promoCode = ''
      this.applyPromoDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
.item-image {
  height: 64px;
  width: 64px;
  border: $default-border;
  object-fit: contain;
  background: $white;

  &.large {
    height: 128px;
    width: 128px;
  }
}

.line-item {
  display: flex;
  justify-content: space-between;
}

.promo {
  color: $success-light;
  font-weight: $font-weight-bold;
}

.promo-entry {
  width: 100%;
  :global(.form-control) {
    border-radius: $sequin-border-radius !important;
  }
}

.total {
  font-weight: $font-weight-bold;
}

.no-padding {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
}

</style>
