<template>
  <p><b>Bra Size:</b> {{ braSizeBand }}{{ braSizeCup }}</p>
  <p><b>Dress Sizes:</b> {{ dressSizes }}</p>
  <p><b>Jumpsuit Sizes:</b> {{ jumpsuitSizes }}</p>
  <p><b>Pant Sizes:</b> {{ pantSizes }}</p>
  <p><b>Waist Sizes:</b> {{ waistSizes }}</p>
  <p><b>Shirt Sizes:</b> {{ shirtSizes }}</p>
</template>
<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const braSizeBand = computed(() => store.state.styleProfile.braSizeBand)
const braSizeCup = computed(() => store.state.styleProfile.braSizeCup)
const dressSizes = computed(() => store.state.styleProfile.dressSizes.join(', '))
const jumpsuitSizes = computed(() => store.state.styleProfile.jumpsuitSizes.join(', '))
const pantSizes = computed(() => store.state.styleProfile.pantSizes.join(', '))
const waistSizes = computed(() => store.state.styleProfile.waistSizes.join(', '))
const shirtSizes = computed(() => store.state.styleProfile.shirtSizes.join(', '))
</script>
