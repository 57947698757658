<template>
  <div class="p-xs">
    <p>What type of closet are you stying?</p>
    <div class="d-flex justify-content-center gap-sm pt-md">
      <BasePill
        v-for="type in closetTypes"
        :key="type.value"
        :model-value="stylistInitiated"
        :value="type.value"
        :text="type.text"
        type="radio"
        name="closetType"
        @update:modelValue="createStylingRequest"/>
    </div>
  </div>
</template>
<script setup>
import BasePill from '@shared/components/ADORN/BasePill.vue'
import { useStylingStore } from '@/stores/styling'
import { storeToRefs } from 'pinia'

const store = useStylingStore()
const { createStylingRequest } = store
const { stylistInitiated } = storeToRefs(store)

const closetTypes = [
  { value: true, text: 'Proactive' },
  { value: false, text: 'Reactive' }
]

</script>
