<template>
  <AccordionWithHeader
    title="Write Note To Client"
    class="stylist-note"
    :expanded="stylistNoteExpanded"
    :border="false"
    @accordion-click="toggleStylistNoteExpanded">
    <BaseTextArea
      v-model="stylingNote"
      class="mt-xs"
      rows="5"/>
    <p class="d-flex justify-content-end fine-print text-pewter font-italic">
      {{ noteStatus }}
    </p>
    <StylingBaseButton
      text="Save Note"
      @click="saveStylingNote(stylingNote)"/>
  </AccordionWithHeader>
</template>
<script setup>
import { computed, ref } from 'vue'
import BaseTextArea from '@/components/global/BaseTextArea.vue'
import AccordionWithHeader from '@/components/global/sequin/AccordionWithHeader.vue'
import StylingBaseButton from '@/components/styling/StylingBaseButton.vue'
import { useStylingStore } from '@/stores/styling'

const stylistNoteExpanded = ref(true)
const toggleStylistNoteExpanded = () => {
  stylistNoteExpanded.value = !stylistNoteExpanded.value
}

const store = useStylingStore()
const { saveStylingNote } = store

const stylingNote = ref(store.stylingNote)

const noteStatus = computed(() => {
  return stylingNote.value === store.stylingNote ? 'Saved' : 'Editing'
})

</script>
<style lang="scss" scoped>
.stylist-note {
  background-color: rgba($orchid-light, 0.1);
  height: 100%;
}
</style>
