<template>
  <edit-delete-menu
    item-name="Post"
    variant="right"
    :can-edit="false"
    @delete-click="onDelete"
    @cancel-click="closeModal"/>
</template>

<script>
import EditDeleteMenu from './EditDeleteMenu'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    EditDeleteMenu
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal',
      'openModal'
    ]),
    async onDelete () {
      this.openModal({ name: 'community-delete-confirmation' })
    }
  }
}
</script>
