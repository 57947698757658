<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        no-padding
        back-button
        @back-click="backClick()">
        <template #header>
          <h5 class="mb-0">
            {{ addOnItemViewing.longTitle }}
          </h5>
        </template>
        <template #body>
          <my-case-item-photo-scroller
            :images="images"/>
          <div
            class="p-3">
            <div
              v-if="addOnItemViewing.brand"
              class="small-text">
              {{ addOnItemViewing.brand.name }}
            </div>
            <div>
              <h6>{{ addOnItemViewing.longTitle }}</h6>
            </div>
            <div
              class="py-1">
              <span v-if="price">
                {{ formatPrice(price, false, false, true) }}
              </span>
              <span v-else>
                From {{ formatPrice(minPrice) }} to {{ formatPrice(maxPrice) }}
              </span>
            </div>
            <div
              v-if="addOnItemViewing.colors[selectedColorIndex].name">
              <div class="fine-print d-flex justify-content-between mt-1 py-1">
                <div
                  class="semi-bold">
                  Color: {{ addOnItemViewing.colors[selectedColorIndex].name }}
                </div>
                <div
                  v-if="addOnDetailsShowAddToCase"
                  class="text-danger">
                  {{ getLowAvailability(selectedType) }}
                </div>
              </div>
              <div class="d-flex justify-content-start pt-1">
                <color-picker
                  v-for="(color, index) in addOnItemViewing.colors"
                  :key="index"
                  :selected="index === selectedColorIndex"
                  :color="color"
                  :clickable="addOnDetailsShowAddToCase"
                  :unavailable="!getAvailableForSelectedType(color)"
                  @click="onColorClick(color, index)"/>
              </div>
            </div>
            <div
              class="mt-3">
              <div v-if="showSize">
                <add-on-item-size-select
                  v-if="addOnDetailsShowAddToCase"
                  v-model="selectedType"
                  :style-color="styleColor"/>
                <div v-else>
                  Size: {{ selectedType }}
                </div>
              </div>
            </div>
            <div class="pt-3">
              <span
                class="small-text"
                v-html="parseMarkdown(addOnItemViewing.shortDescription)"/>
            </div>
            <div
              v-for="(attribute, index) in addOnItemViewing.attributes"
              :key="index"
              class="small-text">
              <i>
                <span v-if="attribute.name">
                  <b>{{ attribute.name }}:</b>
                </span>
                {{ attribute.description }}
              </i>
            </div>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <div class="d-flex justify-content-between">
        <quantity-picker
          v-if="addOnDetailsShowAddToCase"
          :initial-value="addOnItemTypeInitialQuantity"
          :disabled="!addAllowed"
          :max-value="maxQuantity"
          class="w-50 me-2"
          @click="onQuantityChange"/>
        <div class="w-50 ms-2">
          <sequin-button
            v-if="addOnDetailsShowAddToCase"
            :disabled="!addAllowed"
            block
            @click="addToCase()">
            <span v-if="addOnItemTypeInitialQuantity">
              Update
            </span>
            <span
              v-else>
              Add to
            </span>
            case
          </sequin-button>
        </div>
      </div>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import { parseMarkdown, formatPrice } from '@/utils/stringParsing.js'
import SequinButton from '../../global/sequin/SequinButton'
import QuantityPicker from '../../global/sequin/QuantityPicker'
import ColorPicker from './ColorPicker'
import AddOnItemSizeSelect from './AddOnItemSizeSelect'
import AddOnItemsMixin from './AddOnItemsMixin'
import MyCaseItemPhotoScroller from '../common/MyCaseItemPhotoScroller.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ColorPicker,
    AddOnItemSizeSelect,
    MyCaseFlyoutComponent,
    MyCaseItemPhotoScroller,
    MyCaseSection,
    SequinButton,
    QuantityPicker
  },
  mixins: [AddOnItemsMixin],
  data () {
    return {
      selectedColorIndex: 0,
      selectedType: null,
      initialQuantity: null,
      quantity: 1
    }
  },
  computed: {
    ...mapState('case', [
      'addOnItemViewing',
      'addOnItemTypeViewing',
      'addOnItemTypeInitialQuantity',
      'addOnDetailsShowAddToCase',
      'addOnItemsToPurchase',
      'purchasedAddOnItems'
    ]),
    addAllowed () {
      return this.styleType
    },
    showSize () {
      return this.sizeOptions.length > 0
    },
    styleColor () {
      return this.addOnItemViewing.colors[this.selectedColorIndex]
    },
    styleType () {
      const filtered = this.styleColor.sizes.filter(s => s.size === this.selectedType)
      if (filtered.length === 1) { return filtered[0] }
      return null
    },
    images () {
      return this.styleColor.images
    },
    price () {
      if (this.styleType) { return this.styleType.price }
      return null
    },
    sizeOptions () {
      return this.styleColor.sizes.filter(sz => sz.size !== null && sz.currentStock > 0).map(sizeOption => {
        return {
          value: sizeOption.size,
          disabled: false,
          label: sizeOption.size
        }
      })
    },
    updating () {
      return this.addOnItemTypeViewing
    }
  },
  mounted () {
    if (this.updating) {
      const styleColor = this.addOnItemViewing.colors.filter(color => {
        return color.sizes.filter(size => size.id === this.addOnItemTypeViewing).length > 0
      })[0]
      this.selectedColorIndex = this.addOnItemViewing.colors.findIndex(e => e.id === styleColor.id)
      const type = styleColor.sizes.filter(size => size.id === this.addOnItemTypeViewing)[0]
      this.selectedType = type.size
    }
  },
  methods: {
    ...mapActions('case', [
      'getCasePrice',
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName',
      'updateAddOnItemsQuantity'
    ]),
    parseMarkdown,
    formatPrice,
    getAvailableForSelectedType (color) {
      if (this.selectedType === null) { return true }
      return color.sizes.filter(size => size.size === this.selectedType).length > 0
    },
    onQuantityChange (number) {
      this.quantity = number
    },
    addToCase () {
      this.updateAddOnItemsQuantity({
        addOnTypeId: this.styleType.id,
        quantity: this.quantity
      })
      this.getCasePrice()
      this.setMyCaseFlyoutName('confirm-case')
    },
    backClick () {
      this.setMyCaseFlyoutName('confirm-case')
    },
    onColorClick (color, index) {
      if (this.addOnDetailsShowAddToCase) {
        this.selectedColorIndex = index
        if (color.sizes.filter(size => size.size === this.selectedType).length === 0) {
          this.selectedType = null
        }
      }
    },
    getLowAvailability (size) {
      const sizeData = this.styleColor.sizes.filter(s => s.size === size)
      if (sizeData.length === 1 && sizeData[0].currentStock) {
        return `Only ${sizeData[0].currentStock} left!`
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.size-dropdown {
  width: 100%;
}

 </style>
