<template>
  <div class="text-container m-xs">
    <BaseTextArea
      v-model="newClientNote"
      class="text-box"
      placeholder="Add new client note here."
      rows="1"/>
    <SequinButton
      block
      variant="secondary"
      class="save-button"
      @click="addClientNote(newClientNote)">
      Save
    </SequinButton>
  </div>
  <div class="note">
    <div
      v-for="note in sortedClientNotes"
      :key="note.id"
      class="note-container p-xs">
      <div class="note-text p-xs">
        <b>{{ note.createdBy }}</b>
        <p class="comment-text">
          {{ note.note }}
        </p>
        <FeedItemTimestamp
          :timestamp="note.createdAt"
          condensed/>
      </div>
      <SequinButton
        block
        class="delete-button"
        variant="danger"
        @click="deleteClientNote(note.id)">
        Delete
      </SequinButton>
    </div>
  </div>
</template>
<script setup>
import { useStylingStore } from '@/stores/styling'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import BaseTextArea from '@/components/global/BaseTextArea.vue'
import SequinButton from '@/components/global/sequin/SequinButton.vue'
import FeedItemTimestamp from '@/components/community/feed/FeedItemTimestamp.vue'

const store = useStylingStore()

const { clientNotes, newClientNote } = storeToRefs(store)
const { addClientNote, deleteClientNote } = store
const sortedClientNotes = computed(() => clientNotes.value.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))

</script>
<style lang="scss" scoped>
.note {
    height: 125px
  }
.note-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  background-color: rgba($orchid, 0.1);
  border-radius: 4px;
}
.note-text {
  flex: 4;
}
.comment-text {
  display: inline;
  color: $pewter;
  margin-left: 6px;
  word-break: break-word;
  overflow-wrap: break-word;
}

.delete-button {
  flex: 1;
  border: none;
  background: none;
  font-size: 0.7rem;
  padding: 0;
  cursor: pointer;
}
.text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-box {
  flex: 4;
}
.save-button {
  flex: 1;
  border: none;
  background: none;
  font-size: 0.7rem;
  padding: 0;
  cursor: pointer;
}
</style>
