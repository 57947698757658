<template>
  <collection-feed-item
    :preview-images="collectionImages"
    :item-count="styleColorCountFromCollectionId(collection.id)"
    :name="collection.name"/>
</template>

<script>
import { mapGetters } from 'vuex'
import CollectionFeedItem from './CollectionFeedItem.vue'

export default {
  components: {
    CollectionFeedItem
  },
  props: {
    collection: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('collections', [
      'styleColorsFromCollectionId',
      'styleColorCountFromCollectionId'
    ]),
    collectionImages () {
      return this.styleColorsFromCollectionId(this.collection.id).slice(0, 3).map(sc => sc.images.front.url)
    }
  }
}
</script>
