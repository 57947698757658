<template>
  <div class="small-text">
    <!-- PICKUP OPTIONS -->
    <!-- SHIPPING OPTIONS -->
    <div
      v-if="!validShippingOptions || validShippingOptions.length === 0 || updatingAddress"
      class="mt-2">
      Calculating shipping options...
      <div class="mt-3 d-flex justify-content-center">
        <svg-spinner/>
      </div>
    </div>
    <div
      v-else>
      <div class="mb-1 mt-2 font-weight-bold">
        Select a shipping option:
      </div>
      <div
        v-for="option in validShippingOptions"
        :key="option.id"
        class="mt-1">
        <base-radio
          v-model="selectedShippingId"
          :initial-value="option.id"
          name="shipping"
          :text="getOptionDescription(option)"
          sequin
          @update:modelValue="onShippingChange(option)"/>
        <div
          class="ps-3 aligner">
          <div
            v-if="option.deliveryBy === null"
            class="text-pewter">
            Usually arrives in 2-5 business days
          </div>
          <div
            v-if="option.guaranteedDelivery && !(option.discountedCharge === 0 || option.charge === 0)"
            class="text-pewter">
            Guaranteed by {{ deliveryTime(false, option) }}
            <base-tooltip
              class="tooltip">
              <template #trigger>
                <svg-circle-icon
                  class="ms-1 mb-2"
                  variant="neutral"
                  :height="18"
                  :width="18">
                  <svg-question-mark/>
                </svg-circle-icon>
              </template>
              <template #content>
                <span>
                  Your fee can be refunded if your package does
                  not arrive by the guaranteed delivery date.
                </span>
              </template>
            </base-tooltip>
          </div>
          <div
            v-else-if="option.deliveryBy !== null">
            Arriving {{ deliveryTime(false, option) }}
          </div>
          <div
            v-if="option.discountedCharge !== null && option.discountedCharge !== option.charge"
            class="mb-3">
            <span
              class="strike-through me-1">
              {{ formatPrice(option.charge, true) }}
            </span>
            <span
              v-if="option.discountedCharge === 0"
              class="text-info-dark">
              FREE
            </span>
            <span
              v-else>
              {{ formatPrice(option.discountedCharge, true) }}
            </span>
          </div>
          <div
            v-else-if="option.charge > 0"
            class="semi-bold">
            {{ formatPrice(option.charge) }}
          </div>
          <div
            v-if="selectedShippingId === option.id">
            <div
              v-if="option.chargeCalculationType === 'weight_and_location'"
              class="fine-print mt-1 text-pewter pe-3">
              Cost is based on your location and package weight,
              and is subsidized by Armoire!
            </div>
          </div>
          <div
            v-if="option.phoneNumberRequired"
            class="pe-3">
            <base-input
              v-model="phone"
              type="tel"
              prepend="+1-"
              :validations="v$.phone"
              :cleave-options="{
                delimiter: '-',
                numericOnly: true,
                blocks: [3, 3, 4]}"
              label="Phone"
              @blur="handlePhoneChange()"/>
          </div>
          <div
            v-if="option.deliveryInstructionsSupported"
            class="pe-3">
            <base-text-area
              v-model="newDeliveryInstructions"
              :validations="v$.newDeliveryInstructions"
              label="Delivery Instructions"
              placeholder="Delivery Instructions"
              @blur="handleDeliveryChange()"/>
            <div class="fine-print text-gray mt-1">
              Required for apartments or locations with restricted access
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { vuexAccessors } from '../../global/helpers/vuex'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required } from '@vuelidate/validators'
import { phone } from '../../global/vuelidate/customValidators'
import { mapActions, mapState, mapGetters } from 'vuex'
import { formatPrice } from '@/utils/stringParsing.js'
import MyCaseMixin from '../common/MyCaseMixin'
import useAnalytics from '@shared/composables/analytics.js'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgQuestionMark from '@/components/global/svg/SvgQuestionMark.vue'
import BaseRadio from '@/components/global/BaseRadio.vue'
import BaseTooltip from '@/components/global/BaseTooltip.vue'
import BaseInput from '@/components/global/BaseInput.vue'
import BaseTextArea from '@/components/global/BaseTextArea.vue'

export default {
  components: {
    SvgSpinner,
    SvgCircleIcon,
    SvgQuestionMark,
    BaseRadio,
    BaseTooltip,
    BaseInput,
    BaseTextArea
  },
  mixins: [MyCaseMixin],
  setup () {
    const { track } = useAnalytics()
    return {
      track,
      v$: useVuelidate()
    }
  },
  data () {
    return {
      newDeliveryInstructions: '',
      phone: ''
    }
  },
  computed: {
    ...mapState('case', [
      'selectedShippingId'
    ]),
    ...mapGetters('case', [
      'validShippingOptions'
    ]),
    ...mapState('client', [
      'deliveryInstructions',
      'mainPhone'
    ]),
    ...mapState('account', [
      'updatingAddress'
    ]),
    ...vuexAccessors('case', [
      'selectedShippingId'
    ])
  },
  mounted: async function () {
    this.newDeliveryInstructions = this.deliveryInstructions
    this.phone = this.mainPhone.replace(/^\+1-/, '')
    this.$logger.debug('mounted with phone ', this.phone)
    await this.getShippingOptions()
    // set the default: expedited if it's free, otherwise standard
    const freeExpeditedOptions = this.validShippingOptions.filter((x) => {
      return (x.type === 'expedited' && (x.discountedCharge === 0 || x.charge === 0))
    })
    if (freeExpeditedOptions.length > 0) {
      this.selectedShippingId = freeExpeditedOptions[0].id
    } else {
      const standardOptions = this.validShippingOptions.filter(x => x.type === 'standard')
      if (standardOptions.length > 0) {
        // this should always be the case
        this.selectedShippingId = standardOptions[0].id
      }
    }
    this.track('Viewed Checkout Shipping Options')
  },
  methods: {
    ...mapActions('case', [
      'getShippingOptions'
    ]),
    ...mapActions('client', [
      'updateClient'
    ]),
    formatPrice,
    getOptionDescription (option) {
      if (option.type === 'expedited') {
        return `Expedited via ${option.name}`
      }
      return 'Standard'
    },
    handlePhoneChange () {
      this.$logger.debug('handlePhoneChange to ', this.phone)
      if (this.phone !== this.mainPhone) {
        this.updateClient({ mainPhone: this.phone })
      }
    },
    handleDeliveryChange () {
      this.$logger.debug('handleDeliveryChange to ', this.newDeliveryInstructions)
      if (this.newDeliveryInstructions !== this.deliveryInstructions && !this.v$.newDeliveryInstructions.$invalid) {
        this.updateClient({ deliveryInstructions: this.newDeliveryInstructions })
      }
    },
    onShippingChange (option) {
      this.$logger.debug('Shipping option changed to ', option)
      this.selectedShippingId = option.id
    }
  },
  validations: {
    phone: {
      required,
      phone
    },
    newDeliveryInstructions: {
      maxLength: maxLength(255)
    }
  }
}
</script>

<style lang="scss">

.aligner {
  margin-left: 15px;
  margin-top: -5px
}

</style>
