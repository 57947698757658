import { useExperimentsStore } from '@shared/stores/experiments.js'
import { useClientStore } from '@shared/stores/client.js'
import { toPascalCase } from '@/utils/stringParsing'
import logger from '@/logger'
import { useRoute } from 'vue-router'
import { useStyleProfileStore } from '@/stores/styleProfile.js'

export default function useAnalytics () {
  const { getAnalyticsData } = useExperimentsStore()
  const client = useClientStore()
  const styleProfile = useStyleProfileStore()

  const route = useRoute()

  async function track (eventName, data = {}) {
    if (typeof window.analytics !== 'undefined') {
      try {
        const experimentData = await getAnalyticsData()
        const eventProperties = {
          ...experimentData,
          ...data
        }
        if (client?.email) {
          if (!client.reportEvents) return
          eventProperties.email = client.email
        }
        logger.debug('track() analytics composable', eventName, eventProperties)
        window.analytics.track(eventName, eventProperties)
      } catch (e) {
        logger.error('track() error', e)
      }
    }
  }

  async function trackPageView (pageName, data = {}) {
    await track(`Viewed ${pageName}`, data)
  }

  async function trackClickEvent (appEntryPoint, label) {
    await track(`Clicked ${toPascalCase(appEntryPoint)} ${label}`)
  }

  async function trackSignUpPageView (name = null) {
    const pageName = name ?? (route.name ? toPascalCase(route.name) : null)
    if (pageName) {
      await trackPageView(pageName, {
        isReferral: !!route.query.r,
        birthDate: styleProfile.birthDate,
        braSizeBand: styleProfile.braSizeBand,
        braSizeCup: styleProfile.braSizeCup,
        heightFeet: styleProfile.heightFeet,
        heightInches: styleProfile.heightInches,
        signupReasons: styleProfile.signupReasons,
        signupEvents: styleProfile.signupEvents,
        avoids: styleProfile.avoids
      })
    }
  }

  return {
    track,
    trackPageView,
    trackClickEvent,
    trackSignUpPageView
  }
}
