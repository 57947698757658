<template>
  <base-modal
    :full-screen="isMobile"
    :hide-close="isMobile"
    use-title-border
    :center-title="!isMobile"
    flush-content>
    <template #title>
      <h5>
        <plain-button
          v-if="isMobile"
          class="back-button"
          @click="closeModal()">
          <svg-chevron-left/>
        </plain-button>
        <div
          class="text-center">
          Where did you wear this?
        </div>
      </h5>
    </template>
    <template #body>
      <div>
        <div
          class="text-center text-pewter smaller bg-origami py-2">
          Select all that apply
        </div>
        <div>
          <plain-button
            v-for="tag in review.occasionTags"
            :key="tag.tag"
            class="occasion-tag-btn"
            @click="tagOccasion(tag)">
            <occasion-tag
              :tag="tag"
              :show-remove="tag.selected"
              inner-padding
              :variant="tag.selected ? 'reverse' : 'default'"/>
          </plain-button>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-100 px-2 border-top p-3">
        <sequin-button
          :class="{ 'mb-2': isMobile }"
          block
          @click="closeModal()">
          Save
        </sequin-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapState as mapVuexState, mapActions as mapVuexActions } from 'vuex'
import { mapActions, mapState } from 'pinia'
import BaseModal from '@/components/global/BaseModal'
import SequinButton from '../global/sequin/SequinButton'
import PlainButton from '../global/sequin/PlainButton'
import OccasionTag from '../community/sharelook/OccasionTag'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'

export default {
  components: {
    BaseModal,
    OccasionTag,
    PlainButton,
    SequinButton,
    SvgChevronLeft
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('review', [
      'reviews'
    ]),
    review () {
      return this.reviews[this.modalContext.review.itemId]
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    ...mapVuexActions('review', [
      'updateOccasionTags'
    ]),
    tagOccasion (tag) {
      this.updateOccasionTags({
        tagId: tag.id,
        itemId: this.modalContext.review.itemId
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.occasion-tag-btn {
  width: 100%;
  padding: 0 0;
  border-top: $default-border;
  background-color: $white;
}

.back-button {
  padding: 12px 12px;
  position: absolute;
  top: 4px;
}

</style>
