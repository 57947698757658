<!-- Wrapper container for toast notifications -->
<template>
  <div
    id="toast-notifications"
    :class="classes">
    <transition-group
      name="toast-notification-list"
      tag="div"
      class="toast-notification-list">
      <toast-notification-list-item
        v-for="(notification) in toastNotifications"
        :key="notification.id"
        :notification="notification"/>
    </transition-group>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import ToastNotificationListItem from './ToastNotificationListItem.vue'
import { useToastNotificationsStore } from '@/stores/toastNotifications'
import { computed } from 'vue'
import { useElementPresence } from '@/composables/element.js'
import useScreenSize from '@shared/composables/screenSize.js'

const toastNotificationsStore = useToastNotificationsStore()
const { toastNotifications } = storeToRefs(toastNotificationsStore)

const floatingButtonsPresent = useElementPresence('.floating-buttons')

const { isMobile } = useScreenSize()
const classes = computed(() => {
  return {
    'mobile mx-4': isMobile.value,
    'above-floating-buttons': isMobile.value && floatingButtonsPresent.value
  }
})
</script>

<style lang="scss" scoped>
#toast-notifications {
  position: absolute;
  right: 0;
  z-index: $zindex-fixed;
  width: 425px;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  --toast-notifications-bottom: 24px;

  &.mobile {
    position: fixed;
    left: 0;
    right: 0;
    bottom: var(--toast-notifications-bottom);
    margin-left: auto;
    margin-right: auto;
    width: unset;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));

    .toast-notification-list {
      &-enter-from,
      &-leave-to {
        transform: translateY(100%);
      }
    }
  }

  &.above-floating-buttons {
    bottom: calc(var(--toast-notifications-bottom) + 120px);
  }

  .toast-notification-list {
    --animation-speed: 0.4s;

    &-move,
    &-enter-active,
    &-leave-active {
      transition: all var(--animation-speed) ease;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateX(100%);
    }

    &-leave-active {
      position: absolute;
      width: 100%;
    }
  }
}
</style>
