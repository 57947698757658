<template>
  <div>
    <div
      class="d-flex"
      :class="{'in-case': quantity > 0 && !confirmed && variant === 'primary', 'p-3': !confirmed, 'py-2': confirmed}">
      <div
        class="position-relative">
        <div
          class="pointer"
          @click="viewDetails">
          <my-case-item-image
            :image-url="representativeImageUrl"/>
        </div>
      </div>
      <div
        class="ms-4 w-100">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div
              v-if="variant === 'secondary'"
              class="add-on-title small-text">
              Add-On Purchase
            </div>
            <span
              class="pointer"
              @click="viewDetails">
              <u>{{ brand }} {{ title }}</u>
            </span>
            <br>
            <div
              class="small-text">
              <div class="text-pewter">
                {{ sizeColor }}
              </div>
              <div
                v-if="!confirmed && variant === 'primary'"
                class="add-on-price">
                +{{ formatPrice(styleType.price * quantity) }} add-on item<span v-if="quantity > 1">s</span>
              </div>
              <span class="small-text">
                <div
                  v-if="!confirmed"
                  class="my-1 pewter">
                  <quantity-picker
                    :initial-value="quantity"
                    :min-value="0"
                    :max-value="maxQuantity"
                    class="me-2 picker"
                    @click="onQuantityChange"/>
                </div>
              </span>
            </div>
          </div>
          <div
            v-if="variant === 'secondary'"
            class="semi-bold small-text">
            {{ formatPrice(styleType.price * quantity, true) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { formatPrice } from '@/utils/stringParsing.js'
import AddOnItemsMixin from './AddOnItemsMixin'
import MyCaseItemImage from '../common/MyCaseItemImage'
import QuantityPicker from '../../global/sequin/QuantityPicker'

export default {
  components: {
    MyCaseItemImage,
    QuantityPicker
  },
  mixins: [AddOnItemsMixin],
  props: {
    typeId: {
      type: String,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    },
    confirmed: {
      type: Boolean,
      default: false
    },
    backClick: {
      type: String,
      default: 'confirm-case'
    },
    variant: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(/(primary|secondary)/)
      }
    }
  },
  computed: {
    ...mapState('case', [
      'availableAddOnItems'
    ]),
    addOnItem () {
      const items = this.availableAddOnItems.filter(item => {
        const colors = item.colors.filter(color => {
          return color.sizes.filter(size => size.id === this.typeId).length === 1
        })
        return colors.length === 1
      })
      return items[0]
    },
    styleColor () {
      const styleColors = this.addOnItem.colors.filter(color => {
        return color.sizes.filter(size => size.id === this.typeId).length === 1
      })
      return styleColors[0]
    },
    styleType () {
      const styleTypes = this.styleColor.sizes.filter(size => size.id === this.typeId)
      return styleTypes[0]
    },
    sizeColor () {
      const size = this.styleType.size ? `Size ${this.styleType.size}` : ''
      let name = ''
      if (this.styleColor.name) {
        name = size.length > 0 ? `, ${this.styleColor.name}` : `${this.styleColor.name}`
      }
      return `${size}${name}`
    }
  },
  methods: {
    ...mapActions('case', [
      'getCasePrice',
      'updateAddOnItemsQuantity',
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName'
    ]),
    formatPrice,
    viewDetails () {
      this.setMyCaseFlyoutContext({
        addOnDetailsBackClick: this.backClick,
        addOnItemViewing: this.addOnItem,
        addOnItemTypeViewing: this.typeId,
        addOnItemTypeInitialQuantity: this.quantity,
        addOnDetailsShowAddToCase: this.backClick === 'confirm-case'
      })
      this.setMyCaseFlyoutName('add-on-details')
    },
    onQuantityChange (number) {
      this.updateAddOnItemsQuantity({
        addOnTypeId: this.styleType.id,
        quantity: number
      })
      this.getCasePrice()
    }
  }
}

</script>

<style lang="scss" scoped>

.in-case {
  background: rgba($info-light, .05);
}

.add-on-title {
  color: $info-dark;
}

.add-on-price {
  color: $info-dark;
  font-weight: $font-weight-semibold;
}

.picker {
  width: 100px;
}

 </style>
