<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        no-padding
        back-button
        @back-click="backClick()">
        <template #header>
          <h6 class="mb-0">
            {{ styleColor.style.name }}
          </h6>
        </template>
        <template
          #body>
          <photo-snap-scroll
            :length="allImages.length"
            :width="370"
            always-scroll>
            <template #scrollable>
              <preview-image
                v-for="image in allImages"
                :key="image.url"
                class="d-flex align-items-center scroll-item position-relative"
                size="xx-large"
                :src="image.url">
                <template #overlay>
                  <div class="position-absolute rented-tag">
                    <sequin-tag
                      class="mb-3">
                      Previously Rented
                    </sequin-tag>
                  </div>
                </template>
              </preview-image>
            </template>
          </photo-snap-scroll>
          <div
            class="p-3 smaller">
            <br>
            {{ brand }}
            <br>
            <span class="semi-bold">
              {{ styleColor.style.name }}
            </span>
            <div
              class="text-pewter small-text">
              Size {{ item.trueSize }}
            </div>
            <div
              class="text-pewter small-text">
              <span class="strike-through">
                {{ formatPrice(price, true, true) }}
              </span>
            </div>
            <span class="semi-bold">
              {{ formatPrice(item.priceClient, true, true) }}
            </span>
            <span class="text-success semi-bold ms-1">
              {{ getPercentOff(item.priceClient) }}% off
            </span>
            <ul class="ps-4 pt-3">
              <li v-if="color">
                {{ color }}
              </li>
              <li v-if="materials && materials.length">
                {{ formattedMaterialsList(materials) }}
              </li>
              <li v-if="fitType">
                {{ fitType }}
              </li>
              <li
                v-if="sizeGuideUrl"
                class="details size-guide">
                <a
                  :href="sizeGuideUrl"
                  target="_blank">
                  Size Chart
                </a>
              </li>
              <li v-if="inseam">
                Inseam: {{ inseam }} inches
              </li>
              <li v-if="rise">
                Rise: {{ rise }} inches
              </li>
            </ul>
            <closet-review
              v-for="(review, index) in clientReviews"
              :key="'review-' + index"
              :review="review"/>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <div class="d-flex justify-content-between">
        <div class="w-100 ms-2">
          <sequin-button
            block
            @click="addOrRemoveToCase()">
            <span v-if="purchaseSuggestionShowAddToCase">
              Add to
            </span>
            <span
              v-else>
              Remove from
            </span>
            case
          </sequin-button>
        </div>
      </div>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import { formatPrice } from '@/utils/stringParsing.js'
import SequinButton from '../../global/sequin/SequinButton'
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import PhotoSnapScroll from '../../global/sequin/PhotoSnapScroll'
import ClosetReview from '../../closet/ClosetReview'
import SequinTag from '../../global/sequin/SequinTag'
import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import MyCaseMixin from '../common/MyCaseMixin'
import { mapActions, mapState } from 'vuex'
import formatting from '../../global/mixins/formatting'

export default {
  components: {
    PhotoSnapScroll,
    ClosetReview,
    MyCaseFlyoutComponent,
    MyCaseSection,
    SequinButton,
    PreviewImage,
    SequinTag
  },
  mixins: [StyleColorMixin, MyCaseMixin, formatting],
  computed: {
    ...mapState('case', [
      'purchaseSuggestionDetailsBackClick',
      'purchaseSuggestionItemViewing',
      'purchaseSuggestionStyleColor',
      'purchaseSuggestionShowAddToCase'
    ]),
    ...mapState('client', [
      'id'
    ]),
    item () {
      return this.purchaseSuggestionItemViewing
    },
    styleColor () {
      return this.styleColorsMap[this.purchaseSuggestionStyleColor]
    },
    images () {
      return this.styleColor.images
    },
    clientReviews () {
      if (!this.styleColor.reviews) { return [] }
      const ownReviews = this.styleColor.reviews.filter(x => x.clientId === this.id && !x.expertReview &&
          x.feedbackSize === this.item.trueSize)
      if (ownReviews.length > 0) {
        // only show the most recent review
        return ownReviews.slice(0, 1)
      }
      return ownReviews
    }
  },
  mounted () {
    this.getReviews({ styleColorId: this.styleColorId })
  },
  methods: {
    ...mapActions('case', [
      'getAddOnItemsPrice',
      'setMyCaseFlyoutName',
      'addItemToPurchase',
      'removeItemToPurchase'
    ]),
    ...mapActions('closet', [
      'getReviews'
    ]),
    formatPrice,
    addOrRemoveToCase () {
      if (this.purchaseSuggestionShowAddToCase) {
        this.addItemToPurchase({
          id: this.item.id,
          item: this.item,
          source: 'my_case_flyout_purchase_suggestion'
        })
      } else {
        this.removeItemToPurchase(this.item)
      }
      this.setMyCaseFlyoutName(this.purchaseSuggestionDetailsBackClick)
    },
    backClick () {
      this.setMyCaseFlyoutName(this.purchaseSuggestionDetailsBackClick)
    }
  }
}
</script>

<style lang="scss" scoped>

.size-dropdown {
  width: 100%;
}

:deep(.overlay) {
  height: 100%;
}

.rented-tag {
  bottom: 0px;
  left: 20px;
}

 </style>
