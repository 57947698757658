<template>
  <base-flyout>
    <template #title>
      <div class="section-header">
        <plain-button
          class="back-button p-0"
          @click="closeFlyout()">
          <svg-chevron-left/>
        </plain-button>
        <p class="d-flex justify-content-center semi-bold mb-0">
          Item Replacements
        </p>
      </div>
    </template>
    <template #body>
      <div class="d-flex justify-content-center small-text pt-3 pb-3 ps-3 pe-3 item-bg">
        We have high standards and sometimes we are unable to ship all items selected for your case due to quality
        control checks prior to packing.
      </div>
      <div class="small-text p-3 semi-bold pt-1">
        For any item that can’t be shipped, which would you like to receive?
        <div
          v-for="(value, key) in replacementOptions"
          :key="key"
          class="mt-3">
          <base-radio
            v-model="selectedReplacementChoice"
            :initial-value="key"
            name="replacement"
            :text="replacementOptionTitle(value)"
            sequin/>
          <span class="d-flex justify-content-center small-text ps-4 ms-2">
            {{ replacementOptionSubtitle (value) }}
          </span>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-between">
        <div class="w-100 ms-2 mb-2">
          <sequin-button
            :disabled="!selectedReplacementChoice"
            block
            @click="updateReplacementChoice()">
            Save Changes
          </sequin-button>
        </div>
      </div>
    </template>
  </base-flyout>
</template>

<script>
import SequinButton from '../../global/sequin/SequinButton'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'
import ReplacementMixin from './ReplacementMixin'
import { mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import PlainButton from '../../global/sequin/PlainButton'
import { useOverlaysStore } from '@/stores/overlays.js'
import BaseFlyout from '@/components/global/BaseFlyout.vue'
import BaseRadio from '@/components/global/BaseRadio.vue'

export default {
  components: {
    SequinButton,
    SvgChevronLeft,
    PlainButton,
    BaseFlyout,
    BaseRadio
  },
  mixins: [ReplacementMixin],
  methods: {
    ...mapVuexActions('account', [
      'updateReplacementItemChoice'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    ...mapVuexActions('global', [
      'setAlert'
    ]),
    async updateReplacementChoice () {
      try {
        await this.updateReplacementItemChoice(this.selectedReplacementChoice)
        this.setAlert({ msg: 'Your replacement item choice has been set.' })
      } catch (err) {
        this.setAlert({
          msg: 'There was a problem setting your replacement item choice.',
          style: 'warning'
        })
      }
      this.closeFlyout()
    }
  }
}
</script>

<style lang="scss" scoped>

.item-bg {
  background-color: rgba(172, 153, 164, 0.1)
}

.section-header {
  background: $origami;
  padding: 20px;
  text-align: center;
  position: relative;

  .back-button {
    position: absolute;
    left: 8px;
    top: 16px;
    padding: 0;
    background: $origami;
  }

  &.white {
    background: $white;
    border-bottom: $default-border;

    .back-button {
      background: $white;
    }
  }
}
 </style>
