<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName(previousMyCaseFlyoutName)">
        <template #header>
          <h6 class="mb-0">
            Update Payment Method
          </h6>
        </template>
        <template #body>
          <update-default-payment
            class="my-2"
            card-element-id="case-card-element"
            @default-payment-updated="setMyCaseFlyoutName(previousMyCaseFlyoutName)"/>
        </template>
      </my-case-section>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import UpdateDefaultPayment from '../../global/stripe/UpdateDefaultPayment'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    MyCaseFlyoutComponent,
    MyCaseSection,
    UpdateDefaultPayment
  },
  computed: {
    ...mapState('case', [
      'previousMyCaseFlyoutName'
    ])
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ])
  }
}
</script>
