<template>
  <my-case-section-core>
    <template #body>
      <div class="my-1">
        <my-case-item
          v-for="item in packingItems"
          :key="item.item.id"
          :item="item.item"
          hide-price/>
        <my-case-item
          v-for="item in pendingItems"
          :key="item.id"
          variant="hanger"
          hide-price/>
      </div>
    </template>
  </my-case-section-core>
</template>

<script>
import MyCaseSectionCore from '../common/MyCaseSectionCore'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseItem from '../common/MyCaseItem'

export default {
  components: {
    MyCaseItem,
    MyCaseSectionCore
  },
  mixins: [MyCaseMixin]
}
</script>
