<template>
  <sequin-button
    v-if="trackingNumber"
    type="a"
    target="_blank"
    :small="small"
    :href="trackingUrl"
    :variant="variant"
    :block="block">
    Track Case
  </sequin-button>
</template>

<script>
import SequinButton from '../sequin/SequinButton'

export default {
  components: {
    SequinButton
  },
  props: {
    shippedPackage: {
      type: Object,
      required: true
    },
    variant: {
      type: String,
      default: 'secondary'
    },
    block: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    trackingNumber () {
      return this.shippedPackage.trackingNumberOutgoing
    },
    trackingUrl () {
      return this.shippedPackage.trackingLink
    }
  }
}
</script>
