<template>
  <case-error-notification @close-clicked="markAsResolved">
    <div class="text-center p-md-2">
      <b>Whoops! </b>Looks like some of the styles you selected weren't showing up correctly before. Double check the items below before continuing to confirmation.
    </div>
  </case-error-notification>
</template>

<script>
import CaseErrorNotification from './CaseErrorNotification'

export default {
  components: {
    CaseErrorNotification
  },
  beforeUnmount () {
    this.markAsResolved()
  },
  methods: {
    markAsResolved () {
      this.$store.commit('closet/SET_SELECTED_ITEMS_MISMATCH', false)
    }
  }
}
</script>
