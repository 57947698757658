<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <h5 class="mb-0">
            Purchase Item
          </h5>
        </template>
        <template #body>
          <purchase-item-display
            :item-to-purchase="itemToPurchase.item"
            :disabled="formDisabled"
            @total-updated="totalUpdated"
            @applied-promo="promoApplied"/>
        </template>
      </my-case-section>
    </template>
    <template
      v-if="hasPaymentMethod && !isUpdatingPayment"
      #footer>
      <sequin-button
        block
        :disabled="formDisabled || !hasPaymentMethod || total === null"
        @click="submit">
        Confirm Purchase - {{ formatPrice(total, false, false, true) }}
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import SequinButton from '../../global/sequin/SequinButton'
import { formatPrice } from '@/utils/stringParsing.js'
import PurchaseItemDisplay from './PurchaseItemDisplay'

import { mapState, mapActions } from 'vuex'

export default {
  components: {
    MyCaseSection,
    MyCaseFlyoutComponent,
    SequinButton,
    PurchaseItemDisplay
  },
  data () {
    return {
      purchaseComplete: false,
      formDisabled: false,
      formErrors: [],
      total: 0,
      remainingCredit: 0,
      appliedPromoCode: null
    }
  },
  computed: {
    ...mapState('case', [
      'itemToPurchase'
    ]),
    ...mapState('client', [
      'defaultPayment',
      'isUpdatingPayment'
    ]),
    hasPaymentMethod () {
      return Boolean(this.defaultPayment)
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    ...mapActions('client', [
      'buyPackageItem',
      'getItemPrice'
    ]),
    formatPrice,
    totalUpdated (total, remainingCredit) {
      this.total = total
      this.remainingCredit = remainingCredit
    },
    promoApplied (promoCode) {
      this.appliedPromoCode = promoCode
    },
    async submit () {
      try {
        this.formDisabled = true
        await this.buyPackageItem({
          packageItem: this.itemToPurchase,
          promoCode: this.appliedPromoCode,
          source: 'my_case_flyout_purchase_item'
        })
        this.formDisabled = false
        this.formErrors = []
        this.setMyCaseFlyoutName('purchase-complete')
      } catch (errors) {
        this.formErrors = errors
      }
      this.formDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
