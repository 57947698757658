<template>
  <my-case-header>
    <h5>
      Select <b>up to</b> {{ numAllSlots }} {{ items }}
      for your {{ selectedPackages === 0 ? 'first' : 'next' }} case
    </h5>
    <span class="small-text">
      Unused slots will rollover for use in your next regular shipment.
    </span>
    <my-case-slot-counter class="mt-2"/>
  </my-case-header>
</template>

<script>
import MyCaseMixin from '../../common/MyCaseMixin'
import MyCaseSlotCounter from './MyCaseSlotCounter'
import MyCaseHeader from '../../common/header/MyCaseHeader'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    MyCaseHeader,
    MyCaseSlotCounter
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapGetters('client', [
      'numStandardAndRolloverSlots'
    ]),
    items () {
      return `Item${this.numStandardAndRolloverSlots > 1 ? 's' : ''}`
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ])
  }
}
</script>
