<template>
  <my-case-header title="Styling Your Case">
    <p class="small-text mb-0">
      A stylist is building your case! Check back soon to see what was picked for you. <swap-status-message conditional-message="this case arrives"/>
    </p>
  </my-case-header>
</template>

<script>
import MyCaseMixin from '../../common/MyCaseMixin'
import MyCaseHeader from '../../common/header/MyCaseHeader'
import SwapStatusMessage from './SwapStatusMessage'

export default {
  components: {
    MyCaseHeader,
    SwapStatusMessage
  },
  mixins: [MyCaseMixin]
}
</script>
