<template>
  <BaseButton
    :text="option.text"
    :class="{'button-selected': selected}"
    variant="secondary"
    @click="emit('click', $event)">
    <template #icon>
      <ColorCircle
        class="me-xs"
        :color="option.color"/>
    </template>
  </BaseButton>
</template>

<script setup>
import BaseButton from '@shared/components/ADORN/BaseButton'
import ColorCircle from '@/components/styleColorSet/filtersFlyout/ColorCircle.vue'

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})
defineProps({
  option: {
    type: Object,
    required: true
  },
  selected: {
    type: Boolean,
    default: false
  }
})
</script>

<style lang="scss" scoped>
.filter-button {
  font-family: $body-font-family;
  white-space: nowrap;
  &:disabled {
    border-style: dashed;
    user-select: none;
  }
  &.selected {
    color: $plum;
    border-color: $plum;
    background-color: rgba($plum, 0.1);
  }
}
.button-selected {
  border-color: $white !important;
  background-color: $plum !important;
  color: $white !important;
}
</style>
