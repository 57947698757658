<template>
  <my-case-flyout-component>
    <template #header>
      <h5 class="mb-0">
        Confirm Case
      </h5>
    </template>
    <template #body>
      <my-case-section
        no-padding
        back-button
        variant="default"
        @back-click="backClick()">
        <template #header>
          <h6 class="mb-0">
            Complete your wardrobe
          </h6>
          <div
            class="small-text text-pewter pt-3 px-3 mx-3">
            Rent seasonal staples to pair with your new styles
          </div>
        </template>
        <template #body>
          <div class="mt-2">
            <div
              class="bg-white px-1 d-flex flex-wrap">
              <div
                v-for="(recommendation, index) in checkoutRecommendationsSlots"
                :key="index">
                <checkout-recommendation-slot
                  :recommendation="recommendation"/>
              </div>
            </div>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <div class="my-case-footer d-flex justify-content-between">
        <div class="w-100 ms-2">
          <sequin-button
            block
            @click="continueToConfirm()">
            Continue
          </sequin-button>
        </div>
      </div>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import MyCaseMixin from '../common/MyCaseMixin'
import SequinButton from '../../global/sequin/SequinButton'
import { mapActions, mapGetters, mapState } from 'vuex'
import CheckoutRecommendationSlot from './CheckoutRecommendationSlot'

export default {
  components: {
    CheckoutRecommendationSlot,
    MyCaseFlyoutComponent,
    MyCaseSection,
    SequinButton
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('case', [
      'checkoutRecommendationsSlots'
    ]),
    ...mapGetters('client', [
      'availableAddOnItemsToPurchaseAtCheckout'
    ]),
    ...mapGetters('case', [
      'nextFlyout'
    ])
  },
  mounted () {
    this.setMyCaseFlyoutContext({
      checkoutRecommendationsDetailsBackClick: 'checkout-recommendations'
    })
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    continueToConfirm () {
      this.setMyCaseFlyoutName(this.nextFlyout)
    },
    backClick () {
      this.setMyCaseFlyoutName('core')
    }
  }
}
</script>

<style lang="scss" scoped>

.size-dropdown {
  width: 100%;
}

 </style>
