<!--
Custom inline checkbox component with a preview slot,
a name, and a description.
-->
<template>
  <base-checkbox
    v-model="_modelValue"
    :input-value="initialValue"
    :disabled="disabled"
    class="wrapper"
    custom>
    <template #default="{ active }">
      <div
        class="checkbox"
        :class="{ 'active': active }">
        <div class="preview">
          <slot :active="active"/>
          <div class="preview-overlay"/>
        </div>
        <text-line-limit
          tag="h6"
          class="name">
          {{ name }}
        </text-line-limit>
        <text-line-limit
          v-if="description"
          tag="div"
          class="description small-text text-gray">
          {{ description }}
        </text-line-limit>
      </div>
    </template>
  </base-checkbox>
</template>

<script>
import BaseCheckbox from '../BaseCheckbox.vue'
import TextLineLimit from '../../global/sequin/TextLineLimit.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    BaseCheckbox,
    TextLineLimit
  },
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    modelValue: {
      type: [Boolean, Array],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: [String, Number, Object],
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    _modelValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 175px;
}

.checkbox {
  border-radius: 8px;
  padding: 5px 10px;
}

.checkbox.active {
  background-color: $primary;
  color: $white;
}

.preview {
  border: $default-border;
  background-color: $champagne;
  border-radius: 8px;
  margin-bottom: 8px;
  height: 105px;
  display: flex;
  overflow: hidden;
  position: relative;
}

.preview-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.preview img {
  width: 50%;
  object-fit: cover;
}

.preview img + img {
  border-left: $default-border;
}

.name {
  align-self: stretch;
}

.description {
  font-size: 12px
}

.active .description {
  color: $white;
}

</style>
