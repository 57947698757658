<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        no-padding
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <h5 class="mb-0">
            Edit Return Items
          </h5>
        </template>
        <template #body>
          <app-message
            class="text-center"
            :class="{ 'border-bottom': isMobile }"
            variant="brand">
            Changed your mind?<br>Select the items you'd like to move back to “With You” to keep them a little longer.
          </app-message>
          <review-package-item-card
            v-for="item in toReturnItems"
            :key="item.id"
            class="px-1 py-3"
            :class="{'border-bottom-0': !isMobile }"
            selectable
            compact
            :include-belt-message="false"
            :selected="selectedItems.includes(item.id)"
            :package-item="item"
            @selected="onSelectItem(item.id)"/>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <app-message
        v-if="error.length"
        variant="danger">
        {{ error.join('. ') }}
      </app-message>
      <sequin-button
        block
        :disabled="selectedItems.length === 0 || formDisabled"
        @click="submit">
        Remove {{ selectedItems.length > 0 ? selectedItems.length : '' }} Item{{ selectedItems.length === 1 ? '' : 's' }} from Returning
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import AppMessage from '../../global/sequin/AppMessage.vue'
import MyCaseMixin from '../common/MyCaseMixin.vue'
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import ReviewPackageItemCard from '../../review/ReviewPackageItemCard.vue'
import SequinButton from '../../global/sequin/SequinButton'
import { mapActions } from 'vuex'
import { vuexAccessors } from '../../global/helpers/vuex'

export default {
  components: {
    AppMessage,
    MyCaseFlyoutComponent,
    MyCaseSection,
    ReviewPackageItemCard,
    SequinButton
  },
  mixins: [MyCaseMixin],
  data () {
    return {
      selectedItems: [],
      formDisabled: false,
      error: []
    }
  },
  computed: {
    ...vuexAccessors('case', [
      'coreTabSelected'
    ])
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ]),
    ...mapActions('case', [
      'removePackageItemsFromReturning'
    ]),
    onSelectItem (itemId) {
      const currIdx = this.selectedItems.indexOf(itemId)
      if (currIdx > -1) {
        this.selectedItems.splice(currIdx, 1)
      } else {
        this.selectedItems.push(itemId)
      }
    },
    async submit () {
      this.formDisabled = true
      this.error = []
      try {
        await this.removePackageItemsFromReturning(this.selectedItems)
        this.coreTabSelected = 0
        this.setMyCaseFlyoutName('core')
      } catch (err) {
        this.error = err
      }
    }
  }
}
</script>
