<template>
  <base-modal
    use-title-border
    flush-content
    no-footer>
    <template
      #title>
      <b>Likes + Favorites</b>
    </template>
    <template #body>
      <div class="no-padding">
        <transition
          name="fade-height"
          mode="out-in">
          <div
            v-if="!loaded"
            key="loading"
            class="loading">
            <svg-spinner
              v-if="!loaded"
              height="40"
              class="mt-4"/>
          </div>
          <div v-else>
            <div
              v-for="(entry, index) in likesAndFavorites"
              :key="index"
              class="d-flex py-3 px-5 profile-border">
              <profile-header
                :client="entry.client"
                :location="entry.client.location"/>
              <feed-item-action-icons
                :show-likes="entry.liked"
                :show-favorites="entry.favorited"
                :size="28"/>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/global/BaseModal.vue'
import ProfileHeader from './ProfileHeader'
import { mapActions, mapState as mapVuexState } from 'vuex'
import { mapState } from 'pinia'
import FeedItemActionIcons from './FeedItemActionIcons'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  components: {
    BaseModal,
    FeedItemActionIcons,
    ProfileHeader,
    SvgSpinner
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapVuexState('community', [
      'feedItemLikesAndFavorites'
    ]),
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    likesAndFavorites () {
      return this.feedItemLikesAndFavorites[this.modalContext.feedItemId]
    }
  },
  async mounted () {
    await this.getFeedItemLikesAndFavorites(this.modalContext.feedItemId)
    this.loaded = true
  },
  methods: {
    ...mapActions('community', [
      'getFeedItemLikesAndFavorites'
    ])
  }
}
</script>

<style lang="scss" scoped>
.profile-border {
  border-bottom: $default-border;
}

.loading {
  height: 40vh;
  display: flex;
  justify-content: center;
}

.fade-height-enter-active, .fade-height-leave-active {
  transition: min-height .15s, max-height .15s, opacity .15s;
  max-height: 87vh;
  min-height: 0;
}

.fade-height-enter-from, .fade-height-leave-to {
  opacity: 0;
  max-height: 40vh;
  min-height: 40vh;
}

.no-padding {
  margin-right: -15px;
  margin-left: -15px;
}
</style>
