<template>
  <!-- Selected item -->
  <div
    v-if="item">
    <div
      class="d-flex align-items-initial py-2 py-md-3"
      :class="variant">
      <div class="style-color-image-wrapper">
        <plain-button
          v-if="canClickThrough"
          class="p-0 mt-1"
          type="router-link"
          :to="{
            name: 'closet-detail',
            params: {
              id: item.styleColor,
              name: detailPageSlug(styleColor)
            }}"
          @click="onStyleColorClick">
          <my-case-item-image
            :variant="imageVariant"
            :style-color="styleColor"/>
        </plain-button>
        <my-case-item-image
          v-else
          :variant="imageVariant"
          :style-color="styleColor"/>
        <div
          v-if="imageLabel"
          class="image-label text-center">
          {{ imageLabel }}
        </div>
        <plain-button
          v-if="canEdit && !useLargeEditButton"
          class="remove-button"
          :class="variant"
          @click="deselectItem(item)">
          <svg-circle-icon
            class="icon"
            :variant="variant === 'error' ? 'danger' : 'dark'"
            :height="20"
            :width="20">
            <svg-x/>
          </svg-circle-icon>
        </plain-button>
      </div>
      <div
        class="ms-4 w-100 d-flex "
        :class="{
          'flex-column justify-content-start align-items-start': isMobile,
          'justify-content-between align-items-start': !isMobile }">
        <div class="me-2">
          <div
            class="mb-1">
            <slot
              name="itemInfoSlot"/>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span
              v-if="!canClickThrough">
              {{ shortName }}
            </span>
            <plain-button
              v-else-if="variant !== 'addon'"
              class="style-link p-0"
              type="router-link"
              :to="{
                name: 'closet-detail',
                params: {
                  id: item.styleColor,
                  name: detailPageSlug(styleColor)
                }}"
              @click="onStyleColorClick">
              {{ shortName }}
            </plain-button>
            <plain-button
              v-else
              class="style-link p-0"
              @click="onStyleColorClick">
              {{ shortName }}
            </plain-button>
          </div>
          <div class="small-text text-pewter my-1">
            Size {{ item.trueSize }}
          </div>
          <div
            v-if="variant === 'addon'"
            class="add-on-price smaller">
            +{{ formatPrice(item.priceClient, true, true) }} add-on purchase
          </div>
          <div
            v-if="priceClient && !hidePrice && variant !== 'addon'"
            class="small-text text-pewter">
            <span
              v-if="styleColor.style.retailPrice"
              class="strike-through">
              Retail ${{ styleColor.style.retailPrice }}
            </span>
            <b class="mx-1">
              ${{ priceClient }}
            </b>
            <span v-if="discountClient">
              {{ Math.round(discountClient) }}% off
            </span>
          </div>
          <div
            v-if="caseSatisfaction">
            <ToggleSelector
              v-model="toggleValue"
              :options="primaryOptions"
              @set-toggle-selection="selectPrimaryCaseSurveyOption"/>
          </div>
        </div>
        <div class="small-text text-pewter flex-shrink-0">
          <text-link
            v-if="canEdit && useLargeEditButton"
            small
            class="ms-2"
            variant="primary"
            @click="deselectItem(item)">
            <div class="d-flex align-items-center">
              Remove
            </div>
          </text-link>
          <slot/>
        </div>
      </div>
    </div>
    <case-satisfaction-additional-info
      v-if="caseSatisfaction && showSecondarySurveyOptions"
      :header="selectedPrimaryCaseSurveyOption.secondaryChoicesHeader"
      :options="selectedPrimaryCaseSurveyOption.secondaryChoices"
      @emit-text-input-update="setCaseSurveyTextResponse"
      @set-bubble-selection="selectSecondaryCaseSurveyOption"/>
  </div>

  <!-- Empty item slot -->
  <div
    v-else
    class="d-flex align-items-center py-2 py-md-3">
    <plain-button
      :disabled="!canEdit"
      class="item-slot empty me-4"
      @click="onEmptySlotClick">
      <svg-plus
        v-if="variant === 'add'"
        :height="28"
        :width="28"
        stroke="white"
        :stroke-width="2"
        variant="circular"
        :padding="12"
        class="svg-icon"/>
      <svg-circle-icon
        v-if="variant === 'bonus'"
        :height="32"
        :width="32"
        variant="dark"
        :stroke-width="1"
        :padding="12"
        class="svg-icon">
        <svg-star/>
      </svg-circle-icon>
      <svg-circle-icon
        v-else-if="variant === 'birthday'"
        :height="32"
        :width="32"
        :stroke-width="1.3"
        variant="dark"
        class="svg-icon gift">
        <svg-gift/>
      </svg-circle-icon>
      <svg-closet
        v-else-if="variant === 'hanger'"
        :height="32"
        :width="32"/>
    </plain-button>
    <div class="small-text text-pewter">
      <slot/>
    </div>
  </div>
</template>

<script>
import MyCaseItemImage from './MyCaseItemImage'
import MyCaseMixin from './MyCaseMixin'
import PlainButton from '../../global/sequin/PlainButton'
import TextLink from '../../global/sequin/TextLink'
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions } from 'pinia'
import { formatPrice } from '@/utils/stringParsing.js'
import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import SvgCircleIcon from '../../global/svg/SvgCircleIcon'
import SvgPlus from '@/components/global/svg/SvgPlus.vue'
import SvgCloset from '@/components/global/svg/SvgCloset.vue'
import SvgX from '@/components/global/svg/SvgX.vue'
import SvgStar from '@/components/global/svg/SvgStar.vue'
import SvgGift from '@/components/global/svg/SvgGift.vue'
import ToggleSelector from '../../global/sequin/ToggleSelector'
import CaseSatisfactionAdditionalInfo from '../confirmCase/caseSatisfaction/CaseSatisfactionAdditionalInfo.vue'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    SvgCircleIcon,
    SvgCloset,
    SvgPlus,
    SvgX,
    SvgStar,
    SvgGift,
    MyCaseItemImage,
    PlainButton,
    TextLink,
    ToggleSelector,
    CaseSatisfactionAdditionalInfo
  },
  mixins: [MyCaseMixin, StyleColorMixin],
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: value => {
        return value.match(/(default|bonus|birthday|addon|error|success|add)/)
      }
    },
    item: {
      type: Object,
      default: null
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canClickThrough: {
      type: Boolean,
      default: true
    },
    useLargeEditButton: {
      type: Boolean,
      default: false
    },
    imageLabel: {
      type: String,
      default: ''
    },
    hidePrice: {
      type: Boolean,
      default: false
    },
    caseSatisfaction: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showSecondarySurveyOptions: false,
      selectedPrimaryCaseSurveyOption: null,
      toggleValue: null
    }
  },
  computed: {
    ...mapState('case', [
      'caseSatisfactionSurveyOptions'
    ]),
    priceClient () {
      if (!this.hidePrice && !this.itemTypePricing[this.item.id]?.item?.priceClient) {
        this.getItemTypePrice(this.item)
      }
      return this.itemTypePricing[this.item.id]?.item?.priceClient
    },
    discountClient () {
      return this.itemTypePricing[this.item.id]?.item?.discountClient
    },
    styleColor () {
      return this.styleColorsMap[this.item.styleColor]
    },
    shortName () {
      return `${this.styleColor.style.brand} ${this.styleColor.style.subcategory}`
    },
    imageVariant () {
      if (this.variant === 'success') return 'success-brand'
      return 'default'
    },
    packingItemId () {
      return this.packingItems?.find(pi => pi.item.itemType === this.item.id)?.id ?? null
    },
    primaryOptions () {
      return this.caseSatisfactionSurveyOptions.map(o => { return { text: o.label, value: o.id } })
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    ...mapVuexActions('closet', [
      'deselect',
      'setStyleColorSource'
    ]),
    ...mapVuexActions('case', [
      'removeItemToPurchase',
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName',
      'updateCaseSatisfactionSurveyAnswers',
      'updateCaseSatisfactionSurveyText'
    ]),
    formatPrice,
    onStyleColorClick () {
      if (this.variant === 'addon') {
        this.setMyCaseFlyoutContext({
          purchaseSuggestionDetailsBackClick: 'confirm-case',
          purchaseSuggestionItemViewing: this.item,
          purchaseSuggestionStyleColor: this.item.styleColor,
          purchaseSuggestionShowAddToCase: false
        })
        this.setMyCaseFlyoutName('purchase-suggestion-details')
      } else {
        this.setStyleColorSource({
          styleColorId: this.styleColor.id,
          source: 'mycase'
        })
        this.closeFlyout()
      }
    },
    onEmptySlotClick () {
      if (this.$route.name !== 'closet-sections') {
        this.$router.push({ name: 'closet-sections' })
      }
      this.closeFlyout()
    },
    deselectItem (item) {
      if (this.variant === 'addon') {
        this.removeItemToPurchase(item)
      } else {
        this.deselect(item)
      }
    },
    selectPrimaryCaseSurveyOption (selection) {
      this.updateCaseSatisfactionSurveyAnswers({ id: this.packingItemId, cid: selection, scid: null })
      this.selectedPrimaryCaseSurveyOption = this.caseSatisfactionSurveyOptions.find(option => option.id === selection)
      this.showSecondarySurveyOptions = this.selectedPrimaryCaseSurveyOption.secondaryChoices.length > 0
      this.updateCaseSatisfactionSurveyText({ id: this.packingItemId, text: null })
    },
    selectSecondaryCaseSurveyOption (selection) {
      this.updateCaseSatisfactionSurveyAnswers({ id: this.packingItemId, cid: this.selectedPrimaryCaseSurveyOption.id, scid: selection })
    },
    setCaseSurveyTextResponse (input) {
      this.updateCaseSatisfactionSurveyText({ id: this.packingItemId, text: input })
    }
  }
}
</script>

<style lang="scss" scoped>

.addon {
  background: rgba($info-light, .1);
}

.add-on-price {
  color: $info-dark;
  font-weight: $font-weight-semibold;
}

.style-color-image-wrapper {
  position: relative;

  .image-label {
    position: absolute;
    top: 25px;
    background-color: rgba($armor, 0.7);
    color: $white;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    font-size:  $font-size-base * .55;
    padding: 0px 5px;
    width: 100%;
    pointer-events: none;
  }

  .remove-button {
    position: absolute;
    top: -4px;
    right: -8px;
    padding: 0;
    background: none;

    .icon {
      stroke-width: 2px;
    }
  }
}

.item-slot {
  width: 64px;
  height: 64px;
  border: $default-border;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  object-fit: contain;
  background: $white;
  flex-shrink: 0;

  &.addon {
    border: 1px dashed $armor;
  }

  &.empty {
    border: .5px dashed $pewter;
  }

  &.error {
    border: 1px dashed $danger-dark;
  }

  .svg-icon {
    padding: 6px;
    background-color: $ash !important;
  }
}

.style-link {
  text-decoration: underline;
  background: rgba($white, 0);
}
</style>
