<template>
  <div class="d-flex flex-column align-items-center">
    <component
      :is="`${primaryCaseState}-header`"/>
  </div>
</template>

<script>
import { mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

import AwaitingReturnsHeader from './AwaitingReturnsHeader'
import AwaitingSwapHeader from './AwaitingSwapHeader'
import DefaultHeader from '../../common/header/DefaultHeader'
import DeliveredHeader from './DeliveredHeader'
import InactiveMemberHeader from './InactiveMemberHeader'
import MyCaseMixin from '../../common/MyCaseMixin'
import PackingHeader from './PackingHeader'
import PendingStylistCaseHeader from './PendingStylistCaseHeader'
import ReadyForPickupHeader from './ReadyForPickupHeader'
import SelectingHeader from './SelectingHeader'
import ShippingHeader from './ShippingHeader'

export default {
  components: {
    AwaitingReturnsHeader,
    AwaitingSwapHeader,
    DefaultHeader,
    DeliveredHeader,
    InactiveMemberHeader,
    PackingHeader,
    PendingStylistCaseHeader,
    ReadyForPickupHeader,
    SelectingHeader,
    ShippingHeader
  },
  mixins: [MyCaseMixin],
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    ...mapVuexActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName',
      'getAllItemsPrice'
    ])
  }
}
</script>
