<template>
  <base-modal dialog-class="modal modal-lg">
    <template #title>
      <h3 class="mt-lg">
        We've got you covered!
      </h3>
    </template>
    <template #body>
      <div class="d-flex text-center px-sm px-lg-xxl">
        <div class="row">
          <div class="mb-sm">
            We recommend selecting your usual size and the next size up.
          </div>
          <table class="table">
            <thead>
              <tr>
                <th style="width: 60%;">
                  <h5 class="m-0">
                    Size
                  </h5>
                </th>
                <th>
                  <h5 class="m-0">
                    Pant Size
                  </h5>
                </th>
              </tr>
            </thead>
            <tbody class="bg-light">
              <tr
                v-for="line in sizeMap"
                :key="line.key">
                <td>{{ line.size }}</td>
                <td>{{ line.pantSize }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/global/BaseModal.vue'

const sizeMap = [
  { size: '000', pantSize: '23' },
  { size: '00', pantSize: '24' },
  { size: '0', pantSize: '25' },
  { size: '2', pantSize: '26' },
  { size: '4', pantSize: '27' },
  { size: '6', pantSize: '28' },
  { size: '8', pantSize: '29' },
  { size: '10', pantSize: '30' },
  { size: '12', pantSize: '31' },
  { size: '12-14', pantSize: '32' },
  { size: '14W-16', pantSize: '33' },
  { size: '16W-18W', pantSize: '34' },
  { size: '18W-20W', pantSize: '35' },
  { size: '20W-22W', pantSize: '36' },
  { size: '22W-24W', pantSize: '37' }
]
</script>

<style lang="scss" scoped>
  :deep(.modal-lg) {
    max-width: 680px;
  }

  :deep(.modal-content) {
    border-radius: 8px;
  }

  table {
    border: 2px;
  }

  tbody tr:last-child td {
    border-bottom: 0;
  }

  h5 {
    font-size: 1em;
  }

  .row {
    line-height: 1.5rem;
  }
</style>
