<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <h5 class="mb-0">
            Personal Styling
          </h5>
        </template>
        <template #body>
          <div class="p-2">
            <closet-stylist-avatars/>
            <h5 class="mt-3">
              Need more options?
            </h5>
            <p class="small-text mb-3">
              Our personal styling team will read your request and suggest more styles shortly.
            </p>
            <my-case-text-area
              v-model="stylistRequest"
              required
              :rows="isMobile ? 4 : 6"
              placeholder="I'm looking for..."
              @focus="scrollUp"/>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <sequin-button
        block
        :disabled="!stylistRequest || stylistRequest.length === 0 || formDisabled"
        @click="submit">
        Submit Request
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import MyCaseTextArea from '../common/MyCaseTextArea'
import SequinButton from '../../global/sequin/SequinButton'
import { mapActions } from 'vuex'
import ClosetStylistAvatars from '../../closet/ClosetStylistAvatars'

export default {
  components: {
    ClosetStylistAvatars,
    MyCaseFlyoutComponent,
    MyCaseSection,
    MyCaseTextArea,
    SequinButton
  },
  data () {
    return {
      stylistRequest: null,
      formDisabled: false
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ]),
    ...mapActions('closet', [
      'requestStylist'
    ]),
    getStylistImageUrl (index) {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}stylist-${index}.png`
    },
    async submit () {
      const formData = {
        feedbackComments: this.stylistRequest,
        // To do: Identify appropriate source for form data
        source: 'My Case'
      }

      this.formDisabled = true
      await this.requestStylist(formData)
      this.setMyCaseFlyoutName('ask-stylist-success')
    }
  }
}
</script>
