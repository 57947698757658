<template>
  <base-modal
    :full-screen="isMobile"
    use-title-border>
    <template #title>
      <h5 class="mb-0">
        Create New Collection
      </h5>
    </template>
    <template #body>
      <div class="mt-4">
        <div
          class="pt-2 px-0 px-md-4 d-flex flex-column align-items-start">
          <p class="mb-0">
            Name your collection
          </p>
          <base-input
            v-model="name"
            :validations="v$.name"
            :has-float-label="false"
            class="w-100 mt-2"
            label="Like &quot;Napa Trip&quot; or &quot;Work Outfits&quot;"/>
          <p>
            Tag occasions
          </p>
          <tag-occasions v-model="selectedOccasions"/>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-100 p-3">
        <p
          v-if="errors.length"
          class="text-danger">
          {{ errors.join('. ') }}
        </p>
        <sequin-button
          :disabled="v$.$invalid || isSubmitting"
          block
          @click="onCreate">
          Create Collection
        </sequin-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions as mapVuexActions, mapGetters } from 'vuex'
import { mapActions, mapState } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'
import { useOverlaysStore } from '@/stores/overlays.js'

import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import BaseModal from '@/components/global/BaseModal.vue'
import BaseInput from '@/components/global/BaseInput.vue'
import SequinButton from '../../global/sequin/SequinButton.vue'
import TagOccasions from './TagOccasions.vue'

export default {
  components: {
    BaseModal,
    BaseInput,
    SequinButton,
    TagOccasions
  },
  mixins: [StyleColorMixin],
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      name: '',
      selectedOccasions: [],
      isSubmitting: false,
      isPrivate: false,
      errors: []
    }
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(128)
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapGetters('collections', [
      'clientsCollections'
    ]),
    styleColorId () {
      return this.modalContext?.styleColorId
    }
  },
  methods: {
    ...mapVuexActions('collections', [
      'createCollection',
      'getCollectionTags'
    ]),
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    async onCreate () {
      try {
        this.isSubmitting = true

        const newCollection = await this.createCollection({
          name: this.name,
          tags: this.selectedOccasions,
          styleColors: this.styleColorId ? [this.styleColorId] : [],
          private: false // MVP: No private collections
        })

        if (this.styleColorId) {
          this.openModal({ name: 'add-to-collection', context: { styleColorId: this.styleColorId } })
        } else {
          this.openModal({ name: 'collection-suggestions', context: { collectionId: newCollection.id, allowSkip: true } })
        }
      } catch (err) {
        this.errors = err
        this.isSubmitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.heart-icon {
  stroke-width: 1.25px;
  width: 26px;
  height: 26px;
  margin-left: -2px;
}
</style>
