<template>
  <base-modal
    class="edit-profile-modal"
    :hide-close="!isEditProfileHome"
    @close-modal-clicked="cleanupEditProfile">
    <template #title>
      <div class="w-100 px-3 pt-2">
        <h4
          v-if="isEditProfileHome"
          class="text-start m-0 w-100">
          Edit Profile
        </h4>
        <action-header
          v-else
          no-navigation
          class="p-0"
          :show-confirm="editProfileComponentDetails.showConfirm"
          @back-click="setEditProfileComponent('EditProfile')"
          @confirm-click="editProfileComponentDetails.onConfirm">
          {{ editProfileComponentDetails.title }}
          <template #confirm>
            <div class="d-flex align-items-center text-primary font-weight-bold">
              <p class="m-0">
                Done
              </p>
            </div>
          </template>
        </action-header>
      </div>
    </template>
    <template #body>
      <component
        :is="editProfileComponentDetails.componentName"
        class="border"
        v-bind="editProfileComponentDetails.props"
        :class="editProfileComponentDetails.class"
        v-on="editProfileComponentDetails.handlers"/>
    </template>
    <template #footer>
      <div
        v-if="isEditProfileHome"
        class="w-100 p-3">
        <sequin-button
          :disabled="!getEditProfileHasValidUpdates || isSaving"
          block
          @click="onSave()">
          Save Changes
        </sequin-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/global/BaseModal.vue'
import ActionHeader from '../../../global/sequin/ActionHeader'
import EditProfileBody from './EditProfileBody'
import EditProfilePhoto from './EditProfilePhoto'
import EditProfileMixin from './EditProfileMixin'
import SequinButton from '../../../global/sequin/SequinButton'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    BaseModal,
    ActionHeader,
    EditProfileBody,
    EditProfilePhoto,
    SequinButton
  },
  mixins: [EditProfileMixin],
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    async onSave () {
      await this.saveProfileUpdates()

      if (!this.saveError) {
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-profile-modal {
  :global(.modal-header) {
    margin: 0 !important;
    flex-shrink: 0;
  }

  :global(.scrollable) {
    padding-top: 0;
  }
}
</style>
