<template>
  <p><b>Maternity:</b> {{ maternity ? 'yes' : 'no' }}</p>
  <p><b>Nursing:</b> {{ nursing ? 'yes' : 'no' }}</p>
  <p v-if="dueDate">
    <b>Due Date:</b> {{ dueDate }}
  </p>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { useStyleProfileStore } from '@/stores/styleProfile'

const { showMaternity: maternity, showNursing: nursing, dueDate } = storeToRefs(useStyleProfileStore())

</script>
