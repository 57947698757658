<template>
  <div
    v-if="!active"
    class="small-text inactive-status px-3">
    <div
      class="error">
      <span v-if="membershipStatus === 'unpaid'">
        There's an issue with your credit card
      </span>
      <span v-else>
        Your membership is
      </span>
      <span v-if="membershipStatus === 'paused'">
        {{ membershipStatus }}
      </span>
      <span v-else-if="membershipStatus === 'items_out_pending_cancel'">
        pending cancellation.
      </span>
      <span v-else-if="membershipStatus !== 'unpaid'">
        inactive.
      </span>
    </div>
    <div v-if="membershipStatus === 'items_out_pending_cancel'">
      Return your {{ outstandingItems.length }} outstanding item{{ outstandingItems.length > 1 ? 's' : '' }}
      as soon as possible to avoid any additional charges.
    </div>
    <div v-else-if="membershipStatus === 'unpaid'">
      Update your payment method for your items to ship
    </div>
    <div v-else-if="canPickItems || itemsInTransit">
      Checkout is required for your items to ship.
    </div>
  </div>
</template>

<script>
import MyCaseMixin from '../common/MyCaseMixin'
import { mapGetters, mapState } from 'vuex'

export default {
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('client', [
      'membershipStatus'
    ]),
    ...mapGetters('client', [
      'active'
    ]),
    ...mapGetters('closet', [
      'outstandingItems'
    ])
  }
}
</script>

<style lang="scss" scoped>
.inactive-status {
  color: $pewter;
  text-align: center;

  .error {
    color: $danger-dark;
    font-weight: $font-weight-bold;
  }
}
</style>
