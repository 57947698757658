<template>
  <base-modal>
    <template #body>
      <div class="text-center">
        <div class="px-md-5">
          <h3>Shipping Address</h3>
          <address-form
            :address="selectedAddress"
            :button-label="buttonLabel"
            :show-set-selected="showSetSelected"
            :set-selected-initial-value="setSelectedInitialValue"
            @address-added-updated="submit()"/>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapState as mapVuexState } from 'vuex'
import { mapActions, mapState } from 'pinia'
import AddressForm from '../subscribe/AddressForm'
import { useOverlaysStore } from '@/stores/overlays.js'
import BaseModal from '@/components/global/BaseModal.vue'

export default {
  components: {
    BaseModal,
    AddressForm
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('client', [
      'shippingAddress'
    ]),

    buttonLabel () {
      if (this.selectedAddress) { return 'Update Address' }
      return 'Add Address'
    },
    selectedAddress () {
      if (this.modalContext && this.modalContext.address) { return this.modalContext.address }
      return null
    },
    showSetSelected () {
      if (this.shippingAddress && this.selectedAddress) {
        return this.shippingAddress.id !== this.selectedAddress.id
      }
      return true
    },
    setSelectedInitialValue () {
      if (this.modalContext && typeof this.modalContext.setSelectedInitialValue === 'boolean') {
        return this.modalContext.setSelectedInitialValue
      }
      return true
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal',
      'openModal'
    ]),
    submit () {
      if (this.modalContext.nextModal) {
        this.openModal({ name: 'confirm-checkout' })
      } else {
        this.closeModal()
      }
    }
  }
}
</script>
