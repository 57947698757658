<template>
  <div class="item-image-wrapper">
    <img
      class="item-image"
      :class="size"
      :src="url">
    <sequin-tag
      v-if="overlayTag"
      class="position-absolute tag text-center"
      light
      variant="success">
      {{ overlayTag }}
    </sequin-tag>
    <div
      v-if="overlayCheck"
      class="overlay-check"
      :class="size">
      <svg-check
        class="check"/>
    </div>
    <transition
      name="fade"
      appear
      mode="out-in">
      <svg-circle-icon
        v-if="variant === 'success' || variant === 'success-brand'"
        class="success-check"
        :class="size"
        :variant="variant === 'success' ? 'success' : 'tertiary' ">
        <svg-check/>
      </svg-circle-icon>
    </transition>
  </div>
</template>

<script>
import { getImageUrl } from '@/utils/stringParsing.js'
import SequinTag from '../../global/sequin/SequinTag'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'

export default {
  components: {
    SequinTag,
    SvgCheck,
    SvgCircleIcon
  },
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: value => {
        return value.match(/(success|success-brand|default)/)
      }
    },
    styleColor: {
      type: Object,
      default: null
    },
    imageUrl: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'default',
      validator: value => {
        return value.match(/(large|medium|default)/)
      }
    },
    overlayTag: {
      type: String,
      default: ''
    },
    overlayCheck: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    url () {
      if (this.styleColor) {
        return getImageUrl(this.styleColor.images.front, 't')
      }
      return this.imageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.item-image-wrapper {
  position: relative;

  .overlay-check {

    .check {
      color: $white;
      margin-left: 12.5%;
      margin-top: 12.5%;
      width: 75%;
      height: 75%;
      stroke-width: 1px;
    }

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: $plum;
    opacity: 0.75;

    &.default {
      width: 64px;
      height: 64px;
    }

    &.medium {
      width: 72px;
      height: 72px;
    }

    &.large {
      width: 128px;
      height: 128px;
    }
  }

  .tag {
    width: 100%;
    top: calc(50% - 11px);
    left: 0px;
  }

  .item-image {
    border: $default-border;
    object-fit: contain;
    background: $white;

    &.default {
      width: 64px;
      height: 64px;
    }

    &.medium {
      width: 72px;
      height: 72px;
    }

    &.large {
      width: 128px;
      height: 128px;
    }
  }

  .success-check {
    position: absolute;
    top: 20px;
    left: 20px;

    &.medium {
      top: 24px;
      left: 24px;
    }

    &.large {
      top: 52px;
      left: 52px;
    }
  }
}

</style>
