<template>
  <shipping-header-base>
    <swap-status-message/>
  </shipping-header-base>
</template>

<script>
import ShippingHeaderBase from '../../common/header/ShippingHeaderBase'
import SwapStatusMessage from './SwapStatusMessage'

export default {
  components: {
    ShippingHeaderBase,
    SwapStatusMessage
  }
}
</script>
