<template>
  <div class="pt-4 px-4 pb-3">
    <content-stack
      :spacing="4"
      class="item-content">
      <div class="d-flex w-100">
        <preview-image-button
          size="small"
          :src="image"
          @click="navigateToDetailPage"/>
        <content-stack
          class="ms-3 ps-1"
          :spacing="4"
          alignment="left">
          <content-stack
            alignment="left"
            :spacing="2">
            <text-link
              class="py-0 mt-n1"
              size="small-text"
              variant="secondary"
              type="router-link"
              :to="brandPageLink">
              {{ styleColor.style.brand }}
            </text-link>
            <plain-button
              class="p-0"
              @click="navigateToDetailPage">
              <text-line-limit
                tag="h6"
                class="small-text semi-bold"
                :number-of-lines="1">
                {{ styleColor.style.name }}
              </text-line-limit>
            </plain-button>
            <p class="small-text text-pewter mb-0">
              Size {{ packageItem.item.trueSize }}
            </p>
            <div
              v-if="packageItem.item.priceClient"
              class="small-text text-pewter">
              <b class="mx-1">
                ${{ packageItem.item.priceClient }}
              </b>
              {{ Math.round(packageItem.item.discountClient) }}% off
              <span
                v-if="styleColor.style.retailPrice"
                class="strike-through mx-1">
                Retail ${{ styleColor.style.retailPrice }}
              </span>
            </div>
          </content-stack>
          <div class="d-flex">
            <my-case-item-review-buttons :item="packageItem"/>
            <sequin-button
              small
              variant="secondary"
              @click="openPurchaseItemFlyout(packageItem)">
              Buy
            </sequin-button>
          </div>
        </content-stack>
      </div>
      <transition
        name="fade"
        mode="out-in">
        <content-stack
          v-if="memberPhotos === null"
          :spacing="6"
          class="w-100"
          alignment="left">
          <text-placeholder/>
          <div class="d-flex">
            <square-image-placeholder
              v-for="(_, idx) in [...Array(maxNumMemberPhotos)]"
              :key="`placeholder-${styleColorId}-${idx}`"
              :width="72"
              class="border member-photo"/>
          </div>
        </content-stack>
        <plain-button
          v-else-if="memberPhotos.length === 0 && packageItem.reviewed"
          class="full-width share-look mt-3 mb-2"
          @click="onShareLook">
          <square-image
            v-if="clientReviewPhotos && clientReviewPhotos.length"
            :width="58"
            class="share-look-preview ms-2 me-2"
            object-fit="cover"
            object-position="center top"
            :image-source="clientReviewPhotos[0]"/>
          <img
            v-else
            class="icon full-width"
            :src="getIconUrl()">
          <content-stack
            :spacing="0"
            class="ms-1"
            alignment="left">
            <p class="fine-print semi-bold text-secondary mb-0">
              Share your look
            </p>
            <p class="text-secondary fine-print">
              Post the first member look for this style!
            </p>
          </content-stack>
        </plain-button>
        <content-stack
          v-else-if="memberPhotos.length > 0"
          :spacing="6"
          class="w-100"
          alignment="left">
          <div class="d-flex w-100 justify-content-between align-items-center">
            <p class="semi-bold text-pewter small-text mb-0">
              Style inspiration
            </p>
            <text-link
              v-if="memberPhotos.length > maxNumMemberPhotos"
              variant="tertiary"
              @click="onViewMemberPhotos(0)">
              View all
            </text-link>
          </div>
          <div
            v-if="memberPhotos.length > 0"
            class="d-flex align-items-center">
            <plain-button
              v-for="memberPhoto in memberPhotos.slice(0,maxNumMemberPhotos)"
              :key="memberPhoto.url"
              class="p-0 member-photo"
              @click="onViewMemberPhotos(memberPhoto.index)">
              <square-image
                :width="72"
                class="border"
                :image-source="memberPhoto.url"/>
            </plain-button>
            <plain-button
              v-if="showShareLookAction"
              class="p-0 share-look"
              @click="onShareLook">
              <square-image
                v-if="clientReviewPhotos && clientReviewPhotos.length"
                :width="28"
                class="share-look-preview"
                object-fit="cover"
                object-position="center top"
                :image-source="clientReviewPhotos[0]"/>
              <img
                v-else
                class="icon"
                :src="getIconUrl()">
              <p class="fine-print share-look-text text-secondary mb-0">
                Share your look
              </p>
            </plain-button>
          </div>
        </content-stack>
      </transition>
    </content-stack>
  </div>
</template>

<script>
import MyCaseItemReviewButtons from './MyCaseItemReviewButtons.vue'
import PreviewImageButton from '@shared/components/ADORN/PreviewImageButton.vue'
import MyCaseMixin from '../common/MyCaseMixin.vue'
import ContentStack from '../../global/sequin/ContentStack.vue'
import TextLineLimit from '../../global/sequin/TextLineLimit.vue'
import TextLink from '../../global/sequin/TextLink.vue'
import SequinButton from '../../global/sequin/SequinButton.vue'
import SquareImage from '../../global/sequin/SquareImage.vue'
import SquareImagePlaceholder from '../../global/sequin/SquareImagePlaceholder.vue'
import PlainButton from '../../global/sequin/PlainButton.vue'
import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import { ClosetMemberPhotoMixin } from '../../closet/ClosetMemberPhotoMixin'
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions } from 'pinia'
import TextPlaceholder from '../../global/sequin/TextPlaceholder.vue'
import { vuexAccessors } from '../../global/helpers/vuex'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    ContentStack,
    MyCaseItemReviewButtons,
    SequinButton,
    PreviewImageButton,
    SquareImage,
    SquareImagePlaceholder,
    TextLineLimit,
    TextLink,
    TextPlaceholder,
    PlainButton
  },
  mixins: [MyCaseMixin, ClosetMemberPhotoMixin, StyleColorMixin],
  props: {
    packageItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('client', [
      'id'
    ]),
    ...vuexAccessors('closet', [
      'photoAlbumIndex'
    ]),
    styleColorId () {
      return this.packageItem.item.styleColor
    },
    styleColor () {
      return this.styleColorsMap[this.styleColorId]
    },
    image () {
      return this.styleColor.images.front.url
    },
    maxNumMemberPhotos () {
      return this.isMobile ? 4 : 5
    },
    clientLooks () {
      const looks = this.styleColor.looks
      return looks ? looks.filter(look => look.client.id === this.id) : null
    },
    clientReviewPhotos () {
      const reviews = this.styleColor.clientReviews
      return reviews
        ? reviews.filter(review => review.images.length && review.clientId === this.id).flatMap(review => review.images.map(image => image.url))
        : null
    },
    memberPhotos () {
      const { looks, clientReviews } = this.styleColor
      if (!looks || !clientReviews) return null
      return this.memberPhotoAlbumEntries.reduce((memberPhotos, photoAlbumEntry) => {
        if (!photoAlbumEntry.reviewId || photoAlbumEntry.clientId !== this.id) memberPhotos.push({ url: photoAlbumEntry.image, index: photoAlbumEntry.photoAlbumIndex })
        return memberPhotos
      }, [])
    },
    showShareLookAction () {
      return this.memberPhotos.length < this.maxNumMemberPhotos && this.packageItem.reviewed && !this.clientLooks?.length
    },
    closetDetailParams () {
      return {
        id: this.styleColorId,
        name: this.detailPageSlug(this.styleColor)
      }
    }
  },
  mounted () {
    this.getReviews({ styleColorId: this.styleColorId })
    this.getLooksByStyleColor(this.styleColorId)
  },
  methods: {
    ...mapVuexActions('closet', [
      'getReviews',
      'getLooksByStyleColor',
      'setStyleColorSource'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeFlyout',
      'openModal'
    ]),
    ...mapVuexActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName',
      'getAllItemsPrice'
    ]),
    getIconUrl () {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}image-required-icon.png`
    },
    openPurchaseItemFlyout (item) {
      this.setMyCaseFlyoutContext({ itemToPurchase: item })
      this.setMyCaseFlyoutName('purchase-item')
    },
    onStyleColorClick () {
      this.setStyleColorSource({
        styleColorId: this.styleColor.id,
        source: 'mycase'
      })
      this.closeFlyout()
    },
    navigateToDetailPage () {
      if (this.$route.name !== 'closet-detail' || this.$route.params.id !== this.closetDetailParams.id) {
        this.$router.push({
          name: 'closet-detail',
          params: this.closetDetailParams,
          query: this.$route.query
        })
      }
      this.onStyleColorClick()
    },
    onShareLook () {
      // Pre-populate with style and any existing client review photos
      const styleForLookBuilder = { id: this.styleColor.id, image: { url: this.image } }
      const imagesForLookBuilder = this.clientReviewPhotos.length ? [this.clientReviewPhotos[0]] : []
      this.$store.commit('community/SET_LOOK_BUILDER_STYLE_COLORS', [styleForLookBuilder])
      this.$store.commit('community/SET_LOOK_BUILDER_IMAGES', imagesForLookBuilder)
      if (this.isMobile) {
        this.$router.push({ name: 'feed' })
        this.$router.push({ name: 'share-a-look' })
      } else {
        this.$router.push({ name: 'feed' })
        this.openModal({ name: 'share-a-look' })
        this.closeFlyout()
      }
    },
    onViewMemberPhotos (index) {
      this.photoAlbumIndex = index
      this.navigateToDetailPage()

      if (this.isMobileWidth) {
        this.$router.push({
          name: 'closet-detail-member-photos',
          params: this.closetDetailParams
        })
      } else {
        this.openModal({
          name: 'member-photo-detail',
          context: { styleColorId: this.styleColorId }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-content {
  width: 100%;
  max-width: 404px;
  margin: 0 auto;
}

.member-photo {
  margin-right: 12px;
}

.icon {
  width: auto;
  height: 16px;
  margin: 2px 0 5px;

  &.full-width {
    height: 28px;
    width: auto;
    margin: 0 16px 0 24px;
  }
}

.share-look-preview {
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1px;
}

.share-look-text {
  line-height: $font-size-base * .9;
}

.share-look {
  border-radius: 50%;
  width: 72px;
  height: 72px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $secondary;

  &.full-width {
    width: 100%;
    height: 72px;
    flex-direction: row;
    border-radius: 36px;
    padding: 0;
    justify-content: flex-start;
  }
}
</style>
