<template>
  <div>
    <div class="slot-counter">
      <div
        v-for="index in numAllSlotsFilled"
        :key="`selected-${index}`"
        class="slot filled">
        <svg-check class="check"/>
      </div>
      <div
        v-for="index in numAllSlotsAvailable"
        :key="`empty-${index}`"
        class="slot empty"/>
    </div>
  </div>
</template>

<script>
import MyCaseMixin from '../../common/MyCaseMixin'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    SvgCheck
  },
  mixins: [MyCaseMixin]
}
</script>

<style lang="scss" scoped>
.slot-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.slot {
  border: $default-border;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.empty {
    background: $white;
  }

  &.filled {
    background: $velvet;
    border-color: $velvet;

    .check {
      padding: 4px;
      stroke-width: 4px;
      stroke-linecap: round;
      color: $white;
    }

    .star {
      fill: $white;
      color: $white;
    }
  }
}
</style>
