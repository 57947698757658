<template>
  <div>
    <div class="d-flex justify-content-between">
      <sequin-tag
        light
        variant="success">
        Shipped on {{ dateShipped }}
      </sequin-tag>
    </div>
    <div class="my-2">
      <my-case-item
        v-for="item in shippedPackage.packageItems"
        :key="item.item.id"
        :item="item.item"
        hide-price/>
      <my-case-item
        v-for="(item, index) in soldItems"
        :key="index"
        :item="item.item"
        image-label="Purchased"
        hide-price/>
      <add-on-item-type
        v-for="item in addOnItems"
        :key="item.addOnItemType.id"
        :type-id="item.addOnItemType.id"
        :quantity="item.quantity"
        back-click="core"
        confirmed/>
    </div>
    <!-- First package track button will be in Case header -->
    <track-shipment-button
      v-if="index !== 0 || primaryCaseState !== 'shipping' "
      class="my-2"
      :shipped-package="shippedPackage"/>
  </div>
</template>

<script>
import AddOnItemType from '../addon/AddOnItemType'
import { toDayMonthString } from '@/utils/stringParsing.js'
import MyCaseItem from '../common/MyCaseItem'
import MyCaseMixin from '../common/MyCaseMixin'
import SequinTag from '../../global/sequin/SequinTag'
import TrackShipmentButton from '../../global/shipments/TrackShipmentButton'

export default {
  components: {
    AddOnItemType,
    MyCaseItem,
    SequinTag,
    TrackShipmentButton
  },
  mixins: [MyCaseMixin],
  props: {
    shippedPackage: {
      type: Object,
      required: true
    },
    addOnItems: {
      type: Array,
      required: true
    },
    soldItems: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    dateShipped () {
      return toDayMonthString(this.shippedPackage.datetimeShipped)
    }
  }
}
</script>
