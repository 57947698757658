<template>
  <countdown-timer
    v-if="showShipsToday"
    class="text-pewter small-text text-center mb-2"
    :cutoff="sameDayCutoff">
    <div>Ships today if you order in the next</div>
  </countdown-timer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CountdownTimer from '../../global/dates/CountdownTimer'

export default {
  components: {
    CountdownTimer
  },
  computed: {
    ...mapState('client', [
      'shippingEligibility'
    ]),
    ...mapState('closet', [
      'selectedItemTypes'
    ]),
    sameDayCutoff () {
      if (!this.shippingEligibility) { return null }
      if (!this.shippingEligibility.standard.sameDay.cutoff) { return null }
      const cutoff = new Date(Date.parse(
        this.shippingEligibility.standard.sameDay.cutoff))
      return cutoff
    },
    showShipsToday () {
      return this.sameDayCutoff !== null && this.selectedItemTypes.length > 0
    }
  },
  mounted: function () {
    this.getShippingEligibility()
  },
  methods: {
    ...mapActions('client', [
      'getShippingEligibility'
    ])
  }
}
</script>
