<template>
  <div class="outer-box d-flex justify-content-between">
    <div
      class="minus d-flex justify-content-center align-items-center larger"
      :class="{'pointer': number > minValue && !disabled, 'disabled': number === minValue || disabled}"
      @click="decrement">
      −
    </div>
    <div class="d-flex justify-content-center align-items-center number">
      {{ number }}
    </div>
    <div
      class="plus d-flex justify-content-center align-items-center larger"
      :class="{'pointer': number < maxValue && !disabled, 'disabled': number === maxValue || disabled}"
      @click="increment">
      +
    </div>
  </div>
</template>

<script>

export default {
  props: {
    initialValue: {
      type: Number,
      default: 1
    },
    maxValue: {
      type: Number,
      default: 10
    },
    minValue: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      number: 1
    }
  },
  mounted () {
    this.number = this.initialValue
  },
  methods: {
    increment () {
      if (this.number < this.maxValue && !this.disabled) {
        this.number += 1
        this.$emit('click', this.number)
      }
    },
    decrement () {
      if (this.number > this.minValue && !this.disabled) {
        this.number -= 1
        this.$emit('click', this.number)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.outer-box {
  border: 2px solid #DCDCDF;
  border-radius: 4px;
}

.minus {
  background-color: white;
  border-right: 2px solid #DCDCDF;
  width: 50px;
  color: $armor;
  user-select: none;
}

.number {
  background-color: white;
  min-width: 30px;
  min-height: 25px;
}

.disabled {
  background-color: #F2F2F2 !important;
  color: $ash !important;
}

.plus {
  background-color: white;
  color: $armor;
  width: 50px;
  border-left: 2px solid #DCDCDF;
  user-select: none;
}

 </style>
