<template>
  <div
    class="container ps-3 pe-0 py-3">
    <div class="text-success smaller">
      We have backup sizes available for this style!<br>
      Would you like to add a <b>free</b> backup size?
    </div>
    <my-case-item
      :can-click-through="false"
      hide-price
      :item="typeForBackupTypes"/>
    <base-radios
      v-model="selectedBackupItemTypeId"
      buttons
      class="ps-3"
      col-class="px-3 me-3"
      :options="backupOptions"/>
    <div
      class="py-3">
      <sequin-button
        small
        :disabled="!selectedBackupItemTypeId"
        @click="addBackupSize">
        Add free backup size
      </sequin-button>
      <sequin-button
        small
        class="ms-2"
        variant="secondary"
        @click="dismiss">
        No thanks
      </sequin-button>
    </div>
  </div>
</template>

<script>
import MyCaseItem from '../common/MyCaseItem'
import SequinButton from '../../global/sequin/SequinButton'
import { mapState, mapActions } from 'vuex'
import { vuexAccessors } from '../../global/helpers/vuex'
import BaseRadios from '@/components/global/BaseRadios.vue'

export default {
  components: {
    MyCaseItem,
    SequinButton,
    BaseRadios
  },
  computed: {
    ...mapState('case', [
      'backupItemTypes',
      'typeForBackupTypes'
    ]),
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    ...vuexAccessors('case', [
      'selectedBackupItemTypeId'
    ]),
    backupOptions () {
      const options = this.backupItemTypes.map(x => {
        return {
          text: x.trueSize,
          value: x.id
        }
      })
      return options
    }
  },
  methods: {
    ...mapActions('case', [
      'selectBackupSize'
    ]),
    addBackupSize () {
      this.selectBackupSize()
      this.$emit('backup-size-clicked')
    },
    dismiss () {
      this.selectedBackupItemTypeId = null
      this.$emit('no-backup-size-clicked')
    }
  }
}

</script>

<style lang="scss" scoped>

.container {
  background-color: rgba($success-light, .1);
}

</style>
