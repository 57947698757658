<template>
  <div class="d-flex flex-wrap justify-content-start">
    <StylistClosetEditButton
      v-for="closet in recentClosets"
      :key="closet.pk"
      :closet="closet"
      @resume="resumeStyling(closet.pk)"
      @delete="deleteStylistCloset(closet.pk)"/>
  </div>
</template>
<script setup>
import { useStylingStore } from '@/stores/styling'
import { storeToRefs } from 'pinia'
import StylistClosetEditButton from '@/components/styling/StylistClosetEditButton.vue'

const store = useStylingStore()
const { recentClosets } = storeToRefs(store)
const { resumeStyling, deleteStylistCloset } = store

</script>
