<template>
  <div>
    <account-modal-edit-shipping-address v-if="modalName === 'account-edit-shipping-address'"/>
    <account-modal-update-card v-if="modalName === 'update-credit-card'"/>
    <account-modal-correct-shipping v-if="modalName === 'correct-shipping'"/>
    <account-modal-delete-account v-if="modalName === 'delete-account'"/>
    <closet-member-photos v-if="modalName === 'member-photo-detail'"/>
    <closet-modal-buy-item v-if="modalName === 'buy-item'"/>
    <closet-product-photos v-if="modalName === 'product-photo-detail'"/>
    <closet-modal-rating-info v-if="modalName === 'closet-rating-info'"/>
    <collections-modal-add-to-collection v-if="modalName === 'add-to-collection'"/>
    <collections-modal-create-collection v-if="modalName === 'create-collection'"/>
    <collections-modal-delete-collection v-if="modalName === 'delete-collection'"/>
    <collections-modal-edit-collection v-if="modalName === 'edit-collection'"/>
    <collections-modal-share-collection v-if="modalName === 'share-collection'"/>
    <collections-modal-share-client-collections v-if="modalName === 'share-client-collections'"/>
    <collections-modal-share-to-feed v-if="modalName === 'share-collection-to-feed'"/>
    <collections-modal-remove-items v-if="modalName === 'collection-remove-items'"/>
    <collections-modal-suggested-styles v-if="modalName === 'collection-suggestions'"/>
    <community-modal-edit-profile v-if="modalName === 'edit-profile'"/>
    <community-modal-edit-delete-menu v-if="modalName === 'edit-delete-menu'"/>
    <community-modal-confirm-delete v-if="modalName === 'community-delete-confirmation'"/>
    <community-modal-edit-look v-if="modalName === 'edit-look'"/>
    <community-modal-share-a-look v-if="modalName === 'share-a-look'"/>
    <community-modal-view-likes v-if="modalName === 'view-likes'"/>
    <style-profile-modal-denim-sizes v-if="modalName === 'denim-sizes'"/>
    <closet-modal-share-actions v-if="modalName === 'closet-share-actions'"/>
    <review-modal-occasion-picker v-if="modalName === 'pick-occasion'"/>
    <swaps-available-modal v-if="modalName === 'swaps-available'"/>
    <style-color-set-page-share-modal v-if="modalName === 'share-style-color-set-page'"/>
    <referral-q-r-code-modal v-if="modalName === 'referral-qr-code'"/>
    <sign-up-sizing-modal v-if="modalName === 'sign-up-sizing'"/>
    <fed-ex-opt-in-modal v-if="modalName === 'fed-ex-opt-in'"/>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

import CommunityModalEditDeleteMenu from '../community/feed/update/CommunityModalEditDeleteMenu'
import CommunityModalConfirmDelete from '../community/feed/update/CommunityModalConfirmDelete'
import CommunityModalViewLikes from '../community/feed/CommunityModalViewLikes'
import AccountModalEditShippingAddress from '../account/AccountModalEditShippingAddress'
import AccountModalCorrectShipping from '../account/AccountModalCorrectShipping'
import AccountModalDeleteAccount from '../account/AccountModalDeleteAccount.vue'
import AccountModalUpdateCard from '../account/AccountModalUpdateCard'
import StyleProfileModalDenimSizes from '../styleProfile/modals/StyleProfileModalDenimSizes'
import ClosetModalBuyItem from '../closet/ClosetModalBuyItem'
import ClosetMemberPhotos from '../closet/ClosetMemberPhotos'
import ClosetProductPhotos from '../closet/ClosetProductPhotos'
import CollectionsModalAddToCollection from '../collections/update/CollectionsModalAddToCollection.vue'
import CollectionsModalCreateCollection from '../collections/update/CollectionsModalCreateCollection.vue'
import CollectionsModalDeleteCollection from '../collections/update/CollectionsModalDeleteCollection.vue'
import CollectionsModalEditCollection from '../collections/update/CollectionsModalEditCollection.vue'
import CollectionsModalRemoveItems from '../collections/update/CollectionsModalRemoveItems.vue'
import CollectionsModalShareCollection from '../collections/update/CollectionsModalShareCollection.vue'
import CollectionsModalShareClientCollections from '../collections/CollectionsModalShareClientCollections.vue'
import CollectionsModalShareToFeed from '../collections/update/CollectionsModalShareToFeed.vue'
import CollectionsModalSuggestedStyles from '../collections/update/CollectionsModalSuggestedStyles.vue'
import CommunityModalEditLook from '../community/sharelook/CommunityModalEditLook'
import CommunityModalShareALook from '../community/sharelook/CommunityModalShareALook'
import CommunityModalEditProfile from '../community/profile/update/CommunityModalEditProfile'
import ClosetModalShareActions from '../closet/ClosetModalShareActions'
import ReviewModalOccasionPicker from '../review/ReviewModalOccasionPicker'
import ClosetModalRatingInfo from '../closet/ClosetModalRatingInfo'
import SwapsAvailableModal from '../global/swapsAvailable/SwapsAvailableModal.vue'
import StyleColorSetPageShareModal from '../styleColorSet/StyleColorSetPageShareModal.vue'
import ReferralQRCodeModal from '../referral/ReferralQRCodeModal.vue'
import SignUpSizingModal from '../styleProfile/modals/SignUpSizingModal.vue'
import FedExOptInModal from '../account/FedExOptInModal.vue'

export default {
  components: {
    ClosetModalRatingInfo,
    ClosetModalBuyItem,
    ClosetModalShareActions,
    ClosetMemberPhotos,
    ClosetProductPhotos,
    CollectionsModalAddToCollection,
    CollectionsModalCreateCollection,
    CollectionsModalDeleteCollection,
    CollectionsModalEditCollection,
    CollectionsModalRemoveItems,
    CollectionsModalShareCollection,
    CollectionsModalShareClientCollections,
    CollectionsModalShareToFeed,
    CollectionsModalSuggestedStyles,
    CommunityModalEditLook,
    CommunityModalShareALook,
    CommunityModalEditDeleteMenu,
    CommunityModalEditProfile,
    CommunityModalConfirmDelete,
    CommunityModalViewLikes,
    AccountModalEditShippingAddress,
    AccountModalCorrectShipping,
    AccountModalDeleteAccount,
    AccountModalUpdateCard,
    StyleProfileModalDenimSizes,
    ReviewModalOccasionPicker,
    SwapsAvailableModal,
    StyleColorSetPageShareModal,
    ReferralQRCodeModal,
    SignUpSizingModal,
    FedExOptInModal
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalName'
    ])
  }
}
</script>
