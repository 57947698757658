<template>
  <my-case-section-core>
    <template #body>
      <div>
        <sequin-tag
          v-if="!active"
          variant="danger">
          Waiting for Checkout
        </sequin-tag>
        <div class="my-1">
          <my-case-item
            v-for="item in packingItems"
            :key="item.item.id"
            class="mb-1"
            :item="item.item"
            hide-price/>
          <my-case-item
            v-for="(item, index) in soldPackingItems"
            :key="index"
            :item="item"
            class="mb-1"
            image-label="Purchased"
            hide-price/>
          <add-on-item-type
            v-for="item in addOnPackingItems"
            :key="item.addOnItemType.id"
            :type-id="item.addOnItemType.id"
            :quantity="item.quantity"
            class="mb-1"
            back-click="core"
            confirmed/>
        </div>
      </div>
    </template>
  </my-case-section-core>
</template>

<script>
import AddOnItemType from '../addon/AddOnItemType'
import MyCaseSectionCore from '../common/MyCaseSectionCore'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseItem from '../common/MyCaseItem'
import SequinTag from '../../global/sequin/SequinTag'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    AddOnItemType,
    MyCaseItem,
    MyCaseSectionCore,
    SequinTag
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('closet', [
      'addOnPackingItems'
    ]),
    ...mapGetters('client', [
      'active'
    ])
  }
}
</script>
