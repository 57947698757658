<template>
  <base-modal
    :full-screen="isMobile"
    use-title-border
    no-footer>
    <template #title>
      <h5 class="mb-0">
        Share this page
      </h5>
    </template>
    <template #body>
      <div class="w-100 my-2 px-3 d-flex flex-column align-items-center">
        <copy-link
          class="w-100"
          :full-link="productLink"
          :display-link="productLink"
          @copy-link-click="handleCopyLinkClick"/>
        <p class="text-pewter mt-3">
          Anyone with this link can view this page.
        </p>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/global/BaseModal.vue'
import CopyLink from '../global/sequin/CopyLink'
import { mapGetters } from 'vuex'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  components: {
    BaseModal,
    CopyLink
  },
  setup () {
    const { track } = useAnalytics()
    return { track }
  },
  computed: {
    ...mapGetters('client', [
      'shareQueryString'
    ]),
    productLink () {
      return `https://${window.location.hostname}${this.$route.path}` + this.shareQueryString
    }
  },
  methods: {
    handleCopyLinkClick () {
      this.track('Shared Client Collections Page', {
        channel: 'copy-link'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .share-link {
    margin: 0;
    cursor: pointer;
  }
</style>
