<template>
  <base-modal
    :full-screen="isMobile"
    use-title-border>
    <template #title>
      <h5 class="mb-0">
        {{ collection.name }}
      </h5>
    </template>
    <template #body>
      <div>
        <collection-suggestions
          ref="collectionSuggestions"
          :show-confirm-button="false"
          is-clients-collection
          is-first-run
          :collection-id="collectionId"
          @selected-suggestions-updated="onSelectedSuggestionsUpdated"/>
      </div>
    </template>
    <template #footer>
      <div class="p-3">
        <collection-suggestions-confirmation
          block
          :allow-skip="allowSkip"
          :collection-id="collectionId"
          :selected-suggestions="selectedSuggestions"
          @confirmation-success="onConfirmed"/>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapState as mapVuexState } from 'vuex'
import { mapActions, mapState } from 'pinia'
import BaseModal from '@/components/global/BaseModal.vue'
import CollectionSuggestions from '../CollectionSuggestions.vue'
import CollectionSuggestionsConfirmation from '../CollectionSuggestionsConfirmation.vue'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    BaseModal,
    CollectionSuggestions,
    CollectionSuggestionsConfirmation
  },
  data () {
    return {
      selectedSuggestions: []
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('collections', [
      'collectionsById'
    ]),
    allowSkip () {
      return this.modalContext?.allowSkip
    },
    collectionId () {
      return this.modalContext?.collectionId
    },
    collection () {
      return this.collectionsById[this.collectionId]
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    onSelectedSuggestionsUpdated (selected) {
      this.selectedSuggestions = selected
    },
    onConfirmed () {
      if (this.$route.params?.id !== this.collectionId) {
        this.$router.push({ name: 'collection', params: { id: this.collectionId } })
      }
      this.closeModal()
    }

  }
}
</script>
