<template>
  <my-case-flyout v-if="flyoutName === 'my-case'"/>
  <replacement-flyout v-else-if="flyoutName === 'replacement'"/>
  <closet-detail-flyout v-else-if="flyoutName === 'closet-detail'"/>
  <style-color-set-filters-flyout v-else-if="flyoutName === 'style-color-set-filters'"/>
</template>

<script>
import { mapState } from 'pinia'
import MyCaseFlyout from '../case/MyCaseFlyout'
import ReplacementFlyout from '../account/replacements/ReplacementFlyout'
import ClosetDetailFlyout from '../closet/ClosetDetailFlyout'
import StyleColorSetFiltersFlyout from '@/components/styleColorSet/filtersFlyout/StyleColorSetFiltersFlyout'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    MyCaseFlyout,
    ReplacementFlyout,
    ClosetDetailFlyout,
    StyleColorSetFiltersFlyout
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'flyoutName'
    ])
  }
}
</script>
