<template>
  <accordion-with-header
    :expanded="expanded"
    :expanded-max-height="600"
    :title="header"
    :border="false"
    direction="reverse"
    @accordion-click="toggleExpanded">
    <bubble-selector
      :options="visibleOptions.map(option => ({ text: option.label, value: option.id }))"
      @set-bubble-selection="emitSetBubbleSelection"/>
    <div
      v-if="hasTextInput">
      <base-text-area
        v-model="surveyText"
        label="survey text"
        placeholder="Anything else you’d like us to know?"/>
    </div>
  </accordion-with-header>
</template>
<script>
import AccordionWithHeader from '../../../global/sequin/AccordionWithHeader.vue'
import BubbleSelector from '../../../global/sequin/BubbleSelector.vue'
import BaseTextArea from '@/components/global/BaseTextArea.vue'

export default {
  components: {
    AccordionWithHeader,
    BubbleSelector,
    BaseTextArea
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    header: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selection: '',
      expanded: false,
      surveyText: ''
    }
  },
  computed: {
    hasTextInput () {
      if (!!this.selection === false) return false
      const selectedOption = this.options.find(option => option.id === this.selection)
      return selectedOption.isTextField === true
    },
    selectedOption () {
      return this.options.find(option => option.id === this.selection)
    },
    visibleOptions () {
      const options = [...this.options]
      return options.sort((a, b) => {
        if (a.isTextField === true && b.isTextField === false) return 1
        if (a.isTextField === false && b.isTextField === true) return -1
        return 0
      })
    }
  },
  watch: {
    surveyText (val) {
      this.emitTextInputUpdate(val)
    }
  },
  methods: {
    toggleExpanded () {
      this.expanded = !this.expanded
    },
    emitSetBubbleSelection (selection) {
      this.selection = selection
      this.$emit('set-bubble-selection', selection)
    },
    emitTextInputUpdate (surveyText) {
      this.$emit('emit-text-input-update', surveyText)
    }
  }
}
</script>
<style scoped lang="scss">
:deep(.accordion-button) {
  padding: 0 0 16px 0 !important;
  justify-content: flex-end !important;
  svg {
    margin-right: 12px !important;
  }
}
</style>
