<template>
  <base-flyout
    :desktop-size="flyoutName === 'my-case-flyout-core' ? 'large' : 'default'"
    class="my-case-flyout">
    <template #body>
      <component
        :is="flyoutName"
        :ref="flyoutName"/>
    </template>
  </base-flyout>
</template>

<script>
import { mapState, mapActions as mapVuexActions, mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import useAnalytics from '@shared/composables/analytics.js'
import BaseFlyout from '@/components/global/BaseFlyout.vue'
import MyCaseFlyoutAskStylist from './askStylist/MyCaseFlyoutAskStylist'
import MyCaseFlyoutAskStylistSuccess from './askStylist/MyCaseFlyoutAskStylistSuccess'
import MyCaseFlyoutAddOnDetails from './addon/MyCaseFlyoutAddOnDetails'
import MyCaseFlyoutCheckoutRecommendations from './addon/MyCaseFlyoutCheckoutRecommendations'
import MyCaseFlyoutCheckoutRecommendationDetails from './addon/MyCaseFlyoutCheckoutRecommendationDetails'
import MyCaseFlyoutPurchaseSuggestions from './addon/MyCaseFlyoutPurchaseSuggestions'
import MyCaseFlyoutPurchaseSuggestionDetails from './addon/MyCaseFlyoutPurchaseSuggestionDetails'
import MyCaseFlyoutAddressSelection from './addressSelection/MyCaseFlyoutAddressSelection'
import MyCaseFlyoutCorrectShippingAddress from './addressSelection/MyCaseFlyoutCorrectShippingAddress'
import MyCaseFlyoutCore from './core/MyCaseFlyoutCore'
import MyCaseFlyoutConfirmCase from './confirmCase/MyCaseFlyoutConfirmCase'
import MyCaseFlyoutReplacementItems from './confirmCase/MyCaseFlyoutReplacementItems'
import MyCaseFlyoutChooseShipping from './confirmCase/MyCaseFlyoutChooseShipping'
import MyCaseFlyoutConfirmCaseSuccess from './confirmCase/MyCaseFlyoutConfirmCaseSuccess'
import MyCaseFlyoutEditReturns from './returns/MyCaseFlyoutEditReturns.vue'
import MyCaseFlyoutPurchaseShipment from './purchaseShipment/MyCasePurchaseShipment'
import MyCaseFlyoutPurchaseShipmentComplete from './purchaseShipment/MyCasePurchaseShipmentComplete'
import MyCaseFlyoutEditShippingAddress from './addressSelection/MyCaseFlyoutEditShippingAddress'
import MyCaseFlyoutPickupLocationDetails from './pickupLocationDetails/MyCaseFlyoutPickupLocationDetails.vue'
import MyCaseFlyoutPurchaseComplete from './purchaseItem/MyCaseFlyoutPurchaseComplete'
import MyCaseFlyoutPurchaseItem from './purchaseItem/MyCaseFlyoutPurchaseItem'
import MyCaseFlyoutPurchaseReturningItem from './purchaseItem/MyCaseFlyoutPurchaseReturningItem'
import MyCaseFlyoutPurchaseAll from './purchaseItem/MyCaseFlyoutPurchaseAll'
import MyCaseFlyoutUpdatePaymentDetails from './updatePaymentDetails/MyCaseFlyoutUpdatePaymentDetails'
import MyCaseFlyoutSurpriseCase from './askStylist/MyCaseFlyoutSurpriseCase'
import MyCaseFlyoutRequestStylistCaseOne from './askStylist/MyCaseFlyoutRequestStylistCaseOne'
import MyCaseFlyoutRequestStylistCaseSecond from './askStylist/MyCaseFlyoutRequestStylistCaseSecond'
import MyCaseFlyoutRequestStylistCaseThird from './askStylist/MyCaseFlyoutRequestStylistCaseThird'
import PlainButton from '../global/sequin/PlainButton'
import { vuexAccessors } from '../global/helpers/vuex'
import MyCaseFlyoutRequestStylistPickOccasion from './askStylist/MyCaseFlyoutRequestStylistPickOccasion'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    BaseFlyout,
    MyCaseFlyoutEditShippingAddress,
    MyCaseFlyoutAskStylist,
    MyCaseFlyoutAskStylistSuccess,
    MyCaseFlyoutAddressSelection,
    MyCaseFlyoutAddOnDetails,
    MyCaseFlyoutCheckoutRecommendations,
    MyCaseFlyoutCheckoutRecommendationDetails,
    MyCaseFlyoutChooseShipping,
    MyCaseFlyoutCorrectShippingAddress,
    MyCaseFlyoutConfirmCase,
    MyCaseFlyoutReplacementItems,
    MyCaseFlyoutConfirmCaseSuccess,
    MyCaseFlyoutCore,
    MyCaseFlyoutEditReturns,
    MyCaseFlyoutPickupLocationDetails,
    MyCaseFlyoutPurchaseComplete,
    MyCaseFlyoutPurchaseItem,
    MyCaseFlyoutPurchaseReturningItem,
    MyCaseFlyoutPurchaseAll,
    MyCaseFlyoutPurchaseShipment,
    MyCaseFlyoutPurchaseShipmentComplete,
    MyCaseFlyoutPurchaseSuggestions,
    MyCaseFlyoutPurchaseSuggestionDetails,
    MyCaseFlyoutUpdatePaymentDetails,
    MyCaseFlyoutSurpriseCase,
    MyCaseFlyoutRequestStylistCaseOne,
    MyCaseFlyoutRequestStylistCaseSecond,
    MyCaseFlyoutRequestStylistCaseThird,
    MyCaseFlyoutRequestStylistPickOccasion,
    PlainButton
  },
  setup () {
    const { track } = useAnalytics()
    return {
      track
    }
  },
  computed: {
    ...mapState('case', [
      'myCaseFlyoutName'
    ]),
    ...vuexAccessors('case', [
      'coreTabSelected'
    ]),
    ...mapState('closet', [
      'selectedItemTypes',
      'styleColorSources'
    ]),
    ...mapGetters('closet', [
      'styleColorSegmentData'
    ]),
    flyoutName () {
      return 'my-case-flyout-' + this.myCaseFlyoutName
    },
    backButton () {
      switch (this.myCaseFlyoutName) {
        case 'core':
        case 'confirm-case-success':
          return true
        case 'default':
          return false
      }
      return false
    }
  },
  watch: {
    // scroll to top of flyout whenever it changes
    flyoutName: function (newFlyoutName) {
      this.$nextTick(() => {
        this.$refs[newFlyoutName].$el.scrollIntoView()
      })
    }
  },
  created () {
    if (typeof Stripe === 'undefined') {
      // Load the Stripe library (if not yet loaded) when user enters Case flyout
      // Takes ~300-400ms on a 3G connection, so this
      // will be done a long time before it's actually needed (in
      // updating card details for purchasing items, bonus items, etc)
      const script = document.createElement('script')
      script.id = '_stripe-checkout-script'
      script.src = 'https://js.stripe.com/v3/'
      script.type = 'text/javascript'
      document.head.appendChild(script)
    }
  },
  async mounted () {
    const selected = []
    for (const i in this.selectedItemTypes) {
      selected.push({
        itemTypeId: this.selectedItemTypes[i].id,
        styleColor: this.styleColorSegmentData(this.selectedItemTypes[i].styleColor),
        source: this.styleColorSources[this.selectedItemTypes[i].styleColor]
      })
    }
    this.track('Viewed Case', {
      items: selected,
      itemCount: selected.length
    })
  },
  beforeUnmount () {
    this.setMyCaseFlyoutName('core')
    this.coreTabSelected = 0
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    ...mapVuexActions('case', [
      'setMyCaseFlyoutName'
    ]),
    onClose () {
      this.closeFlyout()
    }
  }
}
</script>

<style lang="scss" scoped>
.my-case-header {
  background-color: $origami;
  font-weight: $font-weight-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 12px;
}

.my-case-flyout {
  :global(.scrollable) {
    height: 100dvh;

    :global(.scroll-content) {
      margin-bottom: 0;
    }
  }
}

.back-button {
  background-color: $origami;
  position: absolute;
  left: 8px;
  padding: 4px;
}
</style>
