import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default function useRouteMetadata () {
  const route = useRoute()

  const requiresAuth = computed(() => route.matched.some(record => record.meta.requiresAuth === true))
  const allowAnonymous = computed(() => route.matched.some(record => record.meta.allowAnonymous === true))
  const fullScreen = computed(() => matchesMeta('fullScreen'))
  const hideBanners = computed(() => fullScreen.value || matchesMeta('hideBanners'))
  const hideNavBar = computed(() => fullScreen.value || matchesMeta('hideNavBar'))

  // True if the current route has `metaKey` set to true or if any of its parent routes have `metaKey` set to 'children'.
  function matchesMeta (metaKey) {
    return route.meta[metaKey] === true || route.matched.some(record => record.meta[metaKey] === 'children')
  }

  return {
    requiresAuth,
    allowAnonymous,
    fullScreen,
    hideBanners,
    hideNavBar
  }
}
