<template>
  <my-case-header title="Case">
    <p class="small-text mb-3">
      All of your items have been returned<br><swap-status-message/>
    </p>
    <review-swap-header-buttons v-if="numItemsToReview > 0"/>
  </my-case-header>
</template>

<script>
import MyCaseMixin from '../../common/MyCaseMixin'
import MyCaseHeader from '../../common/header/MyCaseHeader'
import ReviewSwapHeaderButtons from './ReviewSwapHeaderButtons.vue'
import SwapStatusMessage from './SwapStatusMessage.vue'

export default {
  components: {
    MyCaseHeader,
    ReviewSwapHeaderButtons,
    SwapStatusMessage
  },
  mixins: [MyCaseMixin]
}
</script>
