<template>
  <base-modal
    ui-refresh
    flush-content
    :width="isMobile ? '' : '380px'"
    use-title-border>
    <template #title>
      <h5 class="mb-0">
        Collect
      </h5>
      <div>
        Collect a style to save it for later.
      </div>
    </template>
    <template #body>
      <div v-if="initialized">
        <div class="curated-collections">
          <icon-checkbox
            v-model="isFavoriteStyleColor"
            :initial-value="0"
            icon="svg-heart">
            Favorites
          </icon-checkbox>
        </div>
        <div class="collections">
          <collection-preview-checkbox
            v-for="collection in clientsStandardCollections"
            :key="collection.id"
            v-model="selectedCollections"
            :initial-value="collection.id"
            :collection="collection"/>
          <div class="load-more">
            <div v-if="loadingCollections">
              <svg-spinner
                height="32"/>
            </div>
            <text-link
              v-else-if="loadMoreAvailable"
              variant="primary"
              @click="loadMoreCollections">
              Load More Collections
            </text-link>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex flex-column align-items-center">
        <svg-spinner
          height="48"
          class="mb-3"/>
      </div>
    </template>
    <template #footer>
      <div
        v-if="styleColor"
        class="container-fluid">
        <div class="row px-3">
          <sequin-button
            class="col mx-1"
            variant="secondary"
            :disabled="!initialized"
            @click="onCreate">
            Create new
          </sequin-button>
          <sequin-button
            class="col mx-1"
            :disabled="!initialized"
            @click="onSave">
            Save
          </sequin-button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions as mapVuexActions, mapGetters, mapState as mapVuexState } from 'vuex'
import { mapActions, mapState } from 'pinia'
import BaseModal from '@/components/global/BaseModal.vue'
import IconCheckbox from '../../global/sequin/IconCheckbox.vue'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'
import CollectionPreviewCheckbox from './CollectionPreviewCheckbox.vue'
import SequinButton from '../../global/sequin/SequinButton.vue'
import TextLink from '../../global/sequin/TextLink.vue'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    BaseModal,
    SequinButton,
    TextLink,
    IconCheckbox,
    SvgSpinner,
    CollectionPreviewCheckbox
  },
  data () {
    return {
      initialized: false,
      selectedCollections: [],
      isFavoriteStyleColor: false
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('client', {
      clientId: 'id'
    }),
    ...mapVuexState('closet', [
      'styleColorsMap',
      'ratingsMap'
    ]),
    ...mapVuexState('collections', [
      'collectionDataByClientId',
      'clientCollectionsByStyleColorId'
    ]),
    ...mapGetters('collections', [
      'clientsStandardCollections'
    ]),
    collectionsWithStyle () {
      return this.clientCollectionsByStyleColorId[this.styleColorId].map(collection => collection.id)
    },
    clientCollectionData () {
      return this.collectionDataByClientId[this.clientId]
    },
    loadingCollections () {
      return !this.clientCollectionData || this.clientCollectionData?.loading
    },
    loadMoreAvailable () {
      return this.clientCollectionData?.next
    },
    styleColorId () {
      return this.modalContext.styleColorId
    },
    styleColor () {
      return this.styleColorsMap[this.styleColorId]
    }
  },
  async mounted () {
    await this.getClientsCollectionsWithStyleColor(this.styleColorId)
    this.selectedCollections = this.collectionsWithStyle.slice()
    this.isFavoriteStyleColor = this.ratingsMap[this.styleColorId] === 1

    if (!(this.styleColorId in this.styleColorsMap)) {
      await this.getStyleColor(this.styleColorId)
    }

    this.initialized = true
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal',
      'closeModal'
    ]),
    ...mapVuexActions('closet', [
      'getStyleColor',
      'favorite',
      'unfavorite'
    ]),
    ...mapVuexActions('collections', [
      'getClientsCollectionsWithStyleColor',
      'addStyleColorToCollection',
      'removeStyleColorFromCollection',
      'loadMoreClientCollections'
    ]),
    onCreate () {
      this.openModal({
        name: 'create-collection',
        context: {
          styleColorId: this.styleColorId
        }
      })
    },
    async onSave () {
      const wasFavorite = this.ratingsMap[this.styleColorId] === 1
      if (this.isFavoriteStyleColor !== wasFavorite) {
        const method = this.isFavoriteStyleColor ? this.favorite : this.unfavorite
        method({ styleColorId: this.styleColorId })
      }

      const promises = []
      const addedTo = this.selectedCollections.filter(collectionId => !this.collectionsWithStyle.includes(collectionId))
      addedTo.forEach(collectionId => {
        promises.push(this.addStyleColorToCollection({ collectionId, styleColorId: this.styleColorId }))
      })
      const removedFrom = this.collectionsWithStyle.filter(collectionId => !this.selectedCollections.includes(collectionId))
      removedFrom.forEach(collectionId => {
        promises.push(this.removeStyleColorFromCollection({ collectionId, styleColorId: this.styleColorId }))
      })
      await Promise.all(promises)

      this.closeModal()
    },
    loadMoreCollections () {
      this.loadMoreClientCollections({ clientId: this.clientId })
    }
  }
}
</script>

<style lang="scss" scoped>
.curated-collections {
  border-bottom: $light-border;
}

.collections {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 5px;
  padding: 10px 10px;
  height: 325px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.load-more {
  grid-column: span 2;
  text-align: center;
  padding: 10px 0px;
}
</style>
