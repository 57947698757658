<template>
  <photo-snap-scroll
    :length="images.length"
    :width="360"
    always-scroll>
    <template #scrollable>
      <preview-image
        v-for="image in images"
        :key="image.url"
        class="d-flex align-items-center scroll-item"
        size="xx-large"
        :src="image.url"/>
    </template>
  </photo-snap-scroll>
</template>

<script>
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import PhotoSnapScroll from '../../global/sequin/PhotoSnapScroll.vue'

export default {
  components: {
    PhotoSnapScroll,
    PreviewImage
  },
  props: {
    images: {
      type: Array,
      required: true
    }
  }
}
</script>
