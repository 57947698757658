<template>
  <div class="row">
    <AccordionWithHeader
      title="Styling Info"
      class="styling-info"
      :border="false"
      :expanded="stylingInfoSection"
      :expanded-max-height="400"
      @accordion-click="() => stylingInfoSection = !stylingInfoSection">
      <div class="row">
        <AccordionWithHeader
          v-for="section in sections"
          :key="section.title"
          :title="section.title"
          :expanded="section.section.value"
          :expanded-max-height="400"
          :border="false"
          :class="`g-0 ${section.class}`"
          @accordion-click="toggleSection(section.section)">
          <div class="dropdown">
            <component :is="section.component"/>
          </div>
        </AccordionWithHeader>
      </div>
    </AccordionWithHeader>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import AccordionWithHeader from '@/components/global/sequin/AccordionWithHeader.vue'
import StylingInfoBasicInfoSection from '@/components/styling/StylingInfoBasicInfoSection.vue'
import StylingInfoSizingInfoSection from '@/components/styling/StylingInfoSizingInfoSection.vue'
import StylingInfoStyleQuizAnswersSection from '@/components/styling/StylingInfoStyleQuizAnswersSection.vue'
import StylingInfoClientNotesSection from '@/components/styling/StylingInfoClientNotesSection.vue'
import StylingInfoOtherSection from '@/components/styling/StylingInfoOtherSection.vue'

const stylingInfoSection = ref(true)
const basicInfoSection = ref(false)
const sizingInfoSection = ref(false)
const styleQuizAnswersSection = ref(false)
const clientNotesSection = ref(false)
const otherSection = ref(false)

function toggleSection (section) {
  section.value = !section.value
}

const sections = [
  { title: 'Basic Info', section: basicInfoSection, component: StylingInfoBasicInfoSection, class: 'col-2' },
  { title: 'Sizing Info', section: sizingInfoSection, component: StylingInfoSizingInfoSection, class: 'col-2' },
  { title: 'Style Quiz Answers', section: styleQuizAnswersSection, component: StylingInfoStyleQuizAnswersSection, class: 'col-3' },
  { title: 'Client Notes', section: clientNotesSection, component: StylingInfoClientNotesSection, class: 'col-3' },
  { title: 'Other', section: otherSection, component: StylingInfoOtherSection, class: 'col-2' }
]

</script>
<style lang="scss" scoped>
.dropdown {
  height: 180px;
  overflow-y: auto;
  text-align: start;
  word-break: break-word;
  overflow-wrap: break-word;
}
.styling-info {
  background-color: rgba($orchid-light, 0.1);
  height: 100%;
}
</style>
