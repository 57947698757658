<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <div class="px-0 mx-n4">
            <h5 class="mb-0 border-bottom pb-3">
              Unlock Case Swap Early
            </h5>
            <div class="small-text px-3">
              <br>
              Pay your monthly fee now to instantly unlock your case. We'll
              update your billing date, and you can select new styles today.
            </div>
          </div>
        </template>
        <template #body>
          <app-message
            v-if="formErrors.length > 0"
            variant="danger"
            class="p-4 no-padding">
            <div
              v-for="(error, index) in formErrors"
              :key="index">
              <span v-html="error"/>
              <br>
            </div>
          </app-message>
          <h6>Today You Pay</h6>
          <my-case-charge-display
            :price-info="pricing">
            {{ plan.displayName }}
          </my-case-charge-display>
          <div
            class="mt-4">
            <my-case-default-payment-updater/>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <sequin-button
        block
        :disabled="formDisabled || !hasPaymentMethod"
        @click="submit">
        Confirm Purchase <span
          v-if="pricing">
          - {{ formatPrice(pricing.chargeAmount) }}
        </span>
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseChargeDisplay from '../common/MyCaseChargeDisplay'
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import SequinButton from '../../global/sequin/SequinButton'
import { formatPrice } from '@/utils/stringParsing.js'
import MyCaseDefaultPaymentUpdater from '../common/MyCaseDefaultPaymentUpdater'
import AppMessage from '../../global/sequin/AppMessage'
import useAnalytics from '@shared/composables/analytics.js'

import { mapState, mapActions } from 'vuex'

export default {
  components: {
    MyCaseChargeDisplay,
    MyCaseDefaultPaymentUpdater,
    MyCaseSection,
    MyCaseFlyoutComponent,
    AppMessage,
    SequinButton
  },
  setup () {
    const { track } = useAnalytics()
    return {
      track
    }
  },
  data () {
    return {
      pricing: null,
      formDisabled: false,
      formErrors: []
    }
  },
  computed: {
    ...mapState('client', [
      'defaultPayment',
      'plans'
    ]),
    plan () {
      return this.plans.currentBasePlan
    },
    hasPaymentMethod () {
      return Boolean(this.defaultPayment)
    }
  },
  async mounted () {
    this.track('Viewed Unlock Early', { price: this.plan.basePrice })
    this.pricing = await this.getUnlockShipmentPricing()
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    ...mapActions('client', [
      'getUnlockShipmentPricing',
      'unlockShipments'
    ]),
    formatPrice,
    async submit () {
      try {
        this.formDisabled = true
        await this.unlockShipments()
        this.formDisabled = false
        this.formErrors = []
        this.setMyCaseFlyoutName('purchase-shipment-complete')
      } catch (errors) {
        this.formErrors = errors
      }
      this.formDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>

.line-item {
  display: flex;
  justify-content: space-between;
}

.success-light {
  color: $success-light;
}

.no-padding {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
}

</style>
