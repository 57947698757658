<template>
  <my-case-header
    :title="headerText.title"
    variant="orchid">
    <p class="small-text text-white">
      {{ headerText.subtitle }}
    </p>
    <my-case-inactive-checkout-button
      small
      class="mb-2"
      :block="false"/>
  </my-case-header>
</template>

<script>
import { mapState } from 'vuex'
import MyCaseMixin from '../../common/MyCaseMixin'
import MyCaseHeader from '../../common/header/MyCaseHeader'
import MyCaseInactiveCheckoutButton from '../../common/MyCaseInactiveCheckoutButton'
import { toMonthDayYearString } from '@/utils/stringParsing.js'

export default {
  components: {
    MyCaseHeader,
    MyCaseInactiveCheckoutButton
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('client', [
      'membershipDetails',
      'paidThrough'
    ]),
    headerText () {
      switch (this.membershipStatus) {
        case 'unpaid':
          return {
            title: 'Update Payment Method',
            subtitle: 'There\'s an issue with your credit card. Update it to continue.'
          }
        case 'paused':
          return {
            title: 'Membership Paused',
            subtitle: 'Rejoin today to build and ship your next case.'
          }
        case 'items_out_pending_cancel':
          return {
            title: 'Please return your clothes',
            subtitle: `If we do not receive your items by ${toMonthDayYearString(this.membershipDetails.cancelGracePeriodDate)}, you will be charged the full member price for the clothing.`
          }
        default:
          return {
            title: 'Membership Inactive',
            subtitle: 'Subscribe today to start building a case'
          }
      }
    }
  }
}
</script>
