<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <h5 class="mb-0">
            Case Swap Unlocked
          </h5>
        </template>
        <template #body>
          <div class="d-flex flex-column align-items-center p-3 text-center">
            <svg-circle-icon
              :height="36"
              :width="36"
              class="p-2"
              variant="success">
              <svg-check/>
            </svg-circle-icon>
            <p class="my-4">
              You've unlocked your case! <span v-if="canSwapItems">
                {{ isReviewOOC ? `Review at least ${ minItemsToReview } items so that you can swap your items and build a new case.` : 'Now you can swap your items and build a new case.' }}
              </span>
            </p>
            <sequin-button
              v-if="canSwapItems"
              type="router-link"
              :to="{ name: isReviewOOC ? 'review-select' : 'swap-select' }"
              variant="secondary"
              @click="closeFlyout">
              <svg-refresh-c-w
                v-if="!isReviewOOC"
                height="20"
                width="20"
                class="me-2"/>
              {{ isReviewOOC ? 'Review Items' : 'Swap Items' }}
            </sequin-button>
          </div>
        </template>
      </my-case-section>
    </template>
  </my-case-flyout-component>
</template>

<script>
import { mapState, mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'

import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import SequinButton from '../../global/sequin/SequinButton'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgRefreshCW from '@/components/global/svg/SvgRefreshCW.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    MyCaseSection,
    MyCaseFlyoutComponent,
    SequinButton,
    SvgCircleIcon,
    SvgRefreshCW,
    SvgCheck
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('client', [
      'firstName'
    ])
  },
  methods: {
    ...mapVuexActions('case', [
      'setMyCaseFlyoutName'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ])
  }
}
</script>
