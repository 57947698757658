<template>
  <transition
    name="fade"
    appear
    mode="out-in">
    <div
      class="ms-3 py-3 d-flex align-items-top slot">
      <div
        class="pointer"
        @click="viewDetails">
        <my-case-item-image
          size="large"
          :style-color="styleColor"
          :overlay-check="inCase"/>
        <div
          class="w-100 smaller pointer"
          @click="viewDetails">
          {{ brand }}
          <br>
          <span class="semi-bold">
            {{ styleColor.style.name }}
          </span>
          <div
            class="text-pewter small-text">
            Size<span v-if="sizes.length > 1">s</span> {{ sizesDisplay }}
          </div>
          <sequin-tag
            v-if="numStandardSlotsAvailable === 0"
            class="mt-1"
            light
            variant="info">
            {{ formatPrice(extraItemBasePrice, true) }} extra item
          </sequin-tag>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseItemImage from '../common/MyCaseItemImage'
import SequinTag from '../../global/sequin/SequinTag'
import { mapActions, mapGetters } from 'vuex'
import { formatPrice } from '@/utils/stringParsing.js'

export default {
  components: {
    MyCaseItemImage,
    SequinTag
  },
  mixins: [StyleColorMixin, MyCaseMixin],
  props: {
    recommendation: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('client', [
      'numStandardSlotsAvailable'
    ]),
    sampleItem () {
      return this.recommendation.types[0]
    },
    styleColor () {
      return this.styleColorsMap[this.sampleItem.styleColor]
    },
    sizes () {
      if (this.selectedCheckoutRecTypes && this.selectedCheckoutRecTypes.length > 0) {
        return this.selectedCheckoutRecTypes.map(x => x.trueSize)
      } else {
        return this.styleColor.itemTypes.filter(it => it.numAvailable > 0).map(x => x.trueSize)
      }
    },
    sizesDisplay () {
      return this.sizes.join(', ')
    },
    inCase () {
      return this.selectedExtraItemsValid.map(x => x.styleColor).includes(this.sampleItem.styleColor)
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName'
    ]),
    formatPrice,
    viewDetails () {
      this.setMyCaseFlyoutContext({
        checkoutRecommendationStyleColor: this.styleColor
      })
      this.setMyCaseFlyoutName('checkout-recommendation-details')
    }
  }
}
</script>

<style lang="scss" scoped>

.slot {
  width: 160px;
}

 </style>
