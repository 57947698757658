<template>
  <span>
    <!-- Capsule: no swaps available -->
    <span v-if="hasRentalPlan && membershipDetails.allowedShipments === 0 && nextShipmentDate">
      {{ '\n' }}Your next Case Swap unlocks <b>{{ toMonthDayString(nextShipmentDate, 'short') }}.</b>
    </span>

    <!-- Capsule: swaps available -->
    <span v-else-if="hasRentalPlan && membershipDetails.allowedShipments > 0">
      You have <b>{{ membershipDetails.allowedShipments }}</b> Case Swap<span v-if="membershipDetails.allowedShipments > 1">s</span> available<span v-if="itemsInTransit"> and can build a new case once {{ conditionalMessage }}.</span>
    </span>

    <!-- Unlimited -->
    <span v-else-if="hasRentalPlan && membershipDetails.allowedShipments === null">
      You'll be able to swap your items and build a new case once {{ conditionalMessage }}.
    </span>
  </span>
</template>

<script>
import MyCaseMixin from '../../common/MyCaseMixin'
import { toMonthDayString } from '@/utils/stringParsing.js'

export default {
  mixins: [MyCaseMixin],
  props: {
    conditionalMessage: {
      type: String,
      default: 'your package arrives'
    }
  },
  methods: {
    toMonthDayString
  }
}
</script>
