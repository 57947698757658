<template>
  <dropdown-single-select
    v-bind="listeners"
    :model-value="value"
    input-name="size selection"
    :dropdown-options="dropdownOptions"
    :initial-selection-index="initialSelectionIndex"
    :placeholder="placeholder"
    class="size-dropdown"
    small>
    <template #dropdownOptionLabel="{ dropdownOption }">
      <div
        class="d-flex justify-content-between">
        <div>{{ dropdownOption }}</div>
        <div
          class="text-danger">
          {{ getLowAvailability(dropdownOption) }}
        </div>
      </div>
    </template>
  </dropdown-single-select>
</template>

<script>
import DropdownSingleSelect from '../../global/sequin/DropdownSingleSelect'
import { getListeners } from '../../global/helpers/vue'

export default {
  compatConfig: {
    INSTANCE_LISTENERS: false // $listeners are now apart of $attrs in Vue 3
  },
  components: {
    DropdownSingleSelect
  },
  props: {
    value: {
      type: null,
      required: true
    },
    styleColor: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Select a Size'
    },
    initialSelection: {
      type: String,
      default: null
    }
  },
  computed: {
    dropdownOptions () {
      return this.styleColor.sizes.filter(sz => sz.currentStock > 0).map(sizeOption => {
        return {
          value: sizeOption.size,
          disabled: false,
          label: sizeOption.size,
          text: sizeOption.size
        }
      })
    },
    initialSelectionIndex () {
      return this.initialSelection
        ? this.dropdownOptions.map(option => option.value === this.initialSelection)
        : null
    },
    listeners () {
      return getListeners(this.$attrs)
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  unmounted () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    getLowAvailability (size) {
      const sizeData = this.styleColor.sizes.filter(s => s.size === size)
      if (sizeData.length === 1 && sizeData[0].currentStock) {
        return `Only ${sizeData[0].currentStock} left!`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  color: $ash;
}

.size-dropdown {
  width: 100%;
}

</style>
