<template>
  <my-case-with-me-base>
    <template
      v-if="showHeader"
      #header>
      <div class="header-content small-text">
        <p class="mb-0">
          Save an <strong>additional {{ discountPercent }}%</strong> when you
          purchase all of the items with you.
          <text-link
            class="my-n1"
            @click="openPurchaseItemsFlyout()">
            Buy all {{ deliveredItems.length }} items
          </text-link>.
        </p>
      </div>
    </template>
    <template #body="{ deliveredItemsByReturnDate }">
      <div class="py-3">
        <div
          v-for="key in Object.keys(deliveredItemsByReturnDate)"
          :key="key"
          class="mt-md-3 mt-2">
          <my-case-with-me-item
            v-for="(packageItem, idx) in deliveredItemsByReturnDate[key]"
            :key="packageItem.id"
            :class="{'border-top': showHeader || idx !== 0 }"
            :package-item="packageItem"/>
        </div>
      </div>
    </template>
    <template
      v-if="showFooter"
      #end-of-section>
      <div
        class="d-flex"
        :class="{ 'flex-column': isMobile }">
        <sequin-button
          v-if="showSwapReviewButton"
          block
          @click="footerAction">
          <svg-refresh-c-w
            v-if="footerActionType === 'swap'"
            height="20"
            width="20"
            class="me-2"/>
          {{ footerActionText }}
        </sequin-button>
      </div>
    </template>
  </my-case-with-me-base>
</template>

<script>
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseWithMeItem from './MyCaseWithMeItem.vue'
import { mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import TextLink from '../../global/sequin/TextLink'
import SequinButton from '../../global/sequin/SequinButton'
import MyCaseWithMeBase from '../common/MyCaseWithMeBase.vue'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgRefreshCW from '@/components/global/svg/SvgRefreshCW.vue'

export default {
  components: {
    MyCaseWithMeBase,
    MyCaseWithMeItem,
    SequinButton,
    TextLink,
    SvgRefreshCW
  },
  mixins: [MyCaseMixin],
  computed: {
    showSwapReviewButton () {
      return (this.canSwapItems || this.numItemsToReview > 0) && this.primaryCaseState !== 'delivered'
    },
    showFooter () {
      return this.showSwapReviewButton
    },
    showHeader () {
      return this.deliveredItems.length > 1
    },
    footerActionType () {
      return (this.tooManyItemsOut || !this.active || !this.canSwapItems) ? 'review' : 'swap'
    },
    footerActionText () {
      return this.footerActionType === 'review' ? 'Review Items' : 'Swap Items'
    },
    discountPercent () {
      switch (this.deliveredItems.length) {
        case 0:
        case 1:
          return 0
        case 2:
          return 10
        case 3:
          return 15
        default:
          return 20
      }
    }
  },
  mounted () {
    // preload this data to be ready if/when member clicks on buy all button
    if (this.deliveredItems.length > 1) {
      this.getAllItemsPrice()
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    ...mapVuexActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName',
      'getAllItemsPrice'
    ]),
    footerAction () {
      let navigationRoute = 'review-select'
      if (this.footerActionType === 'swap') navigationRoute = 'swap-select'
      this.closeFlyout()
      this.$router.push({ name: navigationRoute })
    },
    openPurchaseItemsFlyout () {
      this.setMyCaseFlyoutName('purchase-all')
    }
  }

}
</script>

<style lang="scss" scoped>
.header-content {
  max-width: 420px;
  margin: 0 auto;
}
</style>
