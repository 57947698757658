<template>
  <base-flyout>
    <template #body>
      <closet-page-detail
        :id="styleColorId"/>
    </template>
    <template #footer>
      <template v-if="loaded">
        <closet-detail-size-select
          v-if="isAvailable && recommendedSizeLoaded"
          v-model="selectionContext.itemType"
          :style-color="styleColor"
          variant="compact"
          @update-size-selection="updateSizeSelection"/>
        <closet-item-type-action-buttons
          v-if="isAvailable && recommendedSizeLoaded"
          class="mt-1 pb-3"
          :selected-item-type="selectionContext.itemType"
          :style-color="styleColor"/>
      </template>
      <template v-else>
        <sequin-button
          block
          type="router-link"
          :to="{
            name: 'style-quiz-intro',
            query: { r: shareProfileId }
          }">
          Sign up to rent this style
        </sequin-button>
      </template>
    </template>
  </base-flyout>
</template>

<script>
import { mapState as mapVuexState, mapActions as mapVuexActions, mapGetters as mapVuexGetters } from 'vuex'
import { mapActions, mapState } from 'pinia'
import ClosetDetailSizeSelect from './ClosetDetailSizeSelect'
import ClosetItemTypeActionButtons from './ClosetItemTypeActionButtons'
import SequinButton from '../global/sequin/SequinButton.vue'
import { useOverlaysStore } from '@/stores/overlays.js'
import { defineAsyncComponent } from 'vue'
import useAnalytics from '@shared/composables/analytics.js'
import BaseFlyout from '@/components/global/BaseFlyout.vue'

export default {
  components: {
    BaseFlyout,
    // Importing dynamically avoids causing an issue with loading ClosetPageDetail
    // directly via route navigation.
    ClosetPageDetail: defineAsyncComponent(() => import('./ClosetPageDetail.vue')),
    ClosetDetailSizeSelect,
    ClosetItemTypeActionButtons,
    SequinButton
  },
  provide () {
    return {
      inClosetDetailFlyout: true,
      forceMobile: true, // Injected into all components via screenSizes.js
      selectionContext: this.selectionContext
    }
  },
  setup () {
    const { track } = useAnalytics()
    return {
      track
    }
  },
  data () {
    return {
      selectionContext: {
        itemType: null
      }
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'flyoutContext'
    ]),
    ...mapVuexState('client', [
      'loaded'
    ]),
    ...mapVuexState('closet', [
      'styleColorsMap',
      'styleColorSources'
    ]),
    ...mapVuexGetters('closet', [
      'styleColorSegmentData'
    ]),
    styleColorId () {
      return this.flyoutContext.styleColorId
    },
    styleColor () {
      return this.styleColorsMap[this.styleColorId] ?? null
    },
    styleColorSource () {
      return this.styleColorSources[this.styleColorId] ?? {}
    },
    recommendedSizeLoaded () {
      return 'recommendedSize' in this.styleColor
    },
    isAvailable () {
      // Based on StyleColorMixin isDiscontinued logic.
      return this.styleColor && this.styleColor.itemTypes && this.styleColor.itemTypes.length
    },
    shareProfileId () {
      return this.$route.query.shareProfileId
    }
  },
  watch: {
    isMobileWidth (newValue) {
      if (newValue) {
        this.closeFlyout('closet-detail')
      }
    }
  },
  async mounted () {
    if (!(this.styleColorId in this.styleColorsMap)) {
      await this.getStyleColor(this.styleColorId)
    }
    if (!this.recommendedSizeLoaded) {
      await this.getClientSizingForStyleColor(this.styleColorId)
    }
    this.track('Opened PDP Flyout', {
      styleColor: this.styleColorSegmentData(this.styleColorId),
      ...this.styleColorSource
    })
  },
  methods: {
    ...mapVuexActions('closet', [
      'getClientSizingForStyleColor',
      'getStyleColor'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    updateSizeSelection (selection) {
      this.selectionContext.itemType = selection
    }
  }
}
</script>
