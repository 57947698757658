<template>
  <div>
    <div
      class="small-text mb-0">
      {{ firstName }} {{ lastName }}
    </div>
    <address-wrapper
      class="small-text"
      :address="shippingAddress"/>
  </div>
</template>

<script>
import AddressWrapper from '../../global/address/AddressWrapper'
import { mapState } from 'vuex'

export default {
  components: {
    AddressWrapper
  },
  props: {
    shippingAddress: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('client', [
      'firstName',
      'lastName'
    ])
  }

}
</script>
