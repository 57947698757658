<template>
  <my-case-header title="Case Packing">
    <p class="small-text mb-0">
      We're packing your case and will notify you when it ships. <slot/>
    </p>
  </my-case-header>
</template>

<script>
import MyCaseMixin from '../MyCaseMixin'
import MyCaseHeader from './MyCaseHeader'

export default {
  components: {
    MyCaseHeader
  },
  mixins: [MyCaseMixin]
}
</script>
