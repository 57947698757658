<template>
  <base-modal
    :hide-close="isMobile"
    :use-body-padding="!isMobile"
    :no-footer="isMobile"
    :use-title-border="isMobile"
    :full-screen="isMobile">
    <template #title>
      <div v-if="!isMobile">
        <h4 class="mb-0 text-start px-3 py-1">
          Share Your Collection
        </h4>
      </div>
      <action-header
        v-else
        no-navigation
        class="row"
        @back-click="closeModal">
        Share Your Collection
        <template #rightHeaderSlot>
          <text-link
            v-if="!postSuccess"
            class="semi-bold"
            :underline="false"
            @click="onShareToFeed">
            Share
          </text-link>
        </template>
      </action-header>
    </template>
    <template #body>
      <div
        :class="{'row': isMobile}">
        <app-message
          v-if="isMobile && postError.length"
          variant="danger">
          {{ postError.join(', ') }}
        </app-message>
        <div>
          <div
            v-if="!postSuccess"
            :class="{ 'border': !isMobile }">
            <collection-feed-preview
              class="border-bottom"
              :collection="collection"/>
            <div class="m-4">
              <h5 v-if="caption === ''">
                Add details...
              </h5>
              <textarea
                ref="captionTextArea"
                v-model="caption"
                rows="4"
                class="caption-text-area"
                placeholder="Share what your collection's about"/>
            </div>
          </div>
          <div
            v-else
            class="d-flex flex-column justify-content-center w-100 align-items-center"
            :class="{ 'px-4': isMobile }">
            <svg-circle-icon
              variant="success"
              class="my-2 mt-4"
              :padding="12"
              :height="52"
              :width="52">
              <svg-check/>
            </svg-circle-icon>
            <h4 class="mb-3">
              Success!
            </h4>
            <p class="text-center">
              Your Collection has been shared to the Community.
            </p>
            <sequin-button
              class="mt-3"
              block
              variant="secondary"
              @click="closeModal">
              Close
            </sequin-button>
            <sequin-button
              type="router-link"
              block
              class="mt-3"
              :to="{ name: 'feed'}">
              View Community
            </sequin-button>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        v-if="!isMobile && !postSuccess"
        class="p-3">
        <app-message
          v-if="postError.length"
          class="mb-2"
          variant="danger">
          {{ postError.join(', ') }}
        </app-message>
        <sequin-button
          block
          :disabled="isPosting"
          @click="onShareToFeed">
          Share to Community
        </sequin-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions as mapVuexActions, mapGetters, mapState as mapVuexState } from 'vuex'
import { mapActions, mapState } from 'pinia'
import BaseModal from '@/components/global/BaseModal.vue'
import ActionHeader from '../../global/sequin/ActionHeader.vue'
import AppMessage from '../../global/sequin/AppMessage.vue'
import CollectionFeedPreview from '../CollectionFeedPreview.vue'
import SequinButton from '../../global/sequin/SequinButton.vue'
import TextLink from '../../global/sequin/TextLink.vue'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    BaseModal,
    ActionHeader,
    AppMessage,
    CollectionFeedPreview,
    SequinButton,
    TextLink,
    SvgCircleIcon,
    SvgCheck
  },
  data () {
    return {
      caption: '',
      postError: [],
      isPosting: false,
      postSuccess: false
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('client', [
      'id'
    ]),
    ...mapVuexState('collections', [
      'collectionsById'
    ]),
    ...mapGetters('collections', [
      'styleColorsFromCollectionId',
      'styleColorCountFromCollectionId'
    ]),
    collectionId () {
      return this.modalContext?.collectionId
    },
    collection () {
      return this.collectionsById[this.collectionId]
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.captionTextArea.focus()
    })
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    ...mapVuexActions('collections', [
      'postCollectionToFeed'
    ]),
    async onShareToFeed () {
      this.isPosting = true
      try {
        await this.postCollectionToFeed({ collectionId: this.collectionId, caption: this.caption })
        this.postSuccess = true
      } catch (err) {
        this.postError = err
        this.isPosting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.caption-text-area {
  width: 100%;
  padding: 0;
  outline: none;
  border: none;
  resize: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: $white;
  }

  &::-webkit-input-placeholder {
    color: $ash;
  }
}
</style>
