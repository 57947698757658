<template>
  <base-modal
    :full-screen="isMobile"
    use-title-border>
    <template #title>
      <h5 class="mb-0">
        Confirm: Delete Collection
      </h5>
    </template>
    <template #body>
      <div class="pt-4 text-center">
        <p>
          Are you sure you want to delete your collection "{{ collection.name }}"?
        </p>
        <p class="mb-0">
          This action is permanent and cannot be undone.
        </p>
      </div>
    </template>
    <template #footer>
      <div class="p-3">
        <app-message
          v-if="errors.length"
          variant="danger">
          {{ errors.join('. ') }}
        </app-message>
        <div
          class="d-flex"
          :class="{ 'flex-column pb-4': isMobile }">
          <sequin-button
            class="me-md-2"
            block
            variant="secondary"
            :disabled="isDeleting"
            @click="closeModal">
            Cancel
          </sequin-button>
          <sequin-button
            class="ms-md-2 mt-3 mt-md-0"
            variant="danger"
            block
            :disabled="isDeleting"
            @click="onDelete">
            Delete Collection
          </sequin-button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions as mapVuexActions, mapState as mapVuexState } from 'vuex'
import { mapActions, mapState } from 'pinia'
import BaseModal from '@/components/global/BaseModal.vue'
import AppMessage from '../../global/sequin/AppMessage.vue'
import SequinButton from '../../global/sequin/SequinButton.vue'
import goBack from '../../global/mixins/goBack'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    BaseModal,
    AppMessage,
    SequinButton
  },
  mixins: [goBack],
  data () {
    return {
      isDeleting: false,
      errors: []
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('collections', [
      'collectionsById'
    ]),
    ...mapVuexState('client', [
      'id'
    ]),
    collectionId () {
      return this.modalContext?.collectionId
    },
    collection () {
      return this.collectionsById[this.collectionId]
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    ...mapVuexActions('collections', [
      'deleteCollection'
    ]),
    async onDelete () {
      try {
        this.isDeleting = true
        await this.deleteCollection({ collectionId: this.collectionId })
        this.goBack()
      } catch (err) {
        this.errors = err
        this.isDeleting = false
      }
    }
  }
}
</script>
