<template>
  <svg
    width="34"
    height="28"
    viewBox="0 0 34 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.0288 26H4.07246C2.63804 26 1.47683 25.3169 1.1353 24.224C0.725467 23.1311 1.27191 21.8333 2.43312 20.9454L16.7774 10.0164C16.9823 9.87979 17.1872 9.87979 17.3921 10.0164L31.7364 20.9454C32.8976 21.8333 33.3758 23.0628 33.0342 24.224C32.6244 25.3169 31.4632 26 30.0288 26ZM17.0506 11.1093L3.04787 21.765C2.2282 22.3798 1.88667 23.1995 2.09159 23.8825C2.29651 24.5656 3.04787 24.9754 4.07246 24.9754H30.0288C31.0533 24.9754 31.8047 24.5656 32.0096 23.8825C32.2145 23.1995 31.873 22.3798 31.0533 21.765L17.0506 11.1093Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"/>
    <path
      d="M17.0511 10.9727C16.7779 10.9727 16.5047 10.7678 16.5047 10.4262V9.06011C16.5047 8.65028 16.8462 8.17214 17.5976 7.21585C18.2807 6.25957 19.2369 5.03006 19.2369 4.27869C19.2369 3.04918 18.2124 2.0929 17.0511 2.0929C15.8899 2.0929 14.8654 3.11749 14.8654 4.27869C14.8654 4.55192 14.6604 4.82514 14.3189 4.82514C13.9774 4.82514 13.7725 4.62022 13.7725 4.27869C13.7725 2.50273 15.2069 1 17.0511 1C18.8954 1 20.3298 2.43443 20.3298 4.27869C20.3298 5.37159 19.3052 6.6694 18.4856 7.8306C18.144 8.30874 17.6659 8.9235 17.5976 9.12842V10.4262C17.5976 10.7678 17.3244 10.9727 17.0511 10.9727Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"/>
  </svg>
</template>
