<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <h5 class="mb-0">
            Style Case for me
          </h5>
        </template>
        <template #body>
          <div class="p-2">
            <div class="d-flex justify-content-center w-100 mb-3">
              <closet-stylist-avatars/>
            </div>
            <p class="small-text text-center mb-3">
              An Armoire personal stylist will select {{ numStandardSlotsAvailable }} personalized piece{{ numStandardSlotsAvailable > 1 ? 's' : '' }}
              <span v-if="numSelectedItems === 0">
                based on your style profile preferences.
              </span>
              <span v-else>
                to complement the {{ numSelectedItems }} style{{ numSelectedItems > 1 ? 's' : '' }} you’ve already picked.
              </span>
              We'll ship your case right away.
            </p>
            <my-case-text-area
              v-model="requestDetails"
              :rows="isMobile ? 6 : 8"
              placeholder="I'm looking for..."/>
            <base-errors :validations="v$.requestDetails"/>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <sequin-button
        block
        :disabled="formDisabled || requestDetails.length === 0"
        @click="requestStylistCase">
        Style Case for me
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import MyCaseTextArea from '../common/MyCaseTextArea'
import SequinButton from '../../global/sequin/SequinButton'
import { mapActions, mapGetters } from 'vuex'
import ClosetStylistAvatars from '../../closet/ClosetStylistAvatars'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BaseErrors from '@/components/global/BaseErrors.vue'

export default {
  components: {
    ClosetStylistAvatars,
    MyCaseFlyoutComponent,
    MyCaseSection,
    MyCaseTextArea,
    SequinButton,
    BaseErrors
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      requestDetails: '',
      formDisabled: false,
      errors: []
    }
  },
  computed: {
    ...mapGetters('client', [
      'numStandardSlotsAvailable',
      'numSelectedItems'
    ]),
    ...mapGetters('closet', [
      'selectedAvailableItemTypes'
    ])
  },
  methods: {
    ...mapActions('closet', [
      'submitCloset'
    ]),
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    async requestStylistCase () {
      this.v$.$touch()
      if (this.v$.$invalid) return

      const formData = {
        stylistCurated: true,
        requestDetails: this.requestDetails
      }

      this.formDisabled = true
      const result = await this.submitCloset(formData)
      this.formDisabled = false

      if (result) {
        this.setMyCaseFlyoutContext({
          confirmedSelectedItems: this.selectedAvailableItemTypes,
          confirmedPackageIsStylistCurated: true
        })
        this.setMyCaseFlyoutName('confirm-case-success')
      } else {
        this.setMyCaseFlyoutContext({ confirmedSelectedItems: [] })
        this.setMyCaseFlyoutName('core')
      }
    }
  },
  validations: {
    requestDetails: {
      required
    }
  }
}
</script>
