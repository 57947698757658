<template>
  <my-case-flyout-component>
    <template #header>
      <h5 class="mb-0">
        Confirm Case
      </h5>
    </template>
    <template #body>
      <my-case-section
        no-padding
        back-button
        variant="default"
        @back-click="backClick()">
        <template #header>
          <h6 class="mb-0">
            Want to purchase a favorite?
          </h6>
          <div
            class="small-text text-pewter pt-3 px-3 mx-3">
            Buy an item you loved at an exclusive discount
          </div>
        </template>
        <template #body>
          <div class="mt-2">
            <div
              class="bg-white px-3">
              <purchase-suggestion
                v-for="item in purchaseSuggestions"
                :key="item.id+1"
                :item="item"/>
            </div>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <div class="my-case-footer d-flex justify-content-between">
        <div class="w-100 ms-2">
          <sequin-button
            block
            @click="continueToConfirm()">
            Continue
          </sequin-button>
        </div>
      </div>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import SequinButton from '../../global/sequin/SequinButton'
import PurchaseSuggestion from './PurchaseSuggestion'
import { mapActions, mapGetters, mapState } from 'vuex'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  components: {
    MyCaseFlyoutComponent,
    MyCaseSection,
    PurchaseSuggestion,
    SequinButton
  },
  setup () {
    const { track } = useAnalytics()
    return { track }
  },
  computed: {
    ...mapState('case', [
      'purchaseSuggestions'
    ]),
    ...mapGetters('client', [
      'availableAddOnItemsToPurchaseAtCheckout'
    ]),
    ...mapGetters('case', [
      'nextFlyout'
    ])
  },
  mounted () {
    this.setMyCaseFlyoutContext({
      purchaseSuggestionDetailsBackClick: 'purchase-suggestions',
      addOnDetailsBackClick: 'purchase-suggestions'
    })
    this.track('Viewed Checkout Purchase Favorite')
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    continueToConfirm () {
      this.setMyCaseFlyoutName(this.nextFlyout)
    },
    backClick () {
      this.setMyCaseFlyoutName('core')
    }
  }
}
</script>

<style lang="scss" scoped>

.size-dropdown {
  width: 100%;
}

 </style>
