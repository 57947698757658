<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        :class="{'success-header': active} "
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <div class="border-bottom p-3">
            <h5 class="mt-1 mb-0">
              {{ itemContainerText }} Confirmed
            </h5>
          </div>
          <div
            v-if="active"
            class="success-animation-wrapper">
            <my-case-confetti-canvas
              class="confetti-canvas"
              :show-confetti="showConfetti"/>
            <div class="member-info">
              <img
                class="mb-3 dress-success"
                height="60"
                :src="successImageUrl">
              <h6>Get excited, {{ firstName }}!</h6>
              <p class="small-text mb-0">
                <span v-if="confirmedPackageIsStylistCurated">
                  Our styling team has received your request and will notify you when your selections are ready for review.
                </span>
                <span v-else>
                  You've got {{ confirmedSelectedItems.length }} new {{ confirmedSelectedItems.length > 1 ? 'styles' : 'style ' }} headed your way
                  <span v-if="hasRentalPlan">, and have rented
                    <span class="highlight">
                      $<ICountUp
                        :end-val="totalRetailValueReceived"
                        :options="countUpOptions"/>
                    </span>
                    of clothing since joining Armoire!
                  </span>
                </span>
              </p>
            </div>
          </div>
          <div v-else>
            <h6>Almost there, {{ firstName }}!</h6>
            <p class="small-text">
              Your items are confirmed. We’ll ship your case as soon as you {{ membershipStatus === 'paused' ? 'resume your membership' : 'complete checkout' }}.
            </p>
            <my-case-inactive-checkout-button/>
          </div>
        </template>
        <template #body>
          <div class="my-3">
            <div
              class="mx-3">
              <sequin-tag
                v-if="active"
                light
                :variant="confirmedPackageIsStylistCurated ? 'primary' : 'info'">
                {{ confirmedPackageIsStylistCurated ? 'Styling your case' : 'Packing' }}
              </sequin-tag>
              <sequin-tag
                v-else
                variant="danger">
                Waiting for Checkout
              </sequin-tag>
              <div
                v-if="confirmedCaseExpedited">
                <div
                  class="semi-bold mt-3 mb-2">
                  {{ deliveryBody }}
                </div>
                <div
                  class="small-text mb-3">
                  Your {{ expeditedShipper.name }} driver will text you when they're on their way.
                </div>
                <app-message
                  v-if="confirmedCaseExpeditedPickup"
                  variant="info"
                  class="my-3 mx-n3">
                  Please have your return bag ready for the driver and ensure
                  the bag is sealed with your return label or full name
                  clearly visible.
                </app-message>
              </div>
              <my-case-shipping-address-selected/>
            </div>
            <div
              v-if="showSubmittedPage">
              <div
                class="w-100 flex-column d-flex align-items-center pt-5"
                :class="animationClass">
                <h6
                  class="text-center pb-5"
                  :style="{fontSize: '22px !important', lineHeight: '26px'}">
                  Thank you for<br> your feedback!
                </h6>
                <svg-confetti-mannequin/>
              </div>
            </div>
            <div v-else>
              <my-case-select-backup-size
                v-if="showSelectBackupSize && backupItemTypes.length > 0"
                @no-backup-size-clicked="showSelectBackupSize = false"
                @backup-size-clicked="showSelectBackupSize = false"/>
              <div class="my-1 mx-3 me-2">
                <h6 v-if="showCaseSurvey">
                  How do you feel about the {{ confirmedSelectedItems.length }} {{ confirmedSelectedItems.length > 1 ? 'items' : 'item ' }} in this Case?
                </h6>
                <my-case-item
                  v-for="item in confirmedSelectedItems"
                  :key="item.id"
                  :item="item"
                  hide-price
                  :case-satisfaction="showCaseSurvey">
                  <template #itemInfoSlot>
                    <sequin-tag
                      v-if="item.id === selectedBackupItemTypeId"
                      light
                      variant="success">
                      Free backup size
                    </sequin-tag>
                  </template>
                </my-case-item>
                <my-case-item
                  v-for="(item, index) in soldFutureItems"
                  :key="index"
                  :item="item"
                  image-label="Purchased"
                  hide-price/>
                <add-on-item-type
                  v-for="item in purchasedAddOnItems"
                  :key="item.addOnTypeId"
                  :type-id="item.addOnTypeId"
                  :quantity="item.quantity"
                  back-click="confirm-case-success"
                  confirmed/>
                <div v-if="confirmedPackageIsStylistCurated">
                  <my-case-item
                    v-for="(_, index) in pendingItems"
                    :key="index"
                    hide-price
                    variant="hanger">
                    <span v-if="index === 0">
                      <span v-if="confirmedSelectedItems.length === 0">
                        A personal stylist is preparing your {{ pendingItems.length }} item case
                      </span>
                      <span v-else>
                        A personal stylist is selecting your {{ pendingItems.length }} additional item{{ pendingItems.length > 1 ? 's' : '' }}
                      </span>
                    </span>
                  </my-case-item>
                </div>
              </div>
            </div>
          </div>
        </template>
      </my-case-section>
    </template>
    <template
      v-if="showCaseSurvey && !showSubmittedPage"
      #footer>
      <div class="d-flex justify-content-around">
        <div class="w-50 ms-2">
          <sequin-button
            block
            :disabled="submitCaseSurveyDisabled"
            @click="submitCaseSurvey">
            <span>Submit</span>
          </sequin-button>
        </div>
      </div>
    </template>
  </my-case-flyout-component>
</template>

<script>
import AddOnItemType from '../addon/AddOnItemType'
import AppMessage from '../../global/sequin/AppMessage'
import MyCaseConfettiCanvas from './MyCaseConfettiCanvas'
import ICountUp from 'vue-countup-v2'
import MyCaseInactiveCheckoutButton from '../common/MyCaseInactiveCheckoutButton'
import MyCaseShippingAddressSelected from '../common/MyCaseShippingAddressSelected'
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import MyCaseItem from '../common/MyCaseItem'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseSelectBackupSize from './MyCaseSelectBackupSize'
import SequinTag from '../../global/sequin/SequinTag'
import { mapState, mapActions as mapVuexActions, mapGetters } from 'vuex'
import { mapActions } from 'pinia'
import dressImages from '../../global/mixins/dressImages'
import SequinButton from '../../global/sequin/SequinButton.vue'
import useAnalytics from '@shared/composables/analytics.js'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgConfettiMannequin from '@/components/global/svg/SvgConfettiMannequin.vue'

export default {
  components: {
    AddOnItemType,
    AppMessage,
    MyCaseConfettiCanvas,
    ICountUp,
    MyCaseFlyoutComponent,
    MyCaseInactiveCheckoutButton,
    MyCaseItem,
    MyCaseSection,
    MyCaseSelectBackupSize,
    MyCaseShippingAddressSelected,
    SequinTag,
    SequinButton,
    SvgConfettiMannequin
  },
  mixins: [MyCaseMixin, dressImages],
  setup () {
    const { track } = useAnalytics()
    return { track }
  },
  data () {
    return {
      showConfetti: false,
      countUpOptions: {
        decimal: '.',
        duration: 0.75,
        prefix: '',
        separator: ',',
        suffix: '',
        useEasing: false,
        useGrouping: true
      },
      showSelectBackupSize: true,
      showSubmittedPage: false,
      showCaseSurvey: true,
      animationClass: ''
    }
  },
  computed: {
    successImageUrl () {
      return this.getDressUrlFromClientId(this.id)
    },
    ...mapState('client', [
      'firstName',
      'retailValueReceived',
      'membershipStatus',
      'id'
    ]),
    ...mapState('closet', [
      'pendingItems'
    ]),
    ...mapState('case', [
      'backupItemTypes',
      'confirmedSelectedItems',
      'confirmedPackageId',
      'confirmedPackageIsStylistCurated',
      'confirmedCaseExpedited',
      'confirmedCaseExpeditedPickup',
      'purchasedAddOnItems',
      'selectedBackupItemTypeId',
      'caseSatisfactionSurveyOptions',
      'caseSatisfactionSurveyAnswers'
    ]),
    ...mapGetters('client', [
      'active',
      'itemContainerText'
    ]),
    ...mapGetters('case', [
      'expeditedShipper'
    ]),
    totalRetailValueReceived () {
      let confirmedSelectedItemsValue = 0
      this.confirmedSelectedItems.forEach(item => {
        confirmedSelectedItemsValue += (this.styleColorsMap[item.styleColor].style?.retailPrice || 0)
      })

      return Math.round(this.retailValueReceived + confirmedSelectedItemsValue)
    },
    deliveryBody () {
      return `Your items will arrive ${this.deliveryTime(false)}`
    },
    submitCaseSurveyDisabled () {
      return Object.keys(this.caseSatisfactionSurveyAnswers).length === 0
    }
  },
  mounted () {
    if (!this.hasRentalPlan) {
      this.showCaseSurvey = false
    }
    this.fetchCaseSatisfactionSurveyOptions()
    if (this.confirmedPackageIsStylistCurated) {
      this.showCaseSurvey = false
    }
    setTimeout(() => { this.showConfetti = true }, this.countUpOptions.duration * 1000)
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    ...mapVuexActions('case', [
      'setMyCaseFlyoutName',
      'fetchCaseSatisfactionSurveyOptions',
      'submitCaseSatisfactionSurveyAnswers'
    ]),
    async submitCaseSurvey () {
      await this.submitCaseSatisfactionSurveyAnswers()
      this.showSubmittedPage = true
      this.animationClass = 'appear'
      this.track('Submitted Case Satisfaction Survey')
      setTimeout(() => { this.showSubmittedPage = false; this.showCaseSurvey = false }, 5000)
      setTimeout(() => { this.animationClass = 'disappear' }, 4000)
    }
  }
}
</script>

<style lang="scss" scoped>
.highlight {
  color: $plum;
  font-weight: $font-weight-bold;
  animation: scale-up-after-count .4s ease .75s;
  display: inline-block;

  @keyframes scale-up-after-count {
    0% { transform: scale(1) }
    50% { transform: scale(1.1) }
    100% { transform: scale(1) }
  }
}

.success-header {
  :deep(.section-header)  {
    padding: 0 !important;
  }

  :deep(.section) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.success-animation-wrapper {
  position: relative;
  height: 212px;
  display: flex;
  align-items: center;
  background-color: $origami;

  .confetti-canvas, .member-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .member-info {
    z-index: 10;
    padding: 20px;
  }
}
:deep(.expandable-content) {
  padding: 0 !important;
}
.dress-success {
  @include scale-up-down-animation;
  animation-delay: .75s;
}

.appear {
  animation: appear .5s ease;
    @keyframes appear {
      0% { opacity: 0 }
      100% { opacity: 1 }
    }
}
.disappear {
    animation: disappear .5s forwards ease-in;

     @keyframes disappear {
      0% {
        opacity: 1
      }

      100% {
        opacity: 0
      }
    }
  }
</style>
