<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        no-padding
        back-button
        @back-click="setMyCaseFlyoutName('choose-shipping')">
        <template #header>
          <h6>Change Shipping Address</h6>
          <sequin-button
            small
            class="mt-2"
            variant="secondary"
            @click="onAddNewAddress()">
            + Add New
          </sequin-button>
        </template>
        <template #body>
          <transition name="fade">
            <app-message
              v-if="errorMessage.length"
              variant="danger"
              class="p-4">
              {{ errorMessage }}
            </app-message>
          </transition>
          <div
            v-if="pickupAddress || shippingAddress"
            class="change-address-section">
            <h5 v-if="pickupAddress">
              Selected Pickup Address
            </h5>
            <h5 v-else-if="shippingAddress">
              Selected Shipping Address
            </h5>
            <div v-if="shippingAddress">
              <my-case-shipping-address :shipping-address="shippingAddress"/>
              <text-link @click="onEditShippingAddress(shippingAddress, true)">
                Edit
              </text-link>
            </div>
            <pickup-address
              v-if="pickupAddress"
              class="small-text mt-0"
              :pickup-address="pickupAddress"/>
          </div>
          <div
            v-for="location in nonSelectedPickupLocations"
            :key="location.id"
            class="change-address-section">
            <div class="d-flex justify-content-between">
              <div>
                <pickup-address
                  class="small-text mt-0"
                  :pickup-address="location"/>
                <text-link
                  class="mt-2 d-block"
                  @click="selectLocation(location)">
                  Select
                </text-link>
              </div>
              <sequin-tag variant="secondary">
                Pick up
              </sequin-tag>
            </div>
          </div>
          <div
            v-for="address in nonSelectedShippingAddresses"
            :key="address.id"
            class="change-address-section">
            <my-case-shipping-address :shipping-address="address"/>
            <div class="mt-2 d-flex">
              <text-link @click="selectAddress(address)">
                Select
              </text-link>
              <text-link
                class="ms-3"
                variant="secondary"
                @click="onEditShippingAddress(address)">
                Edit
              </text-link>
            </div>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <sequin-button
        block
        :disabled="updatingAddress"
        @click="setMyCaseFlyoutName(nextFlyout)">
        {{ updatingAddress ? 'Updating...' : 'Done' }}
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import PickupAddress from '../../global/address/PickupAddress'
import MyCaseShippingAddress from '../common/MyCaseShippingAddress'
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import MyCaseMixin from '../common/MyCaseMixin'
import AppMessage from '../../global/sequin/AppMessage'
import TextLink from '../../global/sequin/TextLink'
import ShippingAddressMixin from '../../account/ShippingAddressMixin'
import SequinButton from '../../global/sequin/SequinButton'
import { mapActions, mapGetters, mapState } from 'vuex'
import SequinTag from '@/components/global/sequin/SequinTag'

export default {
  components: {
    PickupAddress,
    MyCaseFlyoutComponent,
    MyCaseSection,
    MyCaseShippingAddress,
    AppMessage,
    SequinTag,
    TextLink,
    SequinButton
  },
  mixins: [MyCaseMixin, ShippingAddressMixin],
  data () {
    return {
      errorMessage: ''
    }
  },
  computed: {
    ...mapState('client', [
      'firstName',
      'lastName',
      'username'
    ]),
    ...mapState('account', [
      'updatingAddress'
    ]),
    ...mapGetters('case', [
      'nextFlyout'
    ])
  },
  mounted () {
    this.getShippingAddresses(this.username)
    this.getPickupLocations()
    if (!(this.pickupAddress || this.shippingAddress)) {
      this.errorMessage = 'You must add a shipping address or select a pickup location below to confirm your case.'
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName',
      'resetShipping'
    ]),
    ...mapActions('account', [
      'getShippingAddresses',
      'getPickupLocations',
      'selectShippingAddress',
      'selectPickupLocation'
    ]),
    onAddNewAddress () {
      this.resetShipping()
      this.setMyCaseFlyoutContext({
        setShippingAddressEditingSelected: true
      })
      this.setMyCaseFlyoutName('edit-shipping-address')
    },
    onEditShippingAddress (address, selected = false) {
      this.resetShipping()
      this.setMyCaseFlyoutContext({
        shippingAddressEditing: address,
        setShippingAddressEditingSelected: selected
      })
      this.setMyCaseFlyoutName('edit-shipping-address')
    },
    async selectAddress (address) {
      try {
        await this.selectShippingAddress(address)
        this.resetShipping()
        this.errorMessage = ''
      } catch (err) {
        this.errorMessage = 'There was a problem updating your address.'
      }
    },
    async selectLocation (location) {
      try {
        await this.selectPickupLocation(location)
        this.resetShipping()
        this.errorMessage = ''
      } catch (err) {
        this.errorMessage = 'There was a problem setting your pickup location'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.change-address-section {
  padding: 24px;
  border-bottom: $default-border;
}
</style>
