
<template>
  <div
    class="square-image-container"
    :style="{ width, height: width }"/>
</template>

<script>
import inViewport from 'vue-in-viewport-mixin'

export default {
  mixins: [inViewport],
  props: {
    width: {
      type: [Number, String],
      default: 64
    },
    hideOutsideOfViewport: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.square-image-container {
  @include placeholder-loading-animation;
}

.square-image {
  height: 100%;
  width: 100%;
}
</style>
