<template>
  <textarea
    v-bind="$attrs"
    id="my-case-text"
    @input="event => { $emit('input', event.target.value )}"/>
</template>

<script>
export default {

}
</script>

<style lang="scss">
#my-case-text {
  width: 100%;
  border-radius: 2px;
  padding: 8px 16px;
  resize: none;
  border-color: $ash;
  font-size: $font-size-base;
  -webkit-appearance: none;

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $pewter;
  }
}
</style>
