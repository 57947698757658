<template>
  <p><b>Birthday:</b> {{ birthday }}</p>
  <p><b>Height:</b> {{ heightFeet }}'{{ heightInches }}"</p>
  <p><b>Zipcode:</b> {{ stylingInfo.zipcode }}</p>
  <p><b>City:</b> {{ stylingInfo.city }}</p>
  <p><b>State:</b> {{ stylingInfo.state }}</p>
  <p><b>5 day high temp:</b> {{ stylingInfo.forecastTemp }}</p>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import { useStylingStore } from '@/stores/styling'
import { useStyleProfileStore } from '@/stores/styleProfile'

const { birthDate: birthday, heightFeet, heightInches } = storeToRefs(useStyleProfileStore())

const styling = useStylingStore()
const { stylingInfo } = storeToRefs(styling)

</script>
