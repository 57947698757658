<template>
  <base-modal use-title-border>
    <template #title>
      <h5 class="mb-0">
        Delete Post
      </h5>
    </template>
    <template #body>
      <div class="pt-5 text-center">
        <error-message
          v-if="deleteError"
          class="mb-4">
          We encountered an error while trying to delete your {{ itemInFocus.type }}. Please try again.
        </error-message>
        <h5>Are you sure you want to delete this {{ itemInFocus.type }}?</h5>
        <p class="mb-0">
          This action is permanent and cannot be undone.
        </p>
      </div>
    </template>
    <template #footer>
      <div class="w-100 d-flex p-2">
        <sequin-button
          :disabled="isDeleting"
          variant="danger"
          block
          :class="{ 'mb-2': isMobile }"
          class="me-2"
          @click="deleteItem">
          Yes, I'm Sure
        </sequin-button>
        <sequin-button
          :disabled="isDeleting"
          block
          variant="secondary"
          @click="closeModal()">
          Cancel
        </sequin-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapState as mapVuexState, mapActions as mapVuexActions } from 'vuex'
import { mapState, mapActions } from 'pinia'
import BaseModal from '@/components/global/BaseModal.vue'
import SequinButton from '../../../global/sequin/SequinButton'
import ErrorMessage from '../../common/ErrorMessage'
import goBack from '../../../global/mixins/goBack'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    BaseModal,
    ErrorMessage,
    SequinButton
  },
  mixins: [goBack],
  data () {
    return {
      isDeleting: false,
      deleteError: false
    }
  },
  computed: {
    ...mapVuexState('community', [
      'itemInFocus'
    ]),
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    currentFocusItem () {
      return this.itemInFocus
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    ...mapVuexActions('community', [
      'deleteFeedItemComment',
      'deleteLook',
      'deleteGenericFeedItem',
      'deleteFeedItem'
    ]),
    async deleteItem () {
      this.isDeleting = true
      this.deleteError = false
      const parsedItemInFocus = JSON.parse(JSON.stringify(this.itemInFocus))
      const itemInFocusFeedItemId = parsedItemInFocus.item.feedItemId
      const itemInFocusGenericItem = parsedItemInFocus.item // May be undefined.
      if (parsedItemInFocus.type === 'look' || itemInFocusGenericItem?.type.includes('look')) {
        try {
          await this.deleteLook({ feedItemId: itemInFocusFeedItemId, lookId: itemInFocusGenericItem.id })
        } catch {
          this.deleteError = true
          this.isDeleting = false
        }

        if (!this.deleteError && (this.$route.name === 'feed-item' || this.$route.name === 'look')) {
          this.goBack()
        }
      } else if (parsedItemInFocus.type === 'post') {
        if (itemInFocusGenericItem?.type === 'member style color collection') {
          try {
            await this.deleteFeedItem({ feedItemId: itemInFocusFeedItemId })
          } catch (err) {
            this.deleteError = true
            this.isDeleting = false
          }
        } else {
          try {
            await this.deleteGenericFeedItem({ feedItemId: itemInFocusFeedItemId, genericFeedItemId: itemInFocusGenericItem.id })
          } catch {
            this.deleteError = true
            this.isDeleting = false
          }
        }

        if (!this.deleteError && (this.$route.name === 'feed-item')) {
          this.goBack()
        }
      } else {
        try {
          await this.deleteFeedItemComment(this.itemInFocus)
        } catch {
          this.deleteError = true
          this.isDeleting = false
        }
      }

      if (!this.deleteError) {
        this.closeModal()
      }
    }
  }
}
</script>
