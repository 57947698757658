<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('case', [
      'availableAddOnItems'
    ]),
    brand () {
      if (this.addOnItem.brand) { return this.addOnItem.brand.name }
      return ''
    },
    title () {
      if (this.addOnItem.shortTitle) {
        return this.addOnItem.shortTitle
      }
      if (this.addOnItem.longTitle) {
        return this.addOnItem.longTitle
      }
      return ''
    },
    representativeImageUrl () {
      if (this.styleColor) {
        return this.styleColor.images[0].url
      }
      return this.addOnItem.colors[0].images[0].url
    },
    maxQuantity () {
      if (this.styleType && this.styleType.currentStock) { return this.styleType.currentStock }
      return 9 // arbitrary; client doesn't know stock level,
      // but submitting a large number is likely fraudulent, so
      // we should limit it
    },
    maxPrice () {
      const maxReducer = (max, color) => {
        const maxTypePrice = color.sizes.reduce((max, type) => {
          return type.price > max ? type.price : max
        }, 0)
        return max > maxTypePrice ? max : maxTypePrice
      }
      return this.addOnItemViewing.colors.reduce(maxReducer, 0)
    },
    minPrice () {
      const minReducer = (min, color) => {
        const minTypePrice = color.sizes.reduce((min, type) => {
          return type.price < min ? type.price : min
        }, Number.MAX_SAFE_INTEGER)
        return min < minTypePrice ? min : minTypePrice
      }
      return this.addOnItemViewing.colors.reduce(minReducer, Number.MAX_SAFE_INTEGER)
    }
  },
  methods: {
  }
}
</script>
