<template>
  <base-modal>
    <template #body>
      <div class="text-center">
        <h2>Update your card</h2>
        <update-default-payment
          class="mt-4"
          @default-payment-updated="closeModal()"/>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions } from 'pinia'
import UpdateDefaultPayment from '../global/stripe/UpdateDefaultPayment'
import { useOverlaysStore } from '@/stores/overlays.js'
import BaseModal from '@/components/global/BaseModal.vue'

export default {
  components: {
    BaseModal,
    UpdateDefaultPayment
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ])
  }
}

</script>

<style lang="scss" scoped>

:deep(.modal-dialog) {
  max-width: 400px !important;
}

</style>
