<template>
  <my-case-header :title="itemContainerText"/>
</template>

<script>
import { mapGetters } from 'vuex'
import MyCaseHeader from './MyCaseHeader'

export default {
  components: {
    MyCaseHeader
  },
  computed: {
    ...mapGetters('client', [
      'itemContainerText'
    ])
  }
}
</script>
