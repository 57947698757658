export const StyleColorMixin = {
  computed: {
    brand () {
      return this.styleColor.style.brand
    },
    shortName () {
      return this.getShortName(this.styleColor)
    }
  },
  methods: {
    getShortName (styleColor) {
      if (styleColor.style.subcategory === 'Other') return ''
      if (styleColor.style.subcategory === 'Soft Pants') return ' Casual Pants'
      if (styleColor.style.subcategory === 'Soft Jacket') return ' Jacket'
      if (styleColor.style.subcategory === 'Shirt/Button-up') return ' Button-up'
      return ' ' + styleColor.style.subcategory
    }
  }
}
