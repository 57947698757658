<template>
  <my-case-section-core no-padding>
    <template #header>
      <div v-if="hasHeaderSlot">
        <slot name="header"/>
      </div>
    </template>
    <template #body>
      <div class="pb-5 pb-md-3">
        <styles-unavailable v-if="unavailableItemTypes.length"/>
        <selected-items-mismatch v-if="selectedItemsMismatch"/>
        <transition
          name="fade">
          <app-message
            v-if="numExtraSlotsOverLimit > 0 "
            variant="danger"
            class="text-center">
            <div><strong>Item max reached</strong></div>
            <div v-if="maxNumExtraItems">
              <strong>You can add up to {{ maxNumExtraItems }} extra items per case.</strong>
            </div>
            <div>
              Remove at least {{ numExtraSlotsOverLimit }} item{{ numExtraSlotsOverLimit > 1 ? 's' : '' }} to continue.
            </div>
          </app-message>
        </transition>
        <div class="pb-3 pb-md-4">
          <slot name="above-items"/>
          <div
            class="px-4 py-3 py-md-4 px-md-5">
            <my-case-item
              v-for="item in selectedExtraItemsValid"
              :key="`add-on-${item.id}`"
              can-edit
              hide-price
              :use-large-edit-button="!isMobile"
              :item="item">
              <template #itemInfoSlot>
                <sequin-tag
                  light
                  variant="info">
                  {{ formatPrice(extraItemBasePrice, true) }} extra item
                </sequin-tag>
              </template>
            </my-case-item>
            <my-case-item
              v-for="item in selectedExtraItemsOverLimit"
              :key="`add-on-${item.id}`"
              variant="error"
              can-edit
              hide-price
              :use-large-edit-button="!isMobile"
              :item="item">
              <template #itemInfoSlot>
                <sequin-tag
                  light
                  variant="danger">
                  Item max reached
                </sequin-tag>
              </template>
            </my-case-item>
            <my-case-item
              v-for="(item, index) in selectedStandardItems"
              v-bind="$attrs"
              :key="`items-filled-${item.id}`"
              can-edit
              hide-price
              :use-large-edit-button="!isMobile"
              :item="item">
              <template #itemInfoSlot>
                <sequin-tag
                  v-if="index < numRolloverItems"
                  variant="darkGray">
                  ROLLOVER ITEM
                </sequin-tag>
              </template>
            </my-case-item>
            <my-case-item
              v-for="(item, index) in casePurchaseItems"
              :key="index"
              can-edit
              use-large-edit-button
              :item="item"/>
            <slot name="below-standard-items"/>
            <my-case-item
              v-for="(item, index) in soldFutureItems"
              :key="index"
              :item="item"
              image-label="Purchased"
              hide-price/>
          </div>
        </div>
      </div>
    </template>
    <template #sticky-footer>
      <div class="px-4 py-3 px-md-5">
        <my-case-ships-today class="mb-2"/>
        <slot name="end-of-section"/>
      </div>
    </template>
  </my-case-section-core>
</template>

<script>
import MyCaseSectionCore from '../common/MyCaseSectionCore'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseItem from '../common/MyCaseItem'
import MyCaseShipsToday from './MyCaseShipsToday'
import SequinTag from '../../global/sequin/SequinTag'
import AppMessage from '../../global/sequin/AppMessage'
import { formatPrice } from '@/utils/stringParsing.js'
import SelectedItemsMismatch from '../errorNotifications/SelectedItemsMismatch'
import StylesUnavailable from '../errorNotifications/StylesUnavailable'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    MyCaseItem,
    MyCaseShipsToday,
    AppMessage,
    SequinTag,
    MyCaseSectionCore,
    SelectedItemsMismatch,
    StylesUnavailable
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('client', [
      'plans'
    ]),
    hasHeaderSlot () {
      return this.$slots.header
    },
    isCapsule () {
      return this.plans.currentBasePlan.shipmentsPerInterval
    }
  },
  mounted () {
    this.resetShipping()
  },
  methods: {
    ...mapActions('case', [
      'resetShipping'
    ]),
    formatPrice
  }
}
</script>
