<template>
  <my-case-section-core>
    <template #header>
      <span class="small-text">
        {{ soldFutureItems.length > 1 ? 'These items' : 'This item' }} will ship with your next case.
      </span>
    </template>
    <template #body>
      <my-case-item
        v-for="(item, index) in soldFutureItems"
        :key="index"
        :item="item"
        image-label="Purchased"
        hide-price/>
    </template>
  </my-case-section-core>
</template>

<script>
import MyCaseSectionCore from '../common/MyCaseSectionCore'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseItem from '../common/MyCaseItem'

export default {
  components: {
    MyCaseItem,
    MyCaseSectionCore
  },
  mixins: [MyCaseMixin]
}
</script>
