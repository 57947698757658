<template>
  <edit-delete-menu
    item-name="Look"
    variant="right"
    @delete-click="onDelete"
    @edit-click="onEdit"
    @cancel-click="closeModal"/>
</template>

<script>
import EditDeleteMenu from './EditDeleteMenu'
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    EditDeleteMenu
  },
  computed: {
    ...mapState('community', [
      'itemInFocus'
    ])
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal',
      'openModal'
    ]),
    ...mapVuexActions('community', [
      'initializeLookBuilder',
      'deleteLook'
    ]),
    onEdit () {
      this.initializeLookBuilder(this.itemInFocus)
      this.$router.push({ name: 'edit-look' })
      this.closeModal()
    },
    async onDelete () {
      this.openModal({ name: 'community-delete-confirmation' })
    }
  }
}
</script>
