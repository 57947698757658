<template>
  <base-modal
    dialog-class="fit-content"
    :flush-content="true"
    :use-title-border="true">
    <template #title>
      <h5>
        {{ modalTitle }}
      </h5>
    </template>
    <template #body>
      <closet-photo-album
        photo-size="fullscreen"
        :initial-index="photoAlbumIndex"
        :photo-album-entries="photoAlbumEntries"
        @update-index="handleIndexUpdate"/>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '../global/BaseModal'
import ClosetPhotoAlbum from './ClosetPhotoAlbum'
import { mapState as mapVuexState } from 'vuex'
import { mapState } from 'pinia'
import { vuexAccessors } from '../global/helpers/vuex'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  name: 'ClosetProductPhotos',
  components: {
    BaseModal,
    ClosetPhotoAlbum
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('closet', [
      'photoAlbumIndex',
      'photoAlbumEntries'
    ]),
    ...vuexAccessors('closet', [
      'photoAlbumIndex'
    ]),
    modalTitle () {
      return `${this.modalContext.shortName} (${this.photoAlbumIndex + 1}/${this.photoAlbumEntries.length})`
    }
  },
  methods: {
    handleIndexUpdate (newIndex) {
      this.photoAlbumIndex = newIndex
    }
  }
}
</script>
