<template>
  <base-modal
    hide-close>
    <template #body>
      <div class="text-center">
        <div>
          <h6>Are you sure?</h6>
          <sequin-button
            variant="danger"
            @click="handleDeleteAccountClick">
            Delete account
          </sequin-button>
          <sequin-button
            variant="primary"
            class="mt-3"
            @click="closeModal">
            Keep account
          </sequin-button>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions } from 'pinia'
import SequinButton from '../global/sequin/SequinButton.vue'
import { useOverlaysStore } from '@/stores/overlays.js'
import BaseModal from '@/components/global/BaseModal.vue'

export default {
  components: {
    BaseModal,
    SequinButton
  },
  computed: {
    ...mapState('client', [
      'username'
    ])
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    ...mapVuexActions('account', [
      'deleteAccount'
    ]),
    logout () {
      if (window.fbInitialized && window.fbAccessToken) {
        // eslint-disable-next-line
        FB.logout(function (response) { })
      }

      location.href = '/logout/'
    },
    async handleDeleteAccountClick () {
      await this.deleteAccount(this.username)
      this.closeModal()
      this.logout()
    }
  }
}
</script>
