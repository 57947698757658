<template>
  <base-modal
    ui-refresh
    use-title-border
    class="'qr-code-modal'"
    flush-content
    :width="`${width}px`">
    <template #title>
      <h3>
        Refer a Friend
      </h3>
      <p class="m-0">
        Have your friend scan this code with their camera
      </p>
    </template>
    <template #body>
      <div class="qr-code-container">
        <img
          v-if="qrCode"
          :src="qrCode"
          class="qr-code-image color-plum m-lg"
          alt="QR Code">
        <IconOrigamiDress
          ref="origamiDressIcon"
          class="icon color-plum"/>
      </div>
    </template>
    <template #footer>
      <BaseButton
        class="mx-auto d-block"
        text="Close"
        @click="onModalClose"/>
    </template>
  </base-modal>
</template>

<script setup>
import { computed } from 'vue'
import BaseModal from '@/components/global/BaseModal.vue'
import { useOverlaysStore } from '@/stores/overlays.js'
import { useQRCode } from '@vueuse/integrations/useQRCode'
import IconOrigamiDress from '@shared/components/icons/IconOrigamiDress.vue'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import { useStore } from 'vuex'

const overlaysStore = useOverlaysStore()
const store = useStore()
const referralCode = computed(() => store.state.client.referralCode)
const referralUrl = computed(() => `https://www.armoire.style/refer/${referralCode.value}`)
const width = 375
const margin = 20

const qrCode = useQRCode(referralUrl, {
  color: {
    dark: '#520845',
    light: '#FFFFFF'
  },
  width: width - margin * 2,
  errorCorrectionLevel: 'H',
  margin: 0
})

function onModalClose () {
  overlaysStore.closeModal()
}

</script>

<style scoped>
.qr-code-canvas {
    display: block;
  }
.qr-code-container {
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  background-color: white;
  transform: translate(-50%, -50%);
}
.qr-code-image {
  display: block;
  max-width: 100%;
  margin: auto;
}
</style>
