<template>
  <div :style="cssVars">
    <div
      class="flyout-backdrop"
      :style="{'z-index': flyoutZIndex}"
      @click="onFlyoutCloseFromOutside"/>
    <div
      class="flyout"
      :class="side"
      :style="{'z-index': flyoutZIndex}"
      tabindex="-1"
      role="dialog">
      <div
        class="flyout-content">
        <div
          v-if="hasHeaderSlot"
          class="w-100 sticky-top flyout-header">
          <slot name="title"/>
        </div>
        <div
          v-if="scrollable"
          id="flyout-scrollable"
          class="scrollable"
          :class="contentClass">
          <div class="scroll-content">
            <slot name="body"/>
          </div>
        </div>
        <div v-else>
          <slot name="body"/>
        </div>
        <div
          v-if="hasFooterSlot"
          class="flyout-footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  compatConfig: {
    INSTANCE_LISTENERS: false
  },
  props: {
    contentClass: {
      type: String,
      default: ''
    },
    side: {
      type: String,
      default: 'right',
      validator: value => {
        return value.match(/(right|left)/)
      }
    },
    desktopSize: {
      type: String,
      default: 'default',
      validator: value => value.match(/(default|large)/)
    },
    mobileSize: {
      type: String,
      default: 'default',
      validator: value => value.match(/(default|large)/)
    },
    scrollable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'flyoutZIndex'
    ]),
    hasHeaderSlot () {
      return !!this.$slots.title
    },
    hasFooterSlot () {
      return !!this.$slots.footer
    },
    flyoutWidth () {
      if (this.desktopSize === 'large' && !this.isMobile) {
        return '620px'
      } else if (this.mobileSize === 'large' && this.isMobile) {
        return '100vw'
      } else {
        return '370px'
      }
    },
    cssVars () {
      return {
        '--flyoutWidth': `${this.flyoutWidth}`
      }
    },
    routePath () {
      return this.$route.path
    }
  },
  watch: {
    routePath () {
      this.closeFlyout()
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    onFlyoutCloseFromOutside (e) {
      if (e.target === e.currentTarget) {
        this.onFlyoutClose(e)
      }
    },
    onFlyoutClose (e) {
      e.stopPropagation()
      this.$emit('close')
      if (!this.$attrs.onClose) {
        this.closeFlyout()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  $flyoutWidth: var(--flyoutWidth);
  $flyoutOffset: calc(-1 * var(--flyoutWidth));

  .flyout {
    background: white;
    display: block;
    height: 100dvh;
    position: fixed;
    top: 0;
    z-index: $zindex-above-intercom;
    width: 100vw;
    max-width: $flyoutWidth;
    // overflow: hidden;
    // WEIRD: somehow toggling this off makes flyout-content behave appropriately.
    outline: 0;

    &.right {
      right: $flyoutOffset;
      animation: slide-in-right .3s forwards ease-out;

      @keyframes slide-in-right {
        100% { right: 0 }
      }
    }

    &.left {
      left: $flyoutOffset;
      animation: slide-in-left .3s forwards ease-out;

      @keyframes slide-in-left {
        100% { left: 0 }
      }
    }
  }

  .scrollable {
    overflow-y: auto;
    grid-row: 2;

    .scroll-content {
      margin-bottom: 116px;
    }
  }

  .background-origami {
    background-color: $origami;
  }

  .flyout-content {
    position: relative;
    height: 100dvh;
    border-radius: 2px;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }

  .flyout-header {
    border-bottom: $default-border;
    grid-row: 1;
  }

  .flyout-footer {
    border-top: $default-border;
    position: sticky;
    width: 90vw;
    max-width: $flyoutWidth;
    z-index: 2147483003;
    bottom: 0;
    padding: 16px;
    background: $white;
    grid-row: 3;
  }

  .flyout-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1040;
    height: 100dvh;
    background-color: #000;
    opacity: .5;
  }
</style>
