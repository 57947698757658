<template>
  <edit-delete-menu
    item-name="Comment"
    @delete-click="onDelete"
    @edit-click="onEdit"
    @cancel-click="closeModal"/>
</template>

<script>
import EditDeleteMenu from './EditDeleteMenu'
import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    EditDeleteMenu
  },
  computed: {
    ...mapState('community', [
      'itemInFocus'
    ])
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal',
      'openModal'
    ]),
    ...mapVuexActions('community', [
      'deleteFeedItemComment'
    ]),
    onEdit () {
      this.$router.push({ name: 'edit-comment' })
      this.closeModal()
    },
    async onDelete () {
      this.openModal({ name: 'community-delete-confirmation' })
    }
  }
}
</script>
