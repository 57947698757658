// Polyfill for Object.hasOwn: https://github.com/es-shims/Object.hasOwn
if (!Object.hasOwn) {
  Object.defineProperty(Object, 'hasOwn', {
    value: function hasOwn (obj, prop) {
      return Object.prototype.hasOwnProperty.call(obj, prop)
    },
    configurable: true,
    enumerable: false,
    writable: true
  })
}
