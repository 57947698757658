<template>
  <my-case-header title="Case Ready for Pickup">
    <div v-if="pickupReady">
      <p class="small-text text-center mb-1">
        Your case <span class="semi-bold">is ready</span> for pickup at <span class="semi-bold">{{ pickupPackage.pickupFrom.name }}</span>
      </p>
    </div>
    <div v-else>
      <p class="small-text text-center mb-1">
        Your case <span class="semi-bold">will be ready soon</span> for pickup at <span class="semi-bold">{{ pickupPackage.pickupFrom.name }}</span>
      </p>
    </div>
    <slot>
      <sequin-accordion
        class="mt-2"
        expand-text="Pickup Location Details"
        :expand-link-props="{ variant: 'primary' }">
        <div class="d-flex justify-content-between mb-3 align-items-center border-top border-bottom">
          <pickup-address
            class="small-text my-2 text-start"
            :pickup-address="pickupPackage.pickupFrom"/>
          <sequin-button
            type="a"
            small
            variant="secondary"
            target="_blank"
            :href="pickupPackage.pickupFrom.mapLink">
            Open Map
          </sequin-button>
        </div>
      </sequin-accordion>
    </slot>
  </my-case-header>
</template>

<script>
import SequinAccordion from '../../../global/sequin/SequinAccordion'
import MyCaseMixin from '../MyCaseMixin'
import MyCaseHeader from './MyCaseHeader'
import PickupAddress from '../../../global/address/PickupAddress'
import SequinButton from '../../../global/sequin/SequinButton'

export default {
  components: {
    SequinAccordion,
    MyCaseHeader,
    PickupAddress,
    SequinButton
  },
  mixins: [MyCaseMixin],
  computed: {
    pickupPackage () {
      return this.pickupPackages[0]
    },
    pickupReady () {
      return this.pickupPackage.pickupReady
    }
  }
}
</script>
