<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <h5 class="mb-0">
            Purchase Complete
          </h5>
        </template>
        <template #body>
          <div class="d-flex flex-column align-items-center">
            <svg-circle-icon
              :height="36"
              :width="36"
              class="p-2"
              variant="success">
              <svg-check/>
            </svg-circle-icon>
            <p class="mt-2">
              Enjoy your new style{{ itemsPurchased.length > 1 ? 's' : '' }}{{ firstName ? ', ' + firstName : '' }}!
            </p>
            <div class="w-100 my-2">
              <my-case-item
                v-for="item in itemsPurchased"
                :key="item.item.id"
                class="my-n2"
                hide-price
                :item="item.item"/>
            </div>
            <hr
              class="mx-auto"
              style="max-width: 90%">
            <h6 class="my-2 text-center">
              Love discovering new styles with Armoire?
            </h6>
            <p class="text-center">
              Invite your friends + family to discover with you! <referral-credit-msg/>
            </p>
            <referral-share/>
          </div>
        </template>
      </my-case-section>
    </template>
  </my-case-flyout-component>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import MyCaseItem from '../common/MyCaseItem'
import ReferralShare from '../../global/referral/ReferralShare'
import ReferralCreditMsg from '../../global/referral/ReferralCreditMsg'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    MyCaseItem,
    MyCaseSection,
    MyCaseFlyoutComponent,
    ReferralShare,
    ReferralCreditMsg,
    SvgCircleIcon,
    SvgCheck
  },
  computed: {
    ...mapState('client', [
      'firstName'
    ]),
    ...mapState('case', [
      'itemsPurchased'
    ]),
    ...mapState('subscribe', [
      'referralCampaign'
    ])
  },
  beforeUnmount () {
    this.setMyCaseFlyoutContext({
      itemsPurchased: null,
      itemReturningToPurchase: null
    })
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ])
  }
}
</script>
