<template>
  <my-case-section-core no-padding>
    <template
      v-if="hasHeaderSlot"
      #header>
      <slot name="header"/>
    </template>
    <template #body>
      <div
        :class="{ 'pb-5': hasEndOfSectionSlot && isMobile }">
        <slot name="body-header"/>
        <slot
          name="body"
          :delivered-items-by-return-date="deliveredItemsByReturnDate"/>
      </div>
    </template>
    <template #sticky-footer>
      <div
        v-if="hasEndOfSectionSlot"
        class="p-3 px-md-5">
        <slot name="end-of-section"/>
      </div>
    </template>
  </my-case-section-core>
</template>

<script>
import MyCaseSectionCore from './MyCaseSectionCore'
import MyCaseMixin from '../common/MyCaseMixin'
import { mapActions } from 'vuex'

export default {
  components: {
    MyCaseSectionCore
  },
  mixins: [MyCaseMixin],
  computed: {
    hasEndOfSectionSlot () {
      return this.$slots['end-of-section']
    },
    hasHeaderSlot () {
      return this.$slots.header
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName'
    ])
  }

}
</script>
