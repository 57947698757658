<template>
  <base-modal>
    <template #body>
      <div class="text-center">
        <h4>We couldn't verify your address</h4>
        <span v-if="hasCorrectedAddress">
          Suggested address corrections are highlighted in <span class="red">red</span>
        </span>
        <div class="mt-2 compact">
          <b>You entered:</b>
          <br>
          {{ originalAddress.addressLine1 }}
          {{ originalAddress.addressLine2 }}<br>
          {{ originalAddress.city }}, {{ originalAddress.state }} {{ originalAddress.zipcode }}<br>
        </div>
        <div class="row ">
          <div class="col-8 mx-auto">
            <sequin-button
              :disabled="disabled"
              block
              class="mt-2"
              @click="onEditClick">
              Edit this Address
            </sequin-button>
          </div>
        </div>
        <div v-if="hasCorrectedAddress">
          <div class="mt-4 compact">
            <b>We suggest:</b>
            <br>
            <span :class="{ red: originalAddress.addressLine1 !== correctedAddress.addressLine1 }">
              {{ correctedAddress.addressLine1 }}&nbsp;
            </span>
            <span :class="{ red: originalAddress.addressLine2 !== correctedAddress.addressLine2 }">
              {{ correctedAddress.addressLine2 }}
            </span><br>
            <span :class="{ red: originalAddress.city !== correctedAddress.city }">
              {{ correctedAddress.city }},
            </span>
            <span :class="{ red: originalAddress.state !== correctedAddress.state }">
              {{ correctedAddress.state }}&nbsp;
            </span>
            <span :class="{ red: originalAddress.zipcode !== correctedAddress.zipcode }">
              {{ correctedAddress.zipcode }}
            </span><br>
          </div>
          <div class="row ">
            <div class="col-8 mx-auto">
              <sequin-button
                variant="primary"
                :disabled="disabled"
                block
                class="mt-2"
                @click="onUseCorrectedClick">
                Use this Address
              </sequin-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions as mapVuexActions, mapState as mapVuexState } from 'vuex'
import { mapActions, mapState } from 'pinia'
import SequinButton from '../global/sequin/SequinButton'
import { useOverlaysStore } from '@/stores/overlays.js'
import BaseModal from '@/components/global/BaseModal.vue'

export default {
  components: {
    BaseModal,
    SequinButton
  },
  data () {
    return {
      disabled: false,
      emptyAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipcode: ''
      }
    }
  },
  computed: {
    ...mapVuexState('client', [
      'username'
    ]),
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    originalAddress () {
      if (this.modalContext && this.modalContext.originalAddress) {
        return this.modalContext.originalAddress
      }
      return this.emptyAddress
    },
    correctedAddress () {
      if (this.hasCorrectedAddress) {
        return this.modalContext.correctedAddress
      }
      return this.emptyAddress
    },
    hasCorrectedAddress () {
      if (this.modalContext && this.modalContext.correctable) {
        return this.modalContext.correctable
      }
      return false
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal',
      'openModal'
    ]),
    ...mapVuexActions('account', [
      'updateAddress',
      'addAddress',
      'selectShippingAddress'
    ]),
    onEditClick () {
      if (this.modalContext.nextRoute) {
        this.closeModal()
      } else {
        this.openModal({
          name: 'account-edit-shipping-address',
          context: {
            address: this.originalAddress,
            setSelectedInitialValue: this.modalContext.setAsSelectedShipping,
            nextModal: this.modalContext.nextModal
          }
        })
      }
    },
    async onUseCorrectedClick () {
      const updatedAddress = {
        username: this.username,
        addressLine1: this.correctedAddress.addressLine1,
        addressLine2: this.correctedAddress.addressLine2,
        city: this.correctedAddress.city,
        state: this.correctedAddress.state,
        zipcode: this.correctedAddress.zipcode,
        id: this.originalAddress.id
      }
      this.disabled = true
      if (this.originalAddress.id) {
        await this.updateAddress(updatedAddress)
      } else {
        const addedAddress = await this.addAddress(updatedAddress)
        updatedAddress.id = addedAddress.id
      }
      if (this.modalContext.setAsSelectedShipping) {
        await this.selectShippingAddress(updatedAddress)
      }
      this.disabled = false
      if (this.modalContext.nextRoute) {
        this.$router.push({ name: this.modalContext.nextRoute })
        this.closeModal()
      } else if (this.modalContext.nextModal) {
        this.openModal({ name: this.modalContext.nextModal })
      } else {
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.red {
  color: red;
}

</style>
