<!--
Custom full-width checkbox component with an icon and a slot for the body (usually text).
-->
<template>
  <base-checkbox
    v-model="_modelValue"
    :input-value="initialValue"
    :disabled="disabled"
    custom>
    <template #default="{ active }">
      <div
        class="wrapper"
        :class="{ 'active': active }">
        <component
          :is="icon"
          class="icon"
          width="59"
          height="59"/>
        <div class="body">
          <slot :active="active"/>
        </div>
        <div class="check">
          <svg-check
            v-if="active"
            width="28"
            height="28"/>
        </div>
      </div>
    </template>
  </base-checkbox>
</template>

<script>
import BaseCheckbox from '../BaseCheckbox.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    BaseCheckbox,
    SvgCheck
  },
  props: {
    modelValue: {
      type: [Boolean, Array],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: [String, Number, Object],
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    _modelValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.wrapper {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 18px 28px;
}

.icon {
  margin-right: 19px;
}

.wrapper.active {
  background-color: $origami;

  .body {
    font-weight: $font-weight-semibold;
  }

  // Only fill the primary path for icons like svg-case.
  svg.icon :deep(path:first-of-type) {
    fill: $primary;
  }

  svg.icon :deep(path) {
    stroke: $primary;
  }
}

svg.icon :deep(path) {
  stroke-width: 0.5;
}

.check {
  width: 28px;
  text-align: right;
}

.check svg {
  stroke: $primary;
}

</style>
