<template>
  <my-case-flyout-component>
    <template #header>
      <h5 class="mb-0">
        Confirm Case
      </h5>
    </template>
    <template #body>
      <my-case-section
        no-padding
        back-button
        variant="default"
        @back-click="goBack()">
        <template #header>
          <h6 class="mb-0">
            Shipping Details
          </h6>
        </template>
        <template #body>
          <my-case-shipping-address-selected
            class="pt-3 px-3"
            can-edit/>
          <my-case-choose-shipping
            class="pb-3 ps-3 border"/>
          <my-case-expedited-return-pickup
            v-if="selectedShipper && selectedShipper.returnPickupAllowed"
            class="pb-3 px-3 border"/>
          <my-case-default-payment-updater
            v-if="shippingCharge"
            class="p-3 mt-1"/>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <my-case-inactive-status class="mb-3"/>
      <sequin-button
        block
        :disabled="continueDisabled"
        @click="onContinue()">
        Continue {{ chargeText }}
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseChooseShipping from './MyCaseChooseShipping'
import MyCaseDefaultPaymentUpdater from '../common/MyCaseDefaultPaymentUpdater'
import MyCaseExpeditedReturnPickup from './MyCaseExpeditedReturnPickup'
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import MyCaseInactiveStatus from '../common/MyCaseInactiveStatus'
import MyCaseShippingAddressSelected from '../common/MyCaseShippingAddressSelected'
import MyCaseMixin from '../common/MyCaseMixin'
import SequinButton from '../../global/sequin/SequinButton'
import { formatPrice } from '@/utils/stringParsing.js'
import { mapActions, mapState, mapGetters } from 'vuex'
import { vuexAccessors } from '../../global/helpers/vuex'

export default {
  components: {
    MyCaseChooseShipping,
    MyCaseDefaultPaymentUpdater,
    MyCaseExpeditedReturnPickup,
    MyCaseFlyoutComponent,
    MyCaseInactiveStatus,
    MyCaseShippingAddressSelected,
    MyCaseSection,
    SequinButton
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('client', [
      'mainPhone',
      'shippingEligibility',
      'shippedPackages'
    ]),
    ...mapState('case', [
      'selectedShippingId'
    ]),
    ...vuexAccessors('case', [
      'expeditedReturnSelected'
    ]),
    ...mapGetters('client', [
      'numAvailablePurchaseSuggestions',
      'numAvailableCheckoutRecommendations'
    ]),
    ...mapGetters('case', [
      'shippingCharge',
      'selectedShipper',
      'nextFlyout'
    ]),
    chargeText () {
      if (this.shippingCharge > 0) {
        return `+ ${formatPrice(this.shippingCharge)}`
      }
      return ''
    },
    continueDisabled () {
      if (!this.selectedShipper) {
        return true
      }

      if (!this.selectedShipper.returnPickupAllowed) {
        return false
      }

      if (this.selectedShipper.phoneNumberRequired && this.mainPhone.length === 0) {
        return true
      }

      if (this.selectedShipper.returnPickupAllowed &&
          this.expeditedReturnSelected === null) {
        return true
      }

      return false
    }
  },
  mounted: function () {
    this.expeditedReturnSelected = null
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ]),
    goBack () {
      if (this.numAvailableCheckoutRecommendations > 0) {
        this.setMyCaseFlyoutName('checkout-recommendations')
      } else if (this.numAvailablePurchaseSuggestions > 0) {
        this.setMyCaseFlyoutName('purchase-suggestions')
      } else {
        this.setMyCaseFlyoutName('core')
      }
    },
    onContinue () {
      this.setMyCaseFlyoutName(this.nextFlyout)
    }
  }
}

</script>

<style lang="scss" scoped>

.border {
  border-top: $default-border;
  padding: 16px 0 16px 16px;
  background: $white;
  max-width: 100%;
}

</style>
