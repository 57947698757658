<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('request-stylist-case-second')">
        <template #header>
          <h5 class="mb-0">
            Request Stylist Case (3/3)
          </h5>
        </template>
        <template
          #body>
          <h6>
            Any specific styles you'd like to try?
          </h6>
          <p class="small-text mb-3">
            Include any styles, fabrics, colors, or patterns you'd love to see in your Case
          </p>
          <my-case-text-area
            v-model="styleTry"
            required
            :rows="isMobile ? 4 : 4"
            placeholder="I would love to try a midi dress and a plaid top!"/>
          <h6 class="pt-4">
            Any specific styles you'd like to avoid?
          </h6>
          <p class="small-text mb-3">
            Include any styles, fabrics, colors, or patterns you'd prefer to avoid in your Case
          </p>
          <my-case-text-area
            v-model="styleAvoid"
            required
            :rows="isMobile ? 4 : 4"
            placeholder="E.g. No crop tops or wool please"/>
          <h6 class="pt-4">
            Any other notes for your stylist?
          </h6>
          <p class="small-text mb-3">
            Tropical vacation? Corporate dress code? Details help our styling team pick the best items for you!
          </p>
          <my-case-text-area
            v-model="otherNotes"
            required
            :rows="isMobile ? 4 : 4"
            placeholder="E.g. I'll be on vacation in Cabo"/>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <sequin-button
        block
        @click="requestStylistCase">
        Submit Stylist Case Request
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import SequinButton from '../../global/sequin/SequinButton'
import { mapActions, mapGetters, mapState } from 'vuex'
import MyCaseTextArea from '../common/MyCaseTextArea'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  components: {
    MyCaseFlyoutComponent,
    MyCaseSection,
    SequinButton,
    MyCaseTextArea
  },
  props: {
    try: {
      type: String,
      default: ''
    },
    avoid: {
      type: String,
      default: ''
    },
    notes: {
      type: String,
      default: ''
    }
  },
  setup () {
    const { track } = useAnalytics()
    return { track }
  },
  data () {
    return {
      formDisabled: false,
      errors: []
    }
  },
  computed: {
    ...mapState('case', [
      'stylistRequest'
    ]),
    ...mapGetters('closet', [
      'selectedAvailableItemTypes'
    ]),
    styleTry: {
      get () {
        return this.stylistRequest?.stylesToTry || []
      },
      set (value) {
        this.$store.commit('case/UPDATE_STYLIST_REQUEST', { stylesToTry: value })
      }
    },
    styleAvoid: {
      get () {
        return this.stylistRequest?.stylesToAvoid || []
      },
      set (value) {
        this.$store.commit('case/UPDATE_STYLIST_REQUEST', { stylesToAvoid: value })
      }
    },
    otherNotes: {
      get () {
        return this.stylistRequest?.freeNotes || []
      },
      set (value) {
        this.$store.commit('case/UPDATE_STYLIST_REQUEST', { freeNotes: value })
      }
    }
  },
  methods: {
    ...mapActions('closet', [
      'submitCloset'
    ]),
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    async requestStylistCase () {
      const formData = {
        stylistCurated: true,
        requestDetails: this.stylistRequest
      }
      const result = await this.submitCloset(formData)
      if (result) {
        this.setMyCaseFlyoutContext({
          confirmedSelectedItems: this.selectedAvailableItemTypes,
          confirmedPackageIsStylistCurated: true
        })
        this.$store.commit('case/CLEAR_STYLIST_REQUEST')
        this.setMyCaseFlyoutName('confirm-case-success')
      } else {
        this.setMyCaseFlyoutContext({ confirmedSelectedItems: [] })
        this.$store.commit('case/CLEAR_STYLIST_REQUEST')
        this.setMyCaseFlyoutName('core')
      }
      this.track('Requested stylist case', {
        selectedItems: this.selectedAvailableItemTypes.length
      })
    }
  }
}
</script>

<style lang="scss">
.text-area {
  width: 100%;
  border-radius: 2px;
  padding: 8px 16px;
  min-height: 15%;
  resize: none;
  border-color: $ash;
  font-size: $font-size-base;
  -webkit-appearance: none;

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $pewter;
  }
}
</style>
