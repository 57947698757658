<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        no-padding
        @back-click="editEnteredAddress">
        <template #header>
          <h6 class="mb-0">
            Verify address
          </h6>
        </template>
        <template #body>
          <app-message
            class="p-4"
            variant="danger">
            <div><strong>We couldn't verify your address.</strong></div>
            <div>Suggested changes are highlighted in red.</div>
          </app-message>
          <div>
            <div class="correct-address-section small-text">
              <h5>You entered:</h5>
              <div>
                {{ originalAddress.addressLine1 }}
                {{ originalAddress.addressLine2 }}
              </div>
              <div>
                {{ originalAddress.city }}, {{ originalAddress.state }} {{ originalAddress.zipcode }}
              </div>
              <text-link
                variant="secondary"
                @click="editEnteredAddress">
                Edit
              </text-link>
            </div>
            <div class="correct-address-section small-text">
              <h5>We suggest:</h5>
              <div>
                <span :class="{ 'red': originalAddress.addressLine1 !== correctedAddress.addressLine1 }">
                  {{ correctedAddress.addressLine1 }}
                </span>
                <span :class="{ 'red': originalAddress.addressLine2 !== correctedAddress.addressLine2 }">
                  {{ correctedAddress.addressLine2 }}
                </span>
              </div>
              <div>
                <span :class="{ 'red': originalAddress.city !== correctedAddress.city }">
                  {{ correctedAddress.city }},
                </span>
                <span :class="{ 'red': originalAddress.state !== correctedAddress.state }">
                  {{ correctedAddress.state }}
                </span>
                <span :class="{ 'red': originalAddress.zipcode !== correctedAddress.zipcode }">
                  {{ correctedAddress.zipcode }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <sequin-button
        block
        :disabled="isSavingAddress"
        @click="saveSuggestedAddress">
        Use Suggested Address
      </sequin-button>
      <sequin-button
        block
        :disabled="isSavingAddress"
        class="mt-3"
        variant="secondary"
        @click="editEnteredAddress">
        Edit Address
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import AppMessage from '../../global/sequin/AppMessage'
import SequinButton from '../../global/sequin/SequinButton'
import TextLink from '../../global/sequin/TextLink'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    MyCaseFlyoutComponent,
    MyCaseSection,
    AppMessage,
    SequinButton,
    TextLink
  },
  data () {
    return {
      isSavingAddress: false,
      emptyAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipcode: ''
      }
    }
  },
  computed: {
    ...mapState('client', [
      'username'
    ]),
    ...mapState('case', [
      'shippingAddressEditing',
      'setShippingAddressEditingSelected',
      'shippingAddressEditingError'
    ]),
    originalAddress () {
      if (this.shippingAddressEditingError && this.shippingAddressEditingError.originalAddress) {
        return this.shippingAddressEditingError.originalAddress
      }
      return this.emptyAddress
    },
    correctedAddress () {
      return this.shippingAddressEditingError.correctedAddress
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName'
    ]),
    ...mapActions('account', [
      'updateAddress',
      'addAddress',
      'selectShippingAddress'
    ]),
    editEnteredAddress () {
      this.setMyCaseFlyoutContext({ shippingAddressEditing: this.shippingAddressEditingError.originalAddress })
      this.setMyCaseFlyoutName('edit-shipping-address')
    },
    async saveSuggestedAddress () {
      this.isSavingAddress = true
      const updatedAddress = {
        username: this.username,
        addressLine1: this.correctedAddress.addressLine1,
        addressLine2: this.correctedAddress.addressLine2,
        city: this.correctedAddress.city,
        state: this.correctedAddress.state,
        zipcode: this.correctedAddress.zipcode,
        id: this.originalAddress.id
      }
      this.disabled = true
      if (this.originalAddress.id) {
        await this.updateAddress(updatedAddress)
      } else {
        const addedAddress = await this.addAddress(updatedAddress)
        updatedAddress.id = addedAddress.id
      }
      if (this.setShippingAddressEditingSelected) {
        await this.selectShippingAddress(updatedAddress)
      }
      this.setMyCaseFlyoutName('address-selection')
      this.isSavingAddress = false
    }

  }

}
</script>

<style lang="scss" scoped>
.correct-address-section {
  padding: 24px;
  border-bottom: $default-border;
}

.red {
  color: $danger-dark;
}
</style>
