<template>
  <div class="row">
    <AccordionWithHeader
      :title="`Stylist Case Info // Stylist Case Items: ${packingItems.length}/${itemTarget}`"
      class="styling-info"
      :border="false"
      :expanded="showStylistCaseInfo"
      :expanded-max-height="600"
      @accordion-click="() => showStylistCaseInfo = !showStylistCaseInfo">
      <div class="row">
        <div
          v-for="section in sections"
          :key="section.title"
          class="g-0 col p-xs m-xs">
          <b>{{ section.title }}: </b>
          <p>
            {{ section.content }}
          </p>
        </div>
      </div>
      <div
        v-if="packingItems.length > 0"
        class="row p-xs m-xs">
        <div class="d-flex flex-wrap justify-content-start">
          <b>Items in Stylist Case:</b>
        </div>
        <HorizontalScroll>
          <PreviewImageButton
            v-for="item in packingItems"
            :key="item.id"
            :src="source(item.item)"
            size="small"
            class="preview-image-button"
            @click="removeStylistPackageItem(item.item.itemType)"/>
        </HorizontalScroll>
      </div>
    </AccordionWithHeader>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { storeToRefs } from 'pinia'
import { useStylingStore } from '@/stores/styling'
import AccordionWithHeader from '@/components/global/sequin/AccordionWithHeader.vue'
import HorizontalScroll from '@shared/components/ADORN/HorizontalScroll.vue'
import PreviewImageButton from '@shared/components/ADORN/PreviewImageButton.vue'

const showStylistCaseInfo = ref(false)
const styling = useStylingStore()
const { stylingInfo } = storeToRefs(styling)
const { removeStylistPackageItem } = styling

const store = useStore()

const packingItems = computed(() => store.state.closet.packingItems)
const activeStylistCuratedPackage = computed(() => store.state.closet.activeStylistCuratedPackage)

const styleColorsMap = computed(() => store.state.closet.styleColorsMap)

const source = (item) => {
  return styleColorsMap.value[item.styleColor].images.front.url
}

const itemTarget = computed(() => activeStylistCuratedPackage.value.itemTarget)

const sections = [
  { title: 'Bonus Items', content: stylingInfo.value.stylistCaseNote.bonusItems },
  { title: 'Rollover Items', content: stylingInfo.value.stylistCaseNote.rolloverItems },
  { title: 'Categories', content: stylingInfo.value.stylistCaseNote.categories.join(', ') },
  { title: 'Occasions', content: stylingInfo.value.stylistCaseNote.occasions.join(', ') },
  { title: 'Styles to Try', content: stylingInfo.value.stylistCaseNote.stylesToTry },
  { title: 'Styles to Avoid', content: stylingInfo.value.stylistCaseNote.stylesToAvoid },
  { title: 'Free Notes', content: stylingInfo.value.stylistCaseNote.freeNotes }
]

</script>

<style lang="scss" scoped>
.styling-info {
  background-color: rgba($orchid-light, 0.1);
  height: 100%;
}
.preview-image-button {
  position: relative;
  display: inline-block;
}
/* pseudo element to create red overlay */
.preview-image-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($danger-dark, 0.5);
  z-index: 1;
  display: none;
}
/* pseudo element to create 'x' */
.preview-image-button::after {
  content: 'X';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  z-index: 2;
  display: none;
}

.preview-image-button:hover::before,
.preview-image-button:hover::after {
  display: block;
}
</style>
