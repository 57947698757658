<template>
  <footer class="footer p-5">
    <div class="row">
      <div class="col-12 col-lg-2">
        <h5 class="mt-md mb-xxs">
          Experience
        </h5>
        <p>
          <a
            href="https://go.armoire.style/personal-styling"
            target="_blank">
            Personal Styling
          </a>
          <br>
          <a
            href="https://go.armoire.style/collections"
            target="_blank">
            Explore Collections
          </a>
          <br>
          <router-link
            :to="{ name: 'referral'}">
            {{ referralText }}
          </router-link>
          <br>
          <a
            href="https://shop.armoire.style/"
            target="_blank">
            Shop
          </a>
          <br>
          <a
            href="https://support.armoire.style/gifts"
            target="_blank">
            Gift Cards
          </a>
          <br>
          <a
            href="/howitworks"
            target="_blank">
            How it Works
          </a>
          <br>
          <a
            href="https://support.armoire.style/faq"
            target="_blank">
            FAQ
          </a>
        </p>
      </div>
      <div class="col-12 col-lg-2">
        <h5 class="mt-md mb-xxs">
          Company
        </h5>
        <p>
          <a
            href="https://support.armoire.style/story-us"
            target="_blank">
            About Us
          </a>
          <br>
          <a
            href="https://support.armoire.style/about-our-team"
            target="_blank">
            Our Team
          </a>
          <br>
          <a
            href="https://support.armoire.style/press"
            target="_blank">
            Press
          </a>
          <br>
          <a
            href="https://support.armoire.style/careers"
            target="_blank">
            Careers
          </a>
          <br>
          <a
            href="https://support.armoire.style/accessibility"
            target="_blank">
            Accessibility
          </a>
          <br>
          <!--
            below triggers Intercom custom bot
            previously was:
            href="https://support.armoire.style/contact"
            target="_blank"
          -->
          <a
            id="footer-contact-us"
            href="#">
            Contact Us
          </a>
          <!--
          <br>
          <a
            href="https://support.armoire.style/1-1-style-appointments"
            target="_blank">
            1-1 Styling
          </a>
          -->
          <br>
          <a
            href="https://go.armoire.style"
            target="_blank">
            Flagship Boutique
          </a>
        </p>
      </div>
      <div class="col-12 col-lg-2">
        <h5 class="mt-md mb-xxs">
          Social
        </h5>
        <p>
          <a
            href="https://blog.armoire.style/"
            target="_blank">
            Blog
          </a>
          <br>
          <a
            href="http://www.instagram.com/armoire.style/"
            target="_blank">
            Instagram
          </a>
          <br>
          <a
            href="https://www.tiktok.com/@armoire.style"
            target="_blank">
            TikTok
          </a>
          <br>
          <a
            href="http://www.facebook.com/armoire.style"
            target="_blank">
            Facebook
          </a>
          <br>
          <a
            href="https://www.linkedin.com/company/armoire-style/"
            target="_blank">
            LinkedIn
          </a>
          <br>
          <a
            href="https://thesuite.armoire.style/"
            target="_blank">
            The Suite
          </a>
        </p>
      </div>
      <div class="col-12 col-lg-2">
        <h5 class="mb-xs mt-md">
          Get the App
        </h5>
        <div class="d-flex flex-wrap">
          <a
            class="appDownloadLink"
            target="_blank"
            :href="appleAppStoreLink">
            <img
              class="appDownloadIcon"
              alt="Download on the App Store"
              :src="appleAppStoreIcon">
          </a>
          <a
            class="appDownloadLink"
            target="_blank"
            :href="googlePlayStoreLink">
            <img
              class="appDownloadIcon"
              alt="Get it on Google Play"
              :src="googlePlayStoreIcon">
          </a>
        </div>
      </div>
    </div>

    <div class="row small pt-4">
      <div class="col-12 col-lg-2">
        <p>© {{ new Date().getFullYear() }} Armoire Style, Inc.</p>
      </div>
      <div class="col-12 col-lg-2">
        <p>
          <a
            href="https://support.armoire.style/legal"
            target="_blank">
            Legal
          </a>
        </p>
      </div>
      <div class="col-12 col-lg-2">
        <p>
          <a
            href="https://support.armoire.style/rental-policy"
            target="_blank">
            Rental Policy
          </a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import ReferralsMixin from './mixins/ReferralsMixin'
import appDownload from '../global/mixins/appDownload'

export default {
  mixins: [appDownload, ReferralsMixin]
}

</script>

<style lang="scss" scoped>

.footer {
  background: $origami;
}

.appDownloadLink {
  margin: 0 12px 12px 0;
  display: inline-block;
}

.appDownloadIcon {
  width: 132px;
  height: auto;
}

a {
  font-weight: inherit;
  color: $info;
  border-bottom: none;

  :hover {
    cursor: pointer;
  }
}
</style>
