<template>
  <div>
    <h6>{{ itemContainerText }}{{ hasRentalPlan ? ' Extras' : '' }}</h6>
    <my-case-charge-display
      :price-info="casePrice">
      Price
    </my-case-charge-display>
  </div>
</template>

<script>
import MyCaseChargeDisplay from '../common/MyCaseChargeDisplay'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    MyCaseChargeDisplay
  },
  computed: {
    ...mapState('case', [
      'casePrice'
    ]),
    ...mapGetters('client', [
      'hasRentalPlan',
      'itemContainerText'
    ])
  },
  methods: {
    ...mapActions('case', [
      'getCasePrice'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
