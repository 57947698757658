<!-- Represents a single toast notification -->
<template>
  <div :class="['toast-notification small-text', notification.type]">
    <span class="icon">
      <component :is="notification.icon"/>
    </span>
    <span class="message">{{ notification.message }}</span>
    <text-link
      v-if="notification.link"
      class="link"
      type="a"
      variant="inherit"
      @click="notification.link.clickHandler">
      {{ notification.link.text }}
    </text-link>
  </div>
</template>

<script>
import TextLink from './sequin/TextLink.vue'

export default {
  components: {
    TextLink
  },
  props: {
    notification: {
      type: Object,
      required: true,
      validator: value => {
        return ['persistent', 'transient', 'smart'].includes(value.type)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.toast-notification {
  color: white;
  padding: 12px 50px 12px 20px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 15px;
  align-items: baseline;
}

.toast-notification .icon :deep(svg) {
  --size: 12px;
  width: var(--size);
  height: var(--size);
}

.toast-notification .link {
  padding: 0px;

  &:hover {
    color: inherit;
  }
}

.toast-notification.persistent {
  background-color: $orchid;
}

.toast-notification.transient, .toast-notification {
  background-color: $plum;
}

.toast-notification.smart {
  background-color: $teal;
}

</style>
