<template>
  <div class="small-text">
    <div class="d-flex justify-content-between">
      <div>
        <slot/>
      </div>
      <div
        v-if="priceInfo">
        {{ formatPrice(priceInfo.price) }}
      </div>
    </div>
    <div
      v-if="priceInfo"
      class="d-flex justify-content-between">
      <div>Shipping</div>
      <div>
        {{ formatPrice(priceInfo.shipping) }}
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div>Tax</div>
      <div
        v-if="priceInfo">
        {{ formatPrice(priceInfo.tax) }}
      </div>
    </div>
    <div
      v-if="priceInfo && priceInfo.credit"
      class="small-text d-flex justify-content-between text-success bold">
      <div>Account credit</div>
      <div>-{{ formatPrice(priceInfo.credit) }}</div>
    </div>
    <div
      v-if="priceInfo && priceInfo.gcRedeem"
      class="small-text d-flex justify-content-between text-success bold">
      <div>Gift card amount</div>
      <div>-{{ formatPrice(priceInfo.gcRedeem) }}</div>
    </div>
    <div
      v-if="priceInfo && priceInfo.discount"
      class="small-text d-flex justify-content-between text-success bold">
      <div>Discount</div>
      <div>-{{ formatPrice(priceInfo.discount) }}</div>
    </div>
    <div
      class="d-flex justify-content-between total">
      <div><strong>Total</strong></div>
      <div
        v-if="priceInfo">
        <strong>{{ formatPrice(priceInfo.chargeAmount) }}</strong>
      </div>
    </div>
    <hr
      v-if="priceInfo && priceInfo.remainingCredit !== 0"
      class="w-100">
    <div
      v-if="priceInfo && priceInfo.remainingCredit !== 0"
      class="small-text semi-bold text-plum d-flex justify-content-between">
      <div>Remaining account credit</div>
      <div>{{ formatPrice(priceInfo.remainingCredit) }}</div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from '@/utils/stringParsing.js'

export default {
  props: {
    priceInfo: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatPrice
  }
}
</script>
