<template>
  <my-case-section-core>
    <template #header>
      <p class="mb-0 small-text">
        You have already returned {{ mustReviewItems.length > 1 ? 'these items' : 'this item' }}, but we still need your {{ mustReviewItems.length > 1 ? 'reviews' : 'review' }}.
      </p>
    </template>
    <template #body>
      <my-case-item
        v-for="item in mustReviewItems"
        :key="item.id"
        hide-price
        :item="item.item">
        <my-case-item-review-buttons
          class="my-2"
          :item="item"/>
      </my-case-item>
    </template>
  </my-case-section-core>
</template>

<script>
import MyCaseSectionCore from '../common/MyCaseSectionCore'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseItem from '../common/MyCaseItem'
import MyCaseItemReviewButtons from './MyCaseItemReviewButtons'

export default {
  components: {
    MyCaseItem,
    MyCaseSectionCore,
    MyCaseItemReviewButtons
  },
  mixins: [MyCaseMixin]
}
</script>
