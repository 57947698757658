<template>
  <div
    class="d-flex flex-column align-items-center w-100 p-3 border-bottom"
    :class="orchidVariant ? 'bg-orchid text-white' : 'bg-origami'">
    <h5 class="mb-2">
      {{ title }}
    </h5>
    <div class="slot-container">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      validator: value => value.match(/(default|orchid)/),
      default: 'default'
    }
  },
  computed: {
    orchidVariant () {
      return this.variant === 'orchid'
    }
  }
}
</script>

<style lang="scss" scoped>
.slot-container {
  min-width: 304px;
  max-width: 450px;
  text-align: center;
  padding: 0 12px;
}
</style>
