<template>
  <div>
    <my-case-item
      v-for="index in numBonusItemSlotsAvailable"
      :key="`items-${index}`"
      :variant="isBirthdayBonusItem(index) ? 'birthday' : 'bonus'"
      :can-edit="!!canSelectItems"
      hide-price
      class="d-flex py-2">
      <div>
        <p class="bonus-item">
          One-time {{ isBirthdayBonusItem(index) ? 'birthday' : isExtraStylesBonusItem(index) ? 'extra styles' : '' }} bonus item
        </p>
        <p
          v-if="getBonusItem(index).expires"
          class="small-text mb-1">
          Add to case by {{ toMonthDayYearString(getBonusItem(index).expires) }}
        </p>
      </div>
    </my-case-item>
  </div>
</template>

<script>
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseItem from '../common/MyCaseItem'
import { toMonthDayYearString } from '@/utils/stringParsing.js'

export default {
  components: {
    MyCaseItem
  },
  mixins: [MyCaseMixin],
  methods: {
    toMonthDayYearString,
    getBonusItem (index) {
      return this.bonusItems[index - 1 + this.numBonusItemSlotsFilled]
    },
    isBirthdayBonusItem (index) {
      return this.getBonusItem(index).variant === 'birthday'
    },
    isExtraStylesBonusItem (index) {
      return this.getBonusItem(index).variant === 'extra_styles'
    }
  }
}
</script>

<style lang="scss" scoped>
.bonus-item {
  font-weight: $font-weight-bold;
  color: $pewter;
  font-size: $small-text-font-size;
  margin: 0;
}
</style>
