<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        no-padding
        back-button
        @back-click="backClick()">
        <template #header>
          <h6 class="mb-0">
            {{ checkoutRecommendationStyleColor.style.name }}
          </h6>
        </template>
        <template #body>
          <photo-snap-scroll
            :length="allImages.length"
            full-width
            always-scroll>
            <template #scrollable>
              <preview-image
                v-for="image in allImages"
                :key="image.url"
                class="d-flex align-items-center scroll-item position-relative"
                size="xx-large"
                :src="image.url">
                <template #overlay>
                  <sequin-tag
                    v-if="numStandardSlotsAvailable === 0"
                    class="align-self-end justify-self-start mb-3 ms-3"
                    light-solid
                    variant="info">
                    {{ formatPrice(extraItemBasePrice, true) }} extra item
                  </sequin-tag>
                </template>
              </preview-image>
            </template>
          </photo-snap-scroll>
          <div
            class="p-3 smaller">
            <br>
            {{ brand }}
            <br>
            <span class="semi-bold">
              {{ styleColor.style.name }}
            </span>
            <br>
            {{ formatPrice(price, true, true) }} value
            <ul class="ps-4 pt-3">
              <li v-if="color">
                {{ color }}
              </li>
              <li v-if="materials && materials.length">
                {{ formattedMaterialsList(materials) }}
              </li>
              <li v-if="fitType">
                {{ fitType }}
              </li>
              <li
                v-if="sizeGuideUrl"
                class="details size-guide">
                <a
                  :href="sizeGuideUrl"
                  target="_blank">
                  Size Chart
                </a>
              </li>
              <li v-if="inseam?.length > 0">
                Inseam: {{ inseam }} inches
              </li>
              <li v-if="rise">
                Rise: {{ rise }} inches
              </li>
            </ul>
            <div
              class="d-flex align-items-center">
              <span class="me-3">Size</span>
              <sequin-button
                v-for="itemType in availableTypes"
                :key="itemType.id"
                small
                class="ms-1"
                :disabled="getSizeButtonDisabled(itemType)"
                :variant="getTypeSelected(itemType) ? 'active' : 'secondary'"
                @click="addOrRemoveToCase(itemType)">
                {{ itemType.trueSize }}
              </sequin-button>
            </div>
            <div
              v-if="selectedCheckoutRecTypes.length === 0 && numExtraSlotsOverLimit >= 0">
              <strong>You can add up to {{ maxNumExtraItems }} extra items per case.</strong>
              <div>
                Remove one or more items from your case to add this style.
              </div>
            </div>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <div class="d-flex justify-content-between">
        <div class="w-100 ms-2">
          <sequin-button
            block
            @click="continueCheckout()">
            <span v-if="selections.length > 0 ">
              Add to case for {{ formatPrice(extraItemBasePrice * selections.length, true) }}
            </span>
            <span
              v-else>
              Continue
            </span>
          </sequin-button>
        </div>
      </div>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import PhotoSnapScroll from '../../global/sequin/PhotoSnapScroll'
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import SequinTag from '../../global/sequin/SequinTag'
import SequinButton from '../../global/sequin/SequinButton'
import { formatPrice } from '@/utils/stringParsing.js'
import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import MyCaseMixin from '../common/MyCaseMixin'
import { mapState, mapActions, mapGetters } from 'vuex'
import formatting from '../../global/mixins/formatting'

export default {
  components: {
    PhotoSnapScroll,
    MyCaseFlyoutComponent,
    MyCaseSection,
    SequinButton,
    PreviewImage,
    SequinTag
  },
  mixins: [StyleColorMixin, MyCaseMixin, formatting],
  data () {
    return {
      selectedType: null,
      selections: [],
      // Product wants this to be limited to selecting one size at a time
      // (I disagree).  If they change their mind in the future, just need
      // to change this number below and everything will work swimmingly.
      maxSelections: 1
    }
  },
  computed: {
    ...mapState('case', [
      'checkoutRecommendationsSlots',
      'checkoutRecommendationStyleColor',
      'checkoutRecommendationsDetailsBackClick'
    ]),
    ...mapGetters('client', [
      'numStandardSlotsAvailable'
    ]),
    availableTypes () {
      return this.styleColor.itemTypes.filter(it => it.numAvailable > 0)
    },
    selectionsIds () {
      return this.selections.map(x => x.id)
    },
    styleColor () {
      return this.checkoutRecommendationStyleColor
    }
  },
  mounted () {
    this.selections = [...this.selectedCheckoutRecTypes]
    document.addEventListener('click', this.handleClickOutside)
  },
  unmounted () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ]),
    ...mapActions('closet', [
      'deselect',
      'select',
      'setStyleColorSource'
    ]),
    formatPrice,
    getSizeButtonDisabled (type) {
      const selected = this.getTypeSelected(type)
      if (selected) return false
      return (this.selections.length >= this.maxSelections ||
              this.numExtraSlotsOverLimit >= 0)
    },
    getTypeSelected (type) {
      return this.selectionsIds.includes(type.id)
    },
    backClick () {
      this.setMyCaseFlyoutName(this.checkoutRecommendationsDetailsBackClick)
    },
    continueCheckout () {
      const currentSelectedIds = this.selectedExtraItemsValid.map(x => x.id)
      this.styleColor.itemTypes.forEach(type => {
        if (this.selectionsIds.includes(type.id)) {
          if (!currentSelectedIds.includes(type.id)) {
            this.setStyleColorSource({
              styleColorId: this.styleColor.id,
              source: 'checkout-recommendations'
            })
            this.select(type)
          }
        } else if (currentSelectedIds.includes(type.id)) {
          this.deselect(type)
        }
      })
      this.setMyCaseFlyoutName(this.checkoutRecommendationsDetailsBackClick)
    },
    addOrRemoveToCase (type) {
      if (this.getTypeSelected(type)) {
        this.selections = this.selections.filter(x => x.id !== type.id)
      } else {
        this.selections.push(type)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.preview-image.xx-large) {
  border-radius: 0px;
  flex-shrink: 0;
  width: 100%;
}

.size-selector {
  z-index: 1051;
  width: 100%;
}

:deep(.tag) {
  justify-self: start;
}
 </style>
