<!--
Custom inline Collection checkbox component with a preview of the Collection's items,
it's name, and item count.
-->
<template>
  <preview-checkbox
    v-model="_modelValue"
    :initial-value="initialValue"
    :disabled="disabled"
    :name="collection.name"
    :description="countString">
    <img
      v-for="imageUrl in previewImages"
      :key="imageUrl"
      :alt="`Item from ${collection.name} Collection`"
      :src="imageUrl">
  </preview-checkbox>
</template>

<script>
import PreviewCheckbox from '../../global/sequin/PreviewCheckbox.vue'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    PreviewCheckbox
  },
  props: {
    collection: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [Boolean, Array],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: [String, Number, Object],
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    previewImages () {
      return (this.collection?.previewImages ?? []).slice(0, 2)
    },
    countString () {
      return `${this.count} item${this.count === 1 ? '' : 's'}`
    },
    count () {
      return this.collection.count
    },
    _modelValue: {
      get () {
        return this.modelValue
      },
      set (v) {
        this.$emit('update:modelValue', v)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 50%;
  object-fit: cover;
}

img + img {
  border-left: $default-border;
}

</style>
