<template>
  <base-modal
    :full-screen="isMobile"
    use-title-border>
    <template #title>
      <h5 class="mb-0">
        Share Collection
      </h5>
    </template>
    <template #body>
      <div class="row p-3 pt-4">
        <collection-feed-preview
          class="border"
          :collection="collection"/>
      </div>
    </template>
    <template #footer>
      <div class="p-3 pb-5 mb-5 mb-md-0 pb-md-3">
        <sequin-button
          v-if="isClientsCollection"
          class="mb-3"
          variant="secondary"
          block
          @click="onShareToFeed">
          Share to Community
        </sequin-button>
        <sequin-button
          v-if="nativeShareAvailable"
          class="mb-3"
          variant="secondary"
          block
          @click="onNativeShare">
          Share With Friends
        </sequin-button>
        <copy-link
          v-else
          class="mb-3"
          button-variant="secondary"
          :display-link="collectionLink"
          :show-link="false"
          :full-link="collectionLink"
          @copy-link-click="onCopyClick"/>
        <p
          class="
            text-pewter
            text-center
            mb-1">
          Anyone with the link can view this collection
        </p>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/global/BaseModal.vue'
import CollectionFeedPreview from '../CollectionFeedPreview.vue'
import CopyLink from '../../global/sequin/CopyLink.vue'
import SequinButton from '../../global/sequin/SequinButton'
import { mapGetters, mapState as mapVuexState } from 'vuex'
import { mapActions, mapState } from 'pinia'
import useAnalytics from '@shared/composables/analytics.js'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    BaseModal,
    CollectionFeedPreview,
    CopyLink,
    SequinButton
  },
  setup () {
    const { track } = useAnalytics()
    return { track }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('collections', [
      'collectionsById'
    ]),
    ...mapVuexState('client', [
      'id'
    ]),
    ...mapGetters('client', [
      'shareQueryString'
    ]),
    ...mapGetters('collections', [
      'styleColorsFromCollectionId',
      'styleColorCountFromCollectionId'
    ]),
    collectionId () {
      return this.modalContext?.collectionId
    },
    collection () {
      return this.collectionsById[this.collectionId]
    },
    collectionLink () {
      return `https://${window.location.hostname}${this.$route.path}${this.shareQueryString}`
    },
    isClientsCollection () {
      return this.collection.client.id === this.id
    },
    nativeShareAvailable () {
      return !!navigator.share
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openModal'
    ]),
    onNativeShare () {
      navigator.share({
        text: `Check out ${this.isClientsCollection ? 'my' : 'this'} Armoire collection, ${this.collection.name}`,
        url: this.collectionLink
      })

      this.track('Shared Collection', {
        channel: 'native-share',
        id: this.collectionId,
        name: this.collection.name,
        owner: this.collection.client.id
      })
    },
    onCopyClick () {
      this.track('Shared Collection', {
        channel: 'copy-link',
        id: this.collectionId,
        name: this.collection.name,
        owner: this.collection.client.id
      })
    },
    onShareToFeed () {
      this.openModal({ name: 'share-collection-to-feed', context: { collectionId: this.collectionId } })
    }
  }
}
</script>
