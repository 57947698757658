<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        no-padding
        @back-click="setMyCaseFlyoutName('request-stylist-case-one')">
        <template #header>
          <h5 class="mb-0">
            Select all that apply
          </h5>
        </template>
        <template #body>
          <plain-button
            v-for="tag in requestStylist.occasions"
            :key="tag.tag"
            class="occasion-tag-btn"
            @click="tagOccasion(tag)">
            <occasion-tag
              :tag="tag"
              :show-remove="tag.selected"
              inner-padding
              :variant="tag.selected ? 'reverse' : 'default'"/>
          </plain-button>
        </template>
      </my-case-section>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import PlainButton from '../../global/sequin/PlainButton'
import OccasionTag from '../../community/sharelook/OccasionTag'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    MyCaseFlyoutComponent,
    MyCaseSection,
    PlainButton,
    OccasionTag
  },
  computed: {
    ...mapState('case', [
      'stylistRequest'
    ]),
    requestStylist () {
      return this.stylistRequest
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext',
      'updateOccasionTags'
    ]),
    tagOccasion (tag) {
      this.updateOccasionTags({
        tagId: tag.id
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.occasion-tag-btn {
  width: 100%;
  padding: 0 0;
  border-bottom: $default-border;
  background-color: $white;
}
</style>
