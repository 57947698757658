<template>
  <base-modal
    :flush-content="true"
    :use-title-border="true">
    <template #title>
      <h5>
        {{ modalActionHandler.modalTitle }}
      </h5>
    </template>
    <template #body>
      <div class="px-5 pt-4">
        <div class="d-flex justify-content-center">
          <component
            :is="modalActionHandler.iconComponent"
            class="mb-4"
            height="80"
            width="80"/>
        </div>
        <h6
          v-if="isAuthenticated && !isActionCollect"
          class="text-center">
          Saved to your {{ modalActionHandler.modalTitle }}!
        </h6>
        <div class="text-center mt-3 px-3">
          <p>{{ modalActionHandler.modalCopy }}</p>
          <span v-if="!isAuthenticated">
            <p class="pt-5 large-paragraph">
              <span v-if="isActionCollect">To customize your own collection,
              </span> <span v-else>
                To {{ modalActionHandler.action }} this item,
              </span>
              <br>
              <text-link
                bold
                :underline="false"
                size="paragraph"
                type="router-link"
                :to="{ name: 'style-quiz-intro' }">
                create an account
              </text-link>
              or
              <text-link
                bold
                :underline="false"
                size="paragraph"
                type="router-link"
                :to="{ name: 'login' }">
                login</text-link>.
            </p>
          </span>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '../global/BaseModal'
import TextLink from '../global/sequin/TextLink.vue'
import { mapState as mapVuexState } from 'vuex'
import { mapState } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  name: 'ClosetModalRatingInfo',
  components: { BaseModal, TextLink },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('client', [
      'isAuthenticated'
    ]),
    isActionCollect () {
      return this.modalContext?.action === 'collect'
    },
    modalActionHandler () {
      const action = {}
      switch (this.modalContext?.action) {
        case 'favorite':
          action.action = 'favorite'
          action.modalTitle = 'Favorites'
          action.iconComponent = 'svg-favorite-button'
          action.modalCopy = 'Armoire makes smart recommendations based on your Favorites. Show us what you love, and we’ll serve up your next favorite style.'
          break
        case 'dislike':
          action.action = 'dislike'
          action.modalTitle = 'Dislikes'
          action.iconComponent = 'svg-dislike-button'
          action.modalCopy = 'Love everything in your Closet. Let us know what you dislike, and we’ll make smarter recommendations.'
          break
        case 'collect':
          action.action = 'collect'
          action.modalTitle = 'Collections'
          action.iconComponent = 'svg-collect-button'
          action.modalCopy = 'Your wardrobe, your terms. Organize your Closet with Collections.'
          break
      }
      return action
    }
  }
}
</script>

<style lang="scss" scoped>

  .primary-fill {
    fill: $primary;
    stroke: $primary;
  }

  .black-fill {
    fill: $armor;
    stroke: $armor;
  }

  .large-paragraph {
    font-size: 18px !important;
    * {
      font-size: 18px !important;
    }
  }
  .outline {
  border: 1px solid $pewter;
  }

</style>
