<template>
  <my-case-section-core>
    <template #body>
      <div
        v-for="pickupPackage in pickupPackages"
        :key="pickupPackage.id">
        <my-case-item
          v-for="item in pickupPackage.packageItems"
          :key="item.item.id"
          hide-price
          :item="item.item"/>
        <my-case-item
          v-for="(item, index) in soldItemsForPackage(pickupPackage)"
          :key="index"
          :item="item.item"
          image-label="Purchased"
          hide-price/>
        <add-on-item-type
          v-for="item in addOnItemsForPackage(pickupPackage)"
          :key="item.addOnItemType.id"
          :type-id="item.addOnItemType.id"
          :quantity="item.quantity"
          back-click="core"
          confirmed/>
      </div>
    </template>
  </my-case-section-core>
</template>

<script>
import AddOnItemType from '../addon/AddOnItemType'
import MyCaseItem from '../common/MyCaseItem'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseSectionCore from '../common/MyCaseSectionCore.vue'
import { mapState } from 'vuex'

export default {
  components: {
    AddOnItemType,
    MyCaseItem,
    MyCaseSectionCore
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('client', [
      'membershipStatus'
    ]),
    ...mapState('closet', [
      'addOnTransitItems',
      'soldTransitItems'
    ])
  },
  methods: {
    addOnItemsForPackage (pickupPackage) {
      return this.addOnTransitItems.filter(
        x => x.package.id === pickupPackage.id)
    },
    soldItemsForPackage (pickupPackage) {
      return this.soldTransitItems.filter(
        x => x.package.id === pickupPackage.id)
    }
  }
}
</script>
