<template>
  <base-modal
    center-title
    use-title-border>
    <template #title>
      <div class="semi-bold">
        <span
          v-if="membershipDetails.allowedShipments === null">
          Unlimited Case Swaps
        </span>
        <span
          v-else-if="membershipDetails.allowedShipments === 0">
          No Case Swaps Available
        </span>
        <span
          v-else>
          {{ membershipDetails.allowedShipments }} Case Swap{{ (membershipDetails.allowedShipments > 1 )? 's' : '' }} Available
        </span>
      </div>
    </template>
    <template #body>
      <div class="pt-4 px-2 text-center">
        <swaps-available-icon
          class="mx-auto my-3"/>
        <div v-if="membershipDetails.allowedShipments === null">
          <swaps-available-modal-unlimited
            @my-case-link="caseLink"/>
        </div>
        <div v-if="membershipDetails.allowedShipments !== null">
          <swaps-available-modal-capsule
            @my-case-link="caseLink"/>
        </div>
      </div>
    </template>
  </base-modal>
</template>
<script>
import { mapState, mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import BaseModal from '@/components/global/BaseModal.vue'
import MyCaseMixin from '../../case/common/MyCaseMixin.vue'
import SwapsAvailableIcon from './SwapsAvailableIcon.vue'
import SwapsAvailableModalUnlimited from './SwapsAvailableModalUnlimited.vue'
import SwapsAvailableModalCapsule from './SwapsAvailableModalCapsule.vue'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    BaseModal,
    SwapsAvailableIcon,
    SwapsAvailableModalUnlimited,
    SwapsAvailableModalCapsule
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('client', [
      'membershipDetails'
    ])
  },
  methods: {
    ...mapVuexActions('case', [
      'setMyCaseFlyoutName'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeModal',
      'openFlyout'
    ]),
    caseLink (flyoutName = 'core') {
      this.closeModal()
      this.setMyCaseFlyoutName(flyoutName)
      this.openFlyout({ name: 'my-case' })
    }

  }
}
</script>
