<template>
  <base-modal dialog-class="modal modal-lg">
    <template #title>
      <h4>
        We've got you covered!
      </h4>
    </template>
    <template #body>
      <div>
        <div class="row">
          <div class="col-md-6 pb-3">
            Find your denim (waist) size by measuring around the smallest part of your waistline, keeping the tape a bit loose.<br><br>

            Or, use our denim size chart. We recommend trying your usual size and the next size up.
          </div>
          <div class="col-md-6">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <h5 class="m-0">
                      Pant Size
                    </h5>
                  </th>
                  <th>
                    <h5 class="m-0">
                      Denim Size
                    </h5>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-light">
                <tr
                  v-for="(denimSize, pantSize) in sizeMap"
                  :key="denimSize">
                  <td>{{ pantSize }}</td>
                  <td>{{ denimSize }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/global/BaseModal.vue'

export default {
  components: {
    BaseModal
  },
  data () {
    return {
      /* eslint-disable */
      sizeMap: {
        '000': '23',
        '00': '24',
        '0': '25',
        '2': '26',
        '4': '27',
        '6': '28',
        '8': '29',
        '10': '30',
        '12': '31',
        '12-14': '32',
        '14W-16': '33',
        '16W-18W': '34',
        '18W-20W': '35',
        '20W-22W': '36',
        '22W-24W': '37'
      }
      /* eslint-enable */
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.modal-lg) {
    max-width: 720px;
  }

  thead {
    background-color: $dark;
  }

  h5 {
    font-size: 1em;
  }

  .row {
    line-height: 1.5rem;
  }
</style>
