<template>
  <base-modal class="page-share-modal">
    <template #title>
      <div>
        <h3 class="share-header">
          Share
        </h3>
      </div>
    </template>
    <template #body>
      <div class="share-modal-container">
        <p :style="{ 'font-weight': '600 !important' }">
          Social
        </p>
        <div class="d-flex justify-content-around">
          <plain-button
            v-for="option in shareOptions"
            :key="option.name"
            class="share-option-container d-flex flex-column align-items-center p-0 pe-5"
            @click="shareOptionClick(option.name)">
            <component
              :is="option.icon"
              class="mb-2"
              :width="36"
              :height="36"/>
            <p>
              {{ option.name }}
            </p>
          </plain-button>
        </div>
        <span class="d-flex align-items-center">
          <p :style="{ 'font-weight': '600 !important' }">Link</p>
        </span>
        <div class="d-flex align-items-center">
          <base-input
            class="share-link me-3"
            read-only
            :model-value="pageLink"/>
          <text-link
            class="copy-text"
            @click="copyUrl">
            {{ copyText }}
          </text-link>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapState } from 'pinia'
import PlainButton from '../global/sequin/PlainButton'
import BaseModal from '@/components/global/BaseModal.vue'
import BaseInput from '../global/BaseInput'
import TextLink from '../global/sequin/TextLink'
import { useOverlaysStore } from '@/stores/overlays.js'
import useAnalytics from '@shared/composables/analytics.js'
import { mapState as mapVuexState } from 'vuex'

export default {
  components: {
    PlainButton,
    BaseModal,
    BaseInput,
    TextLink
  },
  setup () {
    const { track } = useAnalytics()
    return {
      track
    }
  },
  data () {
    return {
      copyText: 'Copy',
      shareOptions: [
        {
          name: 'Email',
          icon: 'svg-share-email'
        },
        {
          name: 'Facebook',
          icon: 'svg-share-facebook'
        },
        {
          name: 'Pinterest',
          icon: 'svg-share-pinterest'
        }
      ]
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('brands', [
      'brandDetails'
    ]),
    isBrandShare () {
      return this.$route.name === 'brand'
    },
    pageLink () {
      let link = 'https://armoire.style' + this.$route.fullPath
      if (!this.isBrandShare) {
        if (link.indexOf('closet/') > 0 && link.indexOf('closet/u/') === -1) {
          link = link.replace('closet', 'closet/u') // Allow anonymous user access
        }
      }
      return link
    },
    mailToLink () {
      const subject = encodeURIComponent('Check out these Armoire styles')
      let body = `${this.modalContext.styleColorSetPageModal.name}:\n${this.pageLink}`
      if (!this.isBrandShare && this.modalContext.styleColorSetPageModal.description) {
        body += `\n\n${this.modalContext.styleColorSetPageModal.description}`
      }
      body = encodeURIComponent(body)
      return `mailto:?body=${body}&subject=${subject}`
    },
    pinterestLink () {
      const baseUrl = 'https://www.pinterest.com/pin/create/button/'
      return `${baseUrl}?url=${this.pageLink}&media=${this.previewImage}&description=${encodeURIComponent(this.$route.name)}`
    },
    previewImage () {
      return this.modalContext.styleColorSetPageModal.styleColors[0].images.front.url
    }
  },
  methods: {
    copyUrl () {
      const el = document.createElement('textarea')
      el.value = this.pageLink
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.copyText = 'Copied'
      setTimeout(() => {
        this.copyText = 'Copy'
      }, 2000)
      this.trackShare('Copy')
    },
    shareOptionClick (method) {
      this.trackShare(method)
      switch (method) {
        case 'Email':
          window.location.href = this.mailToLink
          break
        case 'Facebook':
          this.$fb.ui(
            {
              method: 'share',
              href: this.pageLink,
              hashtag: '#armoire',
              quote: 'Check out these Armoire styles'
            },
            () => {}
          )
          break
        case 'Pinterest':
          window.open(this.pinterestLink, '_blank')
          break
        default:
          break
      }
    },
    trackShare (method) {
      let name = this.modalContext.styleColorSetPageModal.name
      if (this.isBrandShare) {
        name = this.brandDetails[this.modalContext.styleColorSetPageModal.id].name
      }
      this.track('Clicked Share Method', {
        type: this.$route.name,
        id: this.modalContext.styleColorSetPageModal.id,
        name,
        channel: 'web-share',
        method: method
      })
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.container-fluid) {
  padding: 0;
}
:deep(.modal-header) {
  padding-bottom: 15px;
}
.share-modal-container {
  display: grid;
  grid-template-columns: 13% auto;
  row-gap: 20px;
}
.share-header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-align: left;
  text-transform: uppercase;
}
.page-share-modal {
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    margin: 0;
  }
  .form-group {
    width: 100%;
    margin: 0;
  }
}
.copy-text {
  color: $orchid;
  text-transform: uppercase;
  font-weight: 600 !important;
  text-decoration: none;
  padding: 0;
  font-family: "Montserrat";
}
.share-link :deep(.form-control) {
  border-radius: 0 !important;
  color: #bababf !important;
  background-color: white !important;
}
</style>
