<template>
  <BaseFlyout @close="onClose">
    <template #title>
      <PlainButton @click="onClose">
        <SvgChevronLeft color="white"/>
      </PlainButton>
    </template>
    <template #body>
      <div class="filter-flyout">
        <div>
          <div v-if="isAuthenticated">
            <h5 class="text-center text-white semi-bold">
              Current Sizes
            </h5>
            <div class="pt-3">
              <SizeSelection
                container-class="d-flex flex-wrap justify-content-center px-2 column-gap-xs"
                title=""
                display-only
                :sizes="selectedSizes"
                emit-ref="displaySizes"/>
              <div
                class="d-flex justify-content-center pt-2">
                <BaseButton
                  variant="text-link-light"
                  text="Update"
                  @click="navigateToStyleProfile"/>
              </div>
            </div>
          </div>

          <div
            v-if="!hideSort"
            class="sort-section pt-5">
            <h5 class="text-center text-white semi-bold">
              Sort
            </h5>
            <DropdownSingleSelect
              v-model="sort"
              small
              :dropdown-options="sortOptions"
              placeholder="Sort By"
              :initial-selection-index="0"/>
          </div>

          <h5
            class="text-center text-white semi-bold mt-5">
            Filters
          </h5>
          <div
            v-for="(filter, key) in visibleFilterOptions"
            :key="key">
            <AccordionWithHeader
              :expanded="isExpanded(key)"
              :title="addSpaceBeforeCapitals(key)"
              :expanded-max-height="300"
              class="filter-flyout-title filter-dropdown"
              :border="false"
              @accordion-click="toggleExpanded(key)">
              <FilterSection
                :key="key"
                v-model="filterSelections[key]"
                :class="`${key} mt-3`"
                :title="key"
                :variant="'div'"
                :button-variant="'link'"
                :options="filter"/>
            </AccordionWithHeader>
          </div>
        </div>
      </div>
    </template>
  </BaseFlyout>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import BaseFlyout from '@/components/global/BaseFlyout'
import PlainButton from '@/components/global/sequin/PlainButton'
import FilterSection from '@/components/styleColorSet/filtersFlyout/FilterSection.vue'
import SizeSelection from '@/components/global/sequin/SizeSelection.vue'
import { useOverlaysStore } from '@/stores/overlays.js'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import AccordionWithHeader from '@/components/global/sequin/AccordionWithHeader'
import DropdownSingleSelect from '@/components/global/sequin/DropdownSingleSelect'
import BaseButton from '@shared/components/ADORN/BaseButton'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'
import useStyleColorSet from '@/composables/styleColorSet.js'
import { addSpaceBeforeCapitals } from '@/utils/stringParsing.js'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@shared/stores/client.js'

const store = useStore()
const router = useRouter()

const { isAuthenticated } = storeToRefs(useClientStore())

const expandedSection = ref('')
const overlaysStore = useOverlaysStore()
const styleColorSetFiltersFlyout = computed(() => overlaysStore.flyouts['style-color-set-filters'])
const filterSelections = ref({})
const sort = ref('')
const sortOptions = ref([
  { text: 'Recommended', value: 'recommended' },
  { text: 'Star Rating', value: 'rating' },
  { text: 'Retail Price', value: 'price' },
  { text: 'Newly Available', value: 'new' }
])
async function onClose () {
  const filtersMatch = JSON.stringify(filterSelections.value) === JSON.stringify(styleColorSet.value.filters) && sort.value === styleColorSet.value.sort
  if (!filtersMatch) {
    await applyFilters(filterSelections.value)
  }
  overlaysStore.closeFlyout()
}
function isExpanded (key) {
  return expandedSection.value === key
}
function toggleExpanded (key) {
  expandedSection.value = expandedSection.value === key ? '' : key
}
const styleColorSet = computed(() => {
  return styleColorSetFiltersFlyout.value.context.styleColorSet
})
const filterOptions = computed(() => {
  return styleColorSet.value.filterOptions
})
const visibleFilterOptions = computed(() => {
  // filterOptions that aren't empty
  return Object.entries(filterOptions.value).reduce((acc, [key, value]) => {
    if (value.length > 0) {
      acc[key] = value
    }
    return acc
  }, {})
})
const { sourceType, hideSort } = useStyleColorSet(() => styleColorSet.value)

async function applyFilters (filterSelections) {
  // Browse sorting is handled alongside other filters.
  if (sourceType.value !== 'browse') {
    await store.dispatch('closet/updateSection', {
      id: styleColorSet.value.id,
      sort: sort.value,
      useDetailView: true
    })
  }

  switch (sourceType.value) {
    case 'brand':
      await store.dispatch('brands/updateFilters', {
        brandId: styleColorSet.value.id,
        filters: { ...filterSelections }
      })
      break
    case 'browse': {
      const params = {
        section: router.currentRoute.value.params.section,
        subsection: router.currentRoute.value.params.subsection,
        available: styleColorSet.value.available,
        filters: filterSelections,
        sort: sort.value
      }
      store.dispatch('closet/getStyleColorsByBrowseSection', params)
    } break
    case 'collection':
      await store.dispatch('collections/updateFilters', {
        collectionId: styleColorSet.value.id,
        filters: { ...filterSelections },
        available: styleColorSet.value.available
      })
      break
    case 'search':
      await Promise.allSettled(
        Object.entries(filterSelections).map(([filterType, filterValues]) =>
          store.dispatch('search/updateSearchFilters', {
            key: filterType,
            value: filterValues
          })
        )
      )
      store.dispatch('search/reloadSearch')
      break
    default:
      await Promise.allSettled(
        Object.entries(filterSelections).map(([filterType, filterValues]) =>
          store.dispatch('closet/updateSectionFilters', {
            section: styleColorSet.value,
            key: filterType,
            value: filterValues
          })
        )
      )
      store.dispatch('closet/reloadSection', { useDetailView: true, ...styleColorSet.value })
      break
  }
}

const selectedSizes = computed(() => {
  const numberToLetterSizeOptions = store.getters['styleProfile/numberToLetterSizeOptions']
  const styleProfile = store.state.styleProfile
  const waistSizesMapped = styleProfile.waistSizes.map(size => {
    return {
      text: size,
      value: size
    }
  })
  const allSizes = [...styleProfile.dressSizes, ...styleProfile.shirtSizes, ...styleProfile.pantSizes, ...styleProfile.jumpsuitSizes, ...waistSizesMapped]
  const allSizesMapped = [...numberToLetterSizeOptions().filter((size) => { return allSizes.includes(size.value) }), ...numberToLetterSizeOptions(true).filter((size) => { return allSizes.includes(size.value) })]
  return [...waistSizesMapped, ...allSizesMapped]
})
function navigateToStyleProfile () {
  overlaysStore.closeFlyout()
  router.push({ name: 'style-profile' })
}
onMounted(() => {
  // fetch filter options
  filterSelections.value = JSON.parse(JSON.stringify(styleColorSet.value.filters))
  sort.value = styleColorSet.value.sort
})

</script>

<style scoped lang="scss">
:deep(.flyout) {
  background-color: $plum;
}
.filter-flyout {
  background-color: $plum;
  color: $white;
}

.sort-section {
  display: flex;
  padding: 0 20px 20px;
  justify-content: space-between;
  align-items: center;

}
:deep(.accordion-button) {
  padding: 10px 20px;
  color: $white;
  h5 {
    color: $white;
    font-weight: $font-weight-semibold;
}
}
.colors :deep(.button-group-wrapper) {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 8px;
}
:deep(.expanded) {
  overflow: auto;
    @include hide-scrollbar;
}
:deep(.dropdown-single-select .selected-option) {
  text-transform: uppercase;
}
:deep(.dropdown-list-item.small) {
  color: $armor;
}
:deep(.filter-button-link) {
padding-bottom: 12px !important;
}
:deep(.flyout-header) {
  border: none;
}
</style>
