<template>
  <my-case-section-core no-padding>
    <template #body>
      <div
        v-for="(shippedPackage, index) in shippedPackages"
        :key="shippedPackage.id">
        <my-case-shipped-package
          :index="index"
          :shipped-package="shippedPackage"
          :add-on-items="transitAddOnItemsForPackage(shippedPackage)"
          :sold-items="soldItemsForPackage(shippedPackage)"
          class="on-the-way-section"
          :class="{ 'border-top': index > 0, 'mobile': isMobile }"
          hide-price/>
      </div>
    </template>
  </my-case-section-core>
</template>

<script>
import MyCaseSectionCore from '../common/MyCaseSectionCore'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseShippedPackage from './MyCaseShippedPackage'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    MyCaseSectionCore,
    MyCaseShippedPackage
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('client', [
      'membershipStatus'
    ]),
    ...mapState('closet', [
      'addOnTransitItems',
      'addOnPackingItems'
    ]),
    ...mapGetters('client', [
      'active'
    ])
  },
  methods: {
    transitAddOnItemsForPackage (shippedPackage) {
      return this.addOnTransitItems.filter(
        x => x.package.id === shippedPackage.id)
    },
    soldItemsForPackage (shippedPackage) {
      return this.soldTransitItems.filter(
        x => x.package.id === shippedPackage.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.on-the-way-section {
  padding: 40px 48px;

  &.mobile {
    padding: 24px;
  }
}

</style>
