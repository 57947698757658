<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <div class="semi-bold">
            Pick up at {{ pickupPackage.pickupFrom.name }}
          </div>
        </template>
        <template #body>
          <div class="p-2">
            <div class="d-flex justify-content-between">
              <div>
                <p class="semi-bold small-text mb-0">
                  Address
                </p>
                <pickup-address
                  class="small-text my-2 text-start"
                  :pickup-address="pickupPackage.pickupFrom"/>
              </div>
              <text-link
                type="a"
                small
                target="_blank"
                :href="pickupPackage.pickupFrom.mapLink">
                Open Map
              </text-link>
            </div>
            <div
              v-if="pickupLocationHasKiosks"
              class="mt-3">
              <div
                v-for="section in detailSections"
                :key="section.title"
                class="mb-4">
                <p class="semi-bold small-text mb-1">
                  {{ section.title }}
                </p>
                <p class="small-text">
                  {{ section.description }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </my-case-section>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import { mapActions } from 'vuex'
import PickupAddress from '../../global/address/PickupAddress.vue'
import MyCaseMixin from '../common/MyCaseMixin.vue'
import TextLink from '../../global/sequin/TextLink.vue'

export default {
  components: {
    MyCaseFlyoutComponent,
    MyCaseSection,
    PickupAddress,
    TextLink
  },
  mixins: [MyCaseMixin],
  data () {
    return {
      detailSections: [
        {
          title: 'Checking In',
          description: 'When you arrive, use our in-store kiosk to check in and process any items you\'re returning. You can check in via mobile app, by scanning your return items, or with your Armoire email and password.'
        },
        {
          title: 'Checking Out',
          description: 'Once you’ve made your final selections, you\'ll return to our kiosk for contactless checkout. Simply scan your new items, confirm your choices, and walk out with your fabulous new Armoire styles!'
        }
      ]
    }
  },
  computed: {
    pickupPackage () {
      return this.pickupPackages[0]
    },
    pickupLocationHasKiosks () {
      return !!this.pickupPackage.pickupFrom.kiosks
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ])
  }
}
</script>
