<template>
  <div
    class="input-number"
    :class="{[`input-number--${size}`]: size}">
    <button
      class="input-number__button input-number__button--minus"
      :disabled="disabled"
      @click="decreaseNumber"
      @mousedown="whileMouseDown(decreaseNumber)"
      @mouseup="clearTimer"/>
    <input
      class="input-number__input"
      :min="min"
      :max="max"
      :disabled="disabled"
      type="number"
      :value="numericValue"
      @keypress="validateInput"
      @input="inputValue">
    <button
      class="input-number__button input-number__button--plus"
      :disabled="disabled"
      @click="increaseNumber"
      @mousedown="whileMouseDown(increaseNumber)"
      @mouseup="clearTimer"/>
  </div>
</template>

<script>
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    min: {
      default: 0,
      type: Number
    },
    max: {
      default: 10,
      type: Number
    },
    step: {
      default: 1,
      type: Number
    },
    mouseDownSpeed: {
      default: 100,
      type: Number
    },
    integer: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    size: {
      type: String,
      default: undefined
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      numericValue: this.modelValue,
      timer: null
    }
  },
  watch: {
    numericValue: function (val, oldVal) {
      if (val <= this.min) {
        this.numericValue = parseInt(this.min)
      }
      if (val >= this.max) {
        this.numericValue = parseInt(this.max)
      }
      if (val <= this.max && val >= this.min) {
        this.$emit('update:modelValue', val, oldVal)
      }
    }
  },
  methods: {
    clearTimer () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    whileMouseDown (callback) {
      if (this.timer === null) {
        this.timer = setInterval(() => {
          callback()
        }, this.mouseDownSpeed)
      }
    },
    increaseNumber () {
      this.numericValue += this.step
    },
    decreaseNumber () {
      this.numericValue -= this.step
    },
    isInteger (evt) {
      let key = evt.keyCode || evt.which
      key = String.fromCharCode(key)
      const regex = /[0-9]/
      if (!regex.test(key)) {
        evt.returnValue = false
        if (evt.preventDefault) evt.preventDefault()
      }
    },
    isNumber (evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    validateInput (evt) {
      if (this.integer === true) {
        this.isInteger(evt)
      } else {
        this.isNumber(evt)
      }
    },
    inputValue (evt) {
      this.numericValue = evt.target.value
        ? parseInt(evt.target.value)
        : this.min
    }
  }
}
</script>

<style lang='scss' scoped>
.input-number {
  display: block;
  font-size: 0;
  max-width: 100%;
  overflow: hidden;
  position: relative;

  &__input {
    -moz-appearance: textfield;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    display: block;
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    max-width: 100%;
    min-height: 1.5rem;
    min-width: 3rem;
    padding: 0.4375rem 0.875rem;
    transition: border-color 0.15s;
    width: 100%;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &:focus {
      border-color: #0074d9;
      outline: none;
    }
  }

  &--small {
    &.input-number {
      max-width: 7rem;
    }
    &.input-number > button {
      width: 1.6rem;

      &::before {
        height: 1px;
        width: 40%;
      }

      &::after {
        height: 30%;
        width: 1px;
      }
    }
  }

  &__button {
    background-color: #fff;
    border: 0;
    border-radius: 0.25rem;
    bottom: 1px;
    position: absolute;
    top: 1px;
    width: 2.5rem;
    z-index: 1;

    &:focus {
      outline: none;
    }

    &:hover {
      &::before,
      &::after {
        background-color: $plum;
      }
    }

    &::before,
    &::after {
      background-color: #111;
      content: "";
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.15s;
    }
    &::before {
      height: 1px;
      width: 50%;
    }
    &::after {
      height: 50%;
      width: 1px;
    }

    &--minus {
      border-bottom-right-radius: 0;
      border-right: 1px solid #ddd;
      border-top-right-radius: 0;
      left: 1px;

      &::after {
        visibility: hidden;
      }
    }

    &--plus {
      border-bottom-left-radius: 0;
      border-left: 1px solid #ddd;
      border-top-left-radius: 0;
      right: 1px;
    }
  }
}
</style>
