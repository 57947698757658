<template>
  <div
    :style="{
      'background-color': color,
      'height': width,
      'width': width
    }"
    class="circle"
    :class="{ 'white': color === '#ffffff' }"/>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000'
    },
    width: {
      type: String,
      default: '16px'
    }
  }
}
</script>

<style lang="scss" scoped>
  .circle {
    display: inline-block;
    border-radius: 50%;

    &.white {
      border: 1px solid #BBB;
    }
  }
</style>
