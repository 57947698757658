<template>
  <div>
    <div
      v-if="hasHeaderSlot"
      class="my-case-header"
      :class="{'mobile': isMobile}">
      <plain-button
        v-if="backButton"
        data-intercom-target="intercom-my-case-exit"
        class="back-button p-2"
        :class="{ 'mobile': isMobile }"
        @click="onClose()">
        <svg-chevron-left
          :width="isMobile ? 20 : 24"
          :height="isMobile ? 20 : 24"/>
      </plain-button>
      <slot name="header"/>
    </div>
    <div class="body">
      <slot name="body"/>
    </div>
    <div
      v-if="hasFooterSlot"
      class="my-case-flyout-footer"
      :class="{'pb-4': isMobile}">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import PlainButton from '../../global/sequin/PlainButton'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'

export default {
  components: {
    PlainButton,
    SvgChevronLeft
  },
  props: {
    backButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasHeaderSlot () {
      return this.$slots.header
    },
    hasFooterSlot () {
      return this.$slots.footer
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    onClose () {
      this.closeFlyout()
    }
  }

}
</script>

<style lang="scss" scoped>
.body {
  min-height: 90%;
  overflow-y: unset;
}

 .my-case-flyout-footer {
  border-top: $default-border;
  position: sticky;
  width: 90vw;
  z-index: 1050;
  bottom: 0;
  padding: 16px;
  background: $white;
  max-width: 100%;
}

.my-case-header {
  position: relative;
  font-weight: $font-weight-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 12px;
  border-bottom: $default-border;

  &.mobile {
    padding-top: 18px;
  }

}

.back-button {
  position: absolute;
  left: 8px;
  top: 8px;
  padding: 4px;
  background-color: unset !important;

  &.mobile {
    left: 2px;
    top: 9.5px;
  }
}

</style>
