<template>
  <div
    v-if="!hideBanners"
    id="banners"
    :style="{ top: isTabletOrSmaller ? '50' : '0' }">
    <div
      v-if="isImpersonated && !stylistClosetInProgress"
      class="d-flex justify-content-end align-items-center card-panel text-center bg-warning p-3">
      <p>You are impersonating <b>{{ clientFullName }}</b></p>
      <a
        class="btn btn-danger px-5 mx-2"
        href="/impersonate/stop/?next=/admin/clients/client/">
        Stop
      </a>
    </div>
    <StylingBanner v-if="impersonatorIsStylist"/>
    <SaleBanner v-if="sale"/>
    <BaseBanner
      v-for="banner in banners"
      :key="banner"
      :link="banner.link"
      :type="banner.type"
      :button="banner.button"
      :show-icon="banner.showIcon"
      :close-button="banner.closeButton">
      <template #title>
        {{ banner.title }}
      </template>
      <template #text>
        <div
          v-if="banner.name === 'FirstCaseTimeoutBanner'"
          class="align-self-center">
          <CountdownTimer
            use-days
            :cutoff="new Date(firstCaseDueDate)">
            <template #after>
              <b> left to fill your first case</b>
              before we automatically send you a stylist-filled case.
            </template>
          </CountdownTimer>
        </div>
        <span
          v-else>
          {{ banner.text }}
        </span>
      </template>
    </BaseBanner>
    <!-- Toast notifications should be last, below any banners etc. -->
    <ToastNotificationList/>
  </div>
</template>

<script setup>
import { watch, computed } from 'vue'
import { useStore } from 'vuex'
import { storeToRefs } from 'pinia'
import ToastNotificationList from '@/components/global/ToastNotificationList.vue'
import StylingBanner from '@/components/headers/StylingBanner.vue'
import SaleBanner from '@/components/headers/SaleBanner.vue'
import BaseBanner from '@/components/headers/BaseBanner.vue'
import CountdownTimer from '@/components/global/dates/CountdownTimer.vue'
import useClient from '@/composables/client'
import useRouteMetadata from '@/composables/routeMetadata.js'
import useScreenSize from '@shared/composables/screenSize.js'
import { useStylingStore } from '@/stores/styling'
import { useFedExOptInStore } from '@/stores/fedExOptIn.js'

const { isTabletOrSmaller } = useScreenSize()
const { hideBanners } = useRouteMetadata()

const store = useStore()
const sale = computed(() => store.state.closet.sale)
const {
  isImpersonated,
  impersonatorIsStylist,
  firstCaseDueDate,
  name: clientFullName,
  paymentIsExpired,
  showFirstCaseCountdown
} = useClient()

const styling = useStylingStore()

const stylistClosetInProgress = computed(() => styling.stylistClosetInProgress)

const { dismissFedExBanner, optIntoFedEx } = useFedExOptInStore()
const {
  showFedExBanner,
  showSuccessBanner: showFedExSuccessBanner,
  showFedExPickupBanner
} = storeToRefs(useFedExOptInStore())

const banners = computed(() => {
  const banners = []
  if (paymentIsExpired.value) {
    banners.push(
      {
        name: 'PaymentExpiredBanner',
        title: 'Your credit card has expired.',
        text: 'Please update your payment method.',
        link: {
          title: 'Update now',
          type: 'router-link',
          underline: false,
          bold: true,
          variant: 'inherit',
          to: { name: 'account-billing' }
        },
        type: 'default'
      }
    )
  }
  if (showFirstCaseCountdown.value) {
    banners.push({
      name: 'FirstCaseTimeoutBanner',
      type: 'smart'
    })
  }
  if (showFedExBanner.value) {
    banners.push({
      name: 'FedExSwitchBanner',
      type: 'no-line-break-secondary',
      title: 'More Perks with FedEx!',
      text: 'Get free scheduled pickups & faster processing.',
      showIcon: false,
      button: {
        text: 'Opt into FedEx',
        variant: 'secondary',
        action: () => {
          optIntoFedEx('banner')
        }
      },
      closeButton: {
        action: () => {
          dismissFedExBanner('opt-in')
        }
      }
    })
  }
  if (showFedExSuccessBanner.value) {
    banners.push({
      name: 'FedExSuccessBanner',
      type: 'no-line-break-secondary',
      title: 'Success! 🎉',
      text: 'You\'re now using FedEx for returns.',
      showIcon: false,
      closeButton: {
        action: () => {
          dismissFedExBanner('success')
        }
      }
    })
  }
  if (showFedExPickupBanner.value) {
    banners.push({
      name: 'FedExPickupBanner',
      type: 'no-line-break-primary',
      title: 'Time for your free pickup with FedEx.',
      showIcon: false,
      link: {
        title: 'Schedule Home Pickup',
        type: 'router-link',
        underline: true,
        to: { name: 'account', query: { section: 'shipping-returns' } }
      },
      closeButton: {
        action: () => {
          dismissFedExBanner('pickup')
        }
      }
    })
  }
  return banners
})

// dismiss fedEx success banner after 5 seconds
watch(() => showFedExSuccessBanner.value, (value) => {
  if (value) {
    setTimeout(() => {
      showFedExSuccessBanner.value = false
    }, 5000)
  }
})
</script>

<style lang="scss" scoped>
#banners {
  position: sticky;
  z-index: $zindex-sticky
}
</style>
