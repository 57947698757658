<template>
  <my-case-flyout-component
    ref="flyout">
    <template #body>
      <div class="sticky-top">
        <div>
          <plain-button
            data-intercom-target="intercom-my-case-exit"
            class="back-button bg-origami"
            :class="{ 'mobile': isMobile, 'bg-origami': !orchidVariant, 'bg-orchid': orchidVariant }"
            @click="closeFlyout">
            <svg-chevron-left
              :stroke="orchidVariant ? '#ffffff' : 'currentColor'"
              :width="isMobile ? 20 : 24"
              :height="isMobile ? 20 : 24"/>
          </plain-button>
          <my-case-flyout-core-header/>
        </div>
        <div
          v-if="myCaseTabs.length > 1"
          ref="tabBarContainer"
          class="pivot-bar">
          <div class="pivot-wrapper">
            <sequin-pivot
              ref="tabBar"
              centered
              :tabs="myCaseTabs"
              :tab-selected="coreTabSelected"
              @select-tab="setTabSelected">
              <template #selected-slot>
                <my-case-icon-counter class="ms-2"/>
              </template>
            </sequin-pivot>
          </div>
        </div>
      </div>
      <transition
        name="fade">
        <component
          :is="myCaseTabSelected.component"
          v-if="myCaseTabSelected"
          :id="myCaseTabSelected.id"/>
        <!-- blank space/hide spinner for non_members -->
        <div v-else-if="membershipStatus === 'non_member'"/>
        <div
          v-else
          class="d-flex flex-column align-items-center justify-content-center mt-5">
          <svg-spinner height="40"/>
        </div>
      </transition>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseAwaitingReview from './MyCaseAwaitingReview'
import MyCaseAwaitingSwap from './MyCaseAwaitingSwap.vue'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseSelectItems from './MyCaseSelectItems'
import MyCaseBonusItems from './MyCaseBonusItems'
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseFlyoutCoreHeader from './header/MyCaseFlyoutCoreHeader'
import MyCaseHowToReturn from './MyCaseHowToReturn.vue'
import MyCaseIconCounter from '../common/MyCaseIconCounter'
import MyCasePacking from './MyCasePacking'
import MyCasePurchasedItems from './MyCasePurchasedItems'
import MyCaseReadyForPickup from './MyCaseReadyForPickup.vue'
import MyCaseReturning from './MyCaseReturning'
import MyCaseShipping from './MyCaseShipping'
import MyCaseStylistCaseInProgress from './MyCaseStylistCaseInProgress'
import MyCaseWithMe from './MyCaseWithMe'
import SequinPivot from '../../global/sequin/SequinPivot'
import PlainButton from '../../global/sequin/PlainButton'
import { mapActions } from 'pinia'
import { vuexAccessors } from '../../global/helpers/vuex'
import { useOverlaysStore } from '@/stores/overlays.js'
import { mapState } from 'vuex'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  components: {
    MyCaseAwaitingSwap,
    MyCaseBonusItems,
    MyCaseFlyoutComponent,
    MyCaseFlyoutCoreHeader,
    MyCaseHowToReturn,
    MyCaseIconCounter,
    MyCaseReadyForPickup,
    MyCaseAwaitingReview,
    MyCasePacking,
    MyCasePurchasedItems,
    MyCaseSelectItems,
    MyCaseShipping,
    MyCaseStylistCaseInProgress,
    MyCaseReturning,
    MyCaseWithMe,
    SequinPivot,
    PlainButton,
    SvgChevronLeft,
    SvgSpinner
  },
  mixins: [MyCaseMixin],
  computed: {
    ...vuexAccessors('case', [
      'coreTabSelected'
    ]),
    ...mapState('client', [
      'membershipStatus'
    ]),
    myCaseCoreTabs () {
      return [
        {
          id: 'packing',
          name: 'Packing',
          shouldShow: ((this.packingItems.length && this.pendingItems.length === 0) || (this.soldPackingItems.length)),
          component: 'my-case-packing',
          position: 0
        },
        {
          id: 'stylist-case',
          name: 'Styling',
          shouldShow: this.pendingItems.length,
          component: 'my-case-stylist-case-in-progress',
          position: 0
        },
        {
          id: 'ready-for-pickup',
          name: 'Ready for Pickup',
          shouldShow: this.pickupPackages.length && this.pickupPackages.some(pickupPackage => pickupPackage.packageItems.length > 0),
          component: 'my-case-ready-for-pickup',
          position: 1
        },
        {
          id: 'shipping',
          name: 'Shipping',
          shouldShow: this.shippedPackages.length,
          component: 'my-case-shipping',
          position: 1
        },
        {
          id: 'with-you',
          name: 'With You',
          shouldShow: this.deliveredItems.length,
          component: this.withMeComponent,
          position: 5
        },
        {
          id: 'selected',
          name: 'Selected',
          shouldShow: this.canSelectItems,
          component: this.selectItemsComponent,
          position: this.hasRentalPlan ? 3 : -1
        },
        {
          id: 'returning',
          name: 'Returning',
          shouldShow: this.toReturnItems.length,
          component: 'my-case-returning',
          position: this.primaryCaseState === 'awaiting-returns' ? -1 : 5
        },
        {
          id: 'how-to-return',
          name: 'How to Return',
          shouldShow: this.hasRentalPlan && this.toReturnItems.length === 0 && this.deliveredItems.length > 0,
          component: 'my-case-how-to-return',
          position: 5
        },
        {
          id: 'must-review',
          name: 'Awaiting Review',
          shouldShow: this.mustReviewItems.length,
          component: 'my-case-awaiting-review',
          position: 6
        },
        {
          id: 'purchased',
          name: 'Purchased',
          shouldShow: !this.canSelectItems && this.soldFutureItems.length > 0,
          component: 'my-case-purchased-items',
          position: 7
        },
        {
          id: 'bonus-items',
          name: 'Bonus Items',
          shouldShow: this.numBonusItems > 0 && !this.canSelectItems,
          component: 'my-case-bonus-items',
          position: 9
        },
        {
          id: 'awaiting-swap',
          name: 'Ready for More?',
          shouldShow: this.primaryCaseState === 'awaiting-swap' && this.membershipDetails.unlockShipmentEligible,
          component: 'my-case-awaiting-swap',
          position: 10
        }
      ]
    },
    withMeComponent () {
      return 'my-case-with-me'
    },
    selectItemsComponent () {
      return 'my-case-select-items'
    },
    myCaseTabs () {
      return this.myCaseCoreTabs
        .filter(tab => tab.shouldShow)
        .sort((tabA, tabB) => tabA.position - tabB.position)
    },
    myCaseTabSelected () {
      return this.myCaseTabs[this.coreTabSelected]
    },
    orchidVariant () {
      return this.primaryCaseState === 'inactive-member'
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    setTabSelected ({ id, offset }) {
      setTimeout(() => {
        const tabBarContainer = this.$refs.tabBarContainer
        const scrollable = document.getElementById('flyout-scrollable')
        scrollable.scrollTo({ top: 0 })
        tabBarContainer.scrollTo({ top: 0, left: offset - 48, behavior: 'smooth' })
      }, 175)
      this.coreTabSelected = this.myCaseTabs.findIndex(tab => tab.id === id)
    }
  }
}
</script>

<style lang="scss" scoped>
.pivot-bar {
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-left: 12px;
  align-items: flex-start;
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: 49px;
  border-bottom: $default-border;

  @include hide-scrollbar;
}

.pivot-wrapper {
  position: absolute;
  min-width: calc(100% - 20px);
}

.back-button {
  position: absolute;
  left: 8px;
  top: 8px;
  padding: 4px;

  &.mobile {
    left: 6px;
    top: 12px;
  }
}
</style>
