<template>
  <app-message
    has-close-button
    variant="danger"
    @close-clicked="$emit('close-clicked')">
    <div class="d-flex text-start">
      <slot/>
    </div>
  </app-message>
</template>

<script>
import AppMessage from '../../global/sequin/AppMessage'

export default {
  components: {
    AppMessage
  }
}
</script>
