<template>
  <div
    class="d-flex text-light border-bottom px-xs py-md position-relative"
    :class="background">
    <PlainButton
      v-if="closeButton"
      class="position-absolute top-0 end-0 bg-transparent"
      @click="closeButton.action">
      <SvgX
        color="white"
        width="16"
        height="16"/>
    </PlainButton>
    <div
      class="d-flex flex-column flex-md-row align-items-center w-100"
      :class="{'justify-content-between' : !noLineBreak, 'justify-content-center' : noLineBreak }">
      <div class="d-flex align-items-center">
        <div
          v-if="showIcon"
          :style="{flexBasis: iconSize}">
          <svg-discover
            :width="iconSize"
            :height="iconSize"/>
        </div>
        <p
          class="mb-0 mx-3"
          :class="{'d-flex align-items-center' : !hasTitleSlot, 'd-flex text-center' : noLineBreak, 'flex-column text-center' : isMobile }">
          <b v-if="hasTitleSlot">
            <slot
              name="title"/>
            <br v-if="!noLineBreak">
          </b>
          <span :class="{'ps-xxs' : noLineBreak || !isTabletOrSmaller && !hasTitleSlot }">
            <slot name="text"/>
          </span>
        </p>
      </div>
      <div class="d-flex align-items-center">
        <BaseButton
          v-if="button"
          :class="{'my-sm' : isTabletOrSmaller}"
          :text="button.text"
          :variant="button.variant"
          @click="button.action"/>
        <BaseLink
          v-if="link"
          :to="link.to"
          :text="link.title"
          class="text-link"/>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, useSlots } from 'vue'
import useScreenSize from '@shared/composables/screenSize.js'
import BaseLink from '@shared/components/BaseLink.vue'
import SvgDiscover from '@/components/global/svg/SvgDiscover.vue'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import PlainButton from '@/components/global/sequin/PlainButton.vue'
import SvgX from '@shared/components/icons/SvgX.vue'

const { isMobile, isTabletOrSmaller } = useScreenSize()
const props = defineProps({
  link: {
    type: Object,
    default: null
  },
  type: {
    type: String,
    default: 'default',
    validator: value => {
      return value.match(/(default|no-line-break-primary|no-line-break-secondary|smart|router-link)/)
    }
  },
  button: {
    type: Object,
    default: null
  },
  showIcon: {
    type: Boolean,
    default: true
  },
  closeButton: {
    type: Object,
    default: null
  }
})
const iconSize = computed(() => isTabletOrSmaller.value ? 25 : 35)
// Using slots() to get the current slots
const slots = useSlots()

// Checking if the title slot exists and is not empty
const hasTitleSlot = computed(() => !!slots.title && !!slots.title()[0].children)

const type = computed(() => props.type)

const noLineBreak = computed(() => type.value.includes('no-line-break'))

const background = computed(() => {
  return {
    'bg-teal': type.value === 'smart',
    'bg-primary': type.value === 'default' || type.value.includes('primary'),
    'bg-secondary': type.value.includes('secondary')
  }
})

</script>

<style scoped lang="scss">
:deep(.base-link) {
    color: inherit !important;
  }

</style>
