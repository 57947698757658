import './vue2_compat'
import { isBackendProd, isBeta, isHostBeta, isHostStaging, isHostLocal, isProd } from '@shared/utils/environment.js'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import store from './store/index'
import logger from './logger'
import router from './router'
import App from './App.vue'

import { browserTracingIntegration, init } from '@sentry/vue'
import { captureConsoleIntegration } from '@sentry/integrations'

import VueFB from './plugins/fb-sdk'

import screenSizes from './components/global/mixins/screenSizes'

import vClickOutside from 'click-outside-vue3'
import VueIntercom from '@/homepage/plugins/intercom.js'
import VueSegment from './plugins/segment'

import '@shared/utils/polyfills.js'

const app = createApp(App)

// Frontend logging
app.use(logger)

// Sentry
if (isBackendProd || isHostStaging || isHostBeta) {
  init({
    app,
    environment: isProd ? 'production' : isBeta ? 'beta' : 'staging',
    dsn: 'https://4fe9443a238947fa8c28dc5d82df3850@sentry.io/1781864',
    integrations: [
      browserTracingIntegration({ router }),
      captureConsoleIntegration({ levels: ['error'] })
    ],
    tracesSampleRate: 0.75,
    release: `armoire-vue-app@${process.env.PACKAGE_VERSION}`
  })
}

app.use(createPinia())
app.use(store)
app.use(router)

app.use(vClickOutside)
app.mixin(screenSizes)

// Segment
app.use(VueSegment, {
  prod: isProd,
  beta: isBeta
})

// Intercom for LOCAL DEVELOPMENT ONLY; in production it is initialized by the Homepage app
if (isHostLocal) {
  app.use(VueIntercom, {
    test: true
  })
}

app.use(VueFB) // Facebook SDK for Sharing

app.mount('#app')
window.App = app
