<template>
  <my-case-flyout-component>
    <template #body>
      <div class="bg-origami">
        <my-case-section
          back-button
          @back-click="backToCase">
          <template #header>
            <h5 class="mb-0">
              Request Stylist Case (1/3)
            </h5>
          </template>
          <template
            #body>
            <div class="d-flex justify-content-center w-100 mb-2">
              <closet-stylist-avatars/>
            </div>
            <p class="small-text text-center mb-3">
              An Armoire personal stylist will select <b>{{ totalItems }} piece{{ totalItems > 1 ? 's' : '' }}</b>
              <span v-if="numSelectedItems === 0">
                based on your style profile preferences.
              </span>
              <span v-else>
                to complement the {{ numSelectedItems }} style{{ numSelectedItems > 1 ? 's' : '' }} you’ve already picked.
              </span>
            </p>
          </template>
        </my-case-section>
      </div>
      <div
        v-if="rolloverItems"
        class="container">
        <p class="small-text ps-1 mb-2 mt-4">
          You have {{ rolloverItems }} rollover item{{ rolloverItems > 1 ? 's' : '' }} available
        </p>
        <h6>
          Select the number of rollover items you'd like to use for your styled case
        </h6>
        <div class="row mt-3 ps-1">
          <div class="col-4">
            <input-number
              v-model="rollover"
              size="small"
              :min="0"
              :max="rolloverItems"
              :integer="true"/>
          </div>
          <p
            class="rollover">
            Rollover Items
          </p>
        </div>
      </div>
      <div
        class="container">
        <p class="small-text ps-1 mb-2 mt-4">
          You have {{ bonusItems }} bonus item{{ bonusItems > 1 || bonusItems === 0 ? 's' : '' }} available
        </p>
        <h6>
          Select the number of bonus items you'd like to use for your styled case
        </h6>
        <p
          v-if="bonus > bonusItems"
          class="small-text ps-1 mb-2">
          You are purchasing {{ additionalBonusItems }} extra bonus item{{ additionalBonusItems > 1 ? 's' : '' }} for ${{ additionalBonusItemsCost }}
        </p>
        <p
          v-else
          class="small-text ps-1 mb-2">
          Purchase additional bonus items for just $20
        </p>
        <div class="row mt-3 ps-1">
          <div class="col-4">
            <input-number
              v-model="bonus"
              size="small"
              :min="0"
              :max="maxNumExtraItems"
              :integer="true"/>
          </div>
          <p
            class="bonus">
            Bonus Items
          </p>
        </div>
      </div>
      <div class="container">
        <h6 class="mt-4 mb-2">
          Which of the following types of garments are you interested in receiving? <span class="text-plum">(Required)</span>
        </h6>
        <p>
          Select all that apply
        </p>
        <filter-section
          v-if="globalFiltersLoaded"
          :key="categories"
          v-model="category"
          class="my-3"
          :title="categories"
          variant="div"
          :options="getFilterOptions(categories)"/>
        <h6 class="mt-4">
          Are you dressing for any specific occasions or events? <span class="text-plum">(Required)</span>
        </h6>
        <p>
          Select all that apply
        </p>
        <filter-section
          v-if="globalFiltersLoaded"
          :key="occasions"
          v-model="occasion"
          class="my-3"
          :title="occasions"
          variant="div"
          :options="getFilterOptions(occasions)"/>
      </div>
    </template>
    <template #footer>
      <sequin-button
        block
        :disabled="!category.length || !occasion.length"
        @click="requestStylistCaseOne">
        Continue with {{ totalItems }} styled items
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import SequinButton from '../../global/sequin/SequinButton'
import { mapActions, mapGetters, mapState } from 'vuex'
import ClosetStylistAvatars from '../../closet/ClosetStylistAvatars'
import FilterSection from '@/components/styleColorSet/filtersFlyout/FilterSection'
import InputNumber from './InputNumber'

export default {
  components: {
    ClosetStylistAvatars,
    MyCaseFlyoutComponent,
    MyCaseSection,
    SequinButton,
    FilterSection,
    InputNumber
  },
  data () {
    return {
      localStylistRequest: {}
    }
  },
  computed: {
    ...mapGetters('client', [
      'maxNumExtraItems',
      'numStandardSlotsAvailable',
      'numSelectedItems'

    ]),
    ...mapGetters('closet', [
      'selectedAvailableItemTypes'
    ]),
    ...mapState('closet', [
      'globalFilters',
      'globalFilterKeys',
      'globalFiltersLoaded'
    ]),
    ...mapState('case', [
      'stylistRequest'
    ]),
    ...mapState('client', [
      'membershipDetails',
      'closetItemNumber'
    ]),
    categories () {
      return this.globalFilterKeys[2]
    },
    occasions () {
      return this.globalFilterKeys[0]
    },
    bonusItems () {
      return this.membershipDetails.bonusItems.length > 0 ? this.membershipDetails.bonusItems.length : 0
    },
    additionalBonusItems () {
      return this.bonus - this.bonusItems
    },
    additionalBonusItemsCost () {
      return this.additionalBonusItems * 20
    },
    rolloverItems () {
      return this.membershipDetails.rolloverItems.length > 0 ? this.membershipDetails.rolloverItems.length : 0
    },
    totalItems () {
      return this.stylistRequest?.totalItems
    },
    category: {
      get () {
        return this.stylistRequest?.categories || []
      },
      set (value) {
        this.$store.commit('case/UPDATE_STYLIST_REQUEST', { categories: value })
      }
    },
    occasion: {
      get () {
        return this.stylistRequest?.occasions || []
      },
      set (value) {
        this.$store.commit('case/UPDATE_STYLIST_REQUEST', { occasions: value })
      }
    },
    bonus: {
      get () {
        return this.stylistRequest?.bonusItems
      },
      set (value) {
        const diff = value - this.stylistRequest.bonusItems
        this.$store.commit('case/UPDATE_STYLIST_REQUEST', { bonusItems: value })
        const newTotal = this.totalItems + diff
        this.$store.commit('case/UPDATE_STYLIST_REQUEST', { totalItems: newTotal })
      }
    },
    rollover: {
      get () {
        return this.stylistRequest?.rolloverItems
      },
      set (value) {
        const diff = value - this.stylistRequest.rolloverItems
        this.$store.commit('case/UPDATE_STYLIST_REQUEST', { rolloverItems: value })
        const newTotal = this.totalItems + diff
        this.$store.commit('case/UPDATE_STYLIST_REQUEST', { totalItems: newTotal })
      }
    }
  },

  async created () {
    if (this.stylistRequest === null) {
      this.localStylistRequest = {
        bonusItems: 0,
        rolloverItems: 0,
        totalItems: this.closetItemNumber - this.numSelectedItems,
        categories: [],
        occasions: [],
        stylesToTry: '',
        stylesToAvoid: '',
        freeNotes: '',
        dressSizes: this.$store.state.styleProfile.dressSizes,
        pantSizes: this.$store.state.styleProfile.pantSizes,
        shirtSizes: this.$store.state.styleProfile.shirtSizes,
        jumpsuitSizes: this.$store.state.styleProfile.jumpsuitSizes,
        waistSizes: this.$store.state.styleProfile.waistSizes,
        bodyShape: '',
        braSizeCup: this.$store.state.styleProfile.braSizeCup,
        braSizeBand: this.$store.state.styleProfile.braSizeBand,
        stylistCurated: true
      }
      this.$store.commit('case/SET_STYLIST_REQUEST', this.localStylistRequest)
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    requestStylistCaseOne () {
      this.$logger.info(this.stylistRequest)
      this.setMyCaseFlyoutName('request-stylist-case-second')
    },
    backToCase () {
      this.$store.commit('case/CLEAR_STYLIST_REQUEST')
      this.setMyCaseFlyoutName('core')
    },
    getFilterOptions (filterType) {
      return this.globalFilters.options[filterType]
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.button-group-wrapper) {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.bonus, .rollover {
  padding-top: 0.5rem;
  font-size: 14px;
  font-weight: 700;
}
</style>
