<template>
  <my-case-flyout-component>
    <template #header>
      <h5 class="mb-0">
        Confirm {{ itemContainerText }}
      </h5>
    </template>
    <template #body>
      <transition
        name="fade">
        <app-message
          v-if="submitError"
          variant="danger">
          <div
            v-for="(error, index) in errors"
            :key="index">
            <br>
            <span v-html="error"/>
          </div>
        </app-message>
      </transition>
      <my-case-section
        no-padding
        back-button
        variant="default"
        @back-click="goBack()">
        <template #header>
          <h6>One Last Check!</h6>
          <p class="small-text mb-0">
            Review your styles + {{ pickupAddress ? 'pickup location' : 'shipping address' }} before confirming your {{ itemContainerText }}.
          </p>
        </template>
        <template #body>
          <my-case-shipping-address-selected
            class="pt-3 px-3"
            can-edit/>
          <div
            v-if="hasRentalPlan">
            <div class="d-flex justify-content-between p-3 pb-1 border-top semi-bold small-text">
              <div
                class="pt-1">
                {{ replacementItemSettingsTitle }}
              </div>
              <text-link
                variant="primary"
                @click="openReplacementItemsFlyout">
                Change
              </text-link>
            </div>
            <div
              class="ms-3 small-text">
              {{ replacementItemSettingsSubtitle }}
            </div>
          </div>
          <div
            v-if="fetchingCasePrice"
            class="mt-3 d-flex justify-content-center">
            <svg-spinner/>
          </div>
          <app-message
            v-if="promoErrors.length"
            variant="danger">
            <div
              v-for="(error, index) in promoErrors"
              :key="index">
              <span v-html="error"/>
            </div>
          </app-message>
          <transition
            name="fade">
            <div
              v-if="!fetchingCasePrice && casePrice && casePrice.price > 0"
              class="px-3 py-4 border-top">
              <my-case-extra-items-purchase/>
              <div v-if="!hasRentalPlan">
                <div
                  v-if="appliedPromoCode"
                  class="line-item promo mt-2">
                  <div>Promo code <span class="text-success">{{ appliedPromoCode.toUpperCase() }}</span> applied</div>
                </div>
                <div class="mt-lg d-flex align-items-center justify-content-end">
                  <base-input
                    v-model.trim="promoCode"
                    class="promo-entry"
                    input-id="promoCode"
                    label="Promo code"/>
                  <sequin-button
                    variant="secondary"
                    class="ms-3"
                    small
                    @click="applyPromo">
                    Apply
                  </sequin-button>
                </div>
              </div>
              <my-case-default-payment-updater class="mt-4"/>
            </div>
          </transition>
          <div class="mb-2">
            <my-case-item
              v-for="item in selectedExtraItemsValid"
              :key="`add-on-valid-${item.id}`"
              class="px-3 extra-item"
              hide-price
              :item="item">
              <template #itemInfoSlot>
                <div class="extra-item-price small-text">
                  +{{ formatPrice(extraItemBasePrice, true) }} extra item
                </div>
              </template>
            </my-case-item>
            <add-on-item-type
              v-for="item in addOnItemsToPurchase"
              :key="item.addOnTypeId"
              :type-id="item.addOnTypeId"
              :quantity="item.quantity"
              variant="primary"
              class=""/>
            <my-case-item
              v-for="item in purchaseSuggestionsInCase"
              :key="item.id"
              :item="item.item"
              variant="addon"
              can-edit
              hide-price
              class="px-3"/>
          </div>
          <div class="mb-4">
            <my-case-item
              v-for="item in selectedBonusItems"
              :key="`bonus-${item.id}`"
              class="px-3"
              hide-price
              :item="item">
              <template #itemInfoSlot>
                <div class="bonus-indicator">
                  <svg-circle-icon
                    :height="16"
                    :width="16"
                    :stroke-width="0"
                    :padding="4"
                    variant="secondary"
                    class="bonus-item">
                    <svg-star/>
                  </svg-circle-icon>Bonus Item
                </div>
              </template>
            </my-case-item>
            <my-case-item
              v-for="item in casePurchaseItems"
              :key="`standard-${item.id}`"
              class="px-3 my-n2"
              can-edit
              :item="item"/>
            <my-case-item
              v-for="item in selectedStandardItems"
              :key="`standard-${item.id}`"
              class="px-3 my-n2"
              can-edit
              hide-price
              :item="item"/>
            <my-case-item
              v-for="(item, index) in soldFutureItems"
              :key="index"
              :item="item"
              class="px-3 my-n2"
              image-label="Purchased"
              hide-price/>
          </div>
          <div
            v-if="availableAddOnItems.length > 0"
            class="mt-3">
            <div class="bg-origami py-3 mb-3">
              <div class="d-flex justify-content-center align-items-center">
                <h6>Optional Add Ons</h6>
              </div>
              <div class="d-flex justify-content-center small-text text-pewter">
                Purchase extra items to ship with this case
              </div>
            </div>
            <div
              class="bg-white px-3">
              <add-on-item
                v-for="addOnItem in availableAddOnItems"
                :key="addOnItem.id"
                :add-on-item="addOnItem"/>
            </div>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <my-case-inactive-status class="mb-3"/>
      <sequin-button
        block
        :disabled="confirmCaseDisabled"
        @click="submit()">
        Confirm {{ itemContainerText }}
        <span v-if="membershipStatus !== 'active'"> + Checkout</span>
        <span v-else-if="casePrice && casePrice.chargeAmount > 0 && hasRentalPlan">+ {{ formatPrice(casePrice.chargeAmount) }} extras</span>
        <span v-else-if="casePrice && casePrice.chargeAmount > 0 && !hasRentalPlan"> and Pay {{ formatPrice(casePrice.chargeAmount) }}</span>
      </sequin-button>
      <div
        v-if="!hasRentalPlan"
        class="fine-print text-pewter text-center mt-3">
        All sales final
      </div>
    </template>
  </my-case-flyout-component>
</template>

<script>
import AddOnItem from '../addon/AddOnItem'
import AddOnItemType from '../addon/AddOnItemType'
import BaseInput from '@/components/global/BaseInput.vue'
import MyCaseExtraItemsPurchase from './MyCaseExtraItemsPurchase'
import MyCaseDefaultPaymentUpdater from '../common/MyCaseDefaultPaymentUpdater'
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import MyCaseItem from '../common/MyCaseItem'
import MyCaseInactiveStatus from '../common/MyCaseInactiveStatus'
import MyCaseShippingAddressSelected from '../common/MyCaseShippingAddressSelected'
import TextLink from '../../global/sequin/TextLink.vue'
import MyCaseMixin from '../common/MyCaseMixin'
import AppMessage from '../../global/sequin/AppMessage'
import SequinButton from '../../global/sequin/SequinButton'
import { formatPrice } from '@/utils/stringParsing.js'
import ReplacementMixin from '../../account/replacements/ReplacementMixin'
import { mapActions, mapState, mapGetters } from 'vuex'
import useAnalytics from '@shared/composables/analytics.js'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'
import SvgStar from '@/components/global/svg/SvgStar.vue'

export default {
  components: {
    AddOnItem,
    AddOnItemType,
    BaseInput,
    MyCaseExtraItemsPurchase,
    MyCaseDefaultPaymentUpdater,
    MyCaseFlyoutComponent,
    MyCaseInactiveStatus,
    MyCaseShippingAddressSelected,
    MyCaseItem,
    MyCaseSection,
    AppMessage,
    SequinButton,
    TextLink,
    SvgCircleIcon,
    SvgSpinner,
    SvgStar
  },
  mixins: [MyCaseMixin, ReplacementMixin],
  setup () {
    const { track } = useAnalytics()
    return { track }
  },
  data () {
    return {
      errors: [],
      isSubmitting: false,
      submitError: false,
      fetchingPrice: true,
      promoCode: '',
      appliedPromoCode: null,
      promoErrors: []
    }
  },
  computed: {
    ...mapState('client', [
      'shippingEligibility',
      'pickupAddress',
      'shippingAddress',
      'defaultPayment',
      'mainPhone'
    ]),
    ...mapState('case', [
      'availableAddOnItems',
      'addOnItemsToPurchase',
      'fetchingCasePrice',
      'purchaseSuggestionsInCase',
      'purchaseSuggestions',
      'casePrice',
      'selectedShippingId'
    ]),
    ...mapGetters('client', [
      'employee',
      'itemContainerText',
      'numAvailablePurchaseSuggestions',
      'numAddOnItemsToPurchaseAtCheckout',
      'paymentIsExpired'
    ]),
    ...mapGetters('case', [
      'availablePurchaseSuggestions'
    ]),
    confirmCaseDisabled () {
      return this.fetchingPrice || this.isSubmitting || (!this.employee && !this.pickupAddress && !this.shippingAddress) ||
        (this.numExtraSlotsValid > 0 && (!this.defaultPayment)) ||
        (this.numExtraSlotsValid > 0 && this.bonusItemsTotal === null) ||
        (this.casePrice === null) ||
        (this.casePrice?.chargeAmount > 0 && (!this.defaultPayment || this.paymentIsExpired))
    }
  },
  mounted: async function () {
    await this.getCasePrice()
    this.fetchingPrice = false
    this.track('Viewed Checkout Final Confirmation')
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext',
      'getCasePrice'
    ]),
    ...mapActions('closet', [
      'submitCloset'
    ]),
    formatPrice,
    openReplacementItemsFlyout () {
      this.setMyCaseFlyoutName('replacement-items')
    },
    goBack () {
      if (this.selectedShippingId) {
        this.setMyCaseFlyoutName('choose-shipping')
      } else if (this.numAvailableCheckoutRecommendations > 0) {
        this.setMyCaseFlyoutName('checkout-recommendations')
      } else if (this.numAvailablePurchaseSuggestions > 0) {
        this.setMyCaseFlyoutName('purchase-suggestions')
      } else {
        this.setMyCaseFlyoutName('core')
      }
    },
    async applyPromo () {
      this.fetchingPrice = true
      this.promoErrors = []
      try {
        await this.getCasePrice(this.promoCode)
        this.appliedPromoCode = this.promoCode
      } catch (errors) {
        this.promoErrors = errors
        this.appliedPromoCode = ''
      }
      this.promoCode = ''
      this.fetchingPrice = false
    },
    async submit () {
      try {
        this.isSubmitting = true
        this.submitError = false
        this.setMyCaseFlyoutContext({ confirmedSelectedItems: this.selectedAvailableItemTypes })
        const result = await this.submitCloset()

        if (result) {
          this.setMyCaseFlyoutName('confirm-case-success')
        } else {
          this.setMyCaseFlyoutContext({ confirmedSelectedItems: [] })
          this.setMyCaseFlyoutName('core')
        }
        this.clearAddOnItems()
        this.track('Case Confirmed')
      } catch (errors) {
        this.errors = errors
        this.submitError = true
        this.isSubmitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.extra-item {
  background: rgba($info-light, .05);

  .extra-item-price {
    color: $info-dark;
    font-weight: $font-weight-semibold;
  }
}

.bonus-indicator {
  display: flex;
  align-items: center;
  color: $plum;
  font-size: .75rem;

  .bonus-item {
    margin-right: 4px;
    stroke-width: 0;
  }
}

.border {
  border-top: $default-border;
  padding: 16px;
  background: $white;
  max-width: 100%;
}

</style>
