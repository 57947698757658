<template>
  <base-modal
    :full-screen="isMobile"
    :use-body-padding="false"
    use-title-border>
    <template #title>
      <h5
        class="mb-0">
        Remove Items
      </h5>
    </template>
    <template #body>
      <div>
        <app-message
          class="row text-center"
          variant="danger">
          Select the items you'd like to remove from {{ collection.name }}
        </app-message>
        <collection-select-style-colors
          v-model="selectedItemsToRemove"
          container-class="mt-3 mb-5"
          :style-color-options="collectionStyleColors"/>
        <div
          v-if="collectionStyleColorsLoading"
          class="d-flex flex-column align-items-center">
          <svg-spinner/>
        </div>
        <div
          v-else-if="showLoadMore"
          class="d-flex flex-column align-items-center">
          <text-link
            @click="onLoadMore">
            Load more items
          </text-link>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="p-3">
        <sequin-button
          block
          :disabled="selectedItemsToRemove.length === 0"
          variant="danger"
          @click="onConfirm">
          Remove {{ selectedItemsToRemove.length ? selectedItemsToRemove.length : '' }} Item{{ selectedItemsToRemove.length === 1 ? '' : 's' }}
        </sequin-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions as mapVuexActions, mapGetters, mapState as mapVuexState } from 'vuex'
import { mapActions, mapState } from 'pinia'
import BaseModal from '@/components/global/BaseModal.vue'
import AppMessage from '../../global/sequin/AppMessage.vue'
import CollectionSelectStyleColors from '../CollectionSelectStyleColors.vue'
import SequinButton from '../../global/sequin/SequinButton.vue'
import TextLink from '../../global/sequin/TextLink.vue'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  components: {
    BaseModal,
    AppMessage,
    CollectionSelectStyleColors,
    SequinButton,
    TextLink,
    SvgSpinner
  },
  data () {
    return {
      selectedItemsToRemove: []
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('collections', [
      'collectionsById'
    ]),
    ...mapGetters('collections', [
      'styleColorsFromCollectionId',
      'styleColorCountFromCollectionId',
      'styleColorsLoadingFromCollectionId'
    ]),
    collectionId () {
      return this.modalContext?.collectionId
    },
    collection () {
      return this.collectionsById[this.collectionId]
    },
    collectionStyleColors () {
      return this.styleColorsFromCollectionId(this.collectionId)
    },
    collectionLength () {
      return this.styleColorCountFromCollectionId(this.collection.id)
    },
    collectionStyleColorsLoading () {
      return this.styleColorsLoadingFromCollectionId(this.collectionId, this.showAvailableNowOnly)
    },
    showLoadMore () {
      return this.collectionLength > this.collectionStyleColors?.length
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    ...mapVuexActions('collections', [
      'removeStyleColorFromCollection',
      'getCollectionStyleColorsNextPage'
    ]),
    onConfirm () {
      this.selectedItemsToRemove.forEach(styleColorId => {
        this.removeStyleColorFromCollection({ collectionId: this.collectionId, styleColorId })
      })
      this.closeModal()
    },
    onLoadMore () {
      this.getCollectionStyleColorsNextPage({
        collectionId: this.collectionId
      })
    }
  }
}
</script>
