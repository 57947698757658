<template>
  <base-modal
    :full-screen="isMobile"
    use-title-border>
    <template #title>
      <h5 class="mb-0">
        Edit Collection
      </h5>
    </template>
    <template #body>
      <div class="pt-4 px-1">
        <h5>Name</h5>
        <base-input
          v-model="name"
          :validations="v$.name"
          :disabled="!canEditInfo"
          :has-float-label="false"
          class="w-100 mt-2"
          label="Like &quot;Napa Trip&quot; or &quot;Work Outfits&quot;"/>
        <h5>Description</h5>
        <base-text-area
          v-model="description"
          :validations="v$.description"
          :disabled="!canEditInfo"
          class="mb-4"
          rows="3"
          placeholder="Add what your collection's about"/>
        <div v-if="canEditInfo">
          <h5>
            Occasions
          </h5>
          <tag-occasions
            v-model="selectedOccasions"/>
        </div>
        <h5>Items ({{ collectionLength }} added)</h5>
        <div class="d-flex mb-4">
          <sequin-button
            v-if="canEditInfo"
            block
            :small="isMobile"
            class="me-2"
            variant="secondary"
            @click="onAddItems">
            <svg-plus
              v-if="!isMobile"
              class="button-icon"
              height="16"/> Add Items
          </sequin-button>
          <sequin-button
            v-if="collectionLength > 0"
            block
            :small="isMobile"
            variant="danger"
            @click="onRemoveItems">
            <svg-x
              v-if="!isMobile"
              class="button-icon"
              height="16"/>
            Remove Items
          </sequin-button>
        </div>
        <text-link
          v-if="collection.type !== 'waitlist'"
          class="mt-md-1 mb-5"
          size="paragraph"
          variant="danger"
          @click="onDelete">
          Delete Collection
        </text-link>
      </div>
    </template>
    <template #footer>
      <div class="p-3">
        <sequin-button
          :disabled="v$.$invalid || isSubmitting"
          block
          @click="onSaveChanges">
          Save Changes
        </sequin-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapActions as mapVuexActions, mapGetters, mapState as mapVuexState } from 'vuex'
import { mapActions, mapState } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'
import { useOverlaysStore } from '@/stores/overlays.js'

import BaseModal from '@/components/global/BaseModal.vue'
import BaseInput from '@/components/global/BaseInput.vue'
import BaseTextArea from '@/components/global/BaseTextArea.vue'
import SequinButton from '../../global/sequin/SequinButton.vue'
import TagOccasions from './TagOccasions.vue'
import TextLink from '../../global/sequin/TextLink.vue'
import SvgPlus from '@/components/global/svg/SvgPlus.vue'
import SvgX from '@/components/global/svg/SvgX.vue'

export default {
  components: {
    BaseModal,
    BaseInput,
    BaseTextArea,
    SequinButton,
    TagOccasions,
    TextLink,
    SvgPlus,
    SvgX
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      name: '',
      description: '',
      selectedOccasions: [],
      isPrivate: false,
      isSubmitting: false,
      errors: []
    }
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(128)
    },
    description: {
      maxLength: maxLength(200)
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('collections', [
      'collectionsById'
    ]),
    ...mapGetters('collections', [
      'styleColorCountFromCollectionId'
    ]),
    collectionId () {
      return this.modalContext?.collectionId
    },
    collection () {
      return this.collectionsById[this.collectionId]
    },
    collectionLength () {
      return this.styleColorCountFromCollectionId(this.collection.id)
    },
    canEditInfo () {
      return this.collection.type !== 'waitlist'
    }
  },
  mounted () {
    this.name = this.collection.name
    this.description = this.collection.description
    if (this.collection.type === 'waitlist') this.description = `Add up to ${this.collection.maxItems} items to your waitlist. We’ll notify you as soon as they become available in one of your saved sizes.`
    this.selectedOccasions = this.collection.tags.map(tag => tag.tag)
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeModal',
      'openModal'
    ]),
    ...mapVuexActions('collections', [
      'updateCollection'
    ]),
    onDelete () {
      this.openModal({ name: 'delete-collection', context: { collectionId: this.collectionId } })
    },
    onAddItems () {
      this.openModal({ name: 'collection-suggestions', context: { collectionId: this.collectionId } })
    },
    onRemoveItems () {
      this.openModal({ name: 'collection-remove-items', context: { collectionId: this.collectionId } })
    },
    async onSaveChanges () {
      this.isSubmitting = true
      this.v$.$touch()
      if (this.v$.$invalid) {
        this.isSubmitting = false
        return
      }

      try {
        await this.updateCollection({
          collectionId: this.collectionId,
          data: {
            name: this.name,
            description: this.description,
            tags: this.selectedOccasions
          }
        })
        this.closeModal()
      } catch (err) {
        this.errors = err
        this.isSubmitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button-icon {
  margin-bottom: 2px;
}

:deep(textarea) {
  resize: none;
  height: 120px;
}
</style>
