<template>
  <div
    v-if="collectionTagOptions"
    class="d-flex flex-wrap w-100 mb-3">
    <plain-button
      v-for="tagOption in collectionTagOptions"
      :key="tagOption.tag"
      class="occasion-tag"
      :class="{ 'selected': internalValue.includes(tagOption.tag), 'mobile': isMobile }"
      @click="onSelect(tagOption.tag)">
      <h6
        class="occasion-text"
        :class="{ 'mobile': isMobile }">
        {{ tagOption.title }}
      </h6>
    </plain-button>
  </div>
  <svg-spinner v-else/>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PlainButton from '../../global/sequin/PlainButton.vue'
import SvgSpinner from '@/components/global/svg/SvgSpinner.vue'

export default {
  components: {
    PlainButton,
    SvgSpinner
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('collections', [
      'collectionTagOptions'
    ]),
    internalValue: {
      get () { return this.value },
      set (v) { this.$emit('input', v) }
    }
  },
  mounted () {
    if (!this.collectionTagOptions) this.getCollectionTags()
  },
  methods: {
    ...mapActions('collections', [
      'getCollectionTags'
    ]),
    onSelect (occasionId) {
      const currIndex = this.internalValue.indexOf(occasionId)
      if (currIndex > -1) {
        this.internalValue.splice(currIndex, 1)
      } else {
        this.internalValue.push(occasionId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.occasion-tag {
  background-color: $origami;
  border-radius: 8px;
  color: $pewter;
  padding: 4px 16px;
  width: 112px;
  height: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;

  &.mobile {
    width: 86px;
    height: 92px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .occasion-text {
    margin: 0;

    &.mobile {
      font-size: $small-text-font-size;
      line-height: $small-text-line-height;
    }
  }
  &.selected {
    background-color: $secondary;
    color: $white;
  }
}
</style>
