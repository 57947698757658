import { mapGetters } from 'vuex'

export default {
  inject: {
    // Parent components can provide this to indicate that
    // child components should render their mobile state.
    forceMobile: { default: false }
  },
  computed: {
    ...mapGetters('global', {
      isMobileWidth: 'isMobile',
      viewAspectRatio: 'viewAspectRatio',
      viewHeightUnit: 'viewHeightUnit'
    }),
    isMobile () {
      return this.forceMobile || this.isMobileWidth
    }
  }
}
