<template>
  <sequin-button
    v-if="membershipStatus === 'unpaid'"
    type="router-link"
    :to="{ name: 'account'}"
    :block="block"
    :small="small"
    variant="secondary"
    color="white"
    @click="closeFlyout()">
    Update Payment
  </sequin-button>
  <sequin-button
    v-else-if="membershipStatus === 'paused' || membershipStatus === 'items_out_pending_cancel'"
    type="router-link"
    :to="{ name: 'resume'}"
    :block="block"
    :small="small"
    variant="secondary"
    @click="closeFlyout()">
    Resume membership
  </sequin-button>
  <sequin-button
    v-else
    type="router-link"
    :to="{ name : 'select-plan' }"
    :block="block"
    :small="small"
    variant="secondary"
    @click="closeFlyout()">
    Choose Membership
  </sequin-button>
</template>

<script>
import SequinButton from '../../global/sequin/SequinButton'
import { mapState } from 'vuex'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    SequinButton
  },
  props: {
    small: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('client', [
      'membershipStatus'
    ])
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ])
  }
}
</script>
