<template>
  <div class="d-flex justify-content-between">
    <div
      class="smaller">
      <base-radios
        v-model="pickupChoice"
        class="mt-2"
        :options="pickupOptions"
        label="Do you have items to return?"
        sequin
        @update:modelValue="updatePickupChoice()"/>
      <div
        v-if="pickupChoice === 'yes'"
        class="mt-3">
        <h5>How would you like to return these items?</h5>
        <base-radio
          v-model="returnChoice"
          class="mt-1"
          initial-value="expedited"
          name="return"
          :text="`Return with ${expeditedShipper.name}`"
          sequin
          @update:modelValue="updateReturnChoice()"/>
        <div
          class="ms-3 ps-3 text-pewter small-text">
          Best if your items are ready to return today.
          <div class="text-plum fine-print mt-1">
            Please ensure your bag is sealed and and your
            return label or full name is clearly visible.
          </div>
        </div>
        <base-radio
          v-model="returnChoice"
          class="mt-3"
          initial-value="mail"
          name="return"
          text="Return via mail later"
          sequin
          @update:modelValue="updateReturnChoice()"/>
        <div
          class="ms-3 ps-3 text-pewter small-text">
          Best if you'd like to keep your items longer
          or prefer to return at your own convenience.
        </div>
      </div>
      <br>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { vuexAccessors } from '../../global/helpers/vuex'
import MyCaseMixin from '../common/MyCaseMixin'
import BaseRadios from '@/components/global/BaseRadios.vue'
import BaseRadio from '@/components/global/BaseRadio.vue'

export default {
  components: {
    BaseRadios,
    BaseRadio
  },
  mixins: [MyCaseMixin],
  data () {
    return {
      returnChoice: '',
      pickupChoice: '',
      pickupOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' }]
    }
  },
  computed: {
    ...mapState('client', [
      'shippingAddress'
    ]),
    ...vuexAccessors('case', [
      'expeditedReturnSelected'
    ]),
    ...mapGetters('case', [
      'expeditedShipper'
    ]),
    ...mapGetters('client', [
      'employee'
    ])
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutContext'
    ]),
    updatePickupChoice () {
      this.returnChoice = ''
      this.expeditedReturnSelected = this.pickupChoice === 'yes' ? null : false
      this.setMyCaseFlyoutContext({
        confirmedCaseExpeditedPickup: this.expeditedReturnSelected
      })
    },
    updateReturnChoice () {
      this.expeditedReturnSelected = this.returnChoice === 'expedited'
      this.setMyCaseFlyoutContext({
        confirmedCaseExpeditedPickup: this.expeditedReturnSelected
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
