<template>
  <div>
    <div
      v-if="itemsInTransit > 0">
      <p>
        You currently have a Case in Transit. <br><br>
        You can swap your items and build a new Case as soon as it arrives
      </p>
    </div>
    <div v-if="tooManyItemsOut">
      <p>
        You currently have {{ outstandingItems.length }} items out on your {{ caseSize }} item plan. You will be able to swap your items as soon as we receive your outstanding returns.
      </p>
    </div>
    <div
      v-else-if="isReviewOOC">
      <p>
        You must review at least {{ minItemsToReview }} item{{ minItemsToReview > 1 ? 's' : '' }} before you can use your next Case Swap.
      </p>
    </div>
    <div v-else>
      <p>You can swap your items and build a new Case any time, as long as you don’t already have a Case in Transit</p>
    </div>
    <swaps-available-case-link
      @my-case-link="$emit('my-case-link')">
      <template
        v-if="!itemsInTransit && !tooManyItemsOut && !isReviewOOC"
        #postLinkText>
        <span> to start your next swap.</span>
      </template>
    </swaps-available-case-link>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SwapsAvailableCaseLink from './SwapsAvailableCaseLink.vue'

export default {
  components: {
    SwapsAvailableCaseLink
  },
  computed: {
    ...mapState('client', [
      'membershipDetails',
      'tooManyItemsOut',
      'caseSize'
    ]),
    ...mapGetters('closet', [
      'outstandingItems'
    ]),
    ...mapGetters('client', [
      'itemsInTransit'
    ]),
    ...mapGetters('review', [
      'isReviewOOC',
      'numItemsToReview',
      'minItemsToReview'
    ])
  },
  methods: {

  }
}
</script>
