<template>
  <div>
    <div
      v-if="!hasSwaps">
      <!-- needs to not be hard coded -->
      <p>Your next Case Swap unlocks <strong>{{ toMonthDayString(nextShipmentDate, 'short') }}</strong></p>
    </div>
    <div
      v-if="itemsInTransit > 0">
      <p class="d-inline">
        You currently have a Case in Transit.
      </p>
      <p v-if="hasSwaps">
        You will be able to use a Case Swap as soon as your Case in Transit arrives.
      </p>
    </div>
    <div v-if="tooManyItemsOut">
      <p>
        You currently have {{ outstandingItems.length }} items out on your {{ caseSize }} item plan. We must receive your outstanding returns before you can use your next Case Swap.
      </p>
    </div>
    <div>
      <p v-if="isReviewOOC && !hasSwaps">
        You must review at least {{ minItemsToReview }} item{{ minItemsToReview > 1 ? 's' : '' }} before you can use your next Case Swap.
      </p>
      <p v-if="isReviewOOC && hasSwaps">
        You can swap your items and build a new case as soon as you review at least {{ minItemsToReview }} item{{ minItemsToReview > 1 ? 's' : '' }}.
      </p>
    </div>
    <div v-if="!isReviewOOC && !tooManyItemsOut && !itemsInTransit && hasSwaps">
      <p>You can use a Case Swap to swap your current items and build a new Case</p>
    </div>
    <swaps-available-case-link
      v-if="unlockEarly"
      @my-case-link="$emit('my-case-link','purchase-shipment')">
      <template #preLinkText>
        <span>Tired of waiting?</span>
      </template>
      <template #linkText>
        <span> Unlock Early</span>
      </template>
      <template #postLinkText>
        <span> to swap and select styles today.</span>
      </template>
    </swaps-available-case-link>
    <swaps-available-case-link
      v-else
      @my-case-link="$emit('my-case-link')">
      <template #postLinkText>
        <span
          v-if="!isReviewOOC && !tooManyItemsOut && !itemsInTransit && !unlockEarly">
          to get started with your next swap.
        </span>
      </template>
    </swaps-available-case-link>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SwapsAvailableCaseLink from './SwapsAvailableCaseLink.vue'
import MyCaseMixin from '../../case/common/MyCaseMixin.vue'
import { toMonthDayString } from '@/utils/stringParsing.js'

export default {
  components: {
    SwapsAvailableCaseLink
  },
  mixins: [MyCaseMixin],
  computed: {
    ...mapState('client', [
      'membershipDetails',
      'tooManyItemsOut',
      'caseSize'
    ]),
    ...mapGetters('closet', [
      'outstandingItems'
    ]),
    ...mapGetters('client', [
      'itemsInTransit'
    ]),
    ...mapGetters('review', [
      'isReviewOOC',
      'numItemsToReview',
      'minItemsToReview'
    ]),
    hasSwaps () {
      return this.membershipDetails.allowedShipments > 0
    },
    unlockEarly () {
      return (!this.isReviewOOC && !this.tooManyItemsOut && !this.itemsInTransit && !this.hasSwaps)
    }
  },
  methods: {
    toMonthDayString
  }
}
</script>
