<template>
  <my-case-flyout-component>
    <template #header>
      <h5 class="mb-0">
        Confirm Case
      </h5>
    </template>
    <template #body>
      <my-case-section
        no-padding
        back-button
        variant="default"
        @back-click="backClick()">
        <template #header>
          <h6 class="mb-0">
            Item Replacements
          </h6>
          <div
            class="small-text pt-3">
            We have high standards and sometimes we are unable to ship all items selected due to quality control
            checks prior to packing.
          </div>
        </template>
        <template #body>
          <div class="mt-2">
            <div class="small-text p-3 semi-bold pt-1">
              For any item that can’t be shipped, which would you like to receive
              <div
                v-for="(value, key) in replacementOptions"
                :key="key"
                class="mt-3">
                <base-radio
                  v-model="selectedReplacementChoice"
                  :initial-value="key"
                  name="replacement"
                  :text="replacementOptionTitle(value)"
                  sequin/>
                <span class="d-flex justify-content-center small-text ps-4 ms-2">
                  {{ replacementOptionSubtitle (value) }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <div class="my-case-footer d-flex justify-content-between">
        <div class="w-100 ms-2 mb-2">
          <sequin-button
            :disabled="!selectedReplacementChoice"
            block
            @click="continueToConfirm()">
            Continue
          </sequin-button>
        </div>
      </div>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import SequinButton from '../../global/sequin/SequinButton'
import { mapGetters, mapActions } from 'vuex'
import MyCaseMixin from '../common/MyCaseMixin'
import ReplacementMixin from '../../account/replacements/ReplacementMixin'
import BaseRadio from '@/components/global/BaseRadio.vue'

export default {
  components: {
    MyCaseFlyoutComponent,
    MyCaseSection,
    SequinButton,
    BaseRadio
  },
  mixins: [ReplacementMixin, MyCaseMixin],
  computed: {
    ...mapGetters('case', [
      'nextFlyout'
    ])
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    ...mapActions('account', [
      'updateReplacementItemChoice'
    ]),
    continueToConfirm () {
      this.setMyCaseFlyoutName(this.nextFlyout)
      this.updateReplacementChoice()
    },
    backClick () {
      this.setMyCaseFlyoutName('core')
    },
    async updateReplacementChoice () {
      try {
        await this.updateReplacementItemChoice(this.selectedReplacementChoice)
      } catch (err) {
        this.setAlert({
          msg: 'There was a problem setting your replacement item choice.',
          style: 'warning'
        })
      }
    }
  }
}
</script>
