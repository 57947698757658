<template>
  <div>
    <div
      class="section-header"
      :class="{'white': variant === 'white' }">
      <plain-button
        v-if="backButton"
        class="back-button"
        @click="$emit('back-click')">
        <svg-chevron-left/>
      </plain-button>
      <slot name="header"/>
    </div>
    <div
      class="section"
      :class="{ 'no-padding': noPadding }">
      <slot name="body"/>
    </div>
  </div>
</template>

<script>
import PlainButton from '../../global/sequin/PlainButton'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'

export default {
  components: {
    PlainButton,
    SvgChevronLeft
  },
  props: {
    backButton: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'white'
    }
  }
}
</script>

<style lang="scss" scoped>
.section-header {
  background: $origami;
  padding: 20px;
  text-align: center;
  position: relative;

  .back-button {
    position: absolute;
    left: 8px;
    top: 16px;
    padding: 0;
    background: $origami;
  }

  &.white {
    background: $white;
    border-bottom: $default-border;

    .back-button {
      background: $white;
    }
  }
}

.section {
  padding: 16px;

  &.no-padding {
    padding: 0;
  }
}
</style>
