<template>
  <packing-header-base>
    <swap-status-message/>
  </packing-header-base>
</template>

<script>
import PackingHeaderBase from '../../common/header/PackingHeaderBase'
import SwapStatusMessage from './SwapStatusMessage'

export default {
  components: {
    PackingHeaderBase,
    SwapStatusMessage
  }
}
</script>
