<template>
  <community-modal-share-a-look edit-look/>
</template>

<script>
import CommunityModalShareALook from './CommunityModalShareALook'

export default {
  components: {
    CommunityModalShareALook
  }
}
</script>
