<template>
  <my-case-section-core>
    <template #body>
      <div class="mt-5 pt-5 d-flex flex-column align-items-center jusfity-content-center">
        <svg-case
          class="mt-2"
          width="36"
          height="36"/>
        <p class="text-center w-100 my-3">
          Want to select new items before <b>{{ toMonthDayString(nextShipmentDate, 'short') }}?</b>
        </p>
        <sequin-button
          class="mb-0"
          @click="setMyCaseFlyoutName('purchase-shipment')">
          Unlock Case Swap Early
        </sequin-button>
      </div>
    </template>
  </my-case-section-core>
</template>

<script>
import MyCaseSectionCore from '../common/MyCaseSectionCore.vue'
import MyCaseMixin from '../common/MyCaseMixin.vue'
import SequinButton from '../../global/sequin/SequinButton.vue'
import { toMonthDayString } from '@/utils/stringParsing.js'
import { mapActions } from 'vuex'
import SvgCase from '@/components/global/svg/SvgCase.vue'

export default {
  components: {
    MyCaseSectionCore,
    SequinButton,
    SvgCase
  },
  mixins: [MyCaseMixin],
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ]),
    toMonthDayString
  }
}
</script>
