<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <use
      href="#p1"
      transform="scale(0.2 -0.2)"
      transform-origin="30.5 0"/>
    <title>Share via Pinterest</title>
    <defs>
      <g
        id="p1"
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="path64"
          style="fill:#e60023;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="m 0,0 c -67.374,0 -121.992,-54.618 -121.992,-121.992 0,-51.682 32.155,-95.863 77.535,-113.638 -1.066,9.652 -2.03,24.496 0.423,35.033 2.217,9.521 14.305,60.638 14.305,60.638 0,0 -3.649,7.308 -3.649,18.112 0,16.963 9.832,29.627 22.074,29.627 10.408,0 15.435,-7.814 15.435,-17.184 0,-10.467 -6.664,-26.116 -10.103,-40.618 -2.874,-12.142 6.089,-22.044 18.065,-22.044 21.682,0 38.349,22.862 38.349,55.863 0,29.209 -20.988,49.631 -50.957,49.631 -34.711,0 -55.085,-26.035 -55.085,-52.941 0,-10.485 4.039,-21.728 9.079,-27.84 0.997,-1.208 1.143,-2.266 0.846,-3.499 -0.926,-3.853 -2.984,-12.139 -3.388,-13.835 -0.532,-2.233 -1.768,-2.707 -4.08,-1.631 -15.236,7.092 -24.762,29.368 -24.762,47.26 0,38.482 27.96,73.823 80.605,73.823 42.319,0 75.206,-30.156 75.206,-70.457 0,-42.043 -26.509,-75.879 -63.303,-75.879 -12.362,0 -23.983,6.422 -27.961,14.008 0,0 -6.117,-23.292 -7.6,-29 -2.754,-10.596 -10.189,-23.878 -15.162,-31.981 11.414,-3.534 23.544,-5.44 36.12,-5.44 67.375,0 121.993,54.618 121.993,121.992 C 121.993,-54.618 67.375,0 0,0"/>
      </g>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SvgSharePinterest'
}
</script>

<style scoped>

</style>
