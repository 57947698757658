<template>
  <div
    class="swap-available-icon">
    <span>
      {{ membershipDetails.allowedShipments }}
    </span>
    <svg-refresh-c-w
      class="refresh"
      :class="{'ps-2':
        hasShipments, 'p-1' : !hasShipments
      }"/>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import SvgRefreshCW from '../svg/SvgRefreshCW.vue'

export default {
  components: {
    SvgRefreshCW
  },
  computed: {
    ...mapState('client', [
      'membershipDetails'
    ]),
    hasShipments () {
      return this.membershipDetails.allowedShipments !== null
    }
  }
}
</script>

<style lang="scss" scoped>
.swap-available-icon {
  padding: 0 .35rem;
  border: 1.25px solid #000000;
  border-radius: 4px;
  display: flex;
  width: fit-content;
}

</style>
