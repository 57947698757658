<template>
  <base-modal
    id="edit-delete"
    hide-close>
    <template #body>
      <component
        :is="componentType"
        class="pb-md-0"/>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/global/BaseModal.vue'
import EditDeleteLookMenu from './EditDeleteLookMenu'
import EditDeleteCommentMenu from './EditDeleteCommentMenu'
import DeletePostMenu from './DeletePostMenu'
import { mapState } from 'vuex'

export default {
  components: {
    BaseModal,
    DeletePostMenu,
    EditDeleteCommentMenu,
    EditDeleteLookMenu
  },
  computed: {
    ...mapState('community', [
      'itemInFocus'
    ]),
    componentType () {
      switch (this.itemInFocus.type) {
        case 'comment':
          return 'edit-delete-comment-menu'
        case 'look':
          return 'edit-delete-look-menu'
        case 'post':
          return 'delete-post-menu'
        default:
          return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
