<template>
  <my-case-header :title="deliveredTitle">
    <p
      v-if="showDeliveredSubtitle"
      class="small-text mb-2">
      Your next Case Swap unlocks <span class="semi-bold">{{ toMonthDayString(nextShipmentDate, 'short') }}</span>.
    </p>
    <review-swap-header-buttons/>
  </my-case-header>
</template>

<script>
import MyCaseMixin from '../../common/MyCaseMixin'
import MyCaseHeader from '../../common/header/MyCaseHeader'
import ReviewSwapHeaderButtons from './ReviewSwapHeaderButtons.vue'
import { toMonthDayString } from '@/utils/stringParsing.js'

export default {
  components: {
    MyCaseHeader,
    ReviewSwapHeaderButtons
  },
  mixins: [MyCaseMixin],
  computed: {
    numShipments () {
      return this.membershipDetails.allowedShipments
    },
    deliveredTitle () {
      return this.numShipments > 0
        ? `You have ${this.numShipments} Case Swap${this.numShipments === 1 ? '' : 's'} available.`
        : 'Case Delivered'
    },
    showDeliveredSubtitle () {
      return this.numShipments !== null && !!this.nextShipmentDate
    }
  },
  methods: {
    toMonthDayString
  }
}
</script>
