<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        no-padding
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <h5 class="mb-0">
            Purchase Item
          </h5>
        </template>
        <template #body>
          <div v-if="itemHeldForPurchase">
            <app-message
              class="d-flex align-items-center"
              variant="success">
              <my-case-item-image
                variant="success"
                :style-color="styleColor"/>
              <p class="small-text text-success ms-4 semi-bold m-0">
                Held for purchase
              </p>
            </app-message>
            <div class="p-3">
              <p class="small-text semi-bold mb-0">
                Got it!
              </p>
              <p class="small-text">
                A team member will reach out shortly to review and confirm your purchase details.
              </p>
            </div>
          </div>
          <div
            v-else
            class="mt-2 px-3">
            <my-case-item
              variant="default"
              :item="itemToPurchase.item"
              :can-edit="false"
              hide-price/>
            <p class="small-text my-3 mb-4">
              Because this item is marked as returning, we need to confirm some details.
            </p>
            <h5 class="small-text semi-bold">
              Where is this item currently?
            </h5>
            <sequin-button
              small
              variant="secondary"
              class="mt-2"
              @click="setMyCaseFlyoutName('purchase-item')">
              This item is with me
            </sequin-button>
            <sequin-button
              small
              class="mt-3"
              variant="secondary"
              @click="holdItemForPurchase()">
              I returned this item in the mail
            </sequin-button>
          </div>
        </template>
      </my-case-section>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import SequinButton from '../../global/sequin/SequinButton'

import { mapState, mapActions } from 'vuex'
import AppMessage from '../../global/sequin/AppMessage.vue'
import MyCaseItemImage from '../common/MyCaseItemImage.vue'
import MyCaseItem from '../common/MyCaseItem'

export default {
  components: {
    MyCaseSection,
    MyCaseFlyoutComponent,
    SequinButton,
    MyCaseItem,
    MyCaseItemImage,
    AppMessage
  },
  data () {
    return {
      itemHeldForPurchase: false
    }
  },
  computed: {
    ...mapState('case', [
      'itemToPurchase'
    ]),
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    styleColor () {
      return this.styleColorsMap[this.itemToPurchase.item.styleColor]
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext'
    ]),
    ...mapActions('case', [
      'holdForPurchase'
    ]),
    holdItemForPurchase () {
      this.holdForPurchase(this.itemToPurchase)
      this.itemHeldForPurchase = true
    }
  }
}
</script>
