<template>
  <p><b>Signup Reasons:</b> {{ signupReasons }}</p>
  <p v-if="selectedOccasions">
    <b>Selected Occasions:</b> {{ selectedOccasions }}
  </p>
  <p v-if="selectedCategories">
    <b>Selected Categories:</b> {{ selectedCategories }}
  </p>
  <p v-if="favoriteBrands">
    <b>Favorite Brands:</b> {{ favoriteBrands }}
  </p>
  <p><b>Often:</b> {{ often.join(', ') }}</p>
  <p><b>Sometimes:</b> {{ sometimes.join(', ') }}</p>
  <p><b>Never:</b> {{ never.join(', ') }}</p>
</template>
<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const signupReasons = computed(() => store.state.styleProfile.signupReasons.join(', '))
const selectedOccasions = computed(() => store.state.styleProfile.selectedOccasions.join(', '))
const selectedCategories = computed(() => store.state.styleProfile.selectedCategories.join(', '))
const favoriteBrands = computed(() => store.state.styleProfile.selectedBrands.join(', '))
const clusterRatings = computed(() => store.state.styleProfile.clusterRatings)
/*
example of clusterRatings:
  clusterRatings:{
    "bohemian":"0",
    "classic":"0",
    "edgy":"0",
    "feminine":"0",
    "avant-garde":"0",
    "minimalist":"1",
    "glam":"0",
    "preppy":"0",
    "sporty":"-1"
  },
*/
// for each cluster, the value is a string representation of the rating, where 1 is often, 0 is sometimes, and -1 is never
const sometimes = computed(() => Object.keys(clusterRatings.value).filter(key => clusterRatings.value[key] === '0'))
const often = computed(() => Object.keys(clusterRatings.value).filter(key => clusterRatings.value[key] === '1'))
const never = computed(() => Object.keys(clusterRatings.value).filter(key => clusterRatings.value[key] === '-1'))
</script>
