<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('request-stylist-case-one')">
        <template #header>
          <h5 class="mb-0">
            Request Stylist Case (2/3)
          </h5>
        </template>
        <template #body>
          <h6
            class="mt-lg-5 mb-4"
            :class="{'my-3': isMobile}">
            Do you identify with a specific body shape? <span class="text-plum">(Required)</span>
          </h6>
          <body-shape
            :selected-shape="setShape"
            @shape="getShape"/>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <sequin-button
        block
        :disabled="!setShape"
        @click="requestStylistCaseSecond">
        Continue
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import SequinButton from '../../global/sequin/SequinButton'
import { mapActions, mapState } from 'vuex'
import BodyShape from './BodyShape'

export default {
  components: {
    MyCaseFlyoutComponent,
    MyCaseSection,
    SequinButton,
    BodyShape
  },
  data () {
    return {
      expandedSection: null,
      bodyShape: ''
    }
  },
  computed: {
    ...mapState('case', [
      'stylistRequest'
    ]),
    ...mapState('styleprofile', [
      'dueDate'
    ]),
    setShape () {
      return this.stylistRequest?.bodyShape || ''
    }
  },
  async created () {
    this.showDueDatePicker = this.dueDate !== null
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ]),
    requestStylistCaseSecond () {
      if (this.setShape) {
        this.setMyCaseFlyoutName('request-stylist-case-third')
      }
    },
    getShape (value) {
      this.$store.commit('case/UPDATE_STYLIST_REQUEST', { bodyShape: value })
    }
  }
}
</script>
