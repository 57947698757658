<!-- This is used in
  - StyleColorSetFilters (accordion variant)
  - MyCaseFlyoutRequestStylistCaseOne (div variant)-->
<!-- Button variant is mainly used in StyleColorSetFilters (checkboxes) -->
<template>
  <component
    :is="variant === 'accordion' ? 'accordion-with-header' : 'div'"
    :title="sectionTitle"
    :expanded="expanded"
    :expanded-max-height="9999"
    small
    @accordion-click="expanded = !expanded">
    <div>
      <div
        :id="'section-' + id"
        class="d-flex flex-row g-0 justify-content-between"
        :class="{ 'error-scroll': validations.$error }">
        <div class="d-flex align-items-center">
          <h5
            v-if="variant === 'default'"
            class="header">
            {{ displayedTitle }}
          </h5>
          <span
            v-if="weatherTooltipVisible">
            <sequin-tooltip
              icon-class="ms-2 mb-2"
              max-width="490">
              <p
                class="weather-tooltip mb-0"><b>Warm weather:</b> Think shorts and lightweight dresses for a sunny forecast.<br>
                <b>Cold weather:</b> Styles to combat the chill, like sweaters and coats.<br>
                <b>Year round:</b> Wardrobe staples, like jeans or blazers, for any time of year.</p>
            </sequin-tooltip>
          </span>
        </div>
        <div
          class="text-gray"
          :class="{ 'text-danger font-weight-bold': validations.$error || sectionError }">
          <span v-if="maxSelections">
            Select up to {{ maxSelections }}
          </span>
          <span v-else-if="minSelections">
            Select at least {{ minSelections }}
          </span>
        </div>
      </div>
    </div>
    <div>
      <search-bar
        v-if="searchable"
        v-model="filterTerm"
        placeholder="Search brands"
        class="search mb-3"/>
    </div>
    <div v-if="buttonVariant === 'checkboxes'">
      <base-checkboxes
        v-model="_modelValue"
        :options="displayedOptions"
        col-class="col col-12 my-1"/>
    </div>
    <!-- this is the default buttonVariant -->
    <div
      v-else
      class="button-group-wrapper">
      <div
        v-for="(option, index) in displayedOptions"
        :key="index"
        class="button-wrapper"
        :class="{'button-wrapper-multi-column' : (displayedOptions.length % 2)}">
        <ColorFilterButton
          v-if="option.color"
          :selected="modelValue.includes(option.value)"
          :option="option"
          @click="buttonClick(option.value)"/>
        <filter-select-button
          v-else
          :link-variant="isFilterButtonLinkVariant && !option.color"
          :selected="modelValue.includes(option.value)"
          :class="[{ 'selected': modelValue.includes(option.value) }, { 'disabled': option.disabled }]"
          :disabled="(maxSelected && !modelValue.includes(option.value)) || option.disabled"
          :text="option.text"
          @click="buttonClick(option.value)">
          <span :class="{'small-text align-middle' : !isFilterButtonLinkVariant}">
            {{ option.text }}
          </span>
        </filter-select-button>
        <div
          v-if="maxSelected && !modelValue.includes(option.value)"
          class="button-overlay"
          @click.stop="disabledButtonClick"/>
      </div>
      <div
        v-if="toggleLimit !== null && filteredOptions.length > toggleLimit"
        class="pt-2">
        <text-link
          v-if="!showAll"
          @click="showAll = true">
          View More
        </text-link>
        <text-link
          v-else
          @click="showAll = false">
          View Less
        </text-link>
      </div>
    </div>
  </component>
</template>

<script>
import FilterSelectButton from '@/components/styleColorSet/filtersFlyout/FilterSelectButton'
import ColorCircle from '@/components/styleColorSet/filtersFlyout/ColorCircle'
import TextLink from '@/components//global/sequin/TextLink'
import SearchBar from '@/components//global/sequin/SearchBar'
import AccordionWithHeader from '@/components//global/sequin/AccordionWithHeader'
import BaseCheckboxes from '@/components//global/BaseCheckboxes'
import SequinTooltip from '@/components//global/sequin/SequinTooltip.vue'
import ColorFilterButton from '@/components/styleColorSet/filtersFlyout/ColorFilterButton.vue'
export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    BaseCheckboxes,
    FilterSelectButton,
    ColorCircle,
    SearchBar,
    TextLink,
    AccordionWithHeader,
    SequinTooltip,
    ColorFilterButton
  },
  props: {
    maxSelections: {
      type: Number,
      default: null
    },
    minSelections: {
      type: Number,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    searchable: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    toggleLimit: {
      type: Number,
      default: null
    },
    validations: {
      type: Object,
      default: () => {
        return { $touch () {} }
      }
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    variant: {
      type: String,
      default: 'default',
      validator: v => v.match(/(default|accordion|div)/)
    },
    buttonVariant: {
      type: String,
      default: 'default',
      validator: v => v.match(/(default|checkboxes|link)/)
    },
    displayWeatherTooltip: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      filterTerm: '',
      id: null,
      open: false,
      sectionError: false,
      showAll: true,
      expanded: false
    }
  },
  computed: {
    filteredOptions () {
      if (!this.searchable) return this.options

      const str = this.filterTerm.toLowerCase()
      if (!str) return this.options

      return this.options.filter(option => {
        let pos = 0
        let target = option.text.toLowerCase()
        return [...str].every(s => {
          target = target.substr(pos)
          pos = target.indexOf(s) + 1
          return pos > 0
        })
      })
    },
    displayedOptions () {
      const options = [...this.filteredOptions]

      return this.showAll ? options : options.slice(0, this.toggleLimit)
    },
    displayedTitle () {
      const disp = this.title === 'seasons' ? 'weather' : this.title
      return disp.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()
    },
    maxSelected () {
      if (this.maxSelections) {
        return this.modelValue.length >= this.maxSelections
      }
      return false
    },
    searchIconUrl () {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}search-icon.png`
    },
    sectionTitle () {
      const displayedSelected = this.displayedOptions.filter(x => this.modelValue.includes(x.value))
      const filterCount = displayedSelected.length > 0 ? ` (${displayedSelected.length})` : ''
      return `${this.displayedTitle}${filterCount}`
    },
    _modelValue: {
      get () { return this.modelValue },
      set (v) { this.$emit('update:modelValue', v) }
    },
    weatherTooltipVisible () {
      return (this.displayedTitle === 'weather') && this.displayWeatherTooltip
    },
    isFilterButtonLinkVariant () {
      return this.buttonVariant === 'link'
    }
  },
  mounted () {
    this.id = this._uid

    if (this.toggleLimit !== null) this.showAll = false
  },
  methods: {
    disabledButtonClick () {
      this.$nextTick(() => {
        const e = document.querySelector('#section-' + this.id)
        e.scrollIntoView({ block: 'start', behavior: 'smooth' })
      })

      this.sectionError = true
      setTimeout(() => { this.sectionError = false }, 2000)
    },
    buttonClick (v) {
      const temp = [...this.modelValue]
      if (temp.includes(v)) {
        const index = temp.indexOf(v)
        temp.splice(index, 1)
      } else {
        temp.push(v)
      }
      this.$emit('update:modelValue', temp)
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-wrapper {
    position: relative;
    display: inline;
    min-height: 25px;
  }

  .button-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .header {
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }

  .search {
    border: $default-border;
  }
  .weather-tooltip {
    font-size: 13px;
  }
</style>
