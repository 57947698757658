<template>
  <my-case-select-items-base>
    <template
      #header>
      <div
        v-for="offer in shippingOffers"
        :key="offer.name"
        class="header-content small-text">
        <p class="mb-0">
          FREE <b>{{ offer.name }}</b> shipping with a purchase of {{ formatPrice(offer.minimum) }} or more!
        </p>
      </div>
    </template>
    <template #above-items>
      <FedExCaseCTA
        v-if="!fedexPreferred || showSuccessMessageCase"
        source="case"/>
      <div
        v-if="!isHoliday && numStandardSlotsAvailable > 0"
        class="w-100 d-flex flex-column align-items-center pt-4">
        <p class="small-text mb-0">
          Want a stylist to select for you?
        </p>
        <text-link
          class="mb-3"
          variant="primary"
          @click="continueToRequestStylistCase">
          Request Stylist Case
        </text-link>
      </div>
      <div
        v-if="showFirstCaseCountdown"
        class="small-text text-white bg-teal w-100 p-3 d-flex align-items-center">
        <svg-discover
          :width="isMobile ? 35 : 25"
          :height="isMobile ? 35 : 25"/>
        <countdown-timer
          use-days
          class="mb-0 ps-2 pb-0"
          :style="{fontSize: '13px'}"
          :cutoff="new Date(firstCaseDueDate)">
          <template #after>
            <span class="mb-0">
              <b> left to fill your first case</b>
              before we automatically send you a stylist-filled case.
            </span>
          </template>
        </countdown-timer>
      </div>
      <div
        v-if="hasRentalPlan && numExtraSlotsValid >= 0 && numExtraSlotsValid < maxNumExtraItems"
        class="px-4 px-md-5">
        <my-case-item
          variant="add"
          hide-price
          can-edit>
          Add an extra item for just {{ formatPrice(extraItemBasePrice, true) }}
        </my-case-item>
      </div>
    </template>

    <template #below-standard-items>
      <div
        v-if="selectedStandardItems.length === 0 && casePurchaseItems.length === 0"
        class="small-text">
        Items you add to your {{ itemContainerText }} will display here
      </div>
      <div
        v-for="index in numStandardSlotsAvailable"
        :key="`items-empty-${index}`">
        <my-case-item
          variant="add"
          hide-price
          can-edit>
          <p v-if="index <= numRolloverSlotsAvailable">
            <sequin-tag
              class="align-self-end justify-self-start mb-1"
              variant="darkGray">
              ROLLOVER ITEM
            </sequin-tag>
            <br>
            Expires in {{ getRolloverItemExpireDays(index) }}
          </p>
        </my-case-item>
      </div>

      <my-case-item
        v-for="item in selectedBonusItems"
        :key="item.id"
        can-edit
        hide-price
        :use-large-edit-button="!isMobile"
        :item="item">
        <template #itemInfoSlot>
          <sequin-tag
            light
            variant="secondary">
            <div class="d-flex align-items-center">
              <svg-star
                height="10"
                width="10"
                class="mx-0 me-1"/>
              Bonus Item
            </div>
          </sequin-tag>
        </template>
      </my-case-item>
      <my-case-bonus-item-slots/>
    </template>

    <template #end-of-section>
      <div
        v-if="primaryCaseState !== 'inactive-member'"
        class="d-flex"
        :class="{'flex-column': isMobile }">
        <sequin-button
          :disabled="tooFewSelected || numExtraSlotsOverLimit > 0 || confirmingSelectedItems"
          block
          :class="{ 'ms-1': !isMobile }"
          @click="continueToConfirm">
          Continue to Confirm
        </sequin-button>
      </div>
    </template>
  </my-case-select-items-base>
</template>

<script>
import MyCaseSelectItemsBase from '../common/MyCaseSelectItemsBase'
import MyCaseBonusItemSlots from './MyCaseBonusItemSlots'
import MyCaseMixin from '../common/MyCaseMixin'
import MyCaseItem from '../common/MyCaseItem'
import SequinTag from '../../global/sequin/SequinTag'
import SequinButton from '../../global/sequin/SequinButton'
import { formatPrice, toMonthDayYearString } from '@/utils/stringParsing.js'
import TextLink from '../../global/sequin/TextLink'
import CountdownTimer from '../../global/dates/CountdownTimer.vue'
import { mapActions as mapVuexActions, mapState as mapVuexState, mapGetters } from 'vuex'
import { mapState, mapActions } from 'pinia'
import useAnalytics from '@shared/composables/analytics.js'
import SvgDiscover from '@/components/global/svg/SvgDiscover.vue'
import SvgStar from '@/components/global/svg/SvgStar.vue'
import useHolidays from '@shared/composables/holidays.js'
import FedExCaseCTA from '../fedEx/FedExCaseCTA.vue'
import { useFedExOptInStore } from '@/stores/fedExOptIn'

export default {
  components: {
    TextLink,
    MyCaseItem,
    MyCaseBonusItemSlots,
    SequinTag,
    SequinButton,
    MyCaseSelectItemsBase,
    CountdownTimer,
    SvgDiscover,
    SvgStar,
    FedExCaseCTA
  },
  mixins: [MyCaseMixin],
  setup () {
    const { isHoliday } = useHolidays()
    const { track } = useAnalytics()
    const { fedexPreferred } = useFedExOptInStore()
    return {
      isHoliday,
      track,
      fedexPreferred
    }
  },
  data () {
    return {
      confirmingSelectedItems: false
    }
  },
  computed: {
    ...mapGetters('case', [
      'nextFlyout'
    ]),
    ...mapGetters('client', [
      'itemContainerText',
      'showFirstCaseCountdown'
    ]),
    ...mapVuexState('case', [
      'shippingOffers'
    ]),
    ...mapVuexState('client', [
      'firstCaseDueDate',
      'returnCarrierPreference'
    ]),
    ...mapState(useFedExOptInStore, [
      'showSuccessMessageCase'
    ])
  },
  mounted () {
    this.track('Viewed My Case')
  },
  methods: {
    ...mapVuexActions('case', [
      'setMyCaseFlyoutName'
    ]),
    ...mapActions(useFedExOptInStore, [
      'optIntoFedEx'
    ]),
    formatPrice,
    toMonthDayYearString,
    async continueToConfirm () {
      this.confirmingSelectedItems = true
      await this.checkForSelectedItemsMismatch()
      if (this.selectedItemsMismatch || this.unavailableItemTypes.length > 0) {
        this.confirmingSelectedItems = false
      } else {
        this.setMyCaseFlyoutName(this.nextFlyout)
      }
    },
    continueToRequestStylistCase () {
      if (!this.isHoliday) {
        this.setMyCaseFlyoutName('request-stylist-case-one')
      }
    },
    getRolloverItemExpireDays (index) {
      const rolloverItem = this.rolloverItems[index - 1]
      const today = new Date()
      const rolloverExpireDate = new Date(rolloverItem.expires)
      const diff = rolloverExpireDate.getTime() - today.getTime()
      const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      return `${days} day${days > 1 ? 's' : ''}`
    }
  }
}
</script>
