<template>
  <div
    class="d-flex align-items-center justify-content-center me-2"
    :class="{'selected-circle': selected, 'unselected-circle': !selected, 'pointer': clickable}"
    @click="$emit('click')">
    <div class="position-relative">
      <div
        v-if="unavailable"
        :class="{'outer-diagonal': color.hex !== 'FFFFFF' }"/>
      <div
        v-if="unavailable"
        class="inner-diagonal"/>
      <div
        class="circle"
        :class="{'white': color.hex === 'FFFFFF'}"
        :style="{ background: `#${color.hex}`, backgroundImage: backgroundImage}"/>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    color: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: true
    },
    unavailable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    backgroundImage () {
      if (this.color.metallic) {
        return `linear-gradient(-45deg, #${this.color.hex} 0%, rgba(255,255,255,.85) 50%, #${this.color.hex} 100%)`
      }
      return ''
    }
  }
}

</script>

<style lang="scss" scoped>

.selected-circle {
    background: white;
    border:1px solid black;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.unselected-circle {
    background: white;
    border:1px solid #dddddd;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.circle {
    background: rgb(255,255,255);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    z-index: 10000;

    &.white {
      border: 2px solid #dddddd;
    }
}

.outer-diagonal {
  width: 35px;
  height: 35px;
  border-bottom: 9px solid white;
  -webkit-transform:
      translateY(20px)
      translateX(5px)
      rotate(-45deg);
  position: absolute;
  top: -31px;
  left: -14px;
}

.inner-diagonal {
  width: 31px;
  height: 31px;
  background-color: transparent;
  border-bottom: 2px solid black;
  -webkit-transform:
      translateY(20px)
      translateX(5px)
      rotate(-45deg);
  position: absolute;
  top: -29px;
  left: -14px;
}

</style>
