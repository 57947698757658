<template>
  <div class="toggle-selector-wrapper">
    <label
      v-for="option in options"
      :key="option.value"
      :class="{ 'active': isActive(option.value) }">
      <div>{{ option.text }}</div>
      <input
        v-model="_modelValue"
        :style="{ position: 'absolute', opacity: 0 }"
        :value="option.value"
        name="options"
        type="radio">
    </label>
  </div>
</template>

<script setup>
import { computed, configureCompat } from 'vue'

configureCompat({
  COMPONENT_V_MODEL: false
})

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true,
    validator: value => {
      return value.length === 2 && value.every(option => 'value' in option && 'text' in option)
    }
  }
})

const emit = defineEmits(['update:modelValue', 'set-toggle-selection'])

const _modelValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
    emit('set-toggle-selection', value)
  }
})

const isActive = (optionValue) => {
  return _modelValue.value === optionValue
}
</script>

<style scoped lang="scss">
.toggle-selector-wrapper {
  display: flex;
}
.toggle-selector-wrapper label {
  padding: 5px 16px;
  border: 1px solid #ccc;
  color: $plum;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  background-color: $origami;
  cursor: pointer;
}
.toggle-selector-wrapper label:first-of-type {
  border-radius: $sequin-border-radius 0 0 $sequin-border-radius;
}
.toggle-selector-wrapper label:last-of-type {
  border-radius: 0 $sequin-border-radius $sequin-border-radius 0;
}
.active {
  color: $white !important;
  background-color: $plum !important;
}
</style>
