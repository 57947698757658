<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <h5 class="mb-0">
            Purchase Items
          </h5>
        </template>
        <template #body>
          <app-message
            v-if="formErrors.length > 0"
            variant="danger"
            class="p-4 no-padding">
            <div
              v-for="(error, index) in formErrors"
              :key="index">
              <span v-html="error"/>
              <br>
            </div>
          </app-message>
          <div
            v-for="item in deliveredItems"
            :key="item.id"
            class="my-3 d-flex">
            <my-case-item-image
              :style-color="getStyleColor(item.item)"/>
            <div class="ms-3 w-100 small-text">
              <div class="line-item">
                <div>{{ getShortName(item) }}</div>
                <del>${{ Math.round( getStyleColorPrice(item)) }}</del>
              </div>
              <div class="line-item">
                <div>Size {{ item.item.trueSize }}</div>
                <div>${{ item.item.priceClient }}</div>
              </div>
            </div>
          </div>
          <my-case-charge-display
            :price-info="allItemsPricing">
            Subtotal
          </my-case-charge-display>
          <div
            class="mt-4 pt-2">
            <my-case-default-payment-updater/>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <sequin-button
        block
        :disabled="formDisabled || !hasPaymentMethod"
        @click="submit">
        Confirm Purchase - {{ formatPrice(allItemsPricing.chargeAmount) }}
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import MyCaseChargeDisplay from '../common/MyCaseChargeDisplay'
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseItemImage from '../common/MyCaseItemImage'
import MyCaseSection from '../common/MyCaseSection'
import SequinButton from '../../global/sequin/SequinButton'
import { formatPrice } from '@/utils/stringParsing.js'
import MyCaseDefaultPaymentUpdater from '../common/MyCaseDefaultPaymentUpdater'
import AppMessage from '../../global/sequin/AppMessage'

import { mapState, mapActions } from 'vuex'

export default {
  components: {
    MyCaseChargeDisplay,
    MyCaseDefaultPaymentUpdater,
    MyCaseItemImage,
    MyCaseSection,
    MyCaseFlyoutComponent,
    AppMessage,
    SequinButton
  },
  data () {
    return {
      formDisabled: false,
      formErrors: []
    }
  },
  computed: {
    ...mapState('case', [
      'allItemsPricing'
    ]),
    ...mapState('closet', [
      'styleColorsMap',
      'deliveredItems'
    ]),
    ...mapState('client', [
      'defaultPayment'
    ]),
    hasPaymentMethod () {
      return Boolean(this.defaultPayment)
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName',
      'setMyCaseFlyoutContext',
      'purchaseAllItems'
    ]),
    formatPrice,
    getStyleColor (item) {
      return this.styleColorsMap[item.styleColor]
    },
    getStyleColorPrice (item) {
      const styleColor = this.getStyleColor(item.item)
      return styleColor.style.retailPrice
    },
    getShortName (item) {
      const styleColor = this.getStyleColor(item.item)
      return `${styleColor.style.brand} ${styleColor.style.subcategory}`
    },
    async submit () {
      try {
        this.formDisabled = true
        await this.purchaseAllItems({
          items: this.deliveredItems,
          source: 'my_case_flyout_purchase_all'
        })
        this.formDisabled = false
        this.formErrors = []
        this.setMyCaseFlyoutName('purchase-complete')
      } catch (errors) {
        this.formErrors = errors
      }
      this.formDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>

.line-item {
  display: flex;
  justify-content: space-between;
}

.success-light {
  color: $success-light;
}

.no-padding {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
}

</style>
