<template>
  <my-case-section-core>
    <template #header>
      <p class="small-text mb-0">
        Available with your next case
      </p>
    </template>
    <template #body>
      <my-case-bonus-item-slots/>
    </template>
  </my-case-section-core>
</template>

<script>
import MyCaseSectionCore from '../common/MyCaseSectionCore'
import MyCaseBonusItemSlots from './MyCaseBonusItemSlots'

export default {
  components: {
    MyCaseBonusItemSlots,
    MyCaseSectionCore
  }
}
</script>
