<template>
  <div
    v-if="currentTime < cutoff">
    <slot/>
    <span class="text-bold">
      <span v-if="countdownDays >= 1">
        {{ countdownDays }} day{{ countdownDays > 1 ? 's' : '' }}
      </span>
      <span v-else>
        <span v-if="countdownHours > 0">{{ countdownHours }}
          hour<span v-if="countdownHours > 1">s</span> and </span>
        <span v-if="countdownMinutes >= 0"> {{ countdownMinutes }}</span>
        <span> minute</span><span v-if="countdownMinutes > 1">s </span>
      </span>
    </span>
    <slot name="after"/>
    <br>
  </div>
</template>

<script>

export default {
  props: {
    cutoff: {
      type: Date,
      required: true
    }
  },
  data () {
    return {
      timer: null,
      currentTime: new Date(Date.now())
    }
  },
  computed: {
    countdownDays () {
      const days = (this.cutoff - this.currentTime) / 1000 / 60 / 60 / 24
      return days > 1 ? Math.round(days) : days
    },
    countdownHours () {
      return Math.floor((this.cutoff - this.currentTime) / 1000 / 60 / 60)
    },
    countdownMinutes () {
      return Math.floor((this.cutoff - this.currentTime) / 1000 / 60) -
        (this.countdownHours * 60) + 1
    }
  },
  mounted: function () {
    this.timer = setInterval(() => {
      this.currentTime = new Date(Date.now())
    }, 5000)
  },
  beforeUnmount () {
    clearInterval(this.timer)
  }
}

</script>
