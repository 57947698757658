<template>
  <div>
    <div
      v-for="shape in shapes"
      :key="shape.slug"
      class="card"
      :class="{'selected': selectedShape === shape.slug}"
      @click="pickShape(shape.slug)">
      <div class="card-horizontal">
        <div>
          <hour-glass v-if="shape.slug === 'hourglass'"/>
          <triangle-svg v-if="shape.slug === 'triangle'"/>
          <inverted-triangle v-if="shape.slug === 'inverted-triangle'"/>
          <rectangle-svg v-if="shape.slug === 'rectangle'"/>
          <oval-svg v-if="shape.slug === 'oval'"/>
        </div>
        <div class="card-body">
          <h6 class="card-title">
            {{ shape.name }}
          </h6>
          <p class="card-text text-gray">
            {{ shape.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HourGlass from './HourGlass'
import TriangleSvg from './TriangleSvg'
import InvertedTriangle from './InvertedTriangle'
import OvalSvg from './OvalSvg'
import RectangleSvg from './RectangleSvg'

export default {
  components: {
    HourGlass,
    TriangleSvg,
    OvalSvg,
    InvertedTriangle,
    RectangleSvg
  },
  props: {
    selectedShape: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      shapes: [
        {
          name: 'Hourglass',
          slug: 'hourglass',
          description: 'Defined waist with equal bust and hips'
        },
        {
          name: 'Triangle',
          slug: 'triangle',
          description: 'Bust and waist narrower than hips'
        },
        {
          name: 'Inverted Triangle',
          slug: 'inverted-triangle',
          description: 'Broad shouldered with narrow hips'
        },
        {
          name: 'Rectangle',
          slug: 'rectangle',
          description: 'Straight waisted with equal bust and hips'
        },
        {
          name: 'Oval',
          slug: 'oval',
          description: 'Waist wider than bust and hips'
        }
      ]
    }
  },
  methods: {
    pickShape (shape) {
      let myShape = this.selectedShape
      myShape = shape
      this.$emit('shape', myShape)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.card {
  border: 1px solid rgba(186, 186, 191, 0.5);
  border-radius: 0;
  padding: 1.2rem;
  &.selected {
   background-color: #F7F5F7;
  }
}

.card-body {
  padding: 0 0 0 1em;
}

.card-title {
  font-size: 15px;
}

.card-text {
  font-size: 15px;
}
</style>
