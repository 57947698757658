<template>
  <case-error-notification @close-clicked="deselectUnavailableItemTypes">
    <div
      :class="{ 'd-flex': unavailableItemTypes.length === 1 }"
      class="text-start px-2 py-1 px-md-4 py-md-2">
      <div class="images">
        <my-case-item-image
          v-for="item in unavailableItemTypes"
          :key="item.id"
          class="me-2"
          :style-color="styleColorsMap[item.styleColor]"/>
      </div>
      <div><b>Bummer! </b>Another member grabbed {{ unavailableItemTypes.length > 1 ? 'these styles' : 'this style' }}.</div>
    </div>
  </case-error-notification>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CaseErrorNotification from './CaseErrorNotification'
import MyCaseItemImage from '../common/MyCaseItemImage'

export default {
  components: {
    CaseErrorNotification,
    MyCaseItemImage
  },
  computed: {
    ...mapState('closet', [
      'styleColorsMap',
      'unavailableItemTypes'
    ])
  },
  beforeUnmount () {
    this.deselectUnavailableItemTypes()
  },
  methods: {
    ...mapActions('closet', [
      'deselectUnavailableItemTypes'
    ])
  }
}
</script>

<style lang="scss" scoped>
.images {
  display: flex;
  max-width: 112px;
  flex-shrink: 0;
  margin-right: 4px;
  margin-bottom: 8px;
}
</style>
