<template>
  <BaseButton
    class="styling"
    variant="secondary"
    :text="text"
    @click="emit('click', event)"/>
</template>
<script setup>
import BaseButton from '@shared/components/ADORN/BaseButton.vue'

defineProps({
  text: {
    type: String,
    default: null
  }
})

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})
</script>
<style lang="scss" scoped>
.styling {
  height: 38px;
  font-size: 14px;
}
</style>
