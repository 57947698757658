<template>
  <div>
    <div class="d-flex justify-content-center pt-1">
      <div
        v-if="canSwap"
        class="d-flex header-action flex-column align-items-center my-1 mx-1 mx-md-2">
        <sequin-button
          small
          block
          type="router-link"
          :to="{ name: 'swap-select' }"
          @click="closeFlyout">
          <svg-refresh-c-w
            height="16"
            width="16"
            class="me-1"/> Swap
        </sequin-button>
        <p
          class="mt-2 mb-0 small-text">
          Swap items to build a new case.
        </p>
      </div>
      <div
        v-else-if="showUnlockEarly"
        class="d-flex header-action flex-column align-items-center my-1 mb-2 mx-1 mx-md-2">
        <sequin-button
          small
          block
          variant="secondary"
          @click="setMyCaseFlyoutName('purchase-shipment')">
          <svg-case-filled
            height="16"
            width="16"
            class="me-1 mb-1"/> Unlock Early
        </sequin-button>
      </div>
      <div
        v-else
        :class="{'mobile': isMobile}"
        class="d-flex header-action-group flex-column align-items-center my-1 mx-1 mx-md-2">
        <div
          class="d-flex w-100 justify-content-center align-items-center">
          <sequin-button
            small
            block
            type="button"
            class="w-100 mx-1 mx-md-2"
            variant="primary"
            @click="onReviewItemsClick">
            Review Items
          </sequin-button>
          <sequin-button
            small
            block
            class="w-100 mx-1 mx-md-2"
            type="router-link"
            :to="{ name: 'swap-select' }"
            variant="secondary"
            disabled>
            <svg-refresh-c-w
              height="16"
              width="16"
              class="me-1"/> Swap
          </sequin-button>
        </div>
        <div
          v-if="isReviewOOC"
          class="small-text mt-3">
          <p class="mb-0">
            You have <strong>{{ numItemsToReview }} items</strong> to review.
          </p>
          <p class="mb-0">
            You must review at least <strong>{{ minItemsToReview }} item{{ minItemsToReview > 1 ? 's' : '' }}</strong> before you can swap{{ membershipDetails.allowedShipments === 0 && nextShipmentDate ? ` on ${toMonthDayString(nextShipmentDate, 'short')}` : '' }}.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyCaseMixin from '../../common/MyCaseMixin'
import SequinButton from '../../../global/sequin/SequinButton'
import { toMonthDayString } from '@/utils/stringParsing.js'
import { mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import { vuexAccessors } from '../../../global/helpers/vuex'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgRefreshCW from '@/components/global/svg/SvgRefreshCW.vue'
import SvgCaseFilled from '@/components/global/svg/SvgCaseFilled.vue'

export default {
  components: {
    SequinButton,
    SvgRefreshCW,
    SvgCaseFilled
  },
  mixins: [MyCaseMixin],
  computed: {
    ...vuexAccessors('review', [
      'reviewItems'
    ]),
    canSwap () {
      return this.canSwapItems && !this.isReviewOOC
    },
    showUnlockEarly () {
      return !this.canSwap && this.membershipDetails.allowedShipments === 0 && this.membershipDetails.unlockShipmentEligible && this.nextShipmentDate && !this.itemsInTransit && this.active && !this.isReviewOOC && !this.tooManyItemsOut
    }
  },
  methods: {
    ...mapVuexActions('case', [
      'setMyCaseFlyoutName'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    toMonthDayString,
    onReviewItemsClick () {
      this.reviewItems = []
      this.closeFlyout()
      this.$router.push({ name: 'review-select' })
    }
  }
}
</script>

<style lang="scss" scoped>
.header-action {
  min-width: 220px;
}

.header-action-group {
  width: 360px;

  &.mobile {
    width: 312px;
  }
}
</style>
