<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('address-selection')">
        <template #header>
          <h6 class="mb-0">
            Shipping Address
          </h6>
        </template>
        <template #body>
          <div>
            <address-form-fields
              ref="addressFormFields"
              :address="shippingAddressEditing"
              :show-set-selected="setShippingAddressEditingSelected"
              @correctable-address-error="onCorrectableAddressError"
              @address-added-updated="setMyCaseFlyoutName('address-selection')"/>
          </div>
        </template>
      </my-case-section>
    </template>
    <template #footer>
      <sequin-button
        block
        :disabled="isSavingAddress"
        @click="saveAddress">
        Save Address
      </sequin-button>
      <sequin-button
        block
        :disabled="isSavingAddress"
        class="mt-3"
        variant="secondary"
        @click="setMyCaseFlyoutName('address-selection')">
        Cancel
      </sequin-button>
    </template>
  </my-case-flyout-component>
</template>

<script>
import AddressFormFields from '../../subscribe/AddressFormFields'
import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import MyCaseMixin from '../common/MyCaseMixin'
import SequinButton from '../../global/sequin/SequinButton'
import { mapActions, mapState } from 'vuex'
import useAnalytics from '@shared/composables/analytics.js'

export default {
  components: {
    AddressFormFields,
    MyCaseFlyoutComponent,
    MyCaseSection,
    SequinButton
  },
  mixins: [MyCaseMixin],
  setup () {
    const { track } = useAnalytics()
    return { track }
  },
  data () {
    return {
      isSavingAddress: false
    }
  },
  computed: {
    ...mapState('client', [
      'firstName',
      'lastName'
    ]),
    ...mapState('case', [
      'shippingAddressEditing',
      'setShippingAddressEditingSelected'
    ])
  },
  mounted () {
    this.track('Viewed Checkout Change Shipping Location')
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName'
    ]),
    onCorrectableAddressError ({ error }) {
      this.setMyCaseFlyoutContext({
        shippingAddressEditingError: error
      })
      this.setMyCaseFlyoutName('correct-shipping-address')
    },
    async saveAddress () {
      this.isSavingAddress = true
      await this.$refs.addressFormFields.submit()
      this.isSavingAddress = false
    }
  }

}
</script>
