<template>
  <my-case-flyout-component>
    <template #body>
      <my-case-section
        back-button
        @back-click="setMyCaseFlyoutName('core')">
        <template #header>
          <h5 class="mb-0">
            Stylist Request Received
          </h5>
        </template>
        <template #body>
          <div class="d-flex flex-column align-items-center py-3">
            <svg-circle-icon
              :height="40"
              :width="40"
              class="p-2"
              variant="success">
              <svg-check/>
            </svg-circle-icon>
            <p class="p-3 text-center">
              We've received your stylist request and will notify you when new options are ready!
            </p>
          </div>
        </template>
      </my-case-section>
    </template>
  </my-case-flyout-component>
</template>

<script>
import { mapActions } from 'vuex'

import MyCaseFlyoutComponent from '../common/MyCaseFlyoutComponent'
import MyCaseSection from '../common/MyCaseSection'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'

export default {
  components: {
    MyCaseSection,
    MyCaseFlyoutComponent,
    SvgCircleIcon,
    SvgCheck
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ])
  }
}
</script>
