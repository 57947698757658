<template>
  <awaiting-returns-header-base/>
</template>

<script>
import AwaitingReturnsHeaderBase from '../../common/header/AwaitingReturnsHeaderBase'

export default {
  components: {
    AwaitingReturnsHeaderBase
  }
}
</script>
