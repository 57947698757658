<template>
  <AccordionWithHeader
    :title="`Stylist Closet Preview // Closet Type: ${closetType} // Closet Items: ${closetItems.length}`"
    class="stylist-closet-preview"
    :border="false"
    :expanded-max-height="600"
    :expanded="stylistClosetPreviewExpanded"
    @accordion-click="toggleStylistClosetPreviewExpanded">
    <div class="mt-xs px-xs py-xs bg-white">
      <h3>{{ title }}</h3>
      <div class="headline mb-xs">
        <b
          class="m-0 text-pewter semi-bold whitespace-no-wrap">
          {{ subtitle }}
        </b>
        <div class="line-with-dot"/>
      </div>
      <HorizontalScroll v-if="closetItems.length > 0">
        <PreviewImageButton
          v-for="item in closetItems"
          :key="item.type.styleColor.id"
          :src="source(item)"
          :alt="item.type.styleColor.name"
          size="small"
          class="preview-image-button"
          @click="removeStyleColor(item.type.styleColor.id)"/>
      </HorizontalScroll>
    </div>
  </AccordionWithHeader>
</template>
<script setup>
import { ref, computed } from 'vue'
import HorizontalScroll from '@shared/components/ADORN/HorizontalScroll.vue'
import PreviewImageButton from '@shared/components/ADORN/PreviewImageButton.vue'
import AccordionWithHeader from '@/components/global/sequin/AccordionWithHeader.vue'
import useClient from '@/composables/client'
import { storeToRefs } from 'pinia'
import { useStylingStore } from '@/stores/styling'

const stylistClosetPreviewExpanded = ref(true)
const toggleStylistClosetPreviewExpanded = () => {
  stylistClosetPreviewExpanded.value = !stylistClosetPreviewExpanded.value
}

const store = useStylingStore()
const { closetItems, stylistInitiated } = storeToRefs(store)
const { removeStyleColor } = store
const { impersonator } = useClient()

const closetType = computed(() => stylistInitiated.value ? 'Proactive' : 'Reactive')

const title = computed(() => stylistInitiated.value ? 'Stylist Finds' : 'Your Styling Request')

const subtitle = computed(() => 'Hand selected for you by ' + impersonator.value.firstName)

const source = (item) => {
  return item.type.styleColor.images.front.url
}

</script>
<style lang=scss scoped>
.stylist-closet-preview {
  background-color: rgba($orchid-light, 0.1);
  height: 100%;
}
.preview-image-button {
  position: relative;
  display: inline-block;
}
/* pseudo element to create red overlay */
.preview-image-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($danger-dark, 0.5);
  z-index: 1;
  display: none;
}
/* pseudo element to create 'x' */
.preview-image-button::after {
  content: 'X';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  z-index: 2;
  display: none;
}

.preview-image-button:hover::before,
.preview-image-button:hover::after {
  display: block;
}
.preview-image-placeholder {
  width: 99px;
  height: 132px;
  background-color: $champagne;
  color: $plum;
}
.headline {
  display: flex;
  align-items: center;
}
.line-with-dot {
  width: 100%;
  height: 1px;
  background-color: $ash;
  &::after {
    content: '';
    display: block;
    background-color: $ash;
    position: relative;
    right: -100%;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    top: -2px;
  }
}
</style>
