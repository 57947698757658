<template>
  <div>
    <h6 class="mb-3">
      Payment Details
    </h6>
    <div
      v-if="paymentIsExpired"
      class="text-danger">
      Your credit card is expired.  Please update to continue.
    </div>
    <default-payment
      :show-expiration="paymentIsExpired"
      @edit-card-click="setMyCaseFlyoutName('update-payment-details')"/>
  </div>
</template>

<script>
import DefaultPayment from '../../global/stripe/DefaultPayment'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DefaultPayment
  },
  computed: {
    ...mapState('client', [
      'defaultPayment'
    ]),
    ...mapGetters('client', [
      'paymentIsExpired'
    ]),
    hasPaymentMethod () {
      return Boolean(this.defaultPayment)
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ])
  }
}
</script>
