<template>
  <base-modal
    :full-screen="isMobile"
    use-title-border
    no-footer>
    <template #title>
      <h5>Purchase Item</h5>
    </template>
    <template #body>
      <div
        v-if="purchased"
        class="mt-4 text-center">
        <div class="d-flex justify-content-center mb-4">
          <svg-circle-icon
            :height="36"
            :width="36"
            class="success-icon"
            variant="success">
            <svg-check/>
          </svg-circle-icon>
        </div>
        <h4>Purchase Complete</h4>
        <div>
          We'll ship this item with your next case
        </div>
      </div>
      <h5
        class="mt-3">
        {{ title }}
      </h5>
      <div class="small-text mb-3">
        Size {{ size }}
      </div>
      <purchase-item-display
        v-if="item"
        size="large"
        :item-to-purchase="item"
        :disabled="formDisabled"
        :purchased="purchased"
        :inline-size-and-name="false"
        @applied-promo="promoApplied"/>
      <div
        v-if="purchased">
        <hr>
        <sequin-button
          block
          variant="secondary"
          @click="closeModal">
          Close
        </sequin-button>
      </div>
      <div
        v-else-if="Boolean(defaultPayment) && !isUpdatingPayment">
        <hr>
        <div
          class="small-text text-pewter text-center mt-3">
          We'll set this item aside and ship it with your next case
        </div>
        <base-form-errors :errors="formErrors"/>
        <sequin-button
          block
          :disabled="formDisabled || !hasPaymentMethod"
          @click="onPurchase">
          Confirm Purchase
        </sequin-button>
        <div
          class="fine-print text-pewter text-center mt-3">
          All sales final
        </div>
      </div>
      <div
        v-else
        class="mb-3">
        <!-- for spacing -->
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapState as mapVuexState, mapActions as mapVuexActions } from 'vuex'
import { mapActions, mapState } from 'pinia'
import PurchaseItemDisplay from '../case/purchaseItem/PurchaseItemDisplay'
import SequinButton from '../global/sequin/SequinButton'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'
import BaseModal from '@/components/global/BaseModal.vue'
import BaseFormErrors from '@/components/global/BaseFormErrors.vue'

export default {
  name: 'ClosetModalBuyItem',
  components: {
    BaseModal,
    BaseFormErrors,
    PurchaseItemDisplay,
    SequinButton,
    SvgCircleIcon,
    SvgCheck
  },
  data () {
    return {
      formDisabled: false,
      formErrors: [],
      item: null,
      purchased: false,
      appliedPromoCode: null
    }
  },
  computed: {
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    ...mapVuexState('closet', [
      'itemTypePricing'
    ]),
    itemType () {
      return this.modalContext.selectedItemType
    },
    styleColor () {
      return this.modalContext.styleColor
    },
    size () {
      return this.itemType.trueSize
    },
    title () {
      return `${this.styleColor.style.brand} ${this.styleColor.style.name}`
    },
    ...mapVuexState('client', [
      'defaultPayment',
      'isUpdatingPayment'
    ]),
    hasPaymentMethod () {
      return Boolean(this.defaultPayment)
    }
  },
  async mounted () {
    if (typeof Stripe === 'undefined') {
      const script = document.createElement('script')
      script.id = '_stripe-checkout-script'
      script.src = 'https://js.stripe.com/v3/'
      script.type = 'text/javascript'
      document.head.appendChild(script)
    }

    if (this.modalContext.selectedItemType.id in this.itemTypePricing) {
      this.item = this.itemTypePricing[this.modalContext.selectedItemType.id].item
    } else {
      const data = await this.getItemTypePrice(this.modalContext.selectedItemType)
      this.item = data.item
    }
  },
  methods: {
    ...mapVuexActions('client', [
      'getItemTypePrice',
      'buyItem'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    promoApplied (promoCode) {
      this.appliedPromoCode = promoCode
    },
    async onPurchase () {
      try {
        this.formDisabled = true
        await this.buyItem({
          id: this.item.id,
          promoCode: this.appliedPromoCode,
          source: 'closet_modal_buy_item'
        })
        this.formDisabled = false
        this.formErrors = []
        this.purchased = true
      } catch (errors) {
        this.formErrors = errors
      }
      this.formDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
.success-icon {
  box-shadow: 0 0 0 6px lighten($success-light, 20%);
}
</style>
