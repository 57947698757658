<template>
  <transition
    name="fade"
    appear
    mode="out-in">
    <div
      class="py-3 d-flex">
      <div
        class="position-relative">
        <div
          class="pointer"
          @click="viewDetails">
          <my-case-item-image
            size="large"
            :image-url="representativeImageUrl"/>
        </div>
      </div>
      <div
        class="ms-4 w-50 smaller">
        <span
          class="fine-print">
          {{ brand }}
        </span>
        <br v-if="brand">
        <span
          class="semi-bold pointer"
          @click="viewDetails">
          {{ title }}
        </span>
        <br>
        <div
          class="text-pewter small-text">
          {{ formatPrice(price) }}
          <div
            v-if="showColorsCount"
            class="fine-print">
            {{ addOnItem.colors.length }} colors
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { formatPrice } from '@/utils/stringParsing.js'
import MyCaseItemImage from '../common/MyCaseItemImage'
import AddOnItemsMixin from './AddOnItemsMixin'
import { mapActions } from 'vuex'

export default {
  components: {
    MyCaseItemImage
  },
  mixins: [AddOnItemsMixin],
  props: {
    addOnItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    price () {
      // pick the price of the 1st item
      return this.addOnItem.colors[0].sizes[0].price
    },
    showColorsCount () {
      return this.addOnItem.colors.length > 1
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName'
    ]),
    formatPrice,
    viewDetails () {
      this.setMyCaseFlyoutContext({
        addOnDetailsBackClick: 'confirm-case',
        addOnItemTypeInitialQuantity: 1,
        addOnItemViewing: this.addOnItem,
        addOnDetailsShowAddToCase: true
      })
      this.setMyCaseFlyoutName('add-on-details')
    }
  }
}

</script>
