<template>
  <my-case-header title="Awaiting Returns">
    <slot>
      <p class="small-text">
        You currently have {{ outstandingItems.length }} items out on your {{ caseSize }} item plan. You can’t swap or select new styles until we receive your returns at our warehouse.
      </p>
    </slot>
    <sequin-button
      small
      block
      class="w-50"
      type="router-link"
      :to="{ name: 'account', query: { section: 'shipping-returns'} }"
      @click="closeFlyout">
      Track Returns
    </sequin-button>
  </my-case-header>
</template>

<script>
import MyCaseMixin from '../../common/MyCaseMixin'
import MyCaseHeader from '../../common/header/MyCaseHeader'
import SequinButton from '../../../global/sequin/SequinButton'
import { mapActions } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    MyCaseHeader,
    SequinButton
  },
  mixins: [MyCaseMixin],
  methods: {
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ])
  }
}
</script>
