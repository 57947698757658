<template>
  <div
    class="edit-closet-container d-flex flex-column m-3">
    <div class="preview-container d-flex justify-content-around mb-3">
      <table>
        <thead>
          <tr>
            <th>Closet</th>
            <th>Created</th>
            <th>Published?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ closet.pk }}</td>
            <td>
              <FeedItemTimestamp
                :timestamp="closet.created"
                :condensed="true"/>
            </td>
            <td>
              <SvgCircleIcon
                :variant="variant"
                inverse>
                <component :is="icon"/>
              </SvgCircleIcon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="preview-container d-flex justify-content-around mb-3">
      <PreviewImage
        v-for="item in closet.items"
        :key="item.type.styleColor.pk"
        class="col-1"
        style="width: 60px;"
        :src="source(item)"
        :alt="item.type.styleColor.name"
        size="auto"/>
    </div>
    <div class="d-flex justify-content-around mb-3">
      <BaseButton
        class="action-button col-5"
        variant="primary"
        text="Resume Styling"
        @click="$emit('resume')"/>
      <BaseButton
        class="action-button col-5"
        variant="secondary"
        text="Delete Closet"
        @click="$emit('delete')"/>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import FeedItemTimestamp from '@/components/community/feed/FeedItemTimestamp.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'
import SvgX from '@/components/global/svg/SvgX.vue'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'

const props = defineProps({
  closet: {
    type: Object,
    default: null
  }
})

const source = (item) => item.type.styleColor.images.front.url
const icon = computed(() => props.closet.showClient ? SvgCheck : SvgX)
const variant = computed(() => props.closet.showClient ? 'success' : 'danger')

</script>
<style lang="scss" scoped>
.edit-closet-container {
  border-radius: 5px;
  border: 1px solid $gray;
  width: 200px;
}
.action-button {
  width: 90px !important;
  font-size: .65rem !important;
  padding: 4px !important
}
.preview-container {
  overflow-x: auto;

  table {
    border-collapse: collapse;
    th, td {
      padding: 0.2rem;
      text-align: center;
      color: $gray;
      font-size: .75rem;
      line-height: 20px;
    }
  }
}
</style>
