<template>
  <base-modal
    use-title-border
    flush-content
    no-footer>
    <template #title>
      <h5>
        Share this style
      </h5>
    </template>
    <template #body>
      <div class="d-flex justify-content-center p-4">
        <plain-button class="p-0 mx-3">
          <a
            class="share-link"
            :href="mailToLink"
            target="_blank"
            @click="handleEmailClick">
            <svg-share-email/>
          </a>
        </plain-button>
        <plain-button class="p-0 mx-3">
          <a
            class="share-link"
            target="_blank"
            @click="handleFbClick">
            <svg-share-facebook/>
          </a>
        </plain-button>
        <plain-button class="p-0 mx-3">
          <a
            :href="pinterestLink"
            target="_blank"
            @click="handlePinterestClick">
            <svg-share-pinterest/>
          </a>
        </plain-button>
      </div>
      <h5 class="text-center">
        or
      </h5>
      <div class="px-4 my-2 pb-4">
        <copy-link
          :full-link="productLink"
          :display-link="productLink"
          @copy-link-click="handleCopyLinkClick"/>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/global/BaseModal.vue'
import PlainButton from '../global/sequin/PlainButton'
import CopyLink from '../global/sequin/CopyLink'
import { mapGetters } from 'vuex'
import { mapState } from 'pinia'
import { StyleColorMixin } from '../global/mixins/StyleColorMixin'
import useAnalytics from '@shared/composables/analytics.js'
import { useOverlaysStore } from '@/stores/overlays.js'
import SvgShareEmail from '../global/svg/SvgShareEmail.vue'
import SvgShareFacebook from '../global/svg/SvgShareFacebook.vue'
import SvgSharePinterest from '../global/svg/SvgSharePinterest.vue'

export default {
  name: 'ClosetModalShareActions',
  components: {
    BaseModal,
    CopyLink,
    PlainButton,
    SvgShareEmail,
    SvgShareFacebook,
    SvgSharePinterest
  },
  mixins: [StyleColorMixin],
  setup () {
    const { track } = useAnalytics()
    return {
      track
    }
  },
  computed: {
    ...mapGetters('client', [
      'shareQueryString'
    ]),
    ...mapState(useOverlaysStore, [
      'modalContext'
    ]),
    productDescription () {
      const { styleColor } = this.modalContext
      return `Check out this ${styleColor.style.brand} ${styleColor.style.name} from Armoire`
    },
    productLink () {
      return `https://${window.location.hostname}${this.$route.path}` + this.shareQueryString
    },
    mailToLink () {
      const subject = encodeURIComponent('Saw this and thought of you...')
      const body = encodeURIComponent(`${this.productDescription}:\n${this.productLink}`)
      return `mailto:?body=${body}&subject=${subject}`
    },
    pinterestLink () {
      const baseUrl = 'https://www.pinterest.com/pin/create/button/'
      const imageUrl = this.modalContext.styleColor.images.front.url
      return `${baseUrl}?url=${this.productLink}&media=${imageUrl}&description=${encodeURIComponent(this.productDescription)}`
    }
  },
  methods: {
    handleFbClick () {
      this.$fb.ui({
        method: 'share',
        href: this.productLink,
        quote: 'Look what I found at Armoire!'
      }, () => {})
      this.track('Shared PDP - Facebook')
    },
    handleEmailClick () {
      this.track('Shared PDP - Mail')
    },
    handleCopyLinkClick () {
      this.track('Shared PDP - Copy Link')
    },
    handlePinterestClick () {
      this.track('Shared PDP - Pinterest')
    }
  }
}
</script>

<style lang="scss" scoped>
  .share-link {
    margin: 0;
    cursor: pointer;
  }
</style>
