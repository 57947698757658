<template>
  <div class="sale-banner">
    <b>{{ sale?.displayName ? `${sale.displayName}! `: '' }}<br v-if="isTabletOrSmaller"></b>
    <span
      v-if="sale.homepageMessageHtml"
      class="sale-html"
      v-html="sale.homepageMessageHtml"/>
    <span v-else-if="sale.homepageMessage">
      {{ sale.homepageMessage }}
    </span>
    <p
      v-if="sale.promoCodeMessage">
      {{ sale.promoCodeMessage }}
    </p>
    <p
      v-if="hasRentalPlan"
      class="message">
      <i>Purchased items will ship with your next Case.</i>
    </p>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import useScreenSize from '@shared/composables/screenSize.js'

const { isTabletOrSmaller } = useScreenSize()
const store = useStore()
const sale = computed(() => store.state.closet.sale)
const hasRentalPlan = computed(() => store.getters['client/hasRentalPlan'])

</script>
<style lang="scss" scoped>
.sale-banner {
  color: $white;
  text-align: center;
  background-color: $orchid;
  padding: 12px;
  p {
      margin: 0;
  }

}
:deep(.sale-html) {
  a {
    color: $white !important;
    text-decoration: underline;
  }
}
</style>
