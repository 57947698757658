<template>
  <transition
    name="fade"
    appear
    mode="out-in">
    <div
      class="py-3 d-flex">
      <div
        class="position-relative">
        <div
          class="pointer"
          @click="viewDetails">
          <my-case-item-image
            size="large"
            :style-color="styleColor"
            :overlay-tag="tag"/>
        </div>
      </div>
      <div
        class="ms-4 w-50 smaller pointer"
        @click="viewDetails">
        <br>
        {{ brand }}
        <br>
        <span class="semi-bold">
          {{ styleColor.style.name }}
        </span>
        <div
          class="text-pewter small-text">
          Size {{ item.trueSize }}
        </div>
        <div
          class="text-pewter small-text">
          <span class="strike-through">
            {{ formatPrice(price, true, true) }}
          </span>
          <span class="semi-bold ms-2">
            {{ formatPrice(item.priceClient, true, true) }}
          </span>
          <span class="text-success semi-bold ms-1">
            {{ getPercentOff(item.priceClient) }}% off
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { formatPrice } from '@/utils/stringParsing.js'
import MyCaseItemImage from '../common/MyCaseItemImage'
import { StyleColorMixin } from '../../styleColorSet/StyleColorMixin'
import MyCaseMixin from '../common/MyCaseMixin'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    MyCaseItemImage
  },
  mixins: [StyleColorMixin, MyCaseMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('case', [
      'purchaseSuggestionsInCase'
    ]),
    styleColor () {
      return this.styleColorsMap[this.item.styleColor]
    },
    inCase () {
      return this.purchaseSuggestionsInCase.filter(y => y.id === this.item.id).length > 0
    },
    tag () {
      return this.inCase ? 'Added to case' : ''
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutContext',
      'setMyCaseFlyoutName'
    ]),
    formatPrice,
    viewDetails () {
      this.setMyCaseFlyoutContext({
        purchaseSuggestionItemViewing: this.item,
        purchaseSuggestionStyleColor: this.item.styleColor,
        purchaseSuggestionShowAddToCase: !this.inCase
      })
      this.setMyCaseFlyoutName('purchase-suggestion-details')
    }
  }
}
</script>
