<template>
  <ready-for-pickup-header-base>
    <div
      v-if="pickupLocationHasKiosks"
      class="mt-2">
      <text-link
        class="mt-1"
        @click="setMyCaseFlyoutName('pickup-location-details')">
        View Details
      </text-link>
    </div>
  </ready-for-pickup-header-base>
</template>

<script>
import ReadyForPickupHeaderBase from '../../common/header/ReadyForPickupHeaderBase.vue'
import MyCaseMixin from '../../common/MyCaseMixin'
import TextLink from '../../../global/sequin/TextLink.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    ReadyForPickupHeaderBase,
    TextLink
  },
  mixins: [MyCaseMixin],
  computed: {
    pickupPackage () {
      return this.pickupPackages[0]
    },
    pickupLocation () {
      return this.pickupPackage.pickupFrom
    },
    pickupLocationHasKiosks () {
      return !!this.pickupLocation.kiosks
    }
  },
  methods: {
    ...mapActions('case', [
      'setMyCaseFlyoutName'
    ])
  }
}
</script>
