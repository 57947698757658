<template>
  <div class="stylists">
    <img
      v-for="index in imagesShown"
      :key="index"
      class="stylist-image"
      :src="getStylistImageUrl(index - 1)">
  </div>
</template>

<script>
const IMAGE_COUNT = 6

export default {
  name: 'ClosetStylistAvatars',
  props: {
    imagesShown: {
      type: Number,
      default: 3
    }
  },
  data () {
    return {
      imageUrls: [...Array(IMAGE_COUNT).keys()].map(index =>
        `${process.env.VUE_APP_WEBSITE_IMAGES_URL}stylist-${index + 1}.png`),
      imageSet: Math.floor(Math.random() * (IMAGE_COUNT / this.imagesShown))
    }
  },
  methods: {
    getStylistImageUrl (index) {
      return this.imageUrls[(this.imageSet * this.imagesShown) + index]
    }
  }
}
</script>

<style lang="scss" scoped>
  .stylists {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-left: 8px;

  .stylist-image {
    border-radius: 50%;
    height: 52px;
    width: 52px;
    border: 3px solid $white;
    margin-left: -12px;
  }
}
</style>
