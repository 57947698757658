<template>
  <base-modal
    class="share-look-modal"
    :hide-close="shareLookComponent !== 'Core'"
    @close-modal-clicked="onModalClose">
    <template #title>
      <div class="w-100 px-2 pt-2">
        <h4
          v-if="shareLookComponent === 'Core'"
          class="text-start mb-0">
          {{ editLook ? 'Edit Your Look' : 'Share a Look' }}
        </h4>
        <action-header
          v-else
          class="p-0 mt-n2 mb-2"
          no-navigation
          :show-confirm="modalComponent.showConfirm"
          @back-click="setShareLookComponent('Core')"
          @confirm-click="modalComponent.onConfirm">
          {{ modalComponent.title }}
        </action-header>
      </div>
    </template>
    <template #body>
      <component
        v-bind="modalComponent.props"
        :is="modalComponent.componentName"
        is-modal
        :class="modalComponent.class"
        v-on="modalComponent.handlers"/>
    </template>
    <template #footer>
      <div
        v-if="showSubmit"
        class="p-3">
        <sequin-button
          :disabled="lookBuilder.images.length === 0 || lookBuilder.styleColors.length === 0 || isSubmitting"
          block
          @click="submit">
          {{ editLook ? 'Save Changes' : 'Share Look' }}
        </sequin-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapState, mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import BaseModal from '@/components/global/BaseModal'
import ShareALookBody from './ShareALookBody'
import ActionHeader from '../../global/sequin/ActionHeader'
import AddPhotoBody from './AddPhotoBody'
import TagOccasionBody from './TagOccasionBody'
import TagStylesBody from './TagStylesBody'
import SequinButton from '../../global/sequin/SequinButton'
import ShareALookMixin from './ShareALookMixin'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  components: {
    BaseModal,
    ActionHeader,
    SequinButton,
    ShareALookBody,
    AddPhotoBody,
    TagOccasionBody,
    TagStylesBody
  },
  mixins: [ShareALookMixin],
  props: {
    editLook: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('community', [
      'shareLookComponent',
      'lookBuilder'
    ]),
    showSubmit () {
      return this.shareLookComponent === 'Core'
    },
    modalComponentDetails () {
      return {
        Core: {
          ...this.componentDetails.Core,
          class: 'border',
          props: {
            ...this.componentDetails.Core.props,
            editLook: this.editLook
          }
        },
        AddPhoto: {
          ...this.componentDetails.AddPhoto,
          class: 'border'
        },
        TagStyles: {
          ...this.componentDetails.TagStyles,
          class: ''
        },
        TagOccasion: {
          ...this.componentDetails.TagOccasion,
          class: 'border-end border-start border-top'
        }
      }
    },
    modalComponent () {
      return this.modalComponentDetails[this.shareLookComponent]
    }
  },
  methods: {
    ...mapVuexActions('community', [
      'clearLookBuilder'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeModal'
    ]),
    onModalClose () {
      this.clearLookBuilder()
      this.setShareLookComponent('Core')
    },
    async submit () {
      if (this.editLook) {
        await this.saveLookUpdates()
      } else {
        await this.shareLook()
      }

      if (!this.submitError) {
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.share-look-modal {
  :global(.scrollable) {
    padding-top: 0;
  }
}
</style>
