<template>
  <base-modal
    class="d-flex flex-column flex-md-row justify-content-center text-center"
    :hide-close="!success"
    @close-modal-clicked="closeFedExModal(success)">
    <template #title>
      <h3 class="title">
        {{ title }}
      </h3>
    </template>
    <template #body>
      <div class="d-flex flex-column align-items-center">
        <div v-html="content"/>
        <BaseButton
          v-if="!success"
          class="mt-3"
          text="Opt into FedEx"
          @click="optIntoFedEx('modal')"/>
        <BaseButton
          v-if="!success"
          class="mt-3"
          style="font-size: 14px"
          variant="text-link"
          text="Not Right Now"
          @click="closeFedExModal(success)"/>
      </div>
    </template>
  </base-modal>
</template>

<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useOverlaysStore } from '@/stores/overlays.js'
import { useFedExOptInStore } from '@/stores/fedExOptIn.js'
import BaseModal from '@/components/global/BaseModal.vue'
import BaseButton from '@shared/components/ADORN/BaseButton'

const { closeFedExModal, optIntoFedEx } = useFedExOptInStore()

const { modalContext } = storeToRefs(useOverlaysStore())

const success = computed(() => modalContext.value?.success)
const title = computed(() => {
  return success.value
    ? 'Success! 🎉'
    : 'Switch to FedEx for Hassle-Free Returns'
})
const content = computed(() => {
  return success.value
    ? 'You\'re now using <b>FedEx</b> for returns.'
    : 'No time to drop off? <b>FedEx picks it up for free</b> from your doorstep.'
})

</script>

<!-- to-do: [ENG-4711] typography clean-up -->
<style lang="scss" scoped>
:deep(.modal-dialog) {
  width: fit-content;
  max-width: unset;
}
:deep(.modal-content) {
  padding-top: 16px;
}
.title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
</style>
