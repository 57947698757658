<template>
  <div class="text-center bg-champagne">
    <SequinButton
      v-if="stylistClosetInProgress"
      class="stop-button"
      variant="danger"
      @click="stopStyling">
      Stop Styling
    </SequinButton>
    <AppMessage
      v-if="errors.length"
      variant="danger"
      has-close-button
      @close-clicked="closeError">
      {{ errors }}
    </AppMessage>
    <div
      v-if="!stylistClosetInProgress"
      class="d-flex justify-content-center gap-sm p-xs">
      <StylingBaseButton
        :text="viewStylingInfo ? 'Hide Styling Info' : 'View Styling Info'"
        @click="toggleStylingInfo"/>
      <StylingBaseButton
        text="Create New Stylist Closet"
        @click="createNewStylistCloset"/>
      <StylingBaseButton
        v-if="showStylingButton"
        :text="stylistCaseButton.text"
        @click="stylistCaseButton.action"/>
      <StylingBaseButton
        v-if="recentClosets.length"
        :text="showClosetEditor ? 'Hide Existing Closets' : 'Select an Existing Closet to Edit'"
        @click="toggleShowClosetEditor"/>
    </div>
    <StylistClosetEditor v-if="showClosetEditor && !stylistClosetInProgress"/>
    <StylingInfo v-if="viewStylingInfo && !stylistClosetInProgress"/>
    <StylistCaseNote v-if="stylistCuratedCaseInProgress"/>
    <StylistClosetBuilder v-if="stylistClosetInProgress"/>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useStylingStore } from '@/stores/styling'
import { storeToRefs } from 'pinia'
import SequinButton from '@/components/global/sequin/SequinButton.vue'
import AppMessage from '@/components/global/sequin/AppMessage.vue'
import StylistClosetBuilder from '@/components/styling/StylistClosetBuilder.vue'
import StylistClosetEditor from '@/components/styling/StylistClosetEditor.vue'
import StylingInfo from '@/components/styling/StylingInfo.vue'
import StylingBaseButton from '@/components/styling/StylingBaseButton.vue'
import StylistCaseNote from '@/components/styling/StylistCuratedCaseInfo.vue'

const store = useStore()
const showStylingButton = computed(() => Object.keys(store.state.closet.activeStylistCuratedPackage).length > 0)

const { stylistCuratedCaseFull, stylistCuratedCaseInProgress, stylistClosetInProgress, recentClosets, errors } = storeToRefs(useStylingStore())
const { finishStylistCuratedCase, toggleStylistCuratedCaseInProgress, stopStyling, createNewStylistCloset, closeError } = useStylingStore()

const stylistCaseButton = computed(() => {
  const button = {}
  if (!stylistCuratedCaseInProgress.value) {
    button.text = 'Fill Stylist Case'
    button.action = toggleStylistCuratedCaseInProgress
  } else if (stylistCuratedCaseInProgress.value && stylistCuratedCaseFull.value) {
    button.text = 'Finish Stylist Case'
    button.action = finishStylistCuratedCase
  } else if (stylistCuratedCaseInProgress.value) {
    button.text = 'Pause Stylist Case'
    button.action = toggleStylistCuratedCaseInProgress
  }
  return button
})

const viewStylingInfo = ref(false)
function toggleStylingInfo () {
  viewStylingInfo.value = !viewStylingInfo.value
}

const showClosetEditor = ref(false)
function toggleShowClosetEditor () {
  showClosetEditor.value = !showClosetEditor.value
}
</script>

<style lang="scss" scoped>
.stop-button {
  display: flex;
  align-items: left;
  width: 100px;
  border: none;
  background: none;
  font-size: 0.7rem;
  padding: 0;
  cursor: pointer;
}

</style>
