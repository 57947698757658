<template>
  <div v-if="!closetId">
    <StylistClosetTypeSelector/>
  </div>
  <div
    v-else
    class="row">
    <div class="col-12 p-xs">
      <StylingInfo/>
    </div>
    <div class="col-8 p-xs">
      <StylistClosetPreview :closet-id="closetId"/>
    </div>
    <div class="col-4 p-xs">
      <StylistNote/>
    </div>
    <div class="col-12 p-xs">
      <StylingBaseButton
        ref="submitStyling"
        text="Complete Stylist Closet and Share with Client"
        :disabled="!closetId || !closetItems.length"
        @click="saveStylistCloset"/>
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from 'pinia'
import StylistClosetTypeSelector from '@/components/styling/StylistClosetTypeSelector.vue'
import StylingInfo from '@/components/styling/StylingInfo.vue'
import StylistClosetPreview from '@/components/styling/StylistClosetPreview.vue'
import StylistNote from '@/components/styling/StylistNote.vue'
import StylingBaseButton from '@/components/styling/StylingBaseButton.vue'
import { useStylingStore } from '@/stores/styling'

const store = useStylingStore()
const { closetItems, closetId } = storeToRefs(store)
const { saveStylistCloset } = store

</script>
